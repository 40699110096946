import * as React from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import ExchangeFlow from '../../../views/ExchangeFlow';
import RmaDetails from '../../Rma/RmaDetails';
import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    EnterpriseEnrollment,
    RmaEnums,
    RmaRequest
} from '@hero/hero-types';
import { AxiosErrorResponse } from '../../../types';
import RmaDetailsDataProviderV2 from '../../Rma/RmaDetails/RmaDetailsDataProviderV2';

interface ExchangeRmaProps {
    deviceSerial: string;
    isOrganizationMember?: boolean;
    membership?: BackofficeMembershipDetails;
    rma?: RmaRequest | null;
    userDetails?: BackofficeUserDeviceDetails;
    exchangeRmaError?: AxiosErrorResponse;
    rmaEnums?: RmaEnums;
    enrollment?: EnterpriseEnrollment;
    refetchRmaExchange?: () => void;
}

const ExchangeRma: React.FC<ExchangeRmaProps> = ({
    deviceSerial,
    isOrganizationMember = false,
    membership,
    rma,
    userDetails,
    exchangeRmaError,
    rmaEnums,
    enrollment,
    refetchRmaExchange
}) => {
    const [openExchangeFlow, setOpenExchangeFlow] = React.useState(false);
    const [openRmaDetails, setOpenRmaDetails] = React.useState(false);

    const handleOpenExchangeFlow = React.useCallback(() => {
        setOpenExchangeFlow(true);
    }, []);

    const handleCloseRmaDetails = React.useCallback(() => {
        setOpenRmaDetails(false);
    }, []);

    const handleOpenRmaDetails = React.useCallback(() => {
        setOpenRmaDetails(true);
    }, []);

    return (
        <>
            {!rma || rma?.rma_status === 6 ? (
                <Button size="small" onClick={handleOpenExchangeFlow}>
                    Create Exchange RMA
                </Button>
            ) : null}
            {rma?.rma_status === 0 && rma.rma_type === 2 ? (
                <Button size="small" onClick={handleOpenExchangeFlow}>
                    {`Continue Exchange`}
                </Button>
            ) : null}
            {rma?.rma_status !== 0 && rma?.rma_status !== 6 && rma?.rma_type === 2 ? (
                <Button size="small" onClick={handleOpenRmaDetails}>
                    {rma.id}
                </Button>
            ) : null}
            {openExchangeFlow ? (
                <ExchangeFlow
                    rma={rma || undefined}
                    enterpriseEnrollment={enrollment}
                    userDetails={userDetails}
                    membership={membership}
                    exchangeRmaError={exchangeRmaError}
                    externalControls={[openExchangeFlow, setOpenExchangeFlow]}
                    deviceSerial={deviceSerial}
                    isOrganizationMember={isOrganizationMember}
                    rmaEnums={rmaEnums}
                    onCloseModal={refetchRmaExchange}
                />
            ) : null}
            {rma && rma.id > 0 && openRmaDetails ? (
                <RmaDetailsDataProviderV2
                    rmaId={rma.id}
                    isOrganizationMember={isOrganizationMember}
                >
                    <RmaDetails
                        isOrganizationMember={isOrganizationMember}
                        onCloseModal={handleCloseRmaDetails}
                    />
                </RmaDetailsDataProviderV2>
            ) : null}
        </>
    );
};

export default ExchangeRma;
