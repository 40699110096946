import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { membershipPlansSelector } from '@hero/redux-data/backoffice/membershipPlans/selectors';

import getFilterOptions from '../../../../utils/getFilterOptions';

const useMembershipListOptions = () => {
    const plans = useSelector(membershipPlansSelector, shallowEqual);

    const planOptions: JSX.Element[] = useMemo(() => {
        const options: [string, string][] = plans.map(({ plan_id, name, description }) => [
            String(plan_id),
            description || name || ''
        ]);
        return getFilterOptions(options);
    }, [plans]);

    const statusOptions = useMemo(
        () =>
            getFilterOptions([
                ['not-activated', 'Not Activated Yet'],
                ['active', 'Active'],
                ['prepaid-expired', 'Prepaid Expired'],
                ['payment-failed', 'Payment Failed'],
                ['locked', 'Locked'],
                ['cancelled', 'Cancelled']
            ]),
        []
    );

    const activationTypeOptions: JSX.Element[] = useMemo(
        () =>
            getFilterOptions([
                ['1', 'User'],
                ['2', 'Automatic']
            ]),
        []
    );
    return { planOptions, statusOptions, activationTypeOptions };
};

export default useMembershipListOptions;
