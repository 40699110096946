import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
import { rmaRequest } from './queryKeys';
const useCreateRmaDraft = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}rma/request/`, attributes, params),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [rmaRequest]
            });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useCreateRmaDraft;
