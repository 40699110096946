import React from 'react';
import Logo from '@hero/branding/logo';
import MainSearch from '../../components/MainSearch';
import * as styles from './style.module.css';
import Section from '@hero/ui-kit/layout/Section';
import Link from '../../components/Link';
import HomeIllustration from './HomeIllustration';

const Home: React.FC = () => {
    return (
        <div style={{ background: '#f5f5f5', height: '100vh' }}>
            <Section centered noDefaultPadding>
                <Link to="/organizations" styles={{ marginTop: '8rem', marginBottom: '4rem' }}>
                    Go to organizations
                </Link>
                <Logo width="22rem" height="6rem" styles={{ marginBottom: '3.6rem' }} />
                <MainSearch
                    className={styles.search}
                    searchText="Enter email / serial # / external id"
                    centerSearchModal
                    modalWidth={60}
                    modalHeight={46}
                />
                <HomeIllustration className={styles.illustration} />
            </Section>
        </div>
    );
};

export default Home;
