import React from 'react';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

const DetailsChargeForm: React.FC = () => {
    const {
        formState: { isValid }
    } = useFormContext();

    const regularTopMargin = useComponentMargin({ marginTop: 'regular' });
    const smallTopMargin = useComponentMargin({ marginTop: 'small' });

    return (
        <div style={regularTopMargin}>
            <Input
                name="amount"
                displayName="Enter amount before tax"
                centerLabel
                styles={{ maxWidth: '28rem', ...smallTopMargin }}
                wrapperStyles={{ width: '28rem' }}
            />
            <Button disabled={!isValid} width="large" type="submit">
                Charge
            </Button>
        </div>
    );
};

export default DetailsChargeForm;
