import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../../components/Link';
import { EnterpriseMemberTableDataObject } from './repackTableData';

type Render = TableColumn<EnterpriseMemberTableDataObject>['render'];
type GetLinkRender = (organization_id: number) => Render;

const getEmailRender: GetLinkRender =
    (organization_id) =>
    ({ email, id }) =>
        (
            <Link to={`/organizations/${organization_id}/members/${id}/details`}>
                {email || '(no email)'}
            </Link>
        );

const serialRender: Render = ({ serial, device_id }) =>
    serial ? <Link to={`/devices/${device_id}`}>{serial}</Link> : '-';

type GetTableColumns = (organization_id: number) => TableColumn<EnterpriseMemberTableDataObject>[];
const getTableColumns: GetTableColumns = (organization_id) => [
    {
        colKey: 'email',
        name: 'Email',
        align: 'left',
        headAlign: 'left',
        render: getEmailRender(organization_id)
    },
    {
        colKey: 'name',
        name: 'Name'
    },
    {
        colKey: 'status',
        name: 'Membership\nStatus'
    },
    {
        colKey: 'serial',
        name: 'Serial #',
        render: serialRender
    },
    {
        colKey: 'delivered_at',
        name: 'Delivered\nDate',
        isSortable: true
    },
    {
        colKey: 'activated_at',
        name: 'Active\nDate',
        isSortable: true
    }
];

export default getTableColumns;
