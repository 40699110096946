import React from 'react';

import { CancellationType } from '@hero/hero-types';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

type Props = {
    type: CancellationType | undefined;
};

const ReviewEnterprise: React.FC<Props> = ({ type }) => {
    const cancellationType = type?.code;

    if (cancellationType === 'ec1') {
        return (
            <div>
                <H role="h6" centered>
                    Are you sure you want to cancel this membership?
                </H>
                <P centered>
                    Since Hero device order for this member has NOT yet been filled, after you
                    confirm this cancellation, device order should automatically be cancelled. It is
                    advisable that you confirm with Rush Order that this is the case.
                </P>
            </div>
        );
    }

    if (cancellationType === 'ec2') {
        return (
            <div>
                <H role="h6" centered>
                    Are you sure you want to cancel this membership?
                </H>
                <P centered>
                    Since Hero device order for this member has been filled, after you confirm this
                    cancellation, please proceed with creation of Cancellation RMA.
                </P>
            </div>
        );
    }

    if (cancellationType === 'ec3') {
        return (
            <div>
                <H role="h6" centered>
                    Are you sure you want to transfer this membership to B2C?
                </H>
                <P centered>
                    Once transfer is complete, Enterprise profile for this member will be cancelled
                    and new B2C membership profile created. This change cannot be undone.
                </P>
            </div>
        );
    }

    return null;
};

export default ReviewEnterprise;
