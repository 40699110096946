import React from 'react';
import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import { Reactivate } from './steps';

interface ContinuerReactivationProps {}

const ContinueReactivation: React.FC<ContinuerReactivationProps> = () => {
    const [showModal, setShowModal] = React.useState(false);
    const handleClose = () => setShowModal(false);

    return (
        <>
            <Button variant="outline" onClick={() => setShowModal(true)}>
                Continue Reactivation
            </Button>
            <Modal externalControls={[showModal, setShowModal]} isCancelable onClose={handleClose}>
                <>
                    <P size="large" centered>
                        Are you sure you want to continue with reactivation? Make sure to process
                        the charge/refund manually in Stripe
                    </P>
                    <Reactivate hideDetails />
                </>
            </Modal>
        </>
    );
};

export default ContinueReactivation;
