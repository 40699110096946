import React, { useMemo, useCallback, useState } from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import H from '@hero/ui-kit/typography/H';

import InlineLoader from '../../../components/InlineLoader';
import { RmaTableDataObject } from './utils/useTableDataRepack';
import useListStyles from './utils/useListStyles';
import useRmaData from './utils/useRmaData';

import RmaListFiltering from './RmaListFiletering';
import getTableColumns, { ActionHandler } from './utils/getTableColumns';

import RmaDetails from '../RmaDetails';
import CancellationFlow from '../../../views/CancellationFlow';
import ExchangeFlow from '../../../views/ExchangeFlow';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import { shallowEqual, useSelector } from 'react-redux';
import { isRmaAdminSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import DefaultLayout from '../../../components/DefaultLayout';
import RmaDetailsDataProviderV2 from '../RmaDetails/RmaDetailsDataProviderV2';

const RmaList: React.FC = () => {
    const {
        isFetchingRmaRequests,
        isFetchingEnums,
        tableData,
        pagerProps,
        sort,
        handleSort,
        fetchRmaList
    } = useRmaData();
    const { innerWrapPadding, tableWrapStyles, headerWrapStyles } = useListStyles();
    const isRmaAdmin = useSelector(isRmaAdminSelector, shallowEqual);

    const [selectedRmaId, setSelectedRmaId] = useState<number | null>(null);
    const [openRmaDetails, setOpenRmaDetails] = useState(false);

    const [openCancellationFlow, setOpenCancellationFlow] = useState(false);
    const [openExchangeFlow, setOpenExchangeFlow] = useState(false);

    const handleViewDetailsAction: ActionHandler = useCallback(({ id, rma_type, rma_status }) => {
        setSelectedRmaId(id);

        if (rma_status?.toLowerCase() === 'draft') {
            if (rma_type?.toLowerCase() === 'cancellation') {
                setOpenCancellationFlow(true);
            }
            if (rma_type?.toLowerCase() === 'exchange') {
                setOpenExchangeFlow(true);
            }
        } else {
            setOpenRmaDetails(true);
        }
    }, []);

    const handleCloseModal = useCallback(() => {
        setSelectedRmaId(null);
        setOpenRmaDetails(false);
    }, []);

    const tableColumns = useMemo(
        () => getTableColumns(handleViewDetailsAction),
        [handleViewDetailsAction]
    );

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">RMA</H>
                    {isRmaAdmin ? (
                        <LinkButton to="/rma/create" noDefaultMargin>
                            Create RMA
                        </LinkButton>
                    ) : null}
                </div>
                <div style={innerWrapPadding}>
                    {isFetchingEnums ? (
                        <InlineLoader />
                    ) : (
                        <>
                            <RmaListFiltering />
                            <div style={tableWrapStyles}>
                                <Table<RmaTableDataObject>
                                    columns={tableColumns}
                                    data={tableData}
                                    rowKey="id"
                                    emptyValues="-"
                                    isLoading={isFetchingRmaRequests}
                                    sortColumn={sort.sort_by}
                                    sortDirection={sort.sort_dir}
                                    onColumnSort={handleSort}
                                    pagerProps={pagerProps}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Section>
            {selectedRmaId && openRmaDetails ? (
                <RmaDetailsDataProviderV2 rmaId={selectedRmaId}>
                    <RmaDetails onCloseModal={handleCloseModal} />
                </RmaDetailsDataProviderV2>
            ) : null}
            {selectedRmaId && openCancellationFlow ? (
                <RmaDetailsDataProviderV2 rmaId={selectedRmaId}>
                    <CancellationFlow
                        isCreateNewRma
                        externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                        onCompleteCallback={fetchRmaList}
                        disableCreateRma
                    />
                </RmaDetailsDataProviderV2>
            ) : null}
            {selectedRmaId && openExchangeFlow ? (
                <RmaDetailsDataProviderV2 rmaId={selectedRmaId}>
                    <ExchangeFlow
                        externalControls={[openExchangeFlow, setOpenExchangeFlow]}
                        onCompleteCallback={fetchRmaList}
                    />
                </RmaDetailsDataProviderV2>
            ) : null}
        </DefaultLayout>
    );
};

export default RmaList;
