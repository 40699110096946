import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchUserAdherence, userAdherenceReset } from '@hero/redux-data/backoffice/userAdherence/actionCreators';
import { isUserAdherenceLoadingSelector, userAdherencePaginationSelector, userAdherenceListSelector } from '@hero/redux-data/backoffice/userAdherence/selectors';
import usePagerProps from '../../../../hooks/usePagerProps';
import useTableSortQuery from '../../../../hooks/useTableSortQuery';
import getTableColumns from './utils/getTableColumns';
import repackTableData from './utils/repackTableData';
const useAdherenceListData = (id) => {
    const dispatch = useDispatch();
    const isLoadingUserAdherence = useSelector(isUserAdherenceLoadingSelector);
    const userAdherenceList = useSelector(userAdherenceListSelector, shallowEqual);
    const paginationData = useSelector(userAdherencePaginationSelector, shallowEqual);
    const [sort, handleSort] = useTableSortQuery();
    const tableColumns = useMemo(() => getTableColumns(), []);
    const tableData = useMemo(() => repackTableData(userAdherenceList), [userAdherenceList]);
    const pagerProps = usePagerProps(paginationData);
    const query = window.location.search;
    useEffect(() => {
        const definedQuery = query?.length ? `${query}&user_id=${id}` : `?user_id=${id}`;
        dispatch(fetchUserAdherence(definedQuery));
    }, [dispatch, query, id]);
    useEffect(() => {
        return () => {
            dispatch(userAdherenceReset());
        };
    }, [dispatch]);
    return {
        dispatch,
        tableData,
        tableColumns,
        isLoadingUserAdherence,
        sort,
        handleSort,
        pagerProps
    };
};
export default useAdherenceListData;
