import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import Table from '@hero/ui-kit/tables/Table';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import H from '@hero/ui-kit/typography/H';

import envVars from '../../../constants/envVars';
import DismissAlertModal from './DismissAlertModal';

import { MembershipAlertTableDataObject } from './utils/repackTableData';
import useMembershipMonitoringData from './useMembershipMonitoringData';
import useMembershipMonitoringStyles from './useMembershipMonitoringStyles';
import MembershipMonitoringFiltering from './MembershipMonitoringFiltering';
import AlertNoteModal from './AlertNoteModal';
import OutreachLegend from './OutreachLegend';
import TabBar from '../TabBar';
import DefaultLayout from '../../../components/DefaultLayout';

const MembershipMonitoring: React.FC = () => {
    const { innerWrapPadding, headerWrapStyles, tableWrapStyles } = useMembershipMonitoringStyles();
    const {
        isSavingAlert,
        isFetchingList,
        selectedAlert,
        handleSaveNoteConfirm,
        handleActionCancel,
        handleDismissConfirm,
        tableColumns,
        tableData,
        sort,
        handleSort,
        pagerProps
    } = useMembershipMonitoringData();

    return isSavingAlert ? (
        <Loader />
    ) : (
        <DefaultLayout>
            {selectedAlert && selectedAlert.action === 'update' && (
                <AlertNoteModal
                    onConfirm={handleSaveNoteConfirm}
                    onCancel={handleActionCancel}
                    alertId={selectedAlert.subscription_id}
                />
            )}
            {selectedAlert && selectedAlert.action === 'dismiss' && (
                <DismissAlertModal
                    onConfirm={handleDismissConfirm}
                    onCancel={handleActionCancel}
                    alertId={selectedAlert.subscription_id}
                />
            )}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">Membership</H>
                    <LinkButton
                        to={`${envVars.STRIPE_DASHBOARD_BASE_URL}/customers`}
                        noDefaultMargin
                    >
                        Go To Stripe
                    </LinkButton>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    <MembershipMonitoringFiltering />
                    <OutreachLegend />
                    <div style={tableWrapStyles}>
                        <Table<MembershipAlertTableDataObject>
                            columns={tableColumns}
                            data={tableData}
                            rowKey="subscription_id"
                            emptyValues="-"
                            isLoading={isFetchingList}
                            sortColumn={sort.sort_by}
                            sortDirection={sort.sort_dir}
                            onColumnSort={handleSort}
                            pagerProps={pagerProps}
                        />
                    </div>
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default MembershipMonitoring;
