import React from 'react';

import SearchForm from '../../../../components/SearchForm';
import PageSizeForm from '../../../../components/PageSizeForm';
import FiltersToggle from '../../../../components/FiltersToggle';
import useFilters from '../../../../hooks/useFiltersToggle';

import MembershipFilterForm from './MembershipFilterForm';

import { headerWrapStyles, searchWrapStyles, filterToggleStyles } from './styles';

const MembershiListFiltering: React.FC = () => {
    const [areFiltersExpanded, toggleFiltersExpansion] = useFilters();

    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <SearchForm placeholder="Search by email or serial #" />
                    <FiltersToggle
                        isExpanded={areFiltersExpanded}
                        onClick={toggleFiltersExpansion}
                        styles={filterToggleStyles}
                    />
                </div>
                <PageSizeForm />
            </div>
            {areFiltersExpanded && <MembershipFilterForm />}
        </>
    );
};

export default MembershiListFiltering;
