const modifyForCF = (env) => {
    if (process.env.REACT_APP_CF_PAGES === '1') {
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'master') {
            return process.env[env] || '';
        }
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'stg-02') {
            return process.env[env + '_STG'] || '';
        }
        if (process.env.REACT_APP_CF_PAGES_BRANCH === 'stg-03') {
            return process.env[env + '_STG03'] || '';
        }
        return process.env[env + '_DEV'] || '';
    }
    return process.env[env] || '';
};
const envVars = {
    API_ENDPOINT: modifyForCF('REACT_APP_API_CLOUD_HOSTNAME'),
    API_CLOUD_HOSTNAME: modifyForCF('REACT_APP_API_CLOUD_HOSTNAME'),
    API_CLOUD_SOCKET: modifyForCF('REACT_APP_API_CLOUD_SOCKET'),
    API_COMMERCE_HOSTNAME: modifyForCF('REACT_APP_API_COMMERCE_HOSTNAME'),
    API_STOREFRONT_HOSTNAME: modifyForCF('REACT_APP_API_STOREFRONT_HOSTNAME'),
    API_ID_HOSTNAME: modifyForCF('REACT_APP_API_ID_HOSTNAME'),
    API_ID_CLIENT_ID: modifyForCF('REACT_APP_API_ID_CLIENT_ID'),
    API_RTM_HOSTNAME: modifyForCF('REACT_APP_API_RTM_HOSTNAME'),
    TRACKING_TOKEN: modifyForCF('REACT_APP_TRACKING_TOKEN'),
    SENTRY_ENVIRONMENT: modifyForCF('REACT_APP_SENTRY_ENVIRONMENT'),
    SENTRY_DSN: modifyForCF('REACT_APP_SENTRY_DSN'),
    BACKOFFICE_ENVIRONMENT: modifyForCF('REACT_APP_BACKOFFICE_ENVIRONMENT'),
    API_FILL_HOSTNAME: modifyForCF('REACT_APP_API_FILL_HOSTNAME'),
    SHOW_RTM_DISCHARGE_FEATURE: modifyForCF('REACT_APP_SHOW_RTM_DISCHARGE_FEATURE').toLowerCase() === 'true',
    // BO should get this at some point
    STRIPE_DASHBOARD_BASE_URL: process.env.REACT_APP_STRIPE_DASHBOARD_BASE_URL || '',
    API_PUBLIC_USER: process.env.REACT_APP_API_PUBLIC_USER || '',
    API_PUBLIC_PASS: process.env.REACT_APP_API_PUBLIC_PASS || '',
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY || '',
    GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
    ANDROID_STORE_URL: process.env.REACT_APP_ANDROID_STORE_URL || '',
    IOS_STORE_URL: process.env.REACT_APP_IOS_STORE_URL || '',
    HERO_TERMS_URL: process.env.REACT_APP_HERO_TERMS_URL || '',
    HERO_PRIVACY_POLICY_URL: process.env.REACT_APP_HERO_PRIVACY_POLICY_URL || '',
    HERO_GET_STARTED_URL: process.env.REACT_APP_HERO_GET_STARTED_URL || '',
    HERO_SUPPORT_PHONE_URL: process.env.REACT_APP_HERO_SUPPORT_PHONE_URL || '',
    PRISMIC_URL: process.env.REACT_APP_PRISMIC_URL || '',
    VERSION: process.env.REACT_APP_VERSION || '',
    CLIENT_TYPE: process.env.REACT_APP_CLIENT_TYPE || '',
    DEV_MODE: process.env.REACT_APP_DEV_MODE === 'true',
    IS_BACKOFFICE_APP: true,
    AUTO_LOGOUT_SECONDS: process.env.REACT_APP_AUTO_LOGOUT_SECONDS
        ? +process.env.REACT_APP_AUTO_LOGOUT_SECONDS
        : 0
};
export default envVars;
