import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { prospectSignupErrorSelector } from '@hero/redux-data/backoffice/prospectSignup/selectors';
import { useNotifications } from '../../context/notification';
const useProspectSignupFailAlert = () => {
    const { error, errorMessage, originalMessage } = useSelector(prospectSignupErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage === 'error_creating_checkout'
                    ? errorMessage || 'Error initiating order.'
                    : originalMessage?.replace(/[_-]/g, ' ') ||
                        'Validation error. Please check input data.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage, originalMessage]);
};
export default useProspectSignupFailAlert;
