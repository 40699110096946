import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import refreshTokenMiddleware from '@hero/hero-auth/refreshTokenMiddleware';
import constAccessParams from '../constants/envVars';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
const sagaMiddleware = createSagaMiddleware();
const refreshDTokenMiddleware = refreshTokenMiddleware(constAccessParams.API_ID_HOSTNAME, constAccessParams.API_ID_CLIENT_ID);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(refreshDTokenMiddleware, sagaMiddleware));
const store = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga, constAccessParams);
export { store };
