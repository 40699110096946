import React from 'react';
import { useDispatch } from 'react-redux';

import { EnterpriseEnrollment } from '@hero/hero-types';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import { getUserDeviceDetails } from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';

import useElectionComplete from '../api/useElectionComplete';
import useElectionSubmitLead, {
    Response as SubmitLeadResponse
} from '../api/useElectionSubmitLead';
import { FormParams as ElectionFormParams } from '../steps/Election';
import { FormParams as InsuranceFormParams } from '../steps/Insurance';
import { FormParams as SubmitFormParams } from '../steps/Submit';
import { FormParams as UserRoleFormParams } from '../steps/UserRole';

export type Step = 'UserRole' | 'Insurance' | 'Election' | 'Submit' | 'Success';

type FormValues = Partial<
    UserRoleFormParams & InsuranceFormParams & ElectionFormParams & SubmitFormParams
>;

type ContextType = {
    enrollment: EnterpriseEnrollment;
    step: Step;
    formValues: FormValues;
    providers: SubmitLeadResponse['data']['providers'];
    changeStep: (step: Step) => void;
    handleSetFormValues: (values: FormValues) => void;
    handleSubmitLead: (values: FormValues) => void;
    handleComplete: (values: FormValues) => void;
    handleResetForm: () => void;
    onCloseModal?: () => void;
    error?: string;
};

const NewProviderElectionContext = React.createContext<ContextType | undefined>(undefined);

export const useNewProviderElection = () => {
    const ctx = React.useContext(NewProviderElectionContext);

    if (ctx === undefined) {
        throw new Error(
            `'NewProviderElection' must be used within a 'NewProviderElectionProvider'`
        );
    }

    return ctx;
};

type INewProviderElection = {
    children: React.ReactNode;
    enrollment: EnterpriseEnrollment;
    onCloseModal?: () => void;
};

export const NewProviderElection = ({
    children,
    enrollment,
    onCloseModal
}: INewProviderElection) => {
    const [step, setStep] = React.useState<Step>('UserRole');
    const [formValues, setFormValues] = React.useState<FormValues>({});
    const [leadId, setLeadId] = React.useState<SubmitLeadResponse['data']['lead_id']>();
    const [providers, setProviders] = React.useState<SubmitLeadResponse['data']['providers']>([]);
    const submitLead = useElectionSubmitLead();
    const complete = useElectionComplete();
    const dispatch = useDispatch();

    React.useEffect(() => {
        enrollment.member?.id &&
            dispatch(getUserDeviceDetails({ user_id: enrollment.member?.hero_user_id }));
    }, [enrollment.member?.id, dispatch]);

    const changeStep = React.useCallback((step: Step) => {
        setStep(step);
    }, []);

    const handleSetFormValues = React.useCallback((values: FormValues): FormValues => {
        let newValues = {};
        setFormValues((prevState) => {
            newValues = { ...prevState, ...values };
            return newValues;
        });

        return newValues;
    }, []);

    const handleSubmitLead = React.useCallback(
        (payload: FormValues) => {
            const formData = handleSetFormValues(payload);

            submitLead({
                user_id: enrollment.member?.hero_user_id || 1,
                is_caregiver: formData.user_role === 'cg' || false,
                insurance_type: formData.insurance_type || '',
                ...(formData.insurance_policy_id
                    ? { insurance_policy_id: formData.insurance_policy_id }
                    : {}),
                ...(formData.insurance_name
                    ? { insurance_carrier_by_user: formData.insurance_name }
                    : {}),
                ...(formData.medigap_insurance_name
                    ? { medigap_insurance_name: formData.medigap_insurance_name }
                    : {}),
                ...(formData.medigap_insurance_policy_id
                    ? { medigap_insurance_policy_id: formData.medigap_insurance_policy_id }
                    : {})
            }).then((res) => {
                setLeadId(res.data.data.lead_id);
                setProviders(res.data.data.providers);
                changeStep('Election');
            });
        },
        [submitLead]
    );

    const handleComplete = React.useCallback(
        (payload: FormValues) => {
            const formData = handleSetFormValues(payload);

            complete({
                user_id: enrollment.member?.hero_user_id || 1,
                lead_id: leadId || '',
                provider_id: formData.provider || '',
                is_tcpa_opt_in: true,
                contact_phone: formatPhoneNumber(formData.phone_number || '') || '',
                email: formData.email || ''
            }).then(() => {
                changeStep('Success');
            });
        },
        [submitLead]
    );

    const handleResetForm = React.useCallback(() => {
        setFormValues({});
        setStep('UserRole');
    }, []);

    return (
        <NewProviderElectionContext.Provider
            value={{
                enrollment,
                step,
                formValues,
                providers,
                changeStep,
                handleSetFormValues,
                handleSubmitLead,
                handleComplete,
                handleResetForm,
                onCloseModal,
                error: 'test'
            }}
        >
            {children}
        </NewProviderElectionContext.Provider>
    );
};

export default NewProviderElection;
