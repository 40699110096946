import React from 'react';

import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

import { useNewProviderElection } from '../context/NewProviderElectionProvider';

type Props = {
    closeModal?: () => void;
};

const Success: React.FC<Props> = ({ closeModal }) => {
    const { formValues, providers } = useNewProviderElection();

    return (
        <Section noDefaultPadding>
            <div>
                <H role="h5">Election Complete</H>
                <P>
                    Tell the person on the phone: Your application was forwarded to{' '}
                    {providers.find((p) => p.provider_id === formValues.provider)?.provider_name}.
                    Their specialist will contact you shortly to review your insurance coverage and
                    complete your enrollment.
                </P>
            </div>

            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" variant="inverted" onClick={closeModal}>
                    Ok
                </Button>
            </Section>
        </Section>
    );
};

export default Success;
