import * as React from 'react';
import InlineError from '../InlineError';
import { AxiosError } from 'axios';

interface ProviderApiErrorProps {
    error?: AxiosError | null;
    centered?: boolean;
    styles?: React.CSSProperties;
}

const ProviderApiError: React.FC<ProviderApiErrorProps> = ({ centered = false, error, styles }) => {
    return error?.response?.data && Array.isArray(error.response.data)
        ? error.response.data[0]
              .split(',')
              .map((message: string) => (
                  <InlineError message={message} centered={centered} styles={styles} />
              ))
        : null;
};

export default ProviderApiError;
