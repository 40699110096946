import { useState, useCallback } from 'react';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import trimDeep from '@hero/hero-utils/trimDeep';
import { findState } from './useAdditionalFields';
export const useStripeFields = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardNumberError, updateCardNumberError] = useState('');
    const [cardExpError, updateCardExpError] = useState('');
    const [cardCvcError, updateCardCvcError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleCardNumberChange = useCallback((e) => updateCardNumberError(e.error?.message || ''), []);
    const handleCardExpChange = useCallback((e) => updateCardExpError(e.error?.message || ''), []);
    const handleCardCvcChange = useCallback((e) => updateCardCvcError(e.error?.message || ''), []);
    const handleSubmit = useCallback((additionalValues, validateAdditionalValues, onSubmit) => async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!stripe || !elements) {
            setIsLoading(false);
            return;
        }
        const hasErrors = validateAdditionalValues();
        if (hasErrors) {
            setIsLoading(false);
            return;
        }
        const additionalValuesTrimmed = trimDeep(additionalValues);
        const { name_on_card: name, address1: address_line1, address2: address_line2, city: address_city, province: address_state, zip_code: address_zip } = additionalValuesTrimmed;
        const cardElement = elements.getElement(CardNumberElement);
        const { token, error } = await stripe.createToken(cardElement, {
            name,
            address_line1,
            address_line2,
            address_city,
            address_state,
            address_zip,
            address_country: 'US'
        });
        const { province, ...rest } = additionalValuesTrimmed;
        const state = findState(province);
        !error &&
            token?.id &&
            state &&
            onSubmit({ stripe_token: token.id, province: state.code, ...rest });
        setIsLoading(false);
    }, [stripe, elements]);
    return {
        cardNumberError,
        cardExpError,
        cardCvcError,
        handleCardNumberChange,
        handleCardExpChange,
        handleCardCvcChange,
        handleSubmit,
        isLoading
    };
};
export default useStripeFields;
