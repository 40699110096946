import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { membershipAlertSelector } from '@hero/redux-data/backoffice/membershipAlerts/selectors';
import Takeover from '@hero/ui-kit/components/Takeover';
import Span from '@hero/ui-kit/typography/Span';
import P from '@hero/ui-kit/typography/P';

type Props = {
    onConfirm: (subscription_id: number) => void;
    onCancel: () => void;
    alertId: number;
};

const DismissAlertModal: React.FC<Props> = ({ onConfirm, onCancel, alertId }) => {
    const alert = useSelector(membershipAlertSelector(alertId), shallowEqual);
    const email = alert?.customer_email || '';

    const handleConfirm = useCallback(() => {
        onConfirm(alertId);
    }, [onConfirm, alertId]);

    return alert ? (
        <Takeover
            type="warn"
            title="Dismiss Alert"
            actions={[{ label: 'Remove', action: handleConfirm }, { label: 'Cancel' }]}
            onClose={onCancel}
            isCancelable
        >
            <P size="large" centered>
                Are you sure you want to remove <Span strong>{email}</Span> from monitoring?
            </P>
        </Takeover>
    ) : null;
};

export default DismissAlertModal;
