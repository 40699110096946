import useMembershipPlanUpdateSuccessAlert from './useMembershipPlanUpdateSuccessAlert';
import useUserCreditAddSuccessAlert from './useUserCreditAddSuccessAlert';
import useUserPaymentMethodUpdateSuccessAlert from './useUserPaymentMethodUpdateSuccessAlert';
import useMembershipReactivateSuccessAlert from './useMembershipReactivateSuccessAlert';
import useMembershipAlertSaveSuccessAlert from './useMembershipAlertSaveSuccessAlert';
import useEnterpriseEnrollmentVerifySuccessAlert from './useEnterpriseEnrollmentVerifySuccessAlert';
import useProspectSignupSuccessAlert from './useProspectSignupSuccessAlert';
import useMembershipUserWarnAlert from './useMembershipUserWarnAlert';
import useProspectPromoCodeWarnAlert from './useProspectPromoCodeWarnAlert';
import useOrderCancelFailAlert from './useOrderCancelFailAlert';
import useMembershipDetailsFailAlert from './useMembershipDetailsFailAlert';
import useUserDeviceDetailsFailAlert from './useUserDeviceDetailsFailAlert';
import useUserCreditFailAlert from './useUserCreditFailAlert';
import useMembershipPlansFailAlert from './useMembershipPlansFailAlert';
import useMembershipPlanUpdateFailAlert from './useMembershipPlanUpdateFailAlert';
import useUserPaymentMethodUpdateFailAlert from './useUserPaymentMethodUpdateFailAlert';
import useMembershipReactivateFailAlert from './useMembershipReactivateFailAlert';
import useMembershipListFailAlert from './useMembershipListFailAlert';
import useMembershipAlertListFailAlert from './useMembershipAlertListFailAlert';
import useMembershipAlertSaveFailAlert from './useMembershipAlertSaveFailAlert';
import useOrganizationFailAlert from './useOrganizationFailAlert';
import useEnterpriseMemberDetailsFailAlert from './useEnterpriseMemberDetailsFailAlert';
import useEnterpriseMemberListFailAlert from './useEnterpriseMemberListFailAlert';
import useEnterpriseEnrollmentListFailAlert from './useEnterpriseEnrollmentListFailAlert';
import useEnterpriseEnrollmentVerifyFailAlert from './useEnterpriseEnrollmentVerifyFailAlert';
import useProspectSignupFailAlert from './useProspectSignupFailAlert';
import useEnterpriseEnrollmentSubmitFailAlert from './useEnterpriseEnrollmentSubmitFailAlert';
import useDeviceRestockingCheckFailAlert from './useDeviceRestockingCheckFailAlert';
import useRmaSaveSuccessAlert from './useRmaSaveSuccessAlert';
import useRmaSaveFailAlert from './useRmaSaveFailAlert';
// TODO this approach is abandoned with the new design, no more alert bar -
// messages are shown inline or in modals, so remove one by one...
const useApiAlerts = () => {
    // success alerts
    useMembershipPlanUpdateSuccessAlert();
    useUserCreditAddSuccessAlert();
    useUserPaymentMethodUpdateSuccessAlert();
    useMembershipReactivateSuccessAlert();
    useMembershipAlertSaveSuccessAlert();
    useEnterpriseEnrollmentVerifySuccessAlert();
    useProspectSignupSuccessAlert();
    useRmaSaveSuccessAlert();
    // warn alerts
    useMembershipUserWarnAlert();
    useProspectPromoCodeWarnAlert();
    // fail alerts
    useOrderCancelFailAlert();
    useMembershipDetailsFailAlert();
    useUserDeviceDetailsFailAlert();
    useUserCreditFailAlert();
    useMembershipPlansFailAlert();
    useMembershipPlanUpdateFailAlert();
    useUserPaymentMethodUpdateFailAlert();
    useMembershipReactivateFailAlert();
    useMembershipListFailAlert();
    useMembershipAlertListFailAlert();
    useMembershipAlertSaveFailAlert();
    useOrganizationFailAlert();
    useEnterpriseMemberDetailsFailAlert();
    useEnterpriseMemberListFailAlert();
    useEnterpriseEnrollmentListFailAlert();
    useEnterpriseEnrollmentVerifyFailAlert();
    useProspectSignupFailAlert();
    useEnterpriseEnrollmentSubmitFailAlert();
    useDeviceRestockingCheckFailAlert();
    useRmaSaveFailAlert();
};
export default useApiAlerts;
