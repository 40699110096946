import { all } from 'redux-saga/effects';
import { watchGetUserDeviceDetailsCalls } from '@hero/redux-data/backoffice/userDeviceDetails/saga';
import { watchFetchUserAdherenceCalls } from '@hero/redux-data/backoffice/userAdherence/saga';
import { watchUserResendActivationCodeCalls } from '@hero/redux-data/backoffice/userResendActivationCode/saga';
import { watchUserSetPhoneCalls } from '@hero/redux-data/backoffice/userSetPhone/saga';
import { watchUserSetEnabledFeaturesCalls } from '@hero/redux-data/backoffice/userSetEnabledFeatures/saga';
import { watchUserSetBlockedStatusCalls } from '@hero/redux-data/backoffice/userSetBlockedStatus/saga';
import { watchUserSentTestNotificationCalls } from '@hero/redux-data/backoffice/userSendTestNotification/saga';
import { watchUserDeletePhoneCalls } from '@hero/redux-data/backoffice/userDeletePhone/saga';
import { watchUserConfirmPhoneCalls } from '@hero/redux-data/backoffice/userConfirmPhone/saga';
import { watchFetchMembershipsCalls } from '@hero/redux-data/backoffice/memberships/saga';
import { watchGetMembershipDetailsCalls } from '@hero/redux-data/backoffice/membershipDetails/saga';
import { watchFetchMembershipPlansCalls } from '@hero/redux-data/backoffice/membershipPlans/saga';
import { watchSetMembershipPlanCalls } from '@hero/redux-data/backoffice/membershipPlan/saga';
import { watchGetUserCreditCalls, watchAddUserCreditCalls } from '@hero/redux-data/backoffice/userCredit/saga';
import { watchUpdateUserPaymentMethodCalls } from '@hero/redux-data/backoffice/userPaymentMethod/saga';
import { watchGetCancellationOptionsCalls } from '@hero/redux-data/backoffice/cancellationOptions/saga';
import { watchGetRefundPreviewCalls } from '@hero/redux-data/backoffice/refundPreview/saga';
import { watchGetCancellationFeePreviewCalls } from '@hero/redux-data/backoffice/cancellationFeePreview/saga';
import { watchCancelMembershipCalls } from '@hero/redux-data/backoffice/membershipCancellation/saga';
import { watchGetCancellationDetailsCalls } from '@hero/redux-data/backoffice/cancellationDetails/saga';
import { watchFetchMembershipAlertsCalls } from '@hero/redux-data/backoffice/membershipAlerts/saga';
import { watchSaveMembershipAlertNoteCalls, watchDismissMembershipAlertCalls } from '@hero/redux-data/backoffice/membershipAlert/saga';
import { watchGetOrganizationDetailsCalls } from '@hero/redux-data/backoffice/organization/saga';
import { watchSubmitEnterpriseEnrollmentCalls } from '@hero/redux-data/backoffice/enterpriseEnrollmentSubmission/saga';
import { watchFetchEnterpriseEnrollmentsForVerificationCalls } from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/saga';
import { watchVerifyEnterpriseEnrollmentCalls } from '@hero/redux-data/backoffice/enterpriseEnrollmentVerification/saga';
import { watchFetchEnterpriseMembersCalls } from '@hero/redux-data/backoffice/enterpriseMembers/saga';
import { watchGetEnterpriseMemberDetailsCalls } from '@hero/redux-data/backoffice/enterpriseMemberDetails/saga';
import { watchGetEnterpriseCancellationOptionsCalls } from '@hero/redux-data/backoffice/enterpriseCancellationOptions/saga';
import { watchCancelEnterpriseMembershipCalls } from '@hero/redux-data/backoffice/enterpriseMembershipCancellation/saga';
import { watchGetEnterpriseCancellationDetailsCalls } from '@hero/redux-data/backoffice/enterpriseCancellationDetails/saga';
import { watchReactivateMembershipCalls } from '@hero/redux-data/backoffice/membershipReactivation/saga';
import { watchGetMembershipReactivationDetailsCalls } from '@hero/redux-data/backoffice/membershipReactivationDetails/saga';
import { watchReactivateMembershipRefundChargeCalls } from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/saga';
import { watchSignUpProspectCalls } from '@hero/redux-data/backoffice/prospectSignup/saga';
import { watchGetProspectSignupSurveyOptionsCalls } from '@hero/redux-data/backoffice/prospectSignupSurveyOptions/saga';
import { watchGetRmaEnumsCalls } from '@hero/redux-data/backoffice/rmaEnums/saga';
import { watchFetchRmaRequestsCalls } from '@hero/redux-data/backoffice/rmaRequests/saga';
import { watchRunDeviceRestockingCheckCalls } from '@hero/redux-data/backoffice/deviceRestockingCheck/saga';
import { watchGetRmaRequestCalls, watchCreateDraftRmaRequestCalls, watchUpdateRmaRequestCalls, watchSetRmaRequestPickUpCalls, watchCreateExchangeFulfillmentCalls, watchVoidRmaRequestCalls, watchGetExchangeRmaRequestCalls } from '@hero/redux-data/backoffice/rmaRequest/saga';
import { watchGetCancellationFeeChargeAttemptPreviewCalls } from '@hero/redux-data/backoffice/cancellationFeeChargeAttemptPreview/saga';
import { watchCancellationAttemptFeeChargeCalls } from '@hero/redux-data/backoffice/cancellationAttemptFeeCharge/saga';
import { watchGetPlaceSuggestionsCalls } from '@hero/redux-data/googlePlaceSuggestions/saga';
import { watchGetPlaceDetailsCalls } from '@hero/redux-data/googlePlaceDetails/saga';
import { watchCreateRmaRequestCalls } from '@hero/redux-data/backoffice/rmaRequestSave/saga';
import { watchFetchUsersCalls } from '@hero/redux-data/backoffice/users/saga';
import { watchFetchPatientsCalls } from '@hero/redux-data/backoffice/patients/saga';
import { watchFetchDevicesCalls } from '@hero/redux-data/backoffice/devices/saga';
import { watchFetchDeviceDetailsCalls, watchSetDemoDeviceCalls, watchSetAllowReprogramming, watchRemoveDeviceFromOrganizationCalls, watchRemoveTransferFlagCalls, watchDeleteDeviceCalls } from '@hero/redux-data/backoffice/deviceDetails/saga';
import { watchFetchUnresolvedOtcPricesCalls, watchRemoveUnresolvedOtcPriceCalls, watchSetUnresolvedOtcPriceCalls } from '@hero/redux-data/backoffice/unresolvedOtcPrices/saga';
import { watchActivateMembershipCalls } from '@hero/redux-data/backoffice/activateMembership/saga';
import { watchFetchMitigationListCalls } from '@hero/redux-data/backoffice/mitigationList/saga';
import { watchFetchMitigationSaveCalls } from '@hero/redux-data/backoffice/mitigationSave/saga';
import { watchGetPatientDetailsCalls, watchUpdatePatientDetailsCalls } from '@hero/redux-data/backoffice/patientDetails/saga';
import { watchLoginDoneCalls, watchRefreshTokenCalls, watchLogoutCalls } from '@hero/redux-data/auth/saga';
import { watchLoginCalls, watchUserStatusCalls, watchGetNewUserStatusCalls } from '@hero/redux-data/userLogin/saga';
import { watchFetchLastHazardousDrugsCalls, watchRunHazardousDrugsCalls } from '@hero/redux-data/backoffice/hazardousDrugsCheck/saga';
import { watchGetOrganizationListCalls } from '@hero/redux-data/backoffice/oldOrganizationList/saga';
import { watchCreateOrganizationCalls } from '@hero/redux-data/backoffice/createOrganization/saga';
import { watchOrganizationUpdateCalls } from '@hero/redux-data/backoffice/organizationUpdate/saga';
import { watchFetchOrganizationDetailsCalls } from '@hero/redux-data/backoffice/organizationDetails/saga';
import { watchEnrollmentCreateCalls } from '@hero/redux-data/backoffice/enrollmentCreate/saga';
import { watchSaveRtmCalls } from '@hero/redux-data/backoffice/rtmSave/saga';
import { watchGetRtmStatusDetailsCalls } from '@hero/redux-data/backoffice/rtmStatus/saga';
import { watchPatientInsuranceCardAddCalls, watchPatientInsuranceCardUpdateCalls } from '@hero/redux-data/backoffice/patientInsuranceCard/saga';
import { watchFetchTransferRequestsCalls } from '@hero/redux-data/backoffice/transferRequestList/saga';
import { watchUpdateMembershipFulfillmentCalls } from '@hero/redux-data/backoffice/membershipUpdateFulfillment/saga';
import { watchFetchFillOrdersCalls } from '@hero/redux-data/backoffice/fillOrders/saga';
import { watchGetTransferDetailsCalls } from '@hero/redux-data/backoffice/transferRequestDetails/saga';
import { watchCancelTransferRequestCalls } from '@hero/redux-data/backoffice/transferRequestCancel/saga';
import { watchTransferRequestSetRxTransferredCalls } from '@hero/redux-data/backoffice/transferRequestSetRxTransferred/saga';
import { watchDelayMembershipPlanChangeCalls } from '@hero/redux-data/backoffice/membershipDelayPlanChange/saga';
import { watchGetUserDetailsCalls } from '@hero/redux-data/backoffice/userDetails/saga';
import { watchFetchOrganizationAdherenceAlertsCalls } from '@hero/redux-data/backoffice/organizationAdherenceAlerts/saga';
import { watchFetchOrganizationNoRefillAlertsCalls } from '@hero/redux-data/backoffice/organizationNoRefillAlerts/saga';
import { watchFetchOrganizationDeviceMonitoringListCalls } from '@hero/redux-data/backoffice/organizationDeviceMonitoringList/saga';
export default function* rootSaga(constAccessParams) {
    yield all([
        watchLoginCalls(constAccessParams),
        watchUserStatusCalls(constAccessParams),
        watchFetchUsersCalls(constAccessParams),
        watchGetNewUserStatusCalls(constAccessParams),
        // new sagas follow (legacy above):
        watchGetUserDeviceDetailsCalls(constAccessParams),
        watchGetUserDetailsCalls(constAccessParams),
        watchFetchUserAdherenceCalls(constAccessParams),
        watchUserResendActivationCodeCalls(constAccessParams),
        watchUserSetEnabledFeaturesCalls(constAccessParams),
        watchUserSetPhoneCalls(constAccessParams),
        watchUserSetBlockedStatusCalls(constAccessParams),
        watchUserSentTestNotificationCalls(constAccessParams),
        watchUserConfirmPhoneCalls(constAccessParams),
        watchUserDeletePhoneCalls(constAccessParams),
        watchFetchMembershipsCalls(constAccessParams),
        watchGetMembershipDetailsCalls(constAccessParams),
        watchFetchMembershipPlansCalls(constAccessParams),
        watchSetMembershipPlanCalls(constAccessParams),
        watchGetUserCreditCalls(constAccessParams),
        watchAddUserCreditCalls(constAccessParams),
        watchUpdateUserPaymentMethodCalls(constAccessParams),
        watchGetCancellationOptionsCalls(constAccessParams),
        watchGetRefundPreviewCalls(constAccessParams),
        watchGetCancellationFeePreviewCalls(constAccessParams),
        watchCancelMembershipCalls(constAccessParams),
        watchGetCancellationDetailsCalls(constAccessParams),
        watchFetchMembershipAlertsCalls(constAccessParams),
        watchSaveMembershipAlertNoteCalls(constAccessParams),
        watchDismissMembershipAlertCalls(constAccessParams),
        watchGetOrganizationDetailsCalls(constAccessParams),
        watchSubmitEnterpriseEnrollmentCalls(constAccessParams),
        watchFetchEnterpriseEnrollmentsForVerificationCalls(constAccessParams),
        watchVerifyEnterpriseEnrollmentCalls(constAccessParams),
        watchFetchEnterpriseMembersCalls(constAccessParams),
        watchGetEnterpriseMemberDetailsCalls(constAccessParams),
        watchGetEnterpriseCancellationOptionsCalls(constAccessParams),
        watchCancelEnterpriseMembershipCalls(constAccessParams),
        watchGetEnterpriseCancellationDetailsCalls(constAccessParams),
        watchReactivateMembershipCalls(constAccessParams),
        watchGetMembershipReactivationDetailsCalls(constAccessParams),
        watchReactivateMembershipRefundChargeCalls(constAccessParams),
        watchSignUpProspectCalls(constAccessParams),
        watchGetProspectSignupSurveyOptionsCalls(constAccessParams),
        watchGetRmaEnumsCalls(constAccessParams),
        watchFetchRmaRequestsCalls(constAccessParams),
        watchGetRmaRequestCalls(constAccessParams),
        watchGetExchangeRmaRequestCalls(constAccessParams),
        watchCreateDraftRmaRequestCalls(constAccessParams),
        watchCreateRmaRequestCalls(constAccessParams),
        watchUpdateRmaRequestCalls(constAccessParams),
        watchSetRmaRequestPickUpCalls(constAccessParams),
        watchCreateExchangeFulfillmentCalls(constAccessParams),
        watchVoidRmaRequestCalls(constAccessParams),
        watchRunDeviceRestockingCheckCalls(constAccessParams),
        watchSaveRtmCalls(constAccessParams),
        watchGetRtmStatusDetailsCalls(constAccessParams),
        watchGetCancellationFeeChargeAttemptPreviewCalls(constAccessParams),
        watchCancellationAttemptFeeChargeCalls(constAccessParams),
        watchFetchPatientsCalls(constAccessParams),
        watchFetchDevicesCalls(constAccessParams),
        watchFetchDeviceDetailsCalls(constAccessParams),
        watchSetDemoDeviceCalls(constAccessParams),
        watchSetAllowReprogramming(constAccessParams),
        watchRemoveDeviceFromOrganizationCalls(constAccessParams),
        watchRemoveTransferFlagCalls(constAccessParams),
        watchDeleteDeviceCalls(constAccessParams),
        watchFetchUnresolvedOtcPricesCalls(constAccessParams),
        watchRemoveUnresolvedOtcPriceCalls(constAccessParams),
        watchSetUnresolvedOtcPriceCalls(constAccessParams),
        watchActivateMembershipCalls(constAccessParams),
        watchFetchMitigationListCalls(constAccessParams),
        watchFetchMitigationSaveCalls(constAccessParams),
        watchGetPatientDetailsCalls(constAccessParams),
        watchUpdatePatientDetailsCalls(constAccessParams),
        watchFetchLastHazardousDrugsCalls(constAccessParams),
        watchRunHazardousDrugsCalls(constAccessParams),
        watchPatientInsuranceCardAddCalls(constAccessParams),
        watchPatientInsuranceCardUpdateCalls(constAccessParams),
        watchGetOrganizationListCalls(constAccessParams),
        watchCreateOrganizationCalls(constAccessParams),
        watchOrganizationUpdateCalls(constAccessParams),
        watchFetchOrganizationDetailsCalls(constAccessParams),
        watchEnrollmentCreateCalls(constAccessParams),
        watchFetchFillOrdersCalls(constAccessParams),
        watchFetchTransferRequestsCalls(constAccessParams),
        watchGetTransferDetailsCalls(constAccessParams),
        watchCancelTransferRequestCalls(constAccessParams),
        watchTransferRequestSetRxTransferredCalls(constAccessParams),
        watchUpdateMembershipFulfillmentCalls(constAccessParams),
        watchDelayMembershipPlanChangeCalls(constAccessParams),
        watchFetchOrganizationAdherenceAlertsCalls(constAccessParams),
        watchFetchOrganizationNoRefillAlertsCalls(constAccessParams),
        watchFetchOrganizationDeviceMonitoringListCalls(constAccessParams),
        watchGetPlaceSuggestionsCalls(),
        watchGetPlaceDetailsCalls(),
        watchRefreshTokenCalls(),
        watchLogoutCalls(),
        watchLoginDoneCalls()
    ]);
}
