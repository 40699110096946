import React from 'react';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useChargeFlowContext } from '../context/ChargeFlowProvider';
import useTheme from '@hero/branding/theme';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

export const Fail: React.FC = () => {
    const { colors } = useTheme();
    const margin = useComponentMargin({ marginTop: 'regular' });
    const { closeChargeModal, errorData } = useChargeFlowContext();
    const failColor = {
        color: colors.status.fail.alpha
    };
    return (
        <>
            <H role="h5" styles={failColor}>
                Fail
            </H>
            {errorData.previewError || errorData.chargeError ? (
                <P size="large" strong noDefaultMargin styles={failColor}>
                    {errorData.errorMessage}
                </P>
            ) : null}
            {errorData.chargeError ? (
                <P size="large" strong noDefaultMargin styles={failColor}>
                    {errorData.failedMessage}
                </P>
            ) : null}
            <Button width="large" styles={margin} onClick={closeChargeModal}>
                Close
            </Button>
        </>
    );
};
