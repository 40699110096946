import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRequests } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useCancellationRequestUpdate = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.patch(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/cancellation-requests/${attributes.memberCancellationId}`, { comment: attributes.comment }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [cancellationRequests]
            });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useCancellationRequestUpdate;
