import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useLocalization from '@hero/localization';
import { fetchDeviceDetails } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';
import { deviceDetailsSelector, areDeviceDetailsLoadingSelector, deviceDetailsErrorSelector, userInDeviceDetailsSelector, roleChangeOptionsInDeviceDetailsSelector } from '@hero/redux-data/backoffice/deviceDetails/selectors';
import { isBackofficeAdminSelector, isRefurbishmentAdminSelector, isAppSupportAdminAdminSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import { isActivateMembershipLoadingSelector, activateMembershipErrorSelector } from '@hero/redux-data/backoffice/activateMembership/selectors';
const useDeviceDetailsData = () => {
    const dispatch = useDispatch();
    const { formatElapsedTime } = useLocalization();
    const deviceDetails = useSelector(deviceDetailsSelector, shallowEqual);
    const userDetails = useSelector(userInDeviceDetailsSelector, shallowEqual);
    const roleChangeOptionsInDeviceDetails = useSelector(roleChangeOptionsInDeviceDetailsSelector, shallowEqual);
    const areDeviceDetailsLoading = useSelector(areDeviceDetailsLoadingSelector, shallowEqual);
    const deviceDetailsError = useSelector(deviceDetailsErrorSelector, shallowEqual);
    const isActivateMembershipLoading = useSelector(isActivateMembershipLoadingSelector, shallowEqual);
    const activateMembershipError = useSelector(activateMembershipErrorSelector, shallowEqual);
    const isBackofficeAdmin = useSelector(isBackofficeAdminSelector, shallowEqual);
    const isAppSupportAdminAdmin = useSelector(isAppSupportAdminAdminSelector, shallowEqual);
    const isRefurbishmentAdmin = useSelector(isRefurbishmentAdminSelector, shallowEqual);
    const { device_id, serial } = useParams();
    const getDeviceDetails = useCallback(() => {
        dispatch(fetchDeviceDetails(device_id ? { device_id: +device_id } : { external_serial: serial || '' }));
    }, [dispatch, device_id, serial]);
    useEffect(() => {
        getDeviceDetails();
    }, [getDeviceDetails]);
    return {
        dispatch,
        formatElapsedTime,
        deviceDetails,
        userDetails,
        isLoading: areDeviceDetailsLoading || isActivateMembershipLoading,
        hasError: activateMembershipError.error || deviceDetailsError.error,
        errorMessage: activateMembershipError.errorMessage || deviceDetailsError.errorMessage,
        isBackofficeAdmin,
        isAppSupportAdminAdmin,
        isRefurbishmentAdmin,
        roleChangeOptionsInDeviceDetails,
        getDeviceDetails
    };
};
export default useDeviceDetailsData;
