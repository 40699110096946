import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { membershipDetailsSelector } from '@hero/redux-data/backoffice/membershipDetails/selectors';
import { userDetailsSelector, deviceDetailsSelector } from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import { enterpriseMemberDetailsSelector } from '@hero/redux-data/backoffice/enterpriseMemberDetails/selectors';
import { membershipCancellationSelector } from '@hero/redux-data/backoffice/membershipCancellation/selectors';
import { cancellationDetailsSelector } from '@hero/redux-data/backoffice/cancellationDetails/selectors';
const useModalFlowEyebrow = (isOrganizationMember = false) => {
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const user = useSelector(userDetailsSelector, shallowEqual);
    const device = useSelector(deviceDetailsSelector, shallowEqual);
    const enrollment = useSelector(enterpriseMemberDetailsSelector, shallowEqual);
    const cancellation = useSelector(membershipCancellationSelector, shallowEqual);
    const cancellationDetails = useSelector(cancellationDetailsSelector, shallowEqual);
    const eyebrow = useMemo(() => {
        if (isOrganizationMember) {
            const organizationName = enrollment?.organization.name || '-';
            const userFullName = `${enrollment?.member?.contact?.first_name || ''} ${enrollment?.member?.contact?.last_name || ''}`.trim() ||
                `${enrollment?.contact?.first_name || ''} ${enrollment?.contact?.last_name || ''}`.trim() ||
                '-';
            const userEmail = enrollment?.member?.contact?.email || enrollment?.contact?.email || '-';
            const deviceSerial = enrollment?.member?.devices[0]?.device_serial || '-';
            return `${organizationName} / ${userFullName} / ${userEmail} / ${deviceSerial}`;
        }
        else {
            const userFullName = `${user?.first_name || ''} ${user?.last_name || ''}`.trim() || '-';
            const userEmail = membership?.order_email || '-';
            const deviceSerial = device?.external_serial ||
                cancellationDetails?.subscription.device_serial_number ||
                cancellation?.subscription.device_serial_number ||
                membership?.serial ||
                '-';
            return `${userFullName} / ${userEmail} / ${deviceSerial}`;
        }
    }, [
        isOrganizationMember,
        enrollment,
        membership,
        user,
        device,
        cancellationDetails,
        cancellation
    ]);
    return eyebrow;
};
export default useModalFlowEyebrow;
