import { useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchLastHazardousDrugsCheck, runHazardousDrugsCheck } from '@hero/redux-data/backoffice/hazardousDrugsCheck/actionCreators';
import { isHazardousDrugsCheckLoadingSelector, hazardousDrugsCheckErrorSelector, lastHazardousDrugsCheckSelector } from '@hero/redux-data/backoffice/hazardousDrugsCheck/selectors';
import { deviceDetailsSelector } from '@hero/redux-data/backoffice/deviceDetails/selectors';
const useHazardousDrugsData = () => {
    const dispatch = useDispatch();
    const { device_id } = useParams();
    const deviceDetails = useSelector(deviceDetailsSelector, shallowEqual);
    const lastHazardousDrugCheck = useSelector(lastHazardousDrugsCheckSelector, shallowEqual);
    const isLoading = useSelector(isHazardousDrugsCheckLoadingSelector, shallowEqual);
    const { error, errorMessage } = useSelector(hazardousDrugsCheckErrorSelector, shallowEqual);
    const serial = deviceDetails.external_serial;
    const performHazardousDrugsCheck = useCallback(() => {
        serial && dispatch(runHazardousDrugsCheck({ serial }));
    }, [dispatch, serial]);
    useEffect(() => {
        serial && dispatch(fetchLastHazardousDrugsCheck({ serial }));
    }, [dispatch, serial]);
    return {
        device_id,
        lastHazardousDrugCheck,
        isLoading,
        hasError: error,
        errorMessage,
        performHazardousDrugsCheck
    };
};
export default useHazardousDrugsData;
