import React, { CSSProperties, useMemo } from 'react';

import SearchForm from '../../../components/SearchForm';
import PageSizeForm from '../../../components/PageSizeForm';

const EnrollmentVerificationFiltering: React.FC = () => {
    const headerWrapStyles: CSSProperties = useMemo(
        () => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        }),
        []
    );

    return (
        <div style={headerWrapStyles}>
            <SearchForm placeholder="Search by email, last name or invitation code" />
            <PageSizeForm />
        </div>
    );
};

export default EnrollmentVerificationFiltering;
