import React from 'react';
import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';

export type Props = {
    type?: 'regular' | 'inverted' | 'brand';
} & UiComponentProps;

const HomeIllustration: React.FC<Props> = ({ type = 'brand', styles, ...rest }) => {
    const { colors } = useTheme();
    const color =
        type === 'brand'
            ? colors.brand.primary.alpha
            : type === 'inverted'
            ? colors.neutrals.background
            : colors.neutrals.textAlpha;

    return (
        <svg fill="none" viewBox="0 0 1440 246" style={styles} {...rest}>
            <g
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                clipPath="url(#a)"
            >
                <path d="M0 243.108h297.757V111.614h37.038M334.795 243.108V98.228h16.406v144.875h56.4v-6.776c0-.158-6.539-2.523-7.298-7.863-.768-5.393 2.304-10.016 2.304-10.016s-31.411-3.636-31.411-78.493l22.577 6.443v-6.164l29.956-9.245v-8.863h10.752v9.011l19.972 7.561h19.972L458.09 177.37s1.191 53.415-8.627 65.747h40.932c7.46 0 11.3-8.092 5.924-13.486-5.376-5.393-19.972-18.108-19.972-24.659 0-6.551 4.994-8.475 7.298-8.475 2.304 0 6.144 2.311 6.912 4.24" />
                <path d="M644.572 243.108h-54.473l9.05-61.264h-55.205l10.298 61.264h21.732c-2.919-7.322-13.213-10.016-18.588-10.016-5.376 0-18.818 3.469-24.199 3.469-5.38 0-12.099-3.082-18.818-3.082-4.033 0-8.641 1.347-8.641 4.817 0 3.469 2.691 4.623 8.066 4.623 6.144 0 9.984-3.082 14.017-8.669 6.405-8.872 7.68-41.227 7.68-41.227h-44.552s.772 26.7 5.955 37.239M569.296 177.361s-7.684-15.553-21.894-15.553c-14.21 0-14.21 12.332-14.21 12.332s32.646-.771 32.646-18.496c0-17.726-21.508-13.873-22.276-1.541 0 0 9.215 0 9.215-11.945 0-11.944-15.746-12.3-19.585-12.3-3.84 0-11.525 2.28-11.525 2.28s6.912 8.475 14.596 8.475c7.685 0 12.293-5.01 12.293-8.475s-13.442-18.739-26.884-18.739-13.061 1.784-13.061 1.784 6.912 9.245 16.133 9.245c9.22 0 17.668-5.78 17.668-14.255 0-8.476-6.22-11.945-6.22-11.945s-6.454 11.174 6.22 16.184c12.674 5.011 2.69-18.942-3.454-26.425-6.144-7.484-14.596-8.255-14.596-8.255s3.458 16.185 19.972 12.715c16.514-3.47 4.608-22.348 4.608-22.348s-9.984 16.184 3.84 23.506c13.828 7.322 21.508-7.705 21.508-7.705s-28.038 0-20.354 16.568c7.68 16.567 14.978 12.332 14.978 12.332s-4.805-21.438-15.364-7.322c-1.154 1.541 19.586 18.496.768 23.119 0 0-4.415-10.21 7.105-11.368" />
                <path d="M585.81 83.972c4.608 0 12.292 0 12.674-12.332 0 0-14.21 5.78-14.21 21.577s3.458 15.797 3.458 15.797 13.743-9.245 2.457-18.69c-11.291-9.439-17.435 11.977-15.899 16.874 1.536 4.898 4.994 15.685 13.06 15.685 8.067 0 9.603-4.24 9.603-4.24s-16.595-12.715-22.892 6.357c-6.296 19.073 11.372 19.46 11.372 19.46s4.994-21.96-16.514-17.338c-21.508 4.623 15.364 28.129 24.58 28.129 9.216 0 13.06-1.928 13.06-1.928s-18.05-8.863-27.27 0c-9.216 8.863-8.448 11.944-8.448 11.944s6.53-3.852 21.508-3.852c14.978 0 20.74 13.873 20.74 13.873s-15.364 1.541-20.74-2.311c-5.376-3.853-6.53-5.011-6.53-5.011s-8.448 8.476-8.448 17.338c0 8.863 4.608 15.027 4.608 15.027s17.547-15.414-5.82-29.67" />
                <path d="M591.186 137.914c24.198 0 18.436-21.578 18.436-21.578s-17.668 9.633-7.68 25.43c9.984 15.797 26.808-12.715 26.808-12.715s-18.742-1.541-20.278 15.797M378.413 149.088c0 53.171 21.508 60.11 21.508 60.11l-5.762-56.253-15.746-3.853v-.004ZM789.888 243.108h35.767l6.342-16.572M881.678 226.027c10.685-7.813 17.628-20.465 17.628-34.744 0-19.392-12.809-35.784-30.405-41.123l4.864-23.416h-34.569l4.864 23.416c-17.591 5.339-30.405 21.731-30.405 41.123 0 14.594 7.253 27.489 18.342 35.253M864.683 112.871h19.932L873.091 1.901h-32.646l-11.52 110.97h27.557v13.869" />
                <path d="m881.673 226.022 7.195 17.085h-38.902s5.246-8.285 32.812-8.285c27.567 0 40.084 8.285 40.084 8.285l-15.207-90.545h56.135l-12.517 90.545h17.421c12.315 0 36.126-5.01 43.046-5.01 6.91 0 10.4 5.01 10.4 5.01l5.25-6.582c-13.5-5.853-22.95-19.339-22.95-35.032 0-21.068 17.02-38.145 38.02-38.145 21 0 38.02 17.077 38.02 38.145 0 21.069-17.02 38.145-38.02 38.145M1018.78 150.498l-2.39-5.884M1025.54 167.084l-2.62-6.421" />
                <path d="m1007.79 166.872 30.35-12.445s-7.66-8.723-19.32-3.943c-11.66 4.781-11.03 16.388-11.03 16.388ZM1065.93 150.498l2.4-5.884M1059.17 167.084l2.62-6.421M1076.92 166.872l-30.35-12.445s7.65-8.723 19.32-3.943c11.66 4.781 11.03 16.388 11.03 16.388v0ZM1042.47 146.006v17.342M1038.24 146.006h8.45M1060.48 235.097l6.64 8.011s5.01-4.006 15.91-4.006 22.73 4.006 36.79 4.006V83.202h27.65v159.906M1165.48 243.107V99.314h13.33v143.793H1440M1147.47 111.02h18.01M1125.81 98.228h16.13M1126 130.209h16.13M1126 162.19h16.13M1126 194.171h16.13M1126 226.153h16.13M304.668 129.438h22.663M304.861 150.633h22.663M304.861 171.823h22.663M304.861 193.014h22.663M304.861 214.208h22.663M913.565 152.557l-3.215-10.016h9.642l3.211 10.016M949.759 152.557l5.524-24.272h8.682l-5.92 24.272M911.872 142.541l-4.567-15.801M914.661 140.23l-3.898-13.49M918.501 140.23l-3.898-13.49M963.479 128.285l4.568-15.801M960.69 125.969l3.899-13.485M956.851 125.969l3.898-13.485M931.063 152.557c-1.195-4.073-1.896-9.061-1.896-14.449 0-13.725 4.559-24.854 10.177-24.854 5.619 0 10.177 11.129 10.177 24.854 0 5.388-.7 10.376-1.895 14.449M718.582 9.03h33.144c11.754 0 29.382 8.456 29.382 8.456v218.106s-16.061 7.516-23.193 7.516h-78.819c-7.132 0-23.193-7.516-23.193-7.516V17.486s17.628-8.457 29.381-8.457h33.145" />
                <path d="M747.693 34.847h-54.922v33.522h54.922V34.847ZM718.506 135.409c12.089 0 21.889-9.833 21.889-21.961 0-12.129-9.8-21.96-21.889-21.96-12.09 0-21.89 9.831-21.89 21.96 0 12.128 9.8 21.961 21.89 21.961ZM718.384 224.036c9.602 0 14.596-2.312 14.596-2.312l3.962-27.548s-7.801-2.721-18.553-2.721h.121c-10.752 0-18.553 2.721-18.553 2.721l3.961 27.548s4.994 2.312 14.596 2.312" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M-1 0h1442v246H-1z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HomeIllustration;
