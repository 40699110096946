import React from 'react';

import { Organization } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../components/Link';

type Render = TableColumn<Organization>['render'];

const nameRender: Render = ({ name, id }) => (
    <Link to={`/organizations/${id}/members`}>{name}</Link>
);

type GetTableColumns = () => TableColumn<Organization>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'name',
        name: 'Name',
        align: 'left',
        headAlign: 'left',
        render: nameRender
    }
];

export default getTableColumns;
