import getFilterOptions from '../../../utils/getFilterOptions';
const useMemberFilterFormOptions = () => {
    const memberStatusOptions = getFilterOptions([
        ['ENROLLED', 'Enrolled'],
        ['UNENROLLED', 'Unenrolled'],
        ['ACTIVATED', 'Active'],
        ['PRE_CANCELLATION', 'Pre-Cancellation'],
        ['CANCELLED', 'Cancelled']
    ]);
    return { memberStatusOptions };
};
export default useMemberFilterFormOptions;
