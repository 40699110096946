import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import useRmaEnums from '../../utils/useRmaEnums';
const useTableDataRepack = (rmaRequests) => {
    const { rmaTypes, rmaStatuses, returnStatuses, refundStatuses } = useRmaEnums();
    return rmaRequests.map(({ id, customer_name, rma_type, rma_status, return_status, refund_status, created_at, last_updated_at, created_by }) => ({
        id,
        customer_name,
        rma_type: rmaTypes ? rmaTypes[rma_type] : null,
        rma_status: rmaStatuses && typeof rma_status === 'number' ? rmaStatuses[rma_status] : null,
        return_status: returnStatuses && typeof return_status === 'number'
            ? returnStatuses[return_status]
            : null,
        refund_status: refundStatuses && typeof refund_status === 'number'
            ? refundStatuses[refund_status]
            : null,
        created_at: formatShortDate(normalizeDateString(created_at)),
        last_updated_at: formatShortDate(normalizeDateString(last_updated_at)),
        created_by
    }));
};
export default useTableDataRepack;
