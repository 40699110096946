// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IDRPA_wSOVh0MIGxKFEH {
    display: flex;
    align-items: center;
    padding: 0 2rem; 
    justify-content: space-between;
}
.oKy9SNgLvKLhOw79qB9g {
    text-decoration: none;
    padding: 15px;
    color: #434343;
    font-weight: 600;
    font-size: 14px;
}
.oKy9SNgLvKLhOw79qB9g:hover {
   color: #F26C3A;
}
.jI43cdR4j8vwEv9vjc3o {
    background-color: whitesmoke;
}
.dXKVb6lHLRBvsSJXX2St {
    display: flex;
    align-items: center;
    column-gap: 4rem;
}
.bwBEy_UbUBpgjdL7aAuM {
    display: flex;
    align-items: center;
}

.CSTFjx8sDduOpThESDvm {
    display: flex;
    align-items: center;
    column-gap: 1.4rem;
    width: 34rem;
    border-radius: 1.8rem;
    padding: 0.4rem 1.4rem !important; 
    background-color: #fff !important; 
    border: 2px solid rgba(231, 231, 231, 1) !important;
}`, "",{"version":3,"sources":["webpack://./components/Navigation/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,8BAA8B;AAClC;AACA;IACI,qBAAqB;IACrB,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;GACG,cAAc;AACjB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,iCAAiC;IACjC,iCAAiC;IACjC,mDAAmD;AACvD","sourcesContent":[".navbar {\n    display: flex;\n    align-items: center;\n    padding: 0 2rem; \n    justify-content: space-between;\n}\n.link {\n    text-decoration: none;\n    padding: 15px;\n    color: #434343;\n    font-weight: 600;\n    font-size: 14px;\n}\n.link:hover {\n   color: #F26C3A;\n}\n.container {\n    background-color: whitesmoke;\n}\n.rightSection {\n    display: flex;\n    align-items: center;\n    column-gap: 4rem;\n}\n.leftSection {\n    display: flex;\n    align-items: center;\n}\n\n.search {\n    display: flex;\n    align-items: center;\n    column-gap: 1.4rem;\n    width: 34rem;\n    border-radius: 1.8rem;\n    padding: 0.4rem 1.4rem !important; \n    background-color: #fff !important; \n    border: 2px solid rgba(231, 231, 231, 1) !important;\n}"],"sourceRoot":""}]);
// Exports
export var navbar = `IDRPA_wSOVh0MIGxKFEH`;
export var link = `oKy9SNgLvKLhOw79qB9g`;
export var container = `jI43cdR4j8vwEv9vjc3o`;
export var rightSection = `dXKVb6lHLRBvsSJXX2St`;
export var leftSection = `bwBEy_UbUBpgjdL7aAuM`;
export var search = `CSTFjx8sDduOpThESDvm`;
export default ___CSS_LOADER_EXPORT___;
