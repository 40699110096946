import React, { CSSProperties } from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import Container from '@hero/ui-kit/layout/Container';
import {
    CreateRmaRequestParams,
    UpdateRmaRequestParams
} from '@hero/validators/forms/backoffice/createRmaReqestSchema';
import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';

import StaticOrInput from '../../../components/StaticOrInput';
import StaticOrSelect from '../../../components/StaticOrSelect';
import useRmaEnums from '../utils/useRmaEnums';

type Props = {
    isEditMode?: boolean;
};

const RmaRequestFormFieldsExchange: React.FC<Props> = ({ isEditMode = false }) => {
    const { exchangeSourceOptions } = useRmaEnums();

    const { watch } = useFormContext<CreateRmaRequestParams | UpdateRmaRequestParams>();
    const [
        exchange_tracking_number,
        exchange_device_serial,
        exchange_source,
        exchange_fulfilment_date
    ] = watch([
        'exchange_tracking_number',
        'exchange_device_serial',
        'exchange_source',
        'exchange_fulfilment_date'
    ]);

    const leftColWrapStyles: CSSProperties = {
        gridColumnStart: 1,
        alignItems: 'start'
    };
    const rightColWrapStyles: CSSProperties = {
        gridColumnStart: 2,
        alignItems: 'start'
    };

    return (
        <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="exchange_tracking_number"
                    displayName="Exchange Tracking #"
                    staticValue={exchange_tracking_number}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="exchange_device_serial"
                    displayName="Exchange Device Serial #"
                    staticValue={exchange_device_serial}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                <StaticOrSelect
                    name="exchange_source"
                    displayName="Exchange Source"
                    options={exchangeSourceOptions}
                    staticValue={exchange_source}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                <StaticOrInput
                    name="exchange_fulfilment_date"
                    displayName="Exchange Fulfillment Date"
                    staticValue={
                        exchange_fulfilment_date
                            ? formatShortDate(normalizeDateString(exchange_fulfilment_date, true))
                            : '-'
                    }
                    type="date"
                    placeholder="yyyy-mm-dd"
                    isEditable={isEditMode}
                />
            </Container>
        </Container>
    );
};

export default RmaRequestFormFieldsExchange;
