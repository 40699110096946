import React, { CSSProperties } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';
import P from '@hero/ui-kit/typography/P';

type Props = {
    message: string;
    centered?: boolean;
} & UiComponentProps;

const InlineError: React.FC<Props> = ({ message, centered = false, styles }) => {
    const { colors } = useTheme();

    const mergedStyles: CSSProperties = {
        color: colors.status.fail.alpha,
        ...styles
    };

    return (
        <P role="alert" size="large" strong centered={centered} styles={mergedStyles}>
            {message}
        </P>
    );
};

export default InlineError;
