import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import P from '@hero/ui-kit/typography/P';

interface SuccessProps {
    closeFlowModal: () => void;
}

export const Success: React.FC<SuccessProps> = ({ closeFlowModal }) => {
    const marginTop = useComponentMargin({ marginTop: 'large' });

    return (
        <>
            <Section
                noDefaultPadding
                styles={{ textAlign: 'left', ...marginTop }}
                width="full"
                centered
            >
                <P>Request is successfully send to trusted provider.</P>
            </Section>
            <Section centered>
                <Button width="large" onClick={closeFlowModal}>
                    Close
                </Button>
            </Section>
        </>
    );
};
