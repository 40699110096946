import moment from 'moment';
//recive utc time, export local time
export default function ToLocalTime(date, format) {
    if (date) {
        const utcTime = moment.utc(date);
        const isValid = utcTime.isValid();
        if (isValid) {
            const localTime = utcTime.local();
            if (format) {
                return localTime.format(format);
            }
            return localTime.format();
        }
        return false;
    }
    //return false if date is not valid
    return false;
}
