import React from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import { DischargedQueueMemberDataObject } from '../utils/repackTableData';
import useCancellationRequestReject from '../api/useCancellationRequestReject';
import useCancellationRequestConfirm from '../api/useCancellationRequestConfirm';
import useCancellationRequestTransferD2C from '../api/useCancellationRequestTransferD2C';
import InlineLoader from '../../../../components/InlineLoader';
import QueryInlineError from '../../../../components/QueryInlineError';

interface MemberActionsProps {
    member: DischargedQueueMemberDataObject;
    refetchCancellationRequests?: () => void;
}

const MemberActions: React.FC<MemberActionsProps> = ({ member, refetchCancellationRequests }) => {
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [action, setAction] = React.useState('1');
    const {
        mutate: reject,
        isPending: isRejectLoading,
        isSuccess: isRejectSuccess,
        error: rejectError,
        isError: isRejectError,
        reset: resetReject
    } = useCancellationRequestReject();

    const {
        mutate: confirm,
        isPending: isConfirmLoading,
        isSuccess: isConfirmSuccess,
        error: confirmError,
        isError: isConfirmError,
        reset: resetConfirm
    } = useCancellationRequestConfirm();

    const {
        mutate: transfer,
        isPending: isTransferLoading,
        isSuccess: isTransferSuccess,
        error: transferError,
        isError: isTransferError,
        reset: resetTransfer
    } = useCancellationRequestTransferD2C();

    const handleSelectAction = React.useCallback((event: React.FormEvent<HTMLSelectElement>) => {
        const newAction = event.currentTarget.value;
        setAction(newAction);
        if (['2', '3', '4'].includes(newAction)) {
            setShowConfirmModal(true);
        }
    }, []);

    const confirmText = React.useMemo(() => {
        if (action === '2') {
            return isRejectSuccess
                ? {
                      description: 'Member successfully retained',
                      header: 'Retain in RTM'
                  }
                : {
                      description: 'Are you sure you want to retain this member?',
                      header: 'Retain in RTM'
                  };
        }
        if (action === '3') {
            return isTransferSuccess
                ? {
                      description: 'Member successfully transferred to D2C',
                      header: 'Transfer to D2C'
                  }
                : {
                      description: 'Are you sure you want to transfer this member to D2C?',
                      header: 'Transfer to D2C'
                  };
        }
        if (action === '4') {
            return isConfirmSuccess
                ? {
                      description: 'Member successfully discharged',
                      header: 'Confirm Discharge'
                  }
                : {
                      description: 'Are you sure you want to discharge this member?',
                      header: 'Confirm Discharge'
                  };
        }
        return {
            description: '',
            header: ''
        };
    }, [action, isRejectSuccess, isConfirmSuccess, isTransferSuccess]);

    const handleConfirm = () => {
        const cancellation_id = member.membership_cancellation_id;
        if (action === '2') {
            reject({ cancellation_id });
        }
        if (action === '3') {
            transfer({ cancellation_id });
        }
        if (action === '4') {
            confirm({ cancellation_id });
        }
    };

    const isLoading = React.useMemo(() => {
        return isRejectLoading || isConfirmLoading || isTransferLoading;
    }, [isRejectLoading, isConfirmLoading, isTransferLoading]);

    const isSuccess = React.useMemo(() => {
        return isConfirmSuccess || isRejectSuccess || isTransferSuccess;
    }, [isConfirmSuccess, isRejectSuccess, isTransferSuccess]);

    const handleConfirmModalClose = () => {
        isSuccess && refetchCancellationRequests && refetchCancellationRequests();
        setAction('1');
        setShowConfirmModal(false);
        resetTransfer();
        resetConfirm();
        resetReject();
    };

    return (
        <>
            <select
                disabled={member.status !== 'CREATED'}
                onChange={handleSelectAction}
                value={action}
                style={{
                    border: '1px solid',
                    background: 'transparent',
                    borderRadius: '4rem',
                    padding: '0.6rem',
                    margin: '0.6rem',
                    outline: 'none'
                }}
            >
                <option value="1">Choose</option>
                <option value="2">Retain in RTM</option>
                <option value="3">Transfer to D2C</option>
                <option value="4">Discharge</option>
            </select>

            <Modal
                type={isSuccess ? 'success' : 'warn'}
                width="page"
                styles={{ minWidth: '50rem' }}
                externalControls={[showConfirmModal, setShowConfirmModal]}
                onClose={handleConfirmModalClose}
                isCancelable
            >
                <>
                    <H role="h4">{confirmText.header}</H>
                    <P size="large" strong>
                        {member.name || 'Name not provided'}
                    </P>
                    <P size="large" strong>
                        {member.email || 'Email not provided'}
                    </P>
                    {isLoading ? <InlineLoader /> : <P size="large">{confirmText.description}</P>}
                    {isSuccess ? (
                        <Button variant="primary" onClick={handleConfirmModalClose}>
                            Ok
                        </Button>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <Button onClick={handleConfirm} disabled={isLoading}>
                                Confirm
                            </Button>
                            <Button
                                disabled={isLoading}
                                variant="secondary"
                                onClick={handleConfirmModalClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}

                    {isRejectError || isConfirmError || isTransferError ? (
                        <QueryInlineError
                            centered
                            error={rejectError || confirmError || transferError}
                        />
                    ) : null}
                </>
            </Modal>
        </>
    );
};

export default MemberActions;
