import React, { FC, useCallback, useState } from 'react';
import Form from '@hero/ui-kit/inputs/Form';
import { useExchangeContext } from '../context/ExchangeProvider';
import {
    exchangeMembershipSchema,
    ExchangeMembershipParams
} from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import InlineLoader from '../../../components/InlineLoader';
import CancellationFormFields from '../../forms/CancellationFormFields';
import useGetCancellationOptions from '../../../pages/Membership/api/useGetCancellationOptions';
import QueryInlineError from '../../../components/QueryInlineError';
import useGetEnterpriseCancellationOptions from '../../../pages/Organizations/api/useGetEnterpriseCancellationOptions';

interface DetailsProps {
    closeExchangeModal: () => void;
}

export const Details: FC<DetailsProps> = () => {
    const {
        createDraftRma,
        isOrganizationMember,
        membership,
        exchangeRmaError,
        createRmaDraftError,
        isCreateRmaDraftLoading,
        enterpriseEnrollment
    } = useExchangeContext();
    const [isSubmitted, setIsSubmitted] = useState(false); // dont show error if RMA is not found on get

    const {
        data: cancellationOptionsResponse,
        isLoading: isCancellationOptionsLoading,
        error: cancellationOptionsError
    } = useGetCancellationOptions(
        { id: membership?.id },
        !!membership?.id && !isOrganizationMember
    );

    const enrollmentId = enterpriseEnrollment?.id ? enterpriseEnrollment.id : 0;

    const {
        data: enterpriseCancellationOptionsResponse,
        isLoading: isEnterpriseCancellationOptionsLoading
    } = useGetEnterpriseCancellationOptions({ id: enrollmentId }, !!enrollmentId);

    const cancellationOptions =
        cancellationOptionsResponse?.data?.data ||
        enterpriseCancellationOptionsResponse?.data?.cancellation_options;

    const handleNext = useCallback(
        (formValues: ExchangeMembershipParams) => {
            setIsSubmitted(true);
            createDraftRma(formValues);
        },
        [createDraftRma]
    );

    const isLoading =
        isCancellationOptionsLoading ||
        isCreateRmaDraftLoading ||
        isEnterpriseCancellationOptionsLoading;

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Form validationSchema={exchangeMembershipSchema} submitFn={handleNext}>
            <CancellationFormFields
                isExchangeFlow
                isOrganizationMember={isOrganizationMember}
                cancellationOptions={cancellationOptions}
                enterpriseEnrollment={enterpriseEnrollment}
            />
            {isSubmitted && exchangeRmaError && (
                <QueryInlineError centered error={exchangeRmaError} />
            )}
            {createRmaDraftError && <QueryInlineError centered error={createRmaDraftError} />}
            {cancellationOptionsError && (
                <QueryInlineError centered error={cancellationOptionsError} />
            )}
        </Form>
    );
};
