const tableColumns = [
    {
        colKey: 'slot',
        name: 'Slot'
    },
    {
        colKey: 'name',
        name: 'Name'
    },
    {
        colKey: 'type',
        name: 'Type'
    }
];
export default tableColumns;
