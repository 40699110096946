import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from './useSetQueryParams';
const usePagerClickQuery = () => {
    const queryParams = getQueryParams();
    const setQueryParams = useSetQueryParams();
    const handleSetPageLimit = ({ limit, offset }) => () => {
        const newQueryParams = buildQuery({ ...queryParams, offset, limit });
        setQueryParams(newQueryParams);
    };
    return handleSetPageLimit;
};
export default usePagerClickQuery;
