import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { organizations } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetOrganizations = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/organization/list/`, { ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizations] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useGetOrganizations;
