import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
    cancellationFeePreviewErrorSelector,
    isCancellationFeePreviewLoadingSelector
} from '@hero/redux-data/backoffice/cancellationFeePreview/selectors';
import {
    mitigationSaveErrorSelector,
    isMitigationSaveLoadingSelector
} from '@hero/redux-data/backoffice/mitigationSave/selectors';
import { mitigationListSelector } from '@hero/redux-data/backoffice/mitigationList/selectors';
import {
    isRefundPreviewLoadingSelector,
    refundPreviewErrorSelector
} from '@hero/redux-data/backoffice/refundPreview/selectors';
import Form from '@hero/ui-kit/inputs/Form';
import InlineError from '../../components/InlineError';
import InlineLoader from '../../components/InlineLoader';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import MitigationFormFields from '../forms/MitigationFormFields';
import P from '@hero/ui-kit/typography/P';
import { formatDate } from '@hero/hero-utils/date';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import hardshipOffersSchema from './validator/hardshipOffers';

const StepMitigation: React.FC = () => {
    const { continueCancellation, handleMitigationSave } = useCancellationStateMachine();

    const isFetchingFeePreview = useSelector(isCancellationFeePreviewLoadingSelector);
    const { error: feeError, errorMessage: feeErrorMessage } = useSelector(
        cancellationFeePreviewErrorSelector,
        shallowEqual
    );
    const isFetchingRefundPreview = useSelector(isRefundPreviewLoadingSelector);
    const { error: refundError, errorMessage: refundErrorMessage } = useSelector(
        refundPreviewErrorSelector,
        shallowEqual
    );

    const mitigationList = useSelector(mitigationListSelector, shallowEqual);
    const { error: mitigationSaveError, errorMessage: mitigationSaveErrorMessage } = useSelector(
        mitigationSaveErrorSelector,
        shallowEqual
    );

    const isMitigationSaveInProgress = useSelector(isMitigationSaveLoadingSelector, shallowEqual);

    const isLoading = isFetchingFeePreview || isFetchingRefundPreview || isMitigationSaveInProgress;

    const activeMitigation = mitigationList?.active_mitigation || null;

    const paddingBottom = useComponentPadding({ paddingBottom: 'regular' });

    const preparedMitigationOffers = React.useMemo(() => {
        return [
            {
                description: '1 month free',
                discount_type: 2,
                mitigation_type: 2,
                monthly_fee: 4450,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: '1-month-free'
            },
            {
                description: 'pause subscription',
                discount_type: 2,
                mitigation_type: 4,
                monthly_fee: 0,
                months: 2,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'pause-subscription'
            },
            {
                description: 'mitigation 22%',
                discount_type: 5,
                mitigation_type: 3,
                monthly_fee: 3499,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'mitigation-22'
            },
            {
                description: 'mitigation 33%',
                discount_type: 4,
                mitigation_type: 3,
                monthly_fee: 2999,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'mitigation-33'
            },
            {
                description: 'mitigation 50%',
                discount_type: 1,
                mitigation_type: 3,
                monthly_fee: 2249,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'mitigation-50'
            },
            {
                description: 'mitigation 67%',
                discount_type: 3,
                mitigation_type: 3,
                monthly_fee: 1499,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'mitigation-67'
            },
            {
                description: 'mitigation 77.8%',
                discount_type: 6,
                mitigation_type: 3,
                monthly_fee: 999,
                months: 1,
                name: 'Monthly',
                period: 'MONTHLY',
                saving: 0,
                storefront_key: 'mitigation-78'
            }
        ];
    }, []);

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={hardshipOffersSchema}
            submitFn={handleMitigationSave}
            defaultValues={{ months: 1 }}
        >
            {mitigationList?.previous_mitigations?.length ? (
                <div style={{ ...paddingBottom }}>
                    <>
                        <P strong noDefaultMargin>
                            Offers used:
                        </P>
                        {mitigationList.previous_mitigations.map((item, index) => (
                            <P
                                strong
                                noDefaultMargin
                                key={`${item.mitigation_type}-${index}`}
                            >{`- ${item.mitigation_type} (${formatDate(
                                item.start_date
                            )} - ${formatDate(item.end_date)})`}</P>
                        ))}
                    </>
                </div>
            ) : null}
            {activeMitigation ? (
                <div style={{ ...paddingBottom }}>
                    <P strong noDefaultMargin>
                        Offers active:
                    </P>
                    <P strong noDefaultMargin>{`${activeMitigation.mitigation_type} (${formatDate(
                        activeMitigation.start_date
                    )} - ${formatDate(activeMitigation.end_date)})`}</P>
                </div>
            ) : null}

            <MitigationFormFields
                onContinueCancellation={continueCancellation}
                disabled={!!activeMitigation}
                rtmHardshipOffers={preparedMitigationOffers}
            />
            {feeError && (
                <InlineError
                    centered
                    message={feeErrorMessage || 'Error calculating cancellation fee.'}
                />
            )}
            {refundError && (
                <InlineError centered message={refundErrorMessage || 'Error calculating refund.'} />
            )}
            {mitigationSaveError && (
                <InlineError
                    centered
                    message={mitigationSaveErrorMessage || 'Error saving mitigation.'}
                />
            )}
        </Form>
    );
};

export default StepMitigation;
