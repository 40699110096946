import React from 'react';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

interface MitigationSuccessProps {
    onClose?: () => void;
}

const MitigationSuccess: React.FC<MitigationSuccessProps> = ({ onClose }) => {
    const marginTop = useComponentMargin({ marginTop: 'large' });
    return (
        <>
            <Section>
                <H role="h5">Tell the member:</H>
                <P size="large">
                    <strong>Thank you</strong> for staying with Hero!
                </P>
                <P size="large">
                    We’re always here to help –{' '}
                    <strong>improving your health and well-being</strong> is our goal.
                </P>
            </Section>
            <Section noDefaultPadding centered styles={marginTop}>
                <Button width="large" onClick={onClose}>
                    Ok
                </Button>
            </Section>
        </>
    );
};

export default MitigationSuccess;
