import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useGetEnterpriseMemberDetails from '../api/useGetEnterpriseMemberDetails';
import InlineLoader from '../../../components/InlineLoader';

const MemberDetailsByMemberId: React.FC = () => {
    const params = useParams<Required<{ member_id: string; id: string }>>();

    const navigate = useNavigate();

    const { data: enrollmentResponse } = useGetEnterpriseMemberDetails({
        member_id: params.member_id ? +params.member_id : 0
    });

    React.useEffect(() => {
        if (enrollmentResponse?.data) {
            navigate(`/organizations/${params.id}/members/${enrollmentResponse.data.id}/details`);
        }
    }, [enrollmentResponse, params]);

    return <InlineLoader />;
};

export default MemberDetailsByMemberId;
