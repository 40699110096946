import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Container from '@hero/ui-kit/layout/Container';
import Divider from '@hero/ui-kit/components/Divider';

export const Details = () => {
    const { goToFOP, onCloseReactivationFlow, detailsBtnConfirm, detailsBtnLabel, planDetails } =
        useReactivationFlowContext();

    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginLeft = useComponentMargin({ marginLeft: 'regular' });

    const refundChargeLabel = React.useMemo(() => {
        const label = planDetails.isRefund ? `REFUND` : `CHARGE`;
        return `${label} AMOUNT`;
    }, [planDetails]);

    return (
        <>
            <P centered size="large">
                Prepaid until: <strong>{planDetails.prepaidUntil}</strong>
            </P>
            <Section noDefaultPadding styles={{ textAlign: 'left', ...marginTop }} width="full">
                <Container gridTemplateColumns="1fr 1fr">
                    <div>
                        <P size="large" noDefaultMargin>
                            PLAN ACTIVE DATE
                        </P>
                        <P size="large" strong noDefaultMargin>
                            {planDetails.planActiveDate}
                        </P>
                    </div>
                    <div>
                        <P size="large" noDefaultMargin>
                            PLAN CANCELLED DATE
                        </P>
                        <P size="large" strong noDefaultMargin>
                            {planDetails.planCancelledDate}
                        </P>
                    </div>
                </Container>
                <P size="large" noDefaultMargin styles={marginTop}>
                    PLAN REACTIVATION DATE
                </P>
                <P size="large" strong noDefaultMargin>
                    {planDetails.planReactivationDate}
                </P>
                <Divider noDefaultMargin />
                <Container gridTemplateColumns="1fr 1fr">
                    <div>
                        <P size="large" noDefaultMargin styles={marginTop}>
                            INITIATION FEE PAID
                        </P>
                        <P size="large" strong>
                            {planDetails.initiationFeePayed}
                        </P>
                    </div>
                    <div>
                        <P size="large" noDefaultMargin styles={marginTop}>
                            MEMBERSHIP PAID TO DATE
                        </P>
                        <P size="large" strong noDefaultMargin>
                            {planDetails.membershipPaidToDate}
                        </P>
                    </div>
                </Container>
                <P size="large" noDefaultMargin>
                    CANCELLATION FEE PAID
                </P>
                <P size="large" strong noDefaultMargin>
                    {planDetails.cancellationFeePaid}
                </P>
                <Divider noDefaultMargin />
                <Container gridTemplateColumns="1fr 1fr">
                    <div>
                        <P size="large" noDefaultMargin styles={marginTop}>
                            INITIATION FEE OWED
                        </P>
                        <P size="large" strong>
                            {planDetails.initiationFeeOwed}
                        </P>
                    </div>
                    <div>
                        <P size="large" noDefaultMargin styles={marginTop}>
                            MEMBERSHIP OWED TO DATE
                        </P>
                        <P size="large" strong>
                            {planDetails.membershipOwedToDate}
                        </P>
                    </div>
                </Container>
                <Divider noDefaultMargin />
                <Section centered noDefaultPadding>
                    <P size="large" noDefaultMargin styles={marginTop}>
                        {refundChargeLabel}
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.difference}
                    </P>
                    <P size="small">(+tax if applicable)</P>
                </Section>
            </Section>
            <Section centered noDefaultPadding>
                <div style={{ display: 'flex', alignContent: 'center', ...marginTop }}>
                    <Button onClick={detailsBtnConfirm}>{detailsBtnLabel}</Button>
                    <Button
                        variant="secondary"
                        onClick={onCloseReactivationFlow}
                        styles={marginLeft}
                    >
                        Cancel Reactivation
                    </Button>
                </div>
                <Button
                    onClick={goToFOP}
                    variant="inverted"
                    size="regular"
                    styles={marginTop}
                    noDefaultMargin
                >
                    Add new credit card
                </Button>
            </Section>
        </>
    );
};
