import React, { FC, CSSProperties, useMemo, useCallback } from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';
import ModalFlowHeader from '../../../../components/ModalFlowHeader';
import useModalFlowEyebrow from '../../../../hooks/useModalFlowEyebrow';
import * as ToBeCancelledStepComponents from './steps';
import InlineLoader from '../../../../components/InlineLoader';
import { useToBeCancelledFlowContext } from './context/ToBeCancelledProvider';

type ExchangeStepsModalProps = { isLoading?: boolean } & Pick<ModalProps, 'externalControls'>;

const ExchangeStepsModal: FC<ExchangeStepsModalProps> = ({
    externalControls,
    isLoading = false
}) => {
    const { flowStep, changeFlowStep, onCompleteCallback } = useToBeCancelledFlowContext();
    const [, setExternalState] = externalControls ?? [];
    const eyebrow = useModalFlowEyebrow(true);

    const modalType: ModalProps['type'] = useMemo(() => {
        if (flowStep === 'Confirm') {
            return 'warn';
        }
        if (flowStep === 'Success') {
            return 'success';
        }
        return 'neutral';
    }, [flowStep]);

    const currentStep = useMemo(() => {
        if (flowStep === 'Confirm') {
            return 2;
        }

        return 1;
    }, [flowStep]);

    const steps = useMemo(() => {
        if (flowStep === 'Success') {
            return undefined;
        }
        return ['Details', 'Confirm'];
    }, [flowStep]);

    const heading = useMemo(() => {
        if (flowStep === 'Success') {
            return 'Success';
        }
        return 'To Be Cancelled';
    }, [flowStep]);

    const modalPadding = useSectionPadding({ padding: 'regular' });
    const innerWrapPadding = useComponentPadding({ padding: 'regular' });

    const modalStyles: CSSProperties = {
        width: '100rem',
        ...modalPadding
    };

    const innerWrapStyles: CSSProperties = {
        alignSelf: 'stretch',
        ...innerWrapPadding
    };

    const ExchangeStepComponent = useMemo(() => {
        return ToBeCancelledStepComponents[flowStep];
    }, [flowStep]);

    const closeFlowModal = useCallback(() => {
        changeFlowStep('Details');
        typeof setExternalState === 'function' && setExternalState(false);
        if (flowStep === 'Success') {
            onCompleteCallback && onCompleteCallback();
        }
    }, [setExternalState, changeFlowStep, onCompleteCallback, flowStep]);

    return (
        <Modal
            width="page"
            externalControls={externalControls}
            type={modalType}
            styles={modalStyles}
            noDefaultPadding
            isCancelable
            onClose={closeFlowModal}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <ModalFlowHeader
                        heading={heading}
                        eyebrow={eyebrow}
                        steps={steps}
                        currentStep={currentStep}
                    />
                    <div style={innerWrapStyles}>
                        <ExchangeStepComponent closeFlowModal={closeFlowModal} />
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ExchangeStepsModal;
