import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { rmaRequestSelector } from '@hero/redux-data/backoffice/rmaRequest/selectors';
import useRmaEnums from '../utils/useRmaEnums';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import { UpdateRmaStatusParams, updateRmaStatusSchema } from './validator';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import { RmaEnums, RmaRequest } from '@hero/hero-types';
import getPreparedRmaStatusOptions from '../utils/getPreparedRmaStatusOptions';

const FormElements: React.FC<{ rma: RmaRequest | null; rmaEnums?: RmaEnums }> = ({
    rma,
    rmaEnums
}) => {
    const {
        formState: { isValid }
    } = useFormContext<UpdateRmaStatusParams>();

    const { return_status: defaultReturnStatus, rma_status } = rma ?? {};

    const { returnStatusOptions, rmaStatusOptions } = useRmaEnums(rmaEnums);

    const filteredRmaStatusOptions = rmaStatusOptions.filter(
        (rmaStatusOption) => rmaStatusOption.length && rmaStatusOption[0] !== '0'
    );

    const preparedRmaStatusOptions = getPreparedRmaStatusOptions(filteredRmaStatusOptions);

    return (
        <>
            <DefaultSelect
                name="rma_status"
                displayName="RMA Status"
                defaultValue={rma_status ? `${rma_status}` : '1'}
                options={preparedRmaStatusOptions}
            />

            <DefaultSelect
                name="return_status"
                displayName="Return Status"
                defaultValue={defaultReturnStatus ? `${defaultReturnStatus}` : '1'}
                options={returnStatusOptions.map(([value, label]) => ({ label, value }))}
            />

            <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Update
                </Button>
            </Section>
        </>
    );
};

const RmaStatusForm: React.FC<{
    rma?: RmaRequest | null;
    onSubmit: (attributes: UpdateRmaStatusParams) => void;
    rmaEnums?: RmaEnums;
}> = ({ onSubmit, rma: defaultRma, rmaEnums }) => {
    const rma = defaultRma || useSelector(rmaRequestSelector, shallowEqual);
    const { return_status: defaultReturnStatus, rma_status } = rma ?? {};

    return (
        <Form
            validationSchema={updateRmaStatusSchema}
            submitFn={onSubmit}
            defaultValues={{
                rma_status: rma_status || '1',
                return_status: defaultReturnStatus || '1'
            }}
        >
            <FormElements rma={rma} rmaEnums={rmaEnums} />
        </Form>
    );
};

export default RmaStatusForm;
