import React from 'react';

import { LastHazardousDrugCheckResponse } from '@hero/hero-types';
import P from '@hero/ui-kit/typography/P';

// there's probably a bug in this util, but it's not clear how it shold work
import { DetailsContainer, DetailLabel, Detail } from '../../../../components/Details';

import ManuallyEnteredMedications from './ManuallyEnteredMedications';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import ToLocalTime from '../../../../utils/toLocalTime';

const checkStatuses = {
    UNKNOWN: 'Unknown',
    HAZARDOUS_DRUG_DETECTED: 'Hazardous Drug Detected',
    HAZARDOUS_DRUG_NOT_DETECTED: 'Hazardous Drug Not Detected',
    MANUALLY_ENTERED_DRUGS_DETECTED: 'Manually Entered Drugs Detected'
};

const CheckDetails: React.FC<LastHazardousDrugCheckResponse> = ({ last_check }) => {
    const pMargin = useComponentMargin({ marginTop: 'regular' });
    return last_check ? (
        <>
            <DetailsContainer>
                <DetailLabel>Status</DetailLabel>
                <Detail>{checkStatuses[last_check.hazardous_drugs_check]}</Detail>
                <DetailLabel>Time</DetailLabel>
                <Detail>{ToLocalTime(last_check.ran_at, 'MM/DD/YYYY [at] hh:mm A') || '-'}</Detail>
                <DetailLabel>User</DetailLabel>
                <Detail>{last_check.ran_by}</Detail>
            </DetailsContainer>
            {last_check.manually_entered_medications.length ? (
                <>
                    <P styles={pMargin}>Manually Entered Medications</P>
                    <ManuallyEnteredMedications
                        manually_entered_medications={last_check.manually_entered_medications}
                    />
                </>
            ) : null}
        </>
    ) : null;
};

export default CheckDetails;
