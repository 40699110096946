import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUserPaymentMethodUpdatedSelector } from '@hero/redux-data/backoffice/userPaymentMethod/selectors';
import { useNotifications } from '../../context/notification';
const useUserPaymentMethodUpdateSuccessAlert = () => {
    const isPaymentMethodUpdated = useSelector(isUserPaymentMethodUpdatedSelector);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isPaymentMethodUpdated) {
            id.current = addNotification({
                type: 'success',
                message: 'Payment method has been updated.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isPaymentMethodUpdated]);
};
export default useUserPaymentMethodUpdateSuccessAlert;
