import React from 'react';
import Container from '@hero/ui-kit/layout/Container';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import useRmaEnums from '../../../pages/Rma/utils/useRmaEnums';
import ConfirmSection from './ConfirmSection';
import PickupLocation from './PickupLocation';
import ReturnLocationSelect from '../../../pages/Rma/components/ReturnLocationSelect';
import { RmaEnums } from '@hero/hero-types';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

type Props = {
    buttonLabel?: string;
    state?: string;
    isManual?: boolean;
    rmaEnums?: RmaEnums;
};

const ReturnShippingFormFields: React.FC<Props> = ({
    buttonLabel = 'Complete RMA',
    isManual = false,
    state,
    rmaEnums
}) => {
    const { shippingOptionOptions, returnLocations } = useRmaEnums(rmaEnums);

    const { setValue, watch } = useFormContext();

    const returnLocation = watch('return_location');

    const handleReturnLocationChange = React.useCallback(
        (value: string) => {
            setValue('return_location', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        },
        [setValue]
    );

    return (
        <>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                <ReturnLocationSelect
                    onChange={handleReturnLocationChange}
                    returnLocations={returnLocations}
                    defaultValue={returnLocation}
                />
                <Select
                    defaultValue={
                        shippingOptionOptions && shippingOptionOptions[0]
                            ? shippingOptionOptions[0][0]
                            : undefined
                    }
                    name="return_shipping_option"
                    displayName="Shipping Option"
                    visibleLimit={5}
                    noExtraMargin
                >
                    {shippingOptionOptions.map(([value, label]) => {
                        return <Option key={value} value={value} labelText={label} />;
                    })}
                </Select>
            </Container>
            <StandaloneCheckbox
                name="is_manual_return_shipping"
                labelText="Create shipping manually"
            />
            {isManual ? (
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <Input name="return_shipping_label_url" displayName="Shipping Label URL" />
                    <Input name="return_tracking_number" displayName="Tracking Number" />
                </Container>
            ) : (
                <PickupLocation state={state} />
            )}
            <ConfirmSection buttonLabel={buttonLabel} />
        </>
    );
};

export default React.memo(ReturnShippingFormFields, (prevProps, nextProps) => {
    return (
        prevProps.isManual === nextProps.isManual && prevProps.buttonLabel === nextProps.buttonLabel
    );
});
