import React, { CSSProperties } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';

const DetailsContainer: React.FC<UiComponentProps & { children?: React.ReactNode }> = ({
    children,
    styles
}) => {
    const { sizing } = useTheme();

    const mergedStyles: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: 'min-content auto',
        gridColumnGap: sizing.component.margin.large,
        gridRowGap: sizing.component.margin.regular,
        margin: 0,
        ...sizing.container.large,
        ...styles
    };

    return <dl style={mergedStyles}>{children}</dl>;
};

export default DetailsContainer;
