import React from 'react';
import { noop } from 'ts-essentials';

import membershipFilterSchema from '@hero/validators/forms/backoffice/membershipFilterSchema';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Button from '@hero/ui-kit/inputs/Button';

import useResetFiltersQuery from '../../../../hooks/useResetFiltersQuery';
import useFilterQuery from '../../../../hooks/useFilterQuery';

import useInputChangeHandler from '../../../../hooks/useInputChangeHandler';

import useMembershipListOptions from './useMembershipListOptions';
import useMembershipFilterFormStyles from './useMembershipFilterFormStyles';

const MembershipFilterForm: React.FC = () => {
    const [status, handleStatusChange] = useFilterQuery('status');
    const [plan_id, handlePlanIdChange] = useFilterQuery('plan_id');
    const [activation_type, handleActivationTypeChange] = useFilterQuery('activation_type');
    const [activaton_from, rawHandleActivationFromChange] = useFilterQuery('activation_from');
    const handleActivationFromChange = useInputChangeHandler(rawHandleActivationFromChange);
    const [activaton_to, rawHandleActivationToChange] = useFilterQuery('activation_to');
    const handleActivationToChange = useInputChangeHandler(rawHandleActivationToChange);
    const { planOptions, statusOptions, activationTypeOptions } = useMembershipListOptions();
    const activeFilters = { status, plan_id, activation_type, activaton_from, activaton_to };
    const handleReset = useResetFiltersQuery();

    const {
        filterMargin,
        filterWrapStyles,
        activationFilterStyles,
        statusFilterStyles,
        planFilterStyles,
        resetButtonWrapMargin,
        resetButtonMargin
    } = useMembershipFilterFormStyles();

    return (
        <Form
            validationSchema={membershipFilterSchema}
            defaultValues={activeFilters}
            submitFn={noop}
        >
            <div style={filterWrapStyles}>
                <Select
                    name="activation_type"
                    displayName="Activation Type"
                    onChange={handleActivationTypeChange}
                    defaultValue={activation_type}
                    styles={activationFilterStyles}
                    noExtraMargin
                >
                    {activationTypeOptions}
                </Select>
                <Select
                    name="status"
                    displayName="Membership Status"
                    onChange={handleStatusChange}
                    defaultValue={status}
                    styles={statusFilterStyles}
                    noExtraMargin
                >
                    {statusOptions}
                </Select>
                <Select
                    name="plan_id"
                    displayName="Subscription Plan"
                    defaultValue={plan_id}
                    onChange={handlePlanIdChange}
                    styles={planFilterStyles}
                    noExtraMargin
                >
                    {planOptions}
                </Select>
                <div style={filterMargin}>
                    <Input
                        size="small"
                        type="date"
                        placeholder="yyyy-mm-dd"
                        name="activation_from"
                        displayName="Activation From"
                        onChange={handleActivationFromChange}
                        defaultValue={activaton_from}
                    />
                </div>
                <div style={filterMargin}>
                    <Input
                        size="small"
                        type="date"
                        placeholder="yyyy-mm-dd"
                        name="activation_to"
                        displayName="Activation To"
                        onChange={handleActivationToChange}
                        defaultValue={activaton_to}
                    />
                </div>
                <div style={resetButtonWrapMargin}>
                    <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                        Reset
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default MembershipFilterForm;
