import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

export const RefundCharge: React.FC = () => {
    const { onRefundCharge, refundChargeDetails } = useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'large' });

    return (
        <>
            <H role="h6" centered>
                {refundChargeDetails.header}
            </H>
            <Section
                noDefaultPadding
                styles={{ textAlign: 'left', ...marginTop }}
                width="full"
                centered
            >
                <P size="large" noDefaultMargin>
                    {refundChargeDetails.details}
                </P>
            </Section>
            <Section centered>
                <Button width="large" onClick={onRefundCharge}>
                    {refundChargeDetails.btnLabel}
                </Button>
            </Section>
        </>
    );
};
