import React, { useMemo } from 'react';
import { chargeFeeParams } from '@hero/validators/forms/backoffice/chargeFeeSchema';
import { CancellationFeeParams } from '@hero/validators/forms/backoffice/cancellationFeeSchema';
import { TableColumn } from '@hero/ui-kit/tables/Table';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    getCancellationFeeChargeAttemptPreview,
    cancellationFeeChargeAttemptPreviewReset
} from '@hero/redux-data/backoffice/cancellationFeeChargeAttemptPreview/actionCreators';
import {
    cancellationAttemptFeeCharge,
    cancellationAttemptFeeChargeReset
} from '@hero/redux-data/backoffice/cancellationAttemptFeeCharge/actionCreators';
import {
    cancellationFeeChargeAttemptPreviewSelector,
    isCancellationFeeChargeAttemptPreviewLoadingSelector,
    cancellationFeeChargeAttemptPreviewErrorSelector
} from '@hero/redux-data/backoffice/cancellationFeeChargeAttemptPreview/selectors';
import {
    cancellationAttemptFeeChargeSelector,
    isCancellationAttemptFeeChargeLoadingSelector,
    cancellationAttemptFeeChargeErrorSelector
} from '@hero/redux-data/backoffice/cancellationAttemptFeeCharge/selectors';
import { Cancellation, FeeType } from '@hero/hero-types';
import { formatDate } from '@hero/hero-utils/date';
import formatDollarAmount from '@hero/hero-utils/currency';

type ChargeSteps = 'Details' | 'Confirm' | 'Success' | 'Fail';

export interface BillingAttempts {
    id: number;
    amount: string;
    status: string;
    date: string;
}

const billingAttemptHeaders: TableColumn<BillingAttempts>[] = [
    {
        colKey: 'date',
        name: 'Date'
    },
    {
        colKey: 'amount',
        name: 'Amount'
    },
    {
        colKey: 'status',
        name: 'Status'
    }
];

type ChargeFlowContextType = {
    confirmCancellationCharge: (attributes: CancellationFeeParams) => void;
    confirmCharge: (attributes: chargeFeeParams) => void;
    makeCharge: () => void;
    amountOutstandingLabel: string;
    note: string;
    previousAttemptsLabel: string;
    amountBilledLabel: string;
    amountRemainingLabel: string;
    amountToBeChargedLabel: string;
    billingAttempts: BillingAttempts[];
    billingAttemptHeaders: TableColumn<BillingAttempts>[];
    isLoading: boolean;
    chargeStep: ChargeSteps;
    errorData: {
        previewError: boolean;
        chargeError: boolean;
        failedMessage: string;
        errorMessage: string;
    };
    closeChargeModal: () => void;
    outstandingAmount: number;
    chargeContext: FeeType;
    cancellationMonths: { min: number; max: number };
};

const ChargeFlowContext = React.createContext<ChargeFlowContextType | undefined>(undefined);

export const useChargeFlowContext = () => {
    const ctx = React.useContext(ChargeFlowContext);

    if (ctx === undefined) {
        throw new Error(`'useChargeFlowContext' must be used within a 'ChargeFlowContextProvider'`);
    }

    return ctx;
};

const prepareAmountForBackend = (amount: number): number => {
    const a = amount * 100;
    return +a.toFixed(2);
};

type IChargeFlowProvider = {
    membershipId: number;
    children: React.ReactNode;
    chargeContext?: FeeType;
    onCompleteFlow?: () => void;
    cancellation?: Cancellation;
} & Pick<ModalProps, 'externalControls'>;

const ChargeFlowProvider = ({
    membershipId,
    children,
    chargeContext = 'unreturned_device_fee',
    externalControls,
    onCompleteFlow,
    cancellation
}: IChargeFlowProvider) => {
    const [, setExternalState] = externalControls ?? [];
    const dispatch = useDispatch();
    const cancellationChargeFeePreview = useSelector(
        cancellationFeeChargeAttemptPreviewSelector,
        shallowEqual
    );
    const isChargeFeePreviewLoading = useSelector(
        isCancellationFeeChargeAttemptPreviewLoadingSelector,
        shallowEqual
    );
    const {
        error: previewError,
        errorMessage: previewErrorMessage,
        originalMessage: previewOriginalMessage
    } = useSelector(cancellationFeeChargeAttemptPreviewErrorSelector, shallowEqual);

    const feeCharged = useSelector(cancellationAttemptFeeChargeSelector, shallowEqual);
    const isCancellationAttemptFeeChargeLoading = useSelector(
        isCancellationAttemptFeeChargeLoadingSelector,
        shallowEqual
    );
    const {
        error: chargeError,
        errorMessage: chargeErrorMessage,
        originalMessage: chargeOriginalMessage
    } = useSelector(cancellationAttemptFeeChargeErrorSelector, shallowEqual);

    const errorMessageText =
        previewOriginalMessage ||
        chargeOriginalMessage ||
        previewErrorMessage ||
        chargeErrorMessage;

    const [chargeStep, setChargeStep] = React.useState<ChargeSteps>('Details');
    const isLoading = isChargeFeePreviewLoading || isCancellationAttemptFeeChargeLoading;

    const [amountToBeCharged, setAmountToBeCharged] = React.useState(0);
    const [cancellationChargeMonths, setCancellationChargeMonths] = React.useState(1);

    const feeAttemptAmount = React.useMemo<number>(() => {
        const CFeeAttemptAmount =
            cancellationChargeFeePreview?.cancellation_fee.fee_attempt_amount || 0;
        const UDFeeAttemptAmount =
            cancellationChargeFeePreview?.unreturned_device_fee.fee_attempt_amount || 0;

        return chargeContext === 'cancellation_fee' ? CFeeAttemptAmount : UDFeeAttemptAmount;
    }, [chargeContext, cancellationChargeFeePreview]);

    React.useEffect(() => {
        dispatch(
            getCancellationFeeChargeAttemptPreview({
                subscriptionId: membershipId,
                fee_type: chargeContext
            })
        );
    }, [dispatch, chargeContext, membershipId]);

    const isError = previewError || chargeError;

    React.useEffect(() => {
        if (isError) {
            setChargeStep('Fail');
        }
    }, [isError]);

    React.useEffect(() => {
        if (feeCharged?.amount_remaining || feeCharged?.amount_remaining === 0) {
            setChargeStep(feeCharged?.attempt_success ? 'Success' : 'Fail');
        }
    }, [feeCharged]);

    React.useEffect(() => {
        if (feeAttemptAmount > 0) {
            setChargeStep('Confirm');
        }
    }, [feeAttemptAmount]);

    const confirmCharge = React.useCallback(
        (attributes: chargeFeeParams) => {
            const { amount } = attributes;
            setAmountToBeCharged(amount);
            dispatch(
                getCancellationFeeChargeAttemptPreview({
                    subscriptionId: membershipId,
                    fee_type: chargeContext,
                    amount: prepareAmountForBackend(amount)
                })
            );
        },
        [membershipId, chargeContext, dispatch]
    );

    const confirmCancellationCharge = React.useCallback(
        (attributes: CancellationFeeParams) => {
            const { months } = attributes;
            setCancellationChargeMonths(months);
            dispatch(
                getCancellationFeeChargeAttemptPreview({
                    subscriptionId: membershipId,
                    fee_type: chargeContext,
                    months
                })
            );
        },
        [dispatch, chargeContext, membershipId]
    );

    const makeCharge = React.useCallback(() => {
        dispatch(
            cancellationAttemptFeeCharge({
                ...(chargeContext === 'cancellation_fee' && { months: cancellationChargeMonths }),
                ...(chargeContext === 'unreturned_device_fee' && {
                    amount: prepareAmountForBackend(amountToBeCharged)
                }),
                subscription_id: membershipId,
                fee_type: chargeContext
            })
        );
    }, [amountToBeCharged, membershipId, dispatch, chargeContext, cancellationChargeMonths]);

    const errorData = useMemo(() => {
        const amountOutstanding =
            chargeContext === 'cancellation_fee'
                ? cancellation?.cancellation_fee_outstanding
                : cancellation?.unreturned_device_fee_outstanding;
        return {
            previewError,
            chargeError,
            errorMessage: errorMessageText || 'Something when wrong, please contact support',
            failedMessage: `Charge Failed. Amount remaining: ${formatDollarAmount(
                amountOutstanding || 0,
                true
            )}`
        };
    }, [errorMessageText, cancellation, chargeContext, previewError, chargeError]);

    const note = 'Note: Please allow at least 5 days between retries';

    const previousAttemptsLabel = React.useMemo(() => {
        return chargeContext === 'cancellation_fee'
            ? 'Previous Cancellation Fee billing attempts'
            : 'Previous Unreturned Device Fee billing attempts';
    }, [chargeContext]);

    const feeOutstandingAmount = React.useMemo<number>(() => {
        const CFeeOutstandingAmount =
            cancellationChargeFeePreview?.cancellation_fee.fee_outstanding || 0;
        const UDFeeOutstandingAmount =
            cancellationChargeFeePreview?.unreturned_device_fee.fee_outstanding || 0;

        return chargeContext === 'cancellation_fee'
            ? CFeeOutstandingAmount
            : UDFeeOutstandingAmount;
    }, [cancellationChargeFeePreview, chargeContext]);

    const amountOutstandingLabel = React.useMemo(() => {
        return `Amount Outstanding: ${formatDollarAmount(feeOutstandingAmount, true)} + Tax`;
    }, [feeOutstandingAmount]);

    const amountBilledLabel = React.useMemo(() => {
        return `You have successfully billed ${formatDollarAmount(
            feeCharged?.attempt_details.amount || 0,
            true
        )} + Tax (${formatDollarAmount(feeCharged?.attempt_details.tax || 0, true)})`;
    }, [feeCharged]);

    const amountRemainingLabel = React.useMemo(() => {
        return `Amount remaining: ${formatDollarAmount(feeCharged?.amount_remaining || 0, true)}`;
    }, [feeCharged]);

    const amountToBeChargedLabel = React.useMemo(() => {
        return `Amount to be charged: ${formatDollarAmount(feeAttemptAmount, true)} + Tax`;
    }, [feeAttemptAmount]);

    const closeChargeModal = React.useCallback(() => {
        setExternalState && setExternalState(false);
        dispatch(cancellationFeeChargeAttemptPreviewReset());
        dispatch(cancellationAttemptFeeChargeReset());
        setAmountToBeCharged(0);
        if (['Fail', 'Success'].includes(chargeStep)) {
            onCompleteFlow && onCompleteFlow();
            setChargeStep('Details');
        }
        if (chargeStep === 'Confirm') {
            setChargeStep('Details');
        }
    }, [setExternalState, dispatch, onCompleteFlow, chargeStep]);

    const billingAttempts: BillingAttempts[] = React.useMemo(() => {
        if (cancellationChargeFeePreview?.past_attempts?.length) {
            return cancellationChargeFeePreview.past_attempts.map((item) => ({
                id: item.id,
                date: formatDate(item.attempted_at),
                amount: `${formatDollarAmount(item.amount, true)} + Tax(${formatDollarAmount(
                    item.tax,
                    true
                )})`,
                status: item.success ? 'Success' : 'Fail'
            }));
        }
        return [];
    }, [cancellationChargeFeePreview]);

    const outstandingAmount = useMemo(() => {
        return feeOutstandingAmount > 0 ? feeOutstandingAmount / 100 : 0;
    }, [feeOutstandingAmount]);

    const cancellationMonths = React.useMemo(() => {
        return {
            min: 1,
            max: cancellation?.cancellation_fee?.months || 1
        };
    }, [cancellation]);

    return (
        <ChargeFlowContext.Provider
            value={{
                makeCharge,
                confirmCharge,
                confirmCancellationCharge,
                amountOutstandingLabel,
                note,
                previousAttemptsLabel,
                amountBilledLabel,
                amountRemainingLabel,
                billingAttempts,
                billingAttemptHeaders,
                isLoading,
                chargeStep,
                closeChargeModal,
                errorData,
                amountToBeChargedLabel,
                outstandingAmount,
                chargeContext,
                cancellationMonths
            }}
        >
            {children}
        </ChargeFlowContext.Provider>
    );
};

export default ChargeFlowProvider;
