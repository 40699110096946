import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import useCheckIfEmailBlacklisted from '../api/useCheckIfEmailBlacklisted';
import SuppressionEmailForm from './SuppressionEmailForm';
import { SuppressionEmailParams } from './schema';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useRemoveEmailFromBlacklist from '../api/useRemoveEmailFromBlacklist';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import QueryInlineError from '../../../components/QueryInlineError';

type SuppressionEmailProps = {} & Pick<ModalProps, 'externalControls'>;

type InitialState = {
    isBlacklisted: boolean;
    email?: string;
};

const initialState: InitialState = {
    isBlacklisted: false,
    email: undefined
};

const SuppressionEmail: React.FC<SuppressionEmailProps> = ({ externalControls }) => {
    const [, setExternalState] = externalControls ?? [];
    const {
        mutateAsync: checkEmail,
        isSuccess: isCheckSuccess,
        isError: isCheckError,
        isPending: isCheckLoading,
        error: checkError,
        reset: checkReset
    } = useCheckIfEmailBlacklisted();
    const {
        mutateAsync: removeEmail,
        isSuccess: isRemoveSuccess,
        isError: isRemoveError,
        isPending: isRemoveLoading,
        error: removeError,
        reset: removeReset
    } = useRemoveEmailFromBlacklist();

    const handleClose = () => {
        setState(initialState);
        setExternalState && setExternalState(false);
        removeReset();
        checkReset();
    };

    const [state, setState] = React.useState<InitialState>(initialState);

    const handleCheck = (attributes: SuppressionEmailParams) => {
        setState(initialState);
        checkEmail(attributes)
            .then((response) => {
                setState({ isBlacklisted: response.data.suspended, email: response.data.email });
            })
            .catch(() => null);
    };

    const handleRemoveEmailFromBlacklist = () => {
        state.isBlacklisted
            ? state.email &&
              removeEmail({ email: state.email })
                  .then((response) => {
                      response.data.removed &&
                          setState({
                              isBlacklisted: false,
                              email: response.data.email
                          });
                  })
                  .catch(() => null)
            : handleClose();
    };

    const stateProps = React.useMemo(() => {
        let description = '';

        if (isCheckSuccess) {
            description = `Email: ${state.email} ${
                state.isBlacklisted ? 'is' : 'is not'
            } blacklisted`;
        }
        if (isRemoveSuccess) {
            description = `Email: ${state.email} has successfully removed from blacklist.`;
        }
        return {
            isSuccess: isCheckSuccess || isRemoveSuccess,
            isCheckSuccess,
            isRemoveSuccess,
            description,
            btnLabel: state.isBlacklisted ? 'Remove Email from Blacklist' : 'Ok'
        };
    }, [isCheckSuccess, isRemoveSuccess, state]);

    return (
        <Modal
            type={state.isBlacklisted ? 'warn' : 'neutral'}
            onClose={handleClose}
            isCancelable
            externalControls={externalControls}
        >
            <H role="h4">Suppression Email</H>
            {stateProps.isSuccess ? (
                <Section
                    noDefaultPadding
                    styles={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
                >
                    <P strong size="large" centered>
                        {stateProps.description}
                    </P>
                    <Button
                        width="full"
                        onClick={handleRemoveEmailFromBlacklist}
                        disabled={isRemoveLoading}
                    >
                        {stateProps.btnLabel}
                    </Button>
                </Section>
            ) : (
                <SuppressionEmailForm onSubmit={handleCheck} isLoading={isCheckLoading} />
            )}
            {isRemoveError || isCheckError ? (
                <QueryInlineError centered error={removeError || checkError} />
            ) : null}
        </Modal>
    );
};

export default SuppressionEmail;
