import { useMemo } from 'react';
const useGetRmaFulfillmentSerialNumber = (rma) => {
    return useMemo(() => {
        const { fulfillment_request } = rma ?? {};
        const { fulfillments } = fulfillment_request ?? {};
        const items = fulfillments?.length ? fulfillments[0].items : undefined;
        if (items?.length && items[0].serial_number !== null) {
            return items[0].serial_number;
        }
        return undefined;
    }, [rma]);
};
export default useGetRmaFulfillmentSerialNumber;
