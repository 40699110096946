import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setAllowReprogramming } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';

import { DetailLabel, Detail } from '../../../components/Details';
import YesNoOptions from '../../../components/YesNoOptions';

type Props = {
    external_serial: string | null;
    isRefurbishmentAdmin: boolean;
    allow_reprogramming?: boolean;
};

const ReprogrammingDetails: React.FC<Props> = ({
    external_serial,
    isRefurbishmentAdmin,
    allow_reprogramming
}) => {
    const dispatch = useDispatch();
    const handleAllowReprogramming = useCallback(
        (flag: boolean) =>
            external_serial &&
            dispatch(setAllowReprogramming({ external_serial, allow_reprogramming: flag })),
        [external_serial, dispatch]
    );

    return (
        <>
            <DetailLabel>Allow Reprogramming</DetailLabel>
            <Detail>
                {isRefurbishmentAdmin && external_serial ? (
                    <YesNoOptions
                        predicate={!!allow_reprogramming}
                        handleToggle={handleAllowReprogramming}
                    />
                ) : allow_reprogramming === true ? (
                    'Yes'
                ) : allow_reprogramming === false ? (
                    'No'
                ) : (
                    '-'
                )}
            </Detail>
        </>
    );
};

export default ReprogrammingDetails;
