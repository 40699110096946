// state (0 - scheduled, 1 - consumed on time, 2 - consumed late, 3 - skipped, 4 - missed, 5 - unscheduled, 6 - ignored)
const getUserAdherenceStateLabel = (state) => {
    if (state === 0) {
        return 'Scheduled';
    }
    if (state === 1) {
        return 'Consumed on Time';
    }
    if (state === 2) {
        return 'Consumed Late';
    }
    if (state === 3) {
        return 'Skipped';
    }
    if (state === 4) {
        return 'Missed';
    }
    if (state === 5) {
        return 'Unscheduled';
    }
    if (state === 6) {
        return 'Ignored';
    }
    return '-';
};
export default getUserAdherenceStateLabel;
