import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { rmaRequestSaveErrorSelector } from '@hero/redux-data/backoffice/rmaRequestSave/selectors';
import { useNotifications } from '../../context/notification';
const useRmaSaveFailAlert = () => {
    const { error, errorMessage, originalMessage } = useSelector(rmaRequestSaveErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage === 'previous_rma_not_completed' ||
                    originalMessage === 'object_not_found'
                    ? errorMessage || 'Error saving RMA.'
                    : originalMessage?.replace(/[_-]/g, ' ') ||
                        'Validation error. Please check input data.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage, originalMessage]);
};
export default useRmaSaveFailAlert;
