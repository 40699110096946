import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { orgMemberRtmElection } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import { OrganizationMemberRtmElectionDetails } from '../types';

const useGetOrganizationMemberRtmElectionDetails = (
    params: { member_id: number },
    enabled?: boolean
) => {
    const additional = useQueryRequestParams();

    return useQuery<AxiosResponse<OrganizationMemberRtmElectionDetails>, AxiosError>({
        queryFn: () =>
            axios.get(`${envVars.API_CLOUD_HOSTNAME}backoffice/organization/member-rtm-election`, {
                ...additional,
                params
            }),
        queryKey: [orgMemberRtmElection, params.member_id],
        enabled
    });
};

export default useGetOrganizationMemberRtmElectionDetails;
