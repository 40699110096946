import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import usePagerProps from '../../../hooks/usePagerProps';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import getTableColumns from './utils/getTableColumns';
import repackTableData from './utils/repackTableData';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import useGetOrganizationMembers from '../api/useGetOrganizationMembers';
import useGetOrganization from '../api/useGetOrganization';
const useEnterpriseMemberListData = () => {
    const { id: organization_id } = useParams();
    const { offset, limit = 10, sort_by, search, sort_dir, membership_status } = getQueryParams();
    const { data, isLoading: isFetchingMembers } = useGetOrganizationMembers({
        offset,
        limit,
        sort_by,
        search,
        sort_dir,
        membership_status,
        organization_id
    }, !!organization_id);
    const { data: orgResponse } = useGetOrganization({ id: organization_id ? +organization_id : 0 }, !!organization_id);
    const organization = data?.data?.objects && data?.data?.objects.length
        ? data.data.objects[0].organization
        : orgResponse?.data;
    const memberList = data?.data.objects || [];
    const paginationData = {
        total_count: data?.data.total_count || 0,
        offset: data?.data.offset || 0,
        limit: data?.data.limit || 0
    };
    const tableColumns = useMemo(() => {
        return getTableColumns(organization_id ? +organization_id : 0);
    }, [organization_id]);
    const tableData = useMemo(() => repackTableData(memberList), [memberList]);
    const pagerProps = usePagerProps(paginationData);
    const [sort, handleSort] = useTableSortQuery();
    return {
        organization,
        tableColumns,
        tableData,
        isFetchingMembers,
        sort,
        handleSort,
        pagerProps
    };
};
export default useEnterpriseMemberListData;
