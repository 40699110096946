import * as React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import { SetRmaRequestPickUpParams } from '@hero/validators/forms/backoffice/rmaRequestSchema';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

interface ConfirmSectionProps {
    buttonLabel?: string;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({ buttonLabel = 'Complete RMA' }) => {
    const {
        formState: { isValid }
    } = useFormContext<SetRmaRequestPickUpParams>();

    return (
        <Section centered noDefaultPadding marginTop="regular">
            <Button width="large" type="submit" disabled={!isValid}>
                {buttonLabel}
            </Button>
        </Section>
    );
};

export default ConfirmSection;
