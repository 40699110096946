const getMemberStatusLabel = (status) => {
    switch (status) {
        case 'ENROLLED':
            return 'Enrolled';
        case 'UNENROLLED':
            return 'Unenrolled';
        case 'ACTIVATED':
            return 'Active';
        case 'PRE_CANCELLATION':
            return 'Pre-Cancellation';
        case 'CANCELLED':
            return 'Cancelled';
        default:
            return '-';
    }
};
export default getMemberStatusLabel;
