import React from 'react';
import { noop } from 'ts-essentials';

import adherenceFilterSchema from '@hero/validators/forms/backoffice/adherenceFilterSchema';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Button from '@hero/ui-kit/inputs/Button';
import useResetFiltersQuery from '../../../../hooks/useResetFiltersQuery';
import useFilterQuery from '../../../../hooks/useFilterQuery';
import useInputChangeHandler from '../../../../hooks/useInputChangeHandler';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import getFilterOptions from '../../../../utils/getFilterOptions';
import { normalizeDateString } from '@hero/hero-utils/date';

const AdherenceFilterForm: React.FC = () => {
    const [state, handleStateChange] = useFilterQuery('state');
    const [type, handleAdherenceTypeChange] = useFilterQuery('type');
    const [datetime_from, rawHandleDatetimeFromChange] = useFilterQuery('datetime_from');
    const handleDatetimeFromChange = useInputChangeHandler((value) => {
        rawHandleDatetimeFromChange(normalizeDateString(value, true).replace('T', ' '));
    });
    const [datetime_to, rawHandleDatetimeToChange] = useFilterQuery('datetime_to');
    const handleDatetimeToChange = useInputChangeHandler((value) => {
        rawHandleDatetimeToChange(normalizeDateString(value, true).replace('T', ' '));
    });

    const activeFilters = { state, type, datetime_from, datetime_to };
    const handleReset = useResetFiltersQuery();

    const filterMargin = useComponentMargin({ marginRight: 'regular' });
    const resetButtonMargin = useComponentMargin({ marginBottom: 'regular' });
    const resetButtonWrapMargin = useComponentMargin({ marginTop: 'regular' });
    const filterWrapStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    };

    const stateFilterStyles: React.CSSProperties = {
        width: '26rem',
        ...filterMargin
    };
    const typeFilterStyles: React.CSSProperties = {
        width: '32rem',
        ...filterMargin
    };

    const typeOptions = React.useMemo(
        () =>
            getFilterOptions([
                ['0', 'Inside hero'],
                ['1', 'Outside hero'],
                ['2', 'Future dose']
            ]),
        []
    );

    // state (0 - scheduled, 1 - consumed on time, 2 - consumed late, 3 - skipped, 4 - missed, 5 - unscheduled, 6 - ignored)

    const statusOptions = React.useMemo(
        () =>
            getFilterOptions([
                ['0', 'Scheduled'],
                ['1', 'Consumed on Time'],
                ['2', 'Consumed Late'],
                ['3', 'Skipped'],
                ['4', 'Missed'],
                ['5', 'Unscheduled'],
                ['6', 'Ignored']
            ]),
        []
    );

    return (
        <Form
            validationSchema={adherenceFilterSchema}
            defaultValues={activeFilters}
            submitFn={noop}
        >
            <div style={filterWrapStyles}>
                <Select
                    name="state"
                    displayName="Status"
                    onChange={handleStateChange}
                    defaultValue={state}
                    styles={stateFilterStyles}
                    noExtraMargin
                >
                    {statusOptions}
                </Select>
                <Select
                    name="type"
                    displayName="Type"
                    defaultValue={type}
                    onChange={handleAdherenceTypeChange}
                    styles={typeFilterStyles}
                    noExtraMargin
                >
                    {typeOptions}
                </Select>
                <div style={filterMargin}>
                    <Input
                        size="small"
                        type="date"
                        placeholder="yyyy-mm-dd"
                        name="datetime_from"
                        displayName="Range From"
                        onChange={handleDatetimeFromChange}
                        defaultValue={datetime_from}
                    />
                </div>
                <div style={filterMargin}>
                    <Input
                        size="small"
                        type="date"
                        placeholder="yyyy-mm-dd"
                        name="datetime_to"
                        displayName="Range To"
                        onChange={handleDatetimeToChange}
                        defaultValue={datetime_to}
                    />
                </div>
                <div style={resetButtonWrapMargin}>
                    <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                        Reset
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default AdherenceFilterForm;
