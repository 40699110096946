import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from './useSetQueryParams';
const useTableSortQuery = () => {
    const { sort_by = 'created_at', sort_dir = 'DESC', ...rest } = getQueryParams();
    const setQueryParams = useSetQueryParams();
    const handleSetTableSortQuery = (newSortBy, newSortDir) => {
        const newQueryParams = buildQuery({
            ...rest,
            sort_by: newSortBy,
            sort_dir: newSortDir
        });
        setQueryParams(newQueryParams);
    };
    return [{ sort_by, sort_dir }, handleSetTableSortQuery];
};
export default useTableSortQuery;
