import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { orgCancellationDetails } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetCancellationEnterpriseMembershipDetails = (props, enabled) => {
    const additional = useQueryRequestParams();
    const id = props.id;
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/cancellation/details/`, { ...additional, params: { ...props } }),
        queryKey: [orgCancellationDetails, id],
        enabled
    });
};
export default useGetCancellationEnterpriseMembershipDetails;
