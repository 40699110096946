import React from 'react';

import useFilters from '../../../hooks/useFiltersToggle';
import SearchForm from '../../../components/SearchForm';
import PageSizeForm from '../../../components/PageSizeForm';
import FiltersToggle from '../../../components/FiltersToggle';

import EnterpriseMemberFilterForm from './EnterpriseMemberFilterForm';
import {
    headerWrapStyles,
    searchWrapStyles,
    filterToggleStyles
} from './enterpriseMemberListFilteringStyles';

const EnterpriseMemberListFiltering: React.FC = () => {
    const [areFiltersExpanded, toggleFiltersExpansion] = useFilters();

    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <SearchForm placeholder="Search by name, email, serial # or invitation code" />
                    <FiltersToggle
                        isExpanded={areFiltersExpanded}
                        onClick={toggleFiltersExpansion}
                        styles={filterToggleStyles}
                    />
                </div>
                <PageSizeForm />
            </div>
            {areFiltersExpanded && <EnterpriseMemberFilterForm />}
        </>
    );
};

export default EnterpriseMemberListFiltering;
