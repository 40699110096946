import { TableColumn } from '@hero/ui-kit/tables/Table';
import { UserAdherenceTableDataObject } from './repackTableData';

type GetTableColumns = () => TableColumn<UserAdherenceTableDataObject>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'id',
        name: 'ID',
        align: 'left',
        headAlign: 'left'
    },
    {
        colKey: 'type',
        name: 'Type'
    },
    {
        colKey: 'scheduled',
        name: 'Scheduled',
        isSortable: true
    },
    {
        colKey: 'actual',
        name: 'Actual'
    },
    {
        colKey: 'status',
        name: 'Status'
    }
];

export default getTableColumns;
