import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
const useMemberFilterFormStyles = () => {
    const filterMargin = useComponentMargin({ marginRight: 'regular' });
    const resetButtonMargin = useComponentMargin({ marginBottom: 'regular' });
    const resetButtonWrapMargin = useComponentMargin({ marginTop: 'regular' });
    const filterWrapStyles = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    };
    const memberStatusFilterStyles = {
        width: '26rem',
        ...filterMargin
    };
    return {
        filterWrapStyles,
        memberStatusFilterStyles,
        resetButtonMargin,
        resetButtonWrapMargin
    };
};
export default useMemberFilterFormStyles;
