import React from 'react';

import AlertBar, { Type as AlertType } from '@hero/ui-kit/components/AlertBar';

export type Alert = {
    type: AlertType;
    message: string;
};

type Props = {
    alert: Alert;
    onDismiss: () => void;
};

const NotificationBar: React.FC<Props> = ({ alert, onDismiss }) => (
    <AlertBar
        type={alert.type}
        isDismissable
        autoDismiss
        autoDismissDelay="long"
        onDismiss={onDismiss}
    >
        {alert.message}
    </AlertBar>
);

export default NotificationBar;
