import React, { CSSProperties } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { PaymentUpdateParams } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import StripeInput from '@hero/ui-kit/components/StripeInput';

import StatelessInput from './StatelessInput';
import useStripeFields from './useStripeFields';
import useAdditionalFields from './useAdditionalFields';

type Props = {
    onSubmit: (formValues: PaymentUpdateParams) => void;
    onCancel?: () => void;
    isSubmitDisabled?: boolean;
};

const StripeForm: React.FC<Props> = ({ onSubmit, onCancel, isSubmitDisabled = false }) => {
    const {
        cardNumberError,
        handleCardNumberChange,
        cardExpError,
        handleCardExpChange,
        cardCvcError,
        handleCardCvcChange,
        handleSubmit,
        isLoading
    } = useStripeFields();

    const { values, handleChange, errors, validate } = useAdditionalFields();
    const curriedHandleSubmit = handleSubmit(values, validate, onSubmit);
    const [isDisabled, setIsDisabled] = React.useState(true);

    const hasError = React.useMemo<boolean>(() => {
        let isError = false;
        Object.values(errors).forEach((error) => {
            if (error.length > 0) {
                isError = true;
            }
        });
        return isError;
    }, [errors]);

    React.useEffect(() => {
        if (hasError) {
            setIsDisabled(true);
        } else {
            Object.values(values).forEach((value) => {
                if (value.length > 0) {
                    return setIsDisabled(false);
                }
            });
        }
    }, [values, hasError]);

    const innerWrapperStyles: CSSProperties = {
        width: '60rem'
    };

    return (
        <form onSubmit={curriedHandleSubmit}>
            <div style={innerWrapperStyles}>
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <StatelessInput
                        name="name_on_card"
                        displayName="Name on Card"
                        placeholder="Name on card"
                        value={values.name_on_card}
                        error={errors.name_on_card}
                        onChange={handleChange}
                    />
                    <StripeInput
                        name="card_number"
                        displayName="Card Number"
                        error={!!cardNumberError}
                        errorMessage={cardNumberError}
                    >
                        <CardNumberElement onChange={handleCardNumberChange} />
                    </StripeInput>
                </Container>
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <StripeInput
                        name="card_exp"
                        displayName="Expiration Date"
                        error={!!cardExpError}
                        errorMessage={cardExpError}
                    >
                        <CardExpiryElement onChange={handleCardExpChange} />
                    </StripeInput>
                    <StripeInput
                        name="card_cvc"
                        displayName="CVV/CVC"
                        error={!!cardCvcError}
                        errorMessage={cardCvcError}
                    >
                        <CardCvcElement onChange={handleCardCvcChange} />
                    </StripeInput>
                </Container>
                <StatelessInput
                    name="address1"
                    displayName="Billing Address"
                    placeholder="Street address"
                    value={values.address1}
                    error={errors.address1}
                    onChange={handleChange}
                />
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <StatelessInput
                        name="address2"
                        displayName="Billing Address Line 2"
                        placeholder="Apt, suite, etc."
                        value={values.address2}
                        error={errors.address2}
                        onChange={handleChange}
                    />
                    <StatelessInput
                        name="city"
                        displayName="City"
                        placeholder="City"
                        value={values.city}
                        error={errors.city}
                        onChange={handleChange}
                    />
                </Container>
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <StatelessInput
                        name="province"
                        displayName="State"
                        placeholder="State"
                        value={values.province}
                        error={errors.province}
                        onChange={handleChange}
                    />
                    <StatelessInput
                        name="zip_code"
                        displayName="Zip Code"
                        placeholder="90210"
                        value={values.zip_code}
                        error={errors.zip_code}
                        onChange={handleChange}
                    />
                </Container>
                <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                    <Button
                        width="large"
                        type="submit"
                        disabled={isLoading || isSubmitDisabled || isDisabled}
                    >
                        Update
                    </Button>
                    {onCancel && (
                        <Button width="large" variant="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                    )}
                </Section>
            </div>
        </form>
    );
};

export default StripeForm;
