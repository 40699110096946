import * as yup from 'yup';
const mitigationOffersSchema = yup
    .object()
    .shape({
    mitigation_offers: yup
        .mixed()
        .required('Mitigation offer is required')
        .oneOf(['regular-plans', 'hardship-offers'], 'Please select option')
})
    .defined();
export default mitigationOffersSchema;
