import React from 'react';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useChargeFlowContext } from '../context/ChargeFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

export const Success: React.FC = () => {
    const { amountRemainingLabel, amountBilledLabel, closeChargeModal } = useChargeFlowContext();
    const margin = useComponentMargin({ marginTop: 'regular' });

    return (
        <>
            <H role="h5">Success</H>
            <P noDefaultMargin size="large">
                {amountBilledLabel}
            </P>
            <P noDefaultMargin size="large">
                {amountRemainingLabel}
            </P>
            <Button width="large" styles={margin} onClick={closeChargeModal}>
                Ok
            </Button>
        </>
    );
};
