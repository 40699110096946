import React, { useMemo, useCallback } from 'react';

import Form from '@hero/ui-kit/inputs/Form';

import useSearchForm from './useSearchForm';
import getSearchSchema from './getSearchSchema';
import SearchBar from './SearchBar';

type Props = {
    name?: string;
    displayName?: string;
    placeholder?: string;
};

const SearchForm: React.FC<Props> = ({ name = 'search', ...rest }) => {
    // currently applied search term (to be compared w/ the input value)
    const [activeSearch, handleSetSearch] = useSearchForm(name);
    // not letting leading/trailing whitespace in ...
    const activeSearchTrimmed = (activeSearch || '').trim();

    const schema = useMemo(() => getSearchSchema(name), [name]);
    const defaultValues = useMemo(
        () => ({ [name]: activeSearchTrimmed }),
        [name, activeSearchTrimmed]
    );
    const handleSubmit = useCallback(
        (params: Record<string, string>) => {
            // ... or out
            handleSetSearch(params[name].trim());
        },
        [handleSetSearch, name]
    );

    return (
        <Form validationSchema={schema} defaultValues={defaultValues} submitFn={handleSubmit}>
            <SearchBar
                name={name}
                activeSearch={activeSearchTrimmed}
                onSubmit={handleSubmit}
                {...rest}
            />
        </Form>
    );
};

export default SearchForm;
