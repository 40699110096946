import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useDispatch } from 'react-redux';
import useOrgMemberReactivation from '../../api/useOrgMemberReactivation';
import { getEnterpriseMemberDetails } from '@hero/redux-data/backoffice/enterpriseMemberDetails/actionCreators';
import QueryInlineError from '../../../../components/QueryInlineError';

interface OrgMemberReactivationProps {
    externalDeviceSerial?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    memberId?: number;
    organizationId?: number;
    isResumeMembershipDisabled?: boolean;
}

const OrgMemberReactivation: React.FC<OrgMemberReactivationProps> = ({
    email,
    firstName,
    lastName,
    externalDeviceSerial,
    memberId,
    isResumeMembershipDisabled = false,
    organizationId
}) => {
    const dispatch = useDispatch();
    const [showEdit, setShowEdit] = React.useState(false);

    const handleClose = () => {
        setShowEdit(false);
        memberId && dispatch(getEnterpriseMemberDetails({ id: memberId }));
    };
    const { mutate, isError, isPending, isSuccess, error } = useOrgMemberReactivation();

    const handleReactivate = () => {
        if (externalDeviceSerial && organizationId) {
            mutate({
                external_device_serial: externalDeviceSerial,
                organization_id: organizationId
            });
        }
    };

    const name = React.useMemo(() => {
        if (firstName || lastName) {
            return `${firstName} ${lastName}`;
        }

        return '-';
    }, [firstName, lastName]);

    return (
        <>
            <Button
                size="small"
                onClick={() => setShowEdit(true)}
                disabled={isResumeMembershipDisabled}
                noDefaultMargin
            >
                Resume Membership
            </Button>
            <Modal
                type={isSuccess ? 'success' : 'warn'}
                onClose={handleClose}
                isCancelable
                externalControls={[showEdit, setShowEdit]}
            >
                <>
                    <Section centered paddingVertical="none">
                        <P noDefaultMargin size="small">{`${name} / ${email} / ID: ${memberId}`}</P>
                        <H role="h4">{isSuccess ? `Reactivation Complete` : `Resume Membership`}</H>
                    </Section>
                    {isSuccess ? (
                        <Section centered>
                            <P size="large" strong>
                                {`Successfully reactivated ${name}`}
                            </P>
                            <P>Tell the member:</P>
                            <P>
                                <strong>Thank you</strong> for staying with Hero!
                            </P>
                            <P>
                                We're always here to help -
                                <strong> improving your health and well-being</strong> is our goal.
                            </P>
                            <Button onClick={handleClose} width="full">
                                Ok
                            </Button>
                        </Section>
                    ) : (
                        <>
                            <Section centered paddingVertical="regular" paddingHorizontal="none">
                                <P>{`Are you sure you want to reactivate ${name} membership?`}</P>
                            </Section>
                            <Button
                                onClick={handleReactivate}
                                type="submit"
                                width="large"
                                disabled={isPending}
                            >
                                Reactivate
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                width="large"
                                disabled={isPending}
                            >
                                Cancel
                            </Button>
                            {isError ? <QueryInlineError centered error={error} /> : null}
                        </>
                    )}
                </>
            </Modal>
        </>
    );
};

export default OrgMemberReactivation;
