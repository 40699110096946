import { useState, useCallback, useMemo } from 'react';
import trimDeep from '@hero/hero-utils/trimDeep';
import useCancelMembership from '../pages/Membership/api/useCancelMembership';
import useCancelEnterpriseMembership from '../pages/Organizations/api/useCancelEnterpriseMembership';
const useCancellation = ({ isOrganizationMember = false, membership, enrollment, orgCancellationOptions, cancellationOptions, setReviewStep }) => {
    const id = isOrganizationMember ? enrollment?.id : membership?.id;
    const types = useMemo(() => {
        if (isOrganizationMember) {
            return orgCancellationOptions?.cancellation_options.cancellation_types || [];
        }
        return cancellationOptions?.cancellation_types || [];
    }, [isOrganizationMember, cancellationOptions, orgCancellationOptions]);
    const [submittedValues, setSubmittedValues] = useState(null);
    const [isCancellationDone, setCancellationDone] = useState(false);
    const [cancellationType, setCancellationType] = useState();
    const { data: cancellationDataResponse, mutateAsync: cancelMembership, isPending: isCancellingMembership, error: cancelMembershipError } = useCancelMembership();
    const { data: cancellationDataOrgResponse, mutateAsync: cancelEnterpriseMembership, isPending: isCancellingOrgMembership, error: cancelOrgMembershipError } = useCancelEnterpriseMembership();
    const cancellationData = cancellationDataResponse?.data.data;
    const cancellationOrgData = cancellationDataOrgResponse?.data;
    const isCancelling = isCancellingMembership || isCancellingOrgMembership;
    const cancellingError = cancelMembershipError || cancelOrgMembershipError;
    const handleCancellationFormNext = useCallback((formValues) => {
        const { cancellation_type, ...rest } = formValues;
        const selectedType = cancellation_type ?? types[0]?.code;
        if (id && selectedType) {
            setSubmittedValues(trimDeep({
                cancellation_type: selectedType,
                ...rest
            }));
            const cancellationTypeValue = types.find(({ code }) => code === selectedType);
            setCancellationType(cancellationTypeValue);
            setReviewStep();
        }
    }, [types, id, setSubmittedValues, setCancellationType]);
    const handleCancellationConfirm = useCallback(() => {
        if (id && submittedValues) {
            const { reason_code, reason_sub_code, notes, ...rest } = submittedValues;
            const attributes = {
                reason_code: +reason_code,
                reason_sub_code: +reason_sub_code,
                ...(notes && { notes }),
                ...rest
            };
            if (isOrganizationMember) {
                cancelEnterpriseMembership({
                    enrollment_id: id,
                    ...attributes
                })
                    .then(() => {
                    setCancellationDone(true);
                })
                    .catch(() => null);
            }
            else {
                cancelMembership({
                    id,
                    ...attributes
                })
                    .then(() => {
                    setCancellationDone(true);
                })
                    .catch(() => null);
            }
        }
    }, [id, submittedValues, cancelEnterpriseMembership, cancelMembership, isOrganizationMember]);
    return {
        handleCancellationFormNext,
        handleCancellationConfirm,
        cancellationType,
        isCancellationDone,
        isCancelling,
        cancellingError,
        cancellationData,
        cancellationOrgData
    };
};
export default useCancellation;
