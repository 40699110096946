import React from 'react';

import Section from '@hero/ui-kit/layout/Section';

import DefaultErrorModal from '../../../../components/DefaultErrorModal';
import useDeviceDetailsData from '../useDeviceDetailsData';
import Header from '../Header';
import ChangeRoleModal from './ChangeRoleModal';
import Button from '@hero/ui-kit/inputs/Button';
import DefaultLayout from '../../../../components/DefaultLayout';
import InlineLoader from '../../../../components/InlineLoader';

const ChangeRole: React.FC = () => {
    const {
        deviceDetails,
        isLoading,
        hasError,
        errorMessage,
        isAppSupportAdminAdmin,
        isRefurbishmentAdmin,
        roleChangeOptionsInDeviceDetails
    } = useDeviceDetailsData();

    const [showModal, setShowModal] = React.useState(false);

    return (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <Header
                    isAppSupportAdminAdmin={isAppSupportAdminAdmin}
                    isRefurbishmentAdmin={isRefurbishmentAdmin}
                    externalSerial={deviceDetails.external_serial}
                    deviceId={deviceDetails.id}
                />
                {isLoading ? (
                    <InlineLoader />
                ) : (
                    <>
                        <Button onClick={() => setShowModal(true)}>Change Role</Button>
                        <ChangeRoleModal
                            externalControls={[showModal, setShowModal]}
                            deviceDetails={deviceDetails}
                            roleChangeOptionsInDeviceDetails={roleChangeOptionsInDeviceDetails}
                        />
                    </>
                )}
            </Section>
        </DefaultLayout>
    );
};

export default ChangeRole;
