import Cookie from '@hero/api-services/utils/cookie';
import { accessTokenSelector } from '@hero/redux-data/auth/selectors';
import { useSelector } from 'react-redux';
function privateUseQueryBackofficeRequestParams(accessToken, additional = {}) {
    const CF_Authorization = Cookie.getCookie('CF_Authorization');
    return Object.assign({
        headers: {
            Authorization: 'Bearer ' + accessToken,
            'X-Hero-Account': '',
            'Cf-Access-Jwt-Assertion': CF_Authorization
        },
        timeout: 60000
    }, additional);
}
const useQueryRequestParams = () => {
    const accessToken = useSelector(accessTokenSelector);
    return privateUseQueryBackofficeRequestParams(accessToken || '');
};
export default useQueryRequestParams;
