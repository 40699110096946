import React, { useMemo } from 'react';
import { useWatch } from '@hero/ui-kit/inputs/Form';
import { useSelector, shallowEqual } from 'react-redux';

import {
    heardFromOptionsSelector,
    podcastNameOptionsSelector
} from '@hero/redux-data/backoffice/prospectSignupSurveyOptions/selectors';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Input from '@hero/ui-kit/inputs/Input';

import useStyles from './useStyles';

export const heardFromDefault = '--';

const HeardFromInputs: React.FC = () => {
    const heardFrom = useWatch({ name: 'heard_from', defaultValue: heardFromDefault });
    const podcastName = useWatch({ name: 'podcast_name', defaultValue: '' });

    const heardFromOptions = useSelector(heardFromOptionsSelector, shallowEqual);
    const podcastNameOptions = useSelector(podcastNameOptionsSelector, shallowEqual);
    const { selectStyles } = useStyles();

    const heardForm = useMemo(() => {
        return [heardFromDefault, ...heardFromOptions];
    }, [heardFromOptions]);

    return (
        <>
            <Select
                name="heard_from"
                displayName="How They Heard About Us?"
                styles={selectStyles}
                noExtraMargin
                defaultValue={heardFromDefault}
            >
                {heardForm.map((option) => (
                    <Option key={option} value={option} labelText={option} />
                ))}
            </Select>
            {heardFrom?.toLowerCase().includes('podcast') && (
                <Select
                    name="podcast_name"
                    displayName="Which Podcast?"
                    styles={selectStyles}
                    noExtraMargin
                >
                    {podcastNameOptions.map((option) => (
                        <Option key={option} value={option} labelText={option} />
                    ))}
                </Select>
            )}
            {(heardFrom?.toLowerCase() === 'other' || podcastName?.toLowerCase() === 'other') && (
                <Input name="other" displayName={`Please Specify "Other"`} />
            )}
        </>
    );
};

export default HeardFromInputs;
