import React from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import ChargeFlow from '../ChargeFlow';
import { CancellationDetails } from '@hero/hero-types';
import formatDollarAmount from '@hero/hero-utils/currency';
import useSectionMargin from '@hero/ui-kit/hooks/useSectionMargin';

type Props = {
    data: CancellationDetails;
    onCompleteFlow?: () => void;
    isRetryActive?: boolean;
};

const unreturnedDeviceFeeStatusMap = {
    not_yet_applicable: 'Not yet applicable',
    in_collection: 'In Collection',
    processed: 'Processed',
    partially_processed: 'Partially processed',
    device_returned_no_fee: 'Device return no fee',
    device_returned_refund_pending: 'Device return refund pending',
    collection_agency: 'Collection agency'
};

const UnreturnedDeviceFee: React.FC<Props> = ({ data, onCompleteFlow, isRetryActive = false }) => {
    const sectionLabelStyles: React.CSSProperties = {
        textTransform: 'uppercase'
    };
    const margin = useSectionMargin({ marginTop: 'small' });

    const [showDeviceRetryModal, setShowDeviceRetryModal] = React.useState(false);

    const handleShowDeviceRetryModal = React.useCallback(() => {
        setShowDeviceRetryModal(true);
    }, []);

    const membershipId = data?.subscription?.id;

    return (
        <>
            <P strong styles={sectionLabelStyles}>
                Unreturned device fee
            </P>
            <Container gridTemplateColumns="3fr 6fr">
                <P strong>Fee</P>
                <P>{`${formatDollarAmount(
                    data?.cancellation?.unreturned_device_fee || 0,
                    true
                )} + Tax`}</P>
            </Container>
            <Container gridTemplateColumns="3fr 6fr">
                <P strong>Status</P>
                <P>
                    {data?.cancellation?.unreturned_device_fee_status
                        ? unreturnedDeviceFeeStatusMap[
                              data?.cancellation?.unreturned_device_fee_status
                          ]
                        : '-'}
                </P>
            </Container>
            <Container gridTemplateColumns="3fr 6fr">
                <P strong noDefaultMargin>
                    Fee Collected
                </P>
                <P noDefaultMargin>{`${formatDollarAmount(
                    data?.cancellation?.unreturned_device_fee_collected || 0,
                    true
                )} + Tax`}</P>
                <P strong noDefaultMargin>
                    Fee Outstanding
                </P>
                <P noDefaultMargin>{`${formatDollarAmount(
                    data?.cancellation?.unreturned_device_fee_outstanding || 0,
                    true
                )} + Tax`}</P>
            </Container>
            <Container gridTemplateColumns="3fr 6fr" styles={margin}>
                <P strong noDefaultMargin>
                    Last Billing Attempt:
                    {data?.cancellation?.unreturned_device_fee_last_attempt?.success
                        ? ' Success'
                        : ' Fail'}
                </P>
                <div
                    style={{ display: 'inline-flex', alignItems: 'center', alignContent: 'center' }}
                >
                    <P noDefaultMargin>{`${formatDollarAmount(
                        data?.cancellation?.unreturned_device_fee_last_attempt?.amount || 0,
                        true
                    )} + Tax (${formatDollarAmount(
                        data?.cancellation?.unreturned_device_fee_last_attempt?.tax || 0,
                        true
                    )})`}</P>
                    <Button
                        disabled={!isRetryActive}
                        size="small"
                        noDefaultMargin
                        styles={{ marginLeft: '1.75rem' }}
                        onClick={handleShowDeviceRetryModal}
                    >
                        Retry
                    </Button>
                </div>
            </Container>
            {membershipId && showDeviceRetryModal ? (
                <ChargeFlow
                    cancellation={data.cancellation}
                    onCompleteFlow={onCompleteFlow}
                    membershipId={membershipId}
                    externalControls={[showDeviceRetryModal, setShowDeviceRetryModal]}
                    chargeContext="unreturned_device_fee"
                />
            ) : null}
        </>
    );
};

export default UnreturnedDeviceFee;
