import { useRef, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { membershipCancellationPartialErrorsSelector } from "@hero/redux-data/backoffice/membershipCancellation/selectors";
import { useNotifications } from "../../context/notification";
const useOrderCancelFailAlert = () => {
    const partialErrors = useSelector(membershipCancellationPartialErrorsSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (partialErrors.includes("order_cancellation_error")) {
            id.current = addNotification({
                type: "fail",
                message: "Error cancelling Shopify order.",
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, partialErrors]);
};
export default useOrderCancelFailAlert;
