import React from 'react';

import { Organization } from '@hero/hero-types';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import H from '@hero/ui-kit/typography/H';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';

import InlineLoader from '../../../components/InlineLoader';
import useListStyles from '../../../hooks/useListStyles';

import useOrganizationListData from './useOrganizationListData';
import Divider from '@hero/ui-kit/components/Divider';
import Link from '../../../components/Link';
import showRTMDischargeFeature from '../utils/showDischargeFeature';
import DefaultLayout from '../../../components/DefaultLayout';

const OrganizationList: React.FC = () => {
    const { isLoading, organizationList, pagerProps, tableColumns } = useOrganizationListData();
    const { headerWrapStyles, innerWrapPadding } = useListStyles();

    const filteredLists = React.useMemo(() => {
        const sorted = organizationList.sort((a, b) => {
            if (a?.name < b?.name) {
                return -1;
            }
            if (a?.name > b?.name) {
                return 1;
            }
            return 0;
        });

        const mainOrganizations = [
            'Assure',
            'Cromford Health (THS)',
            'Embrace Integrated Health Solutions',
            'Healthtopia',
            'WellTrackONE Corporation',
            'CareTalk Health',
            'GenieMD'
        ];

        return {
            main: sorted.filter((item) => mainOrganizations.includes(item?.name || '')),
            rest: sorted.filter((item) => !mainOrganizations.includes(item?.name || ''))
        };
    }, [organizationList]);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5" noDefaultMargin>
                        Organizations
                    </H>
                    <LinkButton to="create">Add Organization</LinkButton>
                </div>
                <div style={innerWrapPadding}>
                    {isLoading ? (
                        <InlineLoader />
                    ) : (
                        <>
                            {showRTMDischargeFeature ? (
                                <>
                                    <Link to={`/organizations/discharged-members`}>
                                        Discharged Members
                                    </Link>
                                    <Divider />
                                </>
                            ) : null}
                            <Table<Organization>
                                headerRowStyles={{ display: 'none' }}
                                columns={tableColumns}
                                data={filteredLists.main}
                                rowKey="id"
                                emptyValues="-"
                                isLoading={isLoading}
                                pagerProps={pagerProps}
                            />
                            <Divider />
                            <Table<Organization>
                                headerRowStyles={{ display: 'none' }}
                                columns={tableColumns}
                                data={filteredLists.rest}
                                rowKey="id"
                                emptyValues="-"
                                isLoading={isLoading}
                                pagerProps={pagerProps}
                            />
                        </>
                    )}
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default OrganizationList;
