import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RmaCreate from './RmaCreate';
import RmaList from './RmaList';

const RmaRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="create" element={<RmaCreate />} />
            <Route path="/" element={<RmaList />} />
        </Routes>
    );
};

export default RmaRoutes;
