import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from './store';
import Wrappers from './Wrappers';
import App from './App';
import Routes from './routes';
import '@hero/branding/font/gilroy.css';
import './index.css';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

//@ts-ignore
window.analytics = false;

const container = document.getElementById('app');
const root = createRoot(container!);
const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1, staleTime: 0 } } });

root.render(
    <BrowserRouter>
        <Wrappers store={store}>
            <QueryClientProvider client={queryClient}>
                <>
                    <App />
                    <Routes />
                </>
            </QueryClientProvider>
        </Wrappers>
    </BrowserRouter>
);
