import React from 'react';
import TabBar from '../TabBar';
import Section from '@hero/ui-kit/layout/Section';
import { UserAdherenceTableDataObject } from './utils/repackTableData';
import Table from '@hero/ui-kit/tables/Table';
import useAdherenceListData from './useAdherenceListData';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import UserAdherenceListFilter from './UserAdherenceListFilter';
import { useParams } from 'react-router-dom';
import { UserRouteParams } from '../../types';
import UserHeader from '../UserHeader';
import DefaultLayout from '../../../../components/DefaultLayout';

const UserAdherence: React.FC = () => {
    const { user_id } = useParams<UserRouteParams>();
    const { isLoadingUserAdherence, tableData, tableColumns, sort, handleSort, pagerProps } =
        useAdherenceListData(user_id ? +user_id : 0);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'regular' });
    const tableWrapStyles: React.CSSProperties = {
        overflowX: 'auto'
    };

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <UserHeader />
                <TabBar />
                <div style={innerWrapPadding}>
                    <UserAdherenceListFilter />
                    <div style={tableWrapStyles}>
                        <Table<UserAdherenceTableDataObject>
                            columns={tableColumns}
                            data={tableData}
                            rowKey="id"
                            emptyValues="-"
                            isLoading={isLoadingUserAdherence}
                            sortColumn={sort.sort_by}
                            sortDirection={sort.sort_dir}
                            onColumnSort={handleSort}
                            pagerProps={pagerProps}
                        />
                    </div>
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default UserAdherence;
