export const useRmaListHeaderStyles = () => {
    const headerWrapStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const searchWrapStyles = {
        display: 'flex',
        alignItems: 'flex-start'
    };
    const filterToggleStyles = {
        margin: '2rem 2.4rem 0'
    };
    return {
        headerWrapStyles,
        searchWrapStyles,
        filterToggleStyles
    };
};
export default useRmaListHeaderStyles;
