import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isCreateOrganizationLoadingSelector, isCreateOrganizationUpdatedSelector, createOrganizationSelector, createOrganizationErrorSelector } from '@hero/redux-data/backoffice/createOrganization/selectors';
import { createOrganization, createOrganizationClear } from '@hero/redux-data/backoffice/createOrganization/actionCreators';
const useCreateOrganizationData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(isCreateOrganizationLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isCreateOrganizationUpdatedSelector, shallowEqual);
    const newOrganizationId = useSelector(createOrganizationSelector);
    const { error, errorMessage } = useSelector(createOrganizationErrorSelector, shallowEqual);
    const handleCreateOrganization = useCallback((params) => {
        dispatch(createOrganization(params));
    }, [dispatch]);
    useEffect(() => {
        isUpdated && navigate(`/organizations/${newOrganizationId}/basic-info`);
        return () => {
            dispatch(createOrganizationClear());
        };
    });
    return {
        dispatch,
        isLoading,
        hasError: error,
        errorMessage,
        handleCreateOrganization
    };
};
export default useCreateOrganizationData;
