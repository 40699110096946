import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import getMemberFullName from '../../utils/getMemberFullName';
import getMemberStatusLabel from '../../utils/getMemberStatusLabel';
const repackTableData = (enrollments) => enrollments.map((enrollment) => {
    const { id, contact, member } = enrollment;
    return {
        id,
        email: member?.contact?.email || contact?.email || null,
        name: getMemberFullName(enrollment) || null,
        status: member?.status ? getMemberStatusLabel(member.status) : null,
        serial: member?.devices[0]?.device_serial || null,
        device_id: member?.devices[0]?.hero_device_id || null,
        delivered_at: member?.devices[0]?.delivered_at
            ? formatShortDate(normalizeDateString(member.devices[0].delivered_at))
            : null,
        activated_at: member?.devices[0]?.activated_at
            ? formatShortDate(normalizeDateString(member.devices[0].activated_at))
            : null
    };
});
export default repackTableData;
