import useTheme from '@hero/branding/theme';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import H from '@hero/ui-kit/typography/H';
import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import planOffersSchema from './validator/planOffersSchema';
import { PlanOffer } from '../../pages/Membership/api/useGetRTMTransferOptions';

const getPlanLabel = (plan: PlanOffer) => {
    if (plan.storefront_key === 'biannual') {
        return `24 months, pay $719.76 upfront (equal to $29.99 per month)`;
    }

    if (plan.storefront_key === 'annual') {
        return `Annual, pay $419.88 upfront (equal to $34.99 per month)`;
    }

    return `Monthly, 12 months minimum at $44.99 per month`;
};

const FormElements: React.FC<{ continueCancellation: () => void }> = ({ continueCancellation }) => {
    const { rtmPlanOffers } = useCancellationStateMachine();
    const { borders, colors } = useTheme();
    const {
        formState: { isValid }
    } = useFormContext();

    const radioStyle: React.CSSProperties = {
        padding: '1rem'
    };

    const border: React.CSSProperties = {
        ...borders.bottom,
        ...borders.variant.secondary,
        borderWidth: 2,
        borderColor: colors.neutrals.borderAlpha
    };
    return (
        <>
            <H role="h6">{`Regular plans:`}</H>

            {rtmPlanOffers ? (
                <RadioGroup
                    name="plan_offers"
                    displayName=""
                    noBorders
                    styles={{ marginBottom: '0' }}
                >
                    {rtmPlanOffers.map((plan) => (
                        <Radio
                            key={plan.storefront_key}
                            id={plan.storefront_key}
                            value={plan.storefront_key}
                            labelText={getPlanLabel(plan)}
                            styles={{ ...radioStyle, ...border }}
                        />
                    ))}
                </RadioGroup>
            ) : null}

            <Section centered>
                <Button type="submit" width="large" disabled={!isValid}>
                    Next
                </Button>
                <Button type="button" variant="secondary" onClick={continueCancellation}>
                    Continue Cancellation
                </Button>
            </Section>
        </>
    );
};

const StepPlanOffers: React.FC = () => {
    const { continueCancellation, handleChoosePlanOffer } = useCancellationStateMachine();

    return (
        <Form validationSchema={planOffersSchema} submitFn={handleChoosePlanOffer}>
            <FormElements continueCancellation={continueCancellation} />
        </Form>
    );
};

export default StepPlanOffers;
