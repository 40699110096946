import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import activateMembershipSchema, {
    ActivateMembershipSchema
} from '@hero/validators/forms/backoffice/activateMembershipSchema';
import { activateMembership } from '@hero/redux-data/backoffice/activateMembership/actionCreators';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';

type Props = {
    external_serial: string;
    hero_user_id: number;
    handleClose: () => void;
};

const ActivateMembershipModal: React.FC<Props> = ({
    handleClose,
    external_serial,
    hero_user_id
}) => {
    const dispatch = useDispatch();
    const defaultValues = useMemo(
        () => ({ activated_at: new Date().toISOString().substr(0, 10), auto_activaton: false }),
        []
    );
    const handleSubmit = useCallback(
        ({ activated_at, auto_activation, comment }: ActivateMembershipSchema) => {
            dispatch(
                activateMembership({
                    activated_at: new Date(activated_at as Date)
                        .toLocaleDateString('en-GB')
                        .split('/')
                        .reverse()
                        .join('-'),
                    auto_activation,
                    device_serial: external_serial,
                    hero_user_id,
                    comment
                })
            );
        },
        [external_serial, hero_user_id, dispatch]
    );

    return (
        <Modal onClose={handleClose} isCancelable>
            <H role="h4">Activate Membership</H>
            <P>
                {external_serial.length
                    ? external_serial
                    : 'External Serial not Provided, Activate disabled.'}
            </P>
            <Form
                validationSchema={activateMembershipSchema}
                defaultValues={defaultValues}
                submitFn={handleSubmit}
            >
                <Input name="activated_at" displayName="Activation date" type="date" />
                <StandaloneCheckbox name="auto_activation" labelText="Activate automatically" />
                <TextArea name="comment" displayName="Comment" />
                <Button type="submit" width="full" disabled={external_serial.length === 0}>
                    Activate
                </Button>
                <Button variant="secondary" onClick={handleClose} width="full">
                    Cancel Activation
                </Button>
            </Form>
        </Modal>
    );
};

export default ActivateMembershipModal;
