import React, { useCallback, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import { enterpriseEnrollmentSelector } from '@hero/redux-data/backoffice/enterpriseCancellationOptions/selectors';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import { ToBeCancelledParams } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import StaticFormElement from '../../../../../components/StaticFormElement';
import getActiveDateFormatted from '../../../../../utils/getActiveDateFormatted';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import useGetCancellationRequestsReasons from '../../../../../pages/Organizations/DischargedQueueMemberList/api/useGetCancellationRequestReasons';
import InlineLoader from '../../../../../components/InlineLoader';
import QueryInlineError from '../../../../../components/QueryInlineError';

interface DetailsFormProps {}

const DetailsForm: React.FC<DetailsFormProps> = () => {
    const enrollment = useSelector(enterpriseEnrollmentSelector, shallowEqual);
    const { data, isLoading, isError, error } = useGetCancellationRequestsReasons();

    const {
        reset,
        setValue,
        formState: { isValid }
    } = useFormContext<ToBeCancelledParams>();

    const activatedAt = enrollment?.member?.activated_at;

    const handleReset = useCallback(() => {
        reset();
    }, [reset]);

    const handleSubReasonSelection = useCallback(
        (value: string) => {
            setValue('reason', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        },
        [setValue]
    );

    const reasonLabels = useMemo(() => {
        if (data?.data) {
            return data.data.reasons.map((reason) => reason.reason);
        }
        return [];
    }, [data]);

    return isLoading ? (
        <InlineLoader />
    ) : (
        <>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                <Combobox
                    name="reason"
                    displayName="Reason For Cancellation"
                    suggestions={reasonLabels}
                    onSelection={handleSubReasonSelection}
                    noExtraMargin
                />
                <StaticFormElement label="Plan Active Date">
                    {getActiveDateFormatted(activatedAt)}
                </StaticFormElement>
                <Input name="zendesk_ticket_number" displayName="Zendesk Ticket #" />
            </Container>
            <TextArea rows={4} resize="none" name="notes" displayName="Notes (Optional)" />
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>
                <Button width="large" variant="secondary" onClick={handleReset}>
                    Reset
                </Button>
            </Section>
            {isError ? <QueryInlineError centered error={error} /> : null}
        </>
    );
};

export default DetailsForm;
