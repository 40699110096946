import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import A from '@hero/ui-kit/typography/A';
// there's probably a bug in this util, but it's not clear how it shold work
import { DetailsContainer, DetailLabel, Detail } from '../../../components/Details';
import DefaultErrorModal from '../../../components/DefaultErrorModal';

import useDeviceDetailsData from './useDeviceDetailsData';
import useDeviceDetailsStyles from './useDeviceDetailsStyles';
import MembershipDetails from './MembershipDetails';
import AdditionalControls from './AdditionalControls';
import DemoDetails from './DemoDetails';
import ReprogrammingDetails from './ReprogrammingDetails';
import PillsData from './PillsData';
import LoggingLevel from './LoggingLevel';
import Header from './Header';
import DefaultLayout from '../../../components/DefaultLayout';
import InlineLoader from '../../../components/InlineLoader';
import ToLocalTime from '../../../utils/toLocalTime';
import Link from '../../../components/Link';

const DeviceDetails: React.FC = () => {
    const {
        formatElapsedTime,
        deviceDetails,
        userDetails,
        isLoading,
        hasError,
        errorMessage,
        isBackofficeAdmin,
        isRefurbishmentAdmin,
        isAppSupportAdminAdmin,
        getDeviceDetails
    } = useDeviceDetailsData();
    const { innerWrapPadding } = useDeviceDetailsStyles();

    const previousPrimaryMember = React.useMemo(() => {
        if (deviceDetails?.previous_user_details?.primary_user) {
            return (
                <Link
                    to={`/users/${deviceDetails.previous_user_details.primary_user.hero_user_id}`}
                >
                    {deviceDetails.previous_user_details.primary_user.email}
                </Link>
            );
        }

        return null;
    }, [deviceDetails]);

    const previousCaregiverMembers = React.useMemo(() => {
        const caregiverAdmins = deviceDetails?.previous_user_details?.caregiver_admins;

        if (caregiverAdmins?.length) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {caregiverAdmins.map((caregiverAdmin) => (
                        <Link
                            key={caregiverAdmin.hero_user_id}
                            to={`/users/${caregiverAdmin.hero_user_id}`}
                        >
                            {caregiverAdmin.email}
                        </Link>
                    ))}
                </div>
            );
        }

        return null;
    }, [deviceDetails]);

    return (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <Header
                    isAppSupportAdminAdmin={isAppSupportAdminAdmin}
                    isRefurbishmentAdmin={isRefurbishmentAdmin}
                    externalSerial={deviceDetails.external_serial}
                    deviceId={deviceDetails.id}
                />
                {isLoading ? (
                    <InlineLoader />
                ) : (
                    <div style={innerWrapPadding}>
                        <DetailsContainer>
                            {previousPrimaryMember ? (
                                <>
                                    <DetailLabel>Previous Primary User</DetailLabel>
                                    <Detail>{previousPrimaryMember}</Detail>
                                </>
                            ) : null}
                            {previousCaregiverMembers ? (
                                <>
                                    <DetailLabel>Previous Caregiver Admin User(s)</DetailLabel>
                                    <Detail>{previousCaregiverMembers}</Detail>
                                </>
                            ) : null}
                            <DetailLabel>Internal Serial Number</DetailLabel>
                            <Detail>{deviceDetails.serial || '-'}</Detail>
                            <DetailLabel>External Serial Number</DetailLabel>
                            <Detail>{deviceDetails.external_serial || '-'}</Detail>
                            <DetailLabel>Time Online</DetailLabel>
                            <Detail>
                                {(deviceDetails.relplaced_by && 'deactivated') ||
                                deviceDetails.last_online
                                    ? formatElapsedTime(deviceDetails.last_online)
                                    : 'offline'}
                            </Detail>
                            <MembershipDetails
                                isBackofficeAdmin={!!isBackofficeAdmin}
                                deviceDetails={deviceDetails}
                                userDetails={userDetails}
                            />
                            <DetailLabel>Organization</DetailLabel>
                            <Detail>
                                {userDetails?.organization_membership?.organization ? (
                                    <A
                                        to={`/organizations/${userDetails.organization_membership.organization.id}/basic-info`}
                                    >
                                        {userDetails.organization_membership.organization.name ||
                                            '-'}
                                    </A>
                                ) : (
                                    '-'
                                )}
                            </Detail>
                            <DetailLabel>Owner</DetailLabel>
                            <Detail>
                                {userDetails?.id
                                    ? `${userDetails.first_name} (id ${userDetails.id})`
                                    : 'owner not provided'}
                            </Detail>
                            <DetailLabel>Owner Email</DetailLabel>
                            <Detail>
                                {userDetails?.id ? (
                                    <A to={`/users/${userDetails.id}`}>{userDetails.email}</A>
                                ) : (
                                    'user details not provided'
                                )}
                            </Detail>
                            <DetailLabel>Last Config Date</DetailLabel>
                            <Detail>
                                {ToLocalTime(
                                    deviceDetails.last_config_date,
                                    'YYYY-MM-DD HH:mm:ss'
                                ) || '-'}
                            </Detail>
                            <DetailLabel>Last Error Config Date</DetailLabel>
                            <Detail>
                                {ToLocalTime(
                                    deviceDetails.last_error_config_date || undefined,
                                    'YYYY-MM-DD HH:mm:ss'
                                ) || '-'}
                            </Detail>
                            <DetailLabel>Last Error Config Message</DetailLabel>
                            <Detail>{deviceDetails.last_error_config_error_message || '-'}</Detail>
                            <DetailLabel>Firmware Version</DetailLabel>
                            <Detail>{deviceDetails.firmware_version || '-'}</Detail>
                            <DetailLabel>Odometer</DetailLabel>
                            <Detail>{deviceDetails.odometer || '-'}</Detail>
                            <DetailLabel>Timezone</DetailLabel>
                            <Detail>{deviceDetails.timezone_name || '-'}</Detail>
                            <DemoDetails
                                id={deviceDetails.id}
                                demo={deviceDetails.is_demo_device}
                            />
                            <ReprogrammingDetails
                                external_serial={deviceDetails.external_serial}
                                isRefurbishmentAdmin={!!isRefurbishmentAdmin}
                                allow_reprogramming={deviceDetails.allow_reprogramming}
                            />
                            {deviceDetails?.serial?.startsWith('H200-') ? (
                                <>
                                    <DetailLabel>System Logging Level (H200+ only)</DetailLabel>
                                    <Detail>
                                        <LoggingLevel
                                            type="system"
                                            loggingLevel={
                                                deviceDetails?.settings?.system_logging_level
                                            }
                                            deviceId={deviceDetails.id}
                                            onSuccess={getDeviceDetails}
                                        />
                                    </Detail>
                                    <DetailLabel>App Logging Level (H200+ only)</DetailLabel>
                                    <Detail>
                                        <LoggingLevel
                                            type="app"
                                            header="App Logging Level"
                                            deviceId={deviceDetails.id}
                                            onSuccess={getDeviceDetails}
                                            loggingLevel={
                                                deviceDetails?.settings?.application_logging_level
                                            }
                                        />
                                    </Detail>
                                </>
                            ) : null}
                        </DetailsContainer>
                        <AdditionalControls
                            id={deviceDetails.id}
                            serial={deviceDetails.serial}
                            organizationMembership={userDetails?.organization_membership || null}
                        />
                        <PillsData pills={deviceDetails?.config?.pills} user_id={userDetails?.id} />
                    </div>
                )}
            </Section>
        </DefaultLayout>
    );
};

export default DeviceDetails;
