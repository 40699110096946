import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

export const Success = () => {
    const { onCloseReactivationFlow, successDetails } = useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'large' });

    return (
        <>
            <H role="h6" centered>
                Success! Membership Reactivated
            </H>
            <Section
                noDefaultPadding
                styles={{ textAlign: 'left', ...marginTop }}
                width="full"
                centered
            >
                <ul>
                    {successDetails.list.map((message, index) => (
                        <li key={`${index}-${message}`}>{message}</li>
                    ))}
                </ul>
            </Section>
            <Section centered>
                <Button width="large" onClick={onCloseReactivationFlow}>
                    Return to reactivated membership
                </Button>
            </Section>
        </>
    );
};
