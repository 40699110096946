import React, { CSSProperties } from 'react';

import Loader from '@hero/ui-kit/graphics/Loader';
import { UiComponentProps } from '@hero/ui-kit/types';

const InlineLoader: React.FC<UiComponentProps> = ({ styles }) => {
    const mergedStyles: CSSProperties = {
        padding: '10rem',
        textAlign: 'center',
        ...styles
    };

    return (
        <div style={mergedStyles}>
            <Loader styles={{ width: '7.2rem' }} />
        </div>
    );
};

export default InlineLoader;
