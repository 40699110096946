import React, { CSSProperties } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import H from '@hero/ui-kit/typography/H';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

import Breadcrumbs from '../Breadcrumbs';

type Props = {
    heading: string;
    eyebrow?: string;
    steps?: string[];
    currentStep?: number; // 1-based index
    inverted?: boolean;
} & UiComponentProps;

const ModalFlowHeader: React.FC<Props> = ({
    heading,
    eyebrow,
    steps,
    currentStep,
    inverted = false,
    styles
}) => {
    const headerMargin = useComponentMargin({ marginVertical: 'regular' });

    const mergedStyles: CSSProperties = {
        ...headerMargin,
        ...styles
    };

    return (
        <header style={mergedStyles}>
            <H role="h5" inverted={inverted} centered noDefaultMargin>
                {heading}
            </H>

            {steps && (
                <Breadcrumbs
                    steps={steps}
                    currentStep={currentStep}
                    inverted={inverted}
                    styles={headerMargin}
                />
            )}

            {eyebrow && (
                <Eyebrow
                    centered
                    type={inverted ? 'inverted' : 'regular'}
                    styles={{ lineHeight: 1, ...headerMargin }}
                >
                    {eyebrow}
                </Eyebrow>
            )}
        </header>
    );
};

export default ModalFlowHeader;
