import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import envVars from '../../constants/envVars';

const stripePromise = loadStripe(envVars.STRIPE_KEY);

const StripeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
