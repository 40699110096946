import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import TabBar from './TabBar';
import H from '@hero/ui-kit/typography/H';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    const headerWrapStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    return (
        <Section
            subtheme={['regular', 'background']}
            paddingVertical="small"
            paddingHorizontal="regular"
        >
            <div style={headerWrapStyles}>
                <H role="h5">{title}</H>
            </div>
            <TabBar />
        </Section>
    );
};

export default Header;
