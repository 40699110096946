import React from 'react';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Form from '@hero/ui-kit/inputs/Form';
import dischargedQueueFilterSchema from './schema';
import { useSearchParams } from 'react-router-dom';

const statuses = [
    {
        value: 'all',
        label: 'All'
    },
    {
        value: 'CREATED',
        label: 'Created'
    },
    {
        value: 'APPROVED',
        label: 'Approved'
    },
    {
        value: 'REJECTED',
        label: 'Rejected'
    },
    {
        value: 'COMPLETED',
        label: 'Completed'
    },
    {
        value: 'TRANSFERRED_TO_D2C',
        label: 'Transferred to D2C'
    }
];

const DischargedQueueFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const status = React.useMemo(() => {
        return searchParams.get('status') || undefined;
    }, [searchParams]);

    const handleStatusChange = (newStatus: string) => {
        setSearchParams({ status: newStatus });
    };

    return (
        <Form
            validationSchema={dischargedQueueFilterSchema}
            defaultValues={{ status: status || 'all' }}
            submitFn={() => null}
        >
            <Select
                id="status"
                name="status"
                displayName="Status"
                defaultValue={status || 'all'}
                onChange={handleStatusChange}
                styles={{ width: '40rem' }}
            >
                {statuses.map((status) => (
                    <Option key={status.value} value={`${status.value}`} labelText={status.label} />
                ))}
            </Select>
        </Form>
    );
};

export default DischargedQueueFilter;
