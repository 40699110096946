const getEnrollmentStatusLabel = (status) => {
    switch (status) {
        case 'IMPORTED':
            return 'Imported';
        case 'SUBMITTED':
            return 'Submitted';
        case 'PENDING_TOS_AGREEMENT':
            return 'Pending TOS';
        case 'TOS_AGREED':
            return 'TOS Agreed';
        case 'DOES_NOT_QUALIFY':
            return 'Does Not Qualify';
        case 'SELECTED':
            return 'Selected';
        case 'NOT_SELECTED':
            return 'Not Selected';
        case 'APPROVED':
            return 'Approved';
        case 'REJECTED':
            return 'Rejected';
        default:
            return '-';
    }
};
export default getEnrollmentStatusLabel;
