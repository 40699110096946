import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdherenceMonitoringPage from './AdherenceMonitoring';
import DeviceMonitoringPage from './DeviceMonitoring';
import RunningLowPage from './RunningLow';

const MonitoringRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="adherence" element={<AdherenceMonitoringPage />} />
            <Route path="running-low" element={<RunningLowPage />} />
            <Route path="device" element={<DeviceMonitoringPage />} />
            <Route path="/" element={<Navigate to="/monitoring/adherence" replace />} />
        </Routes>
    );
};

export default MonitoringRoutes;
