import * as yup from 'yup';
import emailValidator from '@hero/validators/email';
const changeRoleSchema = yup
    .object()
    .shape({
    primary_user_is_placeholder: yup.bool().default(false),
    switch_to: yup.lazy((val) => !val
        ? yup.string().nullable().required('Role is required')
        : yup.string().oneOf(['primary_user', 'caregiver'], 'Invalid membership')),
    email: yup
        .string()
        .trim()
        .when(['primary_user_is_placeholder', 'switch_to'], {
        is: (primary_user_is_placeholder, switch_to) => (primary_user_is_placeholder && switch_to === 'primary_user') ||
            switch_to === 'caregiver',
        then: () => yup.lazy((val) => !val
            ? yup.string().trim().required('Email address is required')
            : emailValidator),
        otherwise: (schema) => schema.nullable().notRequired()
    })
})
    .defined();
export default changeRoleSchema;
