import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { enterpriseMemberDetailsErrorSelector } from '@hero/redux-data/backoffice/enterpriseMemberDetails/selectors';
import { useNotifications } from '../../context/notification';
const useEnterpriseMemberDetailsFailAlert = () => {
    const { error, originalMessage } = useSelector(enterpriseMemberDetailsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage?.replace(/[_-]/g, ' ') || 'Error fetching member details.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, originalMessage]);
};
export default useEnterpriseMemberDetailsFailAlert;
