import React from 'react';

import { Pill } from '@hero/hero-types';
import Section from '@hero/ui-kit/layout/Section';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import { SizingProps } from '@hero/ui-kit/types';

import { DetailsContainer, DetailLabel, Detail } from '../../../../components/Details';

import PillScheduleDetails from './PillScheduleDetails';

type Props = {
    pill: Pill;
    user_id?: number;
} & SizingProps;

const PillDetails: React.FC<Props> = ({ pill, user_id, ...rest }) => {
    return (
        <Section border {...rest}>
            <DetailsContainer>
                <DetailLabel>Cartrige</DetailLabel>
                <Detail>{pill.slot}</Detail>
                <DetailLabel>Type</DetailLabel>
                <Detail>{pill.pill_type}</Detail>
                <DetailLabel>Dosage</DetailLabel>
                <Detail>{pill.dosage || '-'}</Detail>
                <DetailLabel>Expiration Date</DetailLabel>
                <Detail>{pill.expires || 'No data'}</Detail>
                <DetailLabel>Date added</DetailLabel>
                <Detail>{pill.in_hero_from || '-'}</Detail>
                <DetailLabel>Pill Status (ok, low, expired)</DetailLabel>
                <Detail>{pill.level_warning || '-'}</Detail>
                <DetailLabel>Pill Amount Remaining</DetailLabel>
                <Detail>{pill.exact_pill_count || pill.estimated_amount || '-'}</Detail>
                <DetailLabel>Safety Lock? (yes/no options)</DetailLabel>
                <Detail>{pill.requires_pin ? 'Pin required' : 'Pin not required'}</Detail>
                <DetailLabel>Stored in Hero?</DetailLabel>
                <Detail>{pill.stored_in_hero ? 'Yes' : 'No'}</Detail>
            </DetailsContainer>
            {pill.schedules && (
                <Section paddingTop="small">
                    <Eyebrow>Schedules</Eyebrow>
                    {pill.schedules.map((schedule, index) => (
                        <PillScheduleDetails key={`${index}-${schedule.id}`} schedule={schedule} />
                    ))}
                </Section>
            )}
        </Section>
    );
};

export default PillDetails;
