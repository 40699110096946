import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { rmaRequestSelector } from '@hero/redux-data/backoffice/rmaRequest/selectors';
import Container from '@hero/ui-kit/layout/Container';
import useRmaEnums from '../utils/useRmaEnums';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import { UpdateRmaRequestParams } from '@hero/validators/forms/backoffice/rmaRequestSchema';
import Input from '@hero/ui-kit/inputs/Input';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import ReturnLocationSelect from '../components/ReturnLocationSelect';
import { isRmaAdminSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import Divider from '@hero/ui-kit/components/Divider';
import PickupLocation from '../../../views/forms/shipping/PickupLocation';
import { RmaEnums, RmaRequest, RmaReturnLocation } from '@hero/hero-types';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import getPreparedRmaStatusOptions from '../utils/getPreparedRmaStatusOptions';

type RmaDetailsManualFormProps = {
    state?: string;
    rma?: RmaRequest | null;
    rmaEnums?: RmaEnums;
};

const RmaDetailsManualForm: React.FC<RmaDetailsManualFormProps> = ({
    state,
    rma: defaultRma,
    rmaEnums
}) => {
    const rma = defaultRma || useSelector(rmaRequestSelector, shallowEqual);
    const isRmaAdmin = useSelector(isRmaAdminSelector, shallowEqual);

    const { return_location, rma_type } = rma ?? {};

    const { returnStatusOptions, rmaTypeOptions, rmaStatusOptions, returnLocations } =
        useRmaEnums(rmaEnums);

    const filteredRmaStatusOptions = rmaStatusOptions.filter(
        (rmaStatusOption) => rmaStatusOption.length && rmaStatusOption[0] !== '0'
    );

    const leftColWrapStyles: React.CSSProperties = {
        gridColumnStart: 1,
        alignItems: 'start'
    };
    const rightColWrapStyles: React.CSSProperties = {
        gridColumnStart: 2,
        alignItems: 'start'
    };

    const {
        setValue,
        watch,
        formState: { isValid }
    } = useFormContext<UpdateRmaRequestParams>();

    const returnLocation =
        (watch('return_location') as unknown as RmaReturnLocation) || return_location;

    const handleReturnLocationChange = React.useCallback(
        (value: string) => {
            setValue('return_location', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        },
        [setValue]
    );

    const handleReturnStatusChange = React.useCallback(
        (value: string) => {
            setValue('return_status', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        },
        [setValue]
    );

    const canEditExchangeFields = React.useMemo<boolean>(() => {
        return rma_type === 2 && !!isRmaAdmin;
    }, [rma_type, isRmaAdmin]);

    const preparedRmaStatusOptions = getPreparedRmaStatusOptions(filteredRmaStatusOptions);

    return (
        <>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular" gridRowGap="regular">
                <Container styles={leftColWrapStyles}>
                    <DefaultSelect
                        name="rma_type"
                        displayName="RMA Type"
                        options={rmaTypeOptions.map(([value, label]) => ({ label, value }))}
                    />
                </Container>
                <Container styles={{ ...rightColWrapStyles }}>
                    <DefaultSelect
                        name="rma_status"
                        displayName="RMA Status"
                        options={preparedRmaStatusOptions}
                    />
                </Container>

                {canEditExchangeFields ? (
                    <>
                        <Container styles={leftColWrapStyles}>
                            <Input
                                name="exchange_device_serial"
                                displayName="Exchange Serial #"
                                placeholder="Exchange serial"
                            />
                        </Container>

                        <Container styles={{ ...rightColWrapStyles }}>
                            <Input
                                name="exchange_tracking_number"
                                displayName="Exchange Tracking #"
                                placeholder="Update Tracking #"
                            />
                        </Container>
                    </>
                ) : null}
                <Container styles={leftColWrapStyles}>
                    <DefaultSelect
                        name="return_status"
                        displayName="Return Status"
                        onChange={(event) => {
                            const value = event.currentTarget.value;
                            handleReturnStatusChange(value);
                        }}
                        options={returnStatusOptions.map(([value, label]) => ({ label, value }))}
                    />
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Input
                        name="return_tracking_number"
                        displayName="Return Tracking Number"
                        placeholder="Update Tracking #"
                    />
                </Container>
                <Container styles={{ ...rightColWrapStyles }}>
                    <ReturnLocationSelect
                        returnLocations={returnLocations}
                        onChange={handleReturnLocationChange}
                        defaultValue={returnLocation}
                    />
                </Container>
            </Container>
            <Divider />
            <div>
                <PickupLocation state={state} hidePhoneField />
            </div>
            <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Update
                </Button>
            </Section>
        </>
    );
};

export default RmaDetailsManualForm;
