import React from 'react';
import H from '@hero/ui-kit/typography/H';
import Divider from '@hero/ui-kit/components/Divider';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import Container from '@hero/ui-kit/layout/Container';
import useEncourageHeroSubReasonNoteMap from '../CancellationFlow/utils/useEncourageHeroNoteSubReasonMap';

interface EncourageHeroFieldsProps {
    selectedSubReason?: string;
}

const EncourageHeroFields: React.FC<EncourageHeroFieldsProps> = ({ selectedSubReason }) => {
    const prepareReason = React.useMemo(() => {
        if (selectedSubReason) {
            const split = selectedSubReason.split('(');
            return split ? split[0].trim() : '';
        }
        return undefined;
    }, [selectedSubReason]);

    const note = useEncourageHeroSubReasonNoteMap(prepareReason);

    return (
        <Container>
            <Divider subtheme={['regular', 'transparent']} styles={{ width: '100%' }} />
            <H role="h6">Let's encourage keeping Hero</H>
            {note}
            <RadioGroup
                name="isCancellationProblemResolved"
                noBorders
                displayName=""
                styles={{ display: 'flex', width: '100%' }}
                disabled={!selectedSubReason}
            >
                <Radio
                    id="radio-1"
                    value={'1'}
                    labelText="Problem is resolved"
                    styles={{ width: '30rem' }}
                />
                <Radio
                    id="radio-0"
                    value={'0'}
                    labelText="Problem cannot be resolved"
                    styles={{ width: '30rem' }}
                />
            </RadioGroup>
        </Container>
    );
};

export default EncourageHeroFields;
