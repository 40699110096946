import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import Loader from '@hero/ui-kit/components/Loader';

import Wrapper from '../Wrapper';
import CheckDetails from './CheckDetails';
import useHazardousDrugsData from './useHazardosDrugsData';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

const HazardousDrugs: React.FC = () => {
    const { lastHazardousDrugCheck, performHazardousDrugsCheck, isLoading } =
        useHazardousDrugsData();
    const buttonMargin = useComponentMargin({ marginTop: 'regular' });

    return isLoading ? (
        <Loader />
    ) : (
        <Wrapper>
            <Section paddingHorizontal="none">
                <H role="h5">Hazardous Drug Check</H>
                <H role="h6">Last Run</H>
                {lastHazardousDrugCheck === null ? (
                    <P>Check was never performed.</P>
                ) : (
                    <CheckDetails last_check={lastHazardousDrugCheck} />
                )}
                <Button onClick={performHazardousDrugsCheck} styles={buttonMargin}>
                    {lastHazardousDrugCheck
                        ? 'Re-Run Hazardous Drug Check'
                        : 'Run Hazardous Drug Check'}
                </Button>
            </Section>
        </Wrapper>
    );
};

export default HazardousDrugs;
