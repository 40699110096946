import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { rmaEnums } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetRmaEnums = (filtered, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}rma/enums/`, {
            ...additional,
            params: {
                filtered
            }
        }),
        queryKey: [rmaEnums],
        enabled
    });
};
export default useGetRmaEnums;
