import React, { CSSProperties } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';

type Props = {
    strong?: boolean;
    children?: React.ReactNode;
} & UiComponentProps;

const DetailLabel: React.FC<Props> = ({ children, strong, styles }) => {
    const { typography } = useTheme();

    const mergedStyles: CSSProperties = {
        ...typography.paragraph.regular,
        ...(strong && typography.strong),
        gridColumnStart: 1,
        whiteSpace: 'nowrap',
        ...styles
    };

    return <dt style={mergedStyles}>{children}</dt>;
};

export default DetailLabel;
