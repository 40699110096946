import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    submitEnterpriseEnrollment,
    enterpriseEnrollmentSubmissionReset
} from '@hero/redux-data/backoffice/enterpriseEnrollmentSubmission/actionCreators';
import {
    isEnterpriseEnrollmentSubmissionLoadingSelector,
    isEnterpriseEnrollmentSubmissionUpdatedSelector,
    enterpriseEnrollmentStatusSelector
} from '@hero/redux-data/backoffice/enterpriseEnrollmentSubmission/selectors';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import Takeover from '@hero/ui-kit/components/Takeover';
import Form from '@hero/ui-kit/inputs/Form';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Span from '@hero/ui-kit/typography/Span';
import enterpriseEnrollmentSubmissionSchema, {
    EnterpriseEnrollmentSubmissionParams
} from '@hero/validators/forms/backoffice/enterpriseEnrollmentSubmissionSchema';

import ConfirmSignupModal from './ConfirmSignupModal';
import SignupFormFields from './SignupFormFields';
import repackValuesForSubmit from './utils/repackValuesForSubmit';
import DefaultLayout from '../../components/DefaultLayout';

const PayorASignup: React.FC = () => {
    const dispatch = useDispatch();
    const isSubmittingEnrollment = useSelector(isEnterpriseEnrollmentSubmissionLoadingSelector);
    const isEnrollmentSubmitted = useSelector(isEnterpriseEnrollmentSubmissionUpdatedSelector);
    const enrollmentStatus = useSelector(enterpriseEnrollmentStatusSelector);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [submittedValues, setSubmittedValues] =
        useState<EnterpriseEnrollmentSubmissionParams | null>(null);

    const defaultValues = useMemo(
        () => (submittedValues ? { ...submittedValues } : {}),
        [submittedValues]
    );

    const fullName = `${submittedValues?.first_name ?? ''} ${
        submittedValues?.last_name ?? ''
    }`.trim();

    useEffect(
        () => () => {
            dispatch(enterpriseEnrollmentSubmissionReset());
        },
        [dispatch]
    );

    const handleSubmit = useCallback((formValues: EnterpriseEnrollmentSubmissionParams) => {
        setSubmittedValues(formValues);
        setConfirmationModalOpen(true);
    }, []);

    const handleReset = useCallback(() => {
        setSubmittedValues(null);
    }, []);

    const handleConfirm = useCallback(() => {
        submittedValues &&
            dispatch(submitEnterpriseEnrollment(repackValuesForSubmit(submittedValues)));
        setConfirmationModalOpen(false);
    }, [dispatch, submittedValues]);

    const handleOutcomeModalClose = useCallback(() => {
        dispatch(enterpriseEnrollmentSubmissionReset());
        setSubmittedValues(null);
    }, [dispatch]);

    return isSubmittingEnrollment ? (
        <Loader />
    ) : (
        <DefaultLayout>
            {confirmationModalOpen && (
                <ConfirmSignupModal
                    submittedValues={submittedValues}
                    onConfirm={handleConfirm}
                    externalControls={[confirmationModalOpen, setConfirmationModalOpen]}
                />
            )}
            {isEnrollmentSubmitted && enrollmentStatus === 'PENDING_TOS_AGREEMENT' && (
                <Takeover
                    type="success"
                    title="Potentially Qualified"
                    onClose={handleOutcomeModalClose}
                >
                    <P size="large" centered>
                        Remind {fullName ? <Span strong>{fullName}</Span> : 'customer'} that they
                        will receive an email with a link and instructions to sign our Terms of
                        Service - at which point the decision about enrollment will be made.
                    </P>
                </Takeover>
            )}
            {isEnrollmentSubmitted && enrollmentStatus === 'DOES_NOT_QUALIFY' && (
                <Takeover
                    type="fail"
                    title="Not Qualified"
                    actions={[{ label: 'Close' }]}
                    onClose={handleOutcomeModalClose}
                    isCancelable
                >
                    <P size="large" inverted centered>
                        {fullName ? <Span strong>{fullName}</Span> : 'Customer'} does not qualify
                        for Hero.
                    </P>
                </Takeover>
            )}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h5">Payor A Sign Up</H>
                <Form
                    validationSchema={enterpriseEnrollmentSubmissionSchema}
                    defaultValues={defaultValues}
                    submitFn={handleSubmit}
                    validationMode="onChange"
                >
                    <SignupFormFields isPrepopulated={!!submittedValues} onReset={handleReset} />
                </Form>
            </Section>
        </DefaultLayout>
    );
};

export default PayorASignup;
