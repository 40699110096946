import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrganizationDetails, organizationDetailsReset } from '@hero/redux-data/backoffice/organization/actionCreators';
import { fetchEnterpriseEnrollmentsForVerification, enterpriseEnrollmentsForVerificationReset } from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/actionCreators';
import { verifyEnterpriseEnrollment, enterpriseEnrollmentVerificationReset } from '@hero/redux-data/backoffice/enterpriseEnrollmentVerification/actionCreators';
import { organizationSelector } from '@hero/redux-data/backoffice/organization/selectors';
import { areEnterpriseEnrollmentsLoadingSelector, enterpriseEnrollmentPaginationSelector, enterpriseEnrollmentListSelector } from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/selectors';
import { isEnterpriseEnrollmentVerificationLoadingSelector, isEnterpriseEnrollmentVerificationUpdatedSelector } from '@hero/redux-data/backoffice/enterpriseEnrollmentVerification/selectors';
import usePrevious from '@hero/react-hooks/usePrevious';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import usePagerProps from '../../../hooks/usePagerProps';
import repackTableData from './utils/repackTableData';
import getTableColumns from './utils/getTableColumns';
import omitProp from '@hero/hero-utils/omitProp';
const useEnrollmentVerificationData = () => {
    const { id: organization_id } = useParams();
    const dispatch = useDispatch();
    const organization = useSelector(organizationSelector, shallowEqual);
    const isFetchingEnrollments = useSelector(areEnterpriseEnrollmentsLoadingSelector);
    const enrollmentList = useSelector(enterpriseEnrollmentListSelector, shallowEqual);
    const paginationData = useSelector(enterpriseEnrollmentPaginationSelector, shallowEqual);
    const isVerifyingEnrollment = useSelector(isEnterpriseEnrollmentVerificationLoadingSelector);
    const isEnrollmentVerified = useSelector(isEnterpriseEnrollmentVerificationUpdatedSelector);
    const wasEnrollmentVerified = usePrevious(isEnrollmentVerified);
    const { search, limit, offset } = getQueryParams();
    const [selectedEnrollment, setSelectedEnrollment] = useState(null);
    const tableData = useMemo(() => repackTableData(enrollmentList), [enrollmentList]);
    const pagerProps = usePagerProps(paginationData);
    const fetchParams = useMemo(() => ({
        organization_id: organization_id ? +organization_id : 0,
        ...(search && { search }),
        ...(offset && { offset }),
        ...(limit && { limit })
    }), [organization_id, search, offset, limit]);
    const fetchEnrollments = useCallback((cbFetchParams) => {
        dispatch(fetchEnterpriseEnrollmentsForVerification(cbFetchParams));
    }, [dispatch]);
    // TODO once other tabs are refactored, move this effect to a wrapper component
    useEffect(() => {
        organization_id && dispatch(getOrganizationDetails({ id: +organization_id }));
        return () => {
            dispatch(organizationDetailsReset());
        };
    }, [dispatch, organization_id]);
    useEffect(() => {
        fetchEnrollments(fetchParams);
    }, [fetchEnrollments, fetchParams]);
    useEffect(() => {
        // execute _only_ if an enrollment has just been verified
        if (!wasEnrollmentVerified && isEnrollmentVerified) {
            // (note: total_count is already decremented within reducer)
            if (pagerProps.total_count > pagerProps.offset) {
                // there were more results on the page - just force the refetch
                fetchEnrollments(fetchParams);
            }
            else if (pagerProps.offset > 0) {
                // not the first page - go to previous page (fetch kicks in automatically)
                fetchEnrollments({ ...fetchParams, offset: 0 });
            }
            else if (search !== '') {
                // no more results with current filter - reset search (fetch kicks in automatically)
                fetchEnrollments({ ...omitProp(fetchParams, 'search') });
            }
            else {
                // basically no more enrollments for verification, but force refetch just for the case
                fetchEnrollments(fetchParams);
            }
        }
    }, [
        wasEnrollmentVerified,
        isEnrollmentVerified,
        search,
        fetchEnrollments,
        fetchParams,
        pagerProps
    ]);
    useEffect(() => () => {
        dispatch(enterpriseEnrollmentsForVerificationReset());
        dispatch(enterpriseEnrollmentVerificationReset());
    }, [dispatch]);
    const handleViewDetailsAction = useCallback((id) => {
        setSelectedEnrollment(id);
    }, []);
    const handleApprove = useCallback((id) => {
        dispatch(verifyEnterpriseEnrollment({ id, approved: true }));
        setSelectedEnrollment(null);
    }, [dispatch]);
    const handleReject = useCallback((id) => {
        dispatch(verifyEnterpriseEnrollment({ id, approved: false }));
        setSelectedEnrollment(null);
    }, [dispatch]);
    const handleBack = useCallback(() => {
        setSelectedEnrollment(null);
    }, []);
    const tableColumns = useMemo(() => getTableColumns(handleViewDetailsAction), [handleViewDetailsAction]);
    return {
        dispatch,
        isVerifyingEnrollment,
        organization,
        selectedEnrollment,
        handleBack,
        handleApprove,
        handleReject,
        tableColumns,
        tableData,
        isFetchingEnrollments,
        pagerProps
    };
};
export default useEnrollmentVerificationData;
