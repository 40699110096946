// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DVcSgJfGUIetoO9yovsU {
    height: 100vh;
    overflow: hidden;
}
.tGt3OFXQMoYQbbYz6mqV {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.b6_6dqR3P4ZeX8kyi8F0 {
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    padding: 20px 40px;
}
.SNdY0C5_Bi6fkNfa2Y5g {
    padding: 0;
    background-color: transparent;
}
.qKMS5XtDTpL8sA6vD3C7 {
    flex: 0 0 auto;
}
.WjC3ZWuGtFUeGXPFQsMH {
    flex: 1 1 auto;
    overflow: auto;
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./components/DefaultLayout/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,6BAA6B;AACjC;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".container {\n    height: 100vh;\n    overflow: hidden;\n}\n.contentWrap {\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n}\n.mainContent {\n    position: relative;\n    display: flex;\n    flex-flow: column;\n    height: 100%;\n    width: 100%;\n    padding: 20px 40px;\n}\n.mainContentModern {\n    padding: 0;\n    background-color: transparent;\n}\n.notificationsWrap {\n    flex: 0 0 auto;\n}\n.contentInnerWrap {\n    flex: 1 1 auto;\n    overflow: auto;\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `DVcSgJfGUIetoO9yovsU`;
export var contentWrap = `tGt3OFXQMoYQbbYz6mqV`;
export var mainContent = `b6_6dqR3P4ZeX8kyi8F0`;
export var mainContentModern = `SNdY0C5_Bi6fkNfa2Y5g`;
export var notificationsWrap = `qKMS5XtDTpL8sA6vD3C7`;
export var contentInnerWrap = `WjC3ZWuGtFUeGXPFQsMH`;
export default ___CSS_LOADER_EXPORT___;
