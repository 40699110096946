import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
const repackTableData = (members, organizations = []) => members.map((member) => {
    const name = member.primary_user_first_name && member.primary_user_last_name
        ? `${member.primary_user_first_name} ${member.primary_user_last_name}`
        : null;
    const caregiver_name = member.caregiver_first_name && member.caregiver_last_name
        ? `${member.caregiver_first_name} ${member.caregiver_last_name}`
        : null;
    return {
        id: member.member_id,
        enrollment_id: member.enrollment_id,
        email: member.member_email,
        name,
        date_discharged: member.date_discharge_requested
            ? formatShortDate(normalizeDateString(member.date_discharge_requested))
            : null,
        organization_id: member.organization_id,
        phone_number: member.primary_user_phone,
        discharge_reason: member.discharge_reason,
        caregiver_name,
        caregiver_phone_number: member.caregiver_phone,
        membership_cancellation_id: member.membership_cancellation_id,
        note: member.comment,
        organization: organizations.find((org) => org.id === member.organization_id)?.name,
        status: member.status || undefined
    };
});
export default repackTableData;
