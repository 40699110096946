// type (0 - in hero, 1 - outside hero, 2 - future dose)
const getUserAdherenceTypeLabel = (type) => {
    if (type === 0) {
        return 'Inside Hero';
    }
    if (type === 1) {
        return 'Outside Hero';
    }
    if (type === 2) {
        return 'Future Dose';
    }
    return '-';
};
export default getUserAdherenceTypeLabel;
