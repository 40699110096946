import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import getStatusLabel from '../../utils/getStatusLabel';
import getActivationTypeLabel from '../../utils/getActivationTypeLabel';
const repackTableData = (memberships) => memberships.map(({ hero_user_id, current_plan, status, activation_type, activated_at, ...rest }) => ({
    ...rest,
    plan: current_plan?.name
        ? `${current_plan.name} (${current_plan.storefront_key || current_plan.key || '-'})`
        : null,
    status: getStatusLabel(status),
    activation_type: getActivationTypeLabel(activation_type),
    activated_at: activated_at ? formatShortDate(normalizeDateString(activated_at)) : null
}));
export default repackTableData;
