import { useSelector, shallowEqual } from 'react-redux';
import { rmaRequestSelector } from '@hero/redux-data/backoffice/rmaRequest/selectors';
import { isDeviceRestockingCheckLoadingSelector, deviceRestockingCheckSelector } from '@hero/redux-data/backoffice/deviceRestockingCheck/selectors';
import useRmaEnums from './useRmaEnums';
const useRestockingCheckResult = () => {
    const rma = useSelector(rmaRequestSelector, shallowEqual);
    const isRunningCheck = useSelector(isDeviceRestockingCheckLoadingSelector);
    const checkResult = useSelector(deviceRestockingCheckSelector, shallowEqual);
    const { reprocessingCheckValues } = useRmaEnums();
    const latestResult = checkResult?.eligible_for_restock &&
        (checkResult.eligible_for_restock === 'yes'
            ? 'Pass - Return to Sonic'
            : checkResult.eligible_for_restock === 'no'
                ? 'Fail - Return to Rush Order'
                : 'Unknown');
    const savedResult = typeof rma?.reprocessing_check === 'number' && reprocessingCheckValues
        ? reprocessingCheckValues[rma.reprocessing_check]
        : 'Not Complete';
    const restockingCheckLabel = (isRunningCheck && 'Checking...') || latestResult || savedResult;
    const suggestedReturnLocation = checkResult?.eligible_for_restock &&
        (checkResult.eligible_for_restock === 'yes' ? '6' : '1'); // 6 - sonic, 1 - rush order
    return {
        restockingCheckLabel,
        suggestedReturnLocation,
        isForRestock: checkResult?.eligible_for_restock
    };
};
export default useRestockingCheckResult;
