import React, { CSSProperties } from 'react';

import Divider from '@hero/ui-kit/components/Divider';

import DetailsSectionCancellation from './DetailsSectionCancellation';
import DetailsSectionRma from './DetailsSectionRma';
import DetailsSectionReason from './DetailsSectionReason';
import DetailsSectionTransfer from './DetailsSectionTransfer';
import {
    EnterpriseCancellationDetails,
    EnterpriseCancellationOptions,
    EnterpriseEnrollment,
    RmaEnums
} from '@hero/hero-types';

type Props = {
    enrollment?: EnterpriseEnrollment;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    orgCancellationOptions?: EnterpriseCancellationOptions;
    onRefetchOrgCancelDetails: () => void;
    rmaEnums?: RmaEnums;
};

const CancellationDetails: React.FC<Props> = ({
    enrollment,
    orgCancellationDetails,
    onRefetchOrgCancelDetails,
    orgCancellationOptions,
    rmaEnums
}) => {
    const detailsEnrollment = orgCancellationDetails?.enrollment || enrollment;
    const detailsCancellation = orgCancellationDetails?.cancellation;
    const rma = orgCancellationDetails?.rma;

    const enrollmentData = detailsEnrollment;
    const cancellationData = detailsCancellation;

    const wrapStyles: CSSProperties = {
        maxWidth: '120rem'
    };

    return enrollmentData && cancellationData ? (
        <div style={wrapStyles}>
            <DetailsSectionCancellation
                enrollment={enrollmentData}
                cancellation={cancellationData}
            />
            {cancellationData?.cancellation_type?.code === 'ec2' && (
                <>
                    <Divider />
                    <DetailsSectionRma
                        enrollment={enrollmentData}
                        cancellation={cancellationData}
                        rma={rma}
                        fetchEnterpriseCancellationDetails={onRefetchOrgCancelDetails}
                        rmaEnums={rmaEnums}
                        orgCancellationDetails={orgCancellationDetails}
                        orgCancellationOptions={orgCancellationOptions}
                    />
                </>
            )}
            {cancellationData?.cancellation_type?.code === 'ec3' && (
                <>
                    <Divider />
                    <DetailsSectionTransfer
                        enrollment={enrollmentData}
                        cancellation={cancellationData}
                    />
                </>
            )}
            <Divider />
            <DetailsSectionReason cancellation={cancellationData} />
        </div>
    ) : null;
};

export default CancellationDetails;
