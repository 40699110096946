import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import schema from '@hero/validators/forms/backoffice/createOrganization';

import InlineLoader from '../../../components/InlineLoader';
import useListStyles from '../../../hooks/useListStyles';
import DefaultErrorModal from '../../../components/DefaultErrorModal';

import useCreateOrganizationData from './useCreateOrganizationData';
import DefaultLayout from '../../../components/DefaultLayout';

const OrganizationCreate: React.FC = () => {
    const { isLoading, hasError, errorMessage, handleCreateOrganization } =
        useCreateOrganizationData();
    const { headerWrapStyles, innerWrapPadding } = useListStyles();

    return isLoading ? (
        <InlineLoader />
    ) : (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
                width="contained"
            >
                <div style={headerWrapStyles}>
                    <H role="h4">Create Organization</H>
                </div>
                <div style={innerWrapPadding}>
                    <Form validationSchema={schema} submitFn={handleCreateOrganization}>
                        <Input name="name" displayName="Name" required />
                        <Input
                            name="external_user_id_type"
                            displayName="External user ID type"
                            required
                        />
                        <Button type="submit" width="large">
                            Submit
                        </Button>
                    </Form>
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default OrganizationCreate;
