import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { unlockPaymentFailed } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useUnlockPaymentFailed = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios
            .post(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/member/unlock-payment-failed`, { ...attributes }, { ...params, timeout: 50000 })
            .then((response) => response),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [unlockPaymentFailed] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useUnlockPaymentFailed;
