import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchDeviceDetails } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';
import { deviceDetailsSelector, areDeviceDetailsLoadingSelector, deviceDetailsErrorSelector } from '@hero/redux-data/backoffice/deviceDetails/selectors';
const useRefurbishmentDetailsData = () => {
    const dispatch = useDispatch();
    const { device_id } = useParams();
    const deviceDetails = useSelector(deviceDetailsSelector, shallowEqual);
    const areDeviceDetailsLoading = useSelector(areDeviceDetailsLoadingSelector, shallowEqual);
    const { error, errorMessage } = useSelector(deviceDetailsErrorSelector, shallowEqual);
    const deviceName = deviceDetails.external_serial || device_id;
    useEffect(() => {
        device_id && dispatch(fetchDeviceDetails({ device_id: +device_id }));
    }, [dispatch, device_id]);
    return {
        device_id,
        deviceName,
        isLoading: areDeviceDetailsLoading,
        hasError: error,
        errorMessage
    };
};
export default useRefurbishmentDetailsData;
