import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import React from 'react';
import useRTMTransfer from '../../pages/Membership/api/useRTMTransfer';
import { PaymentUpdateParams } from '@hero/hero-types';
import InlineLoader from '../../components/InlineLoader';
import { StripeForm, StripeWrapper } from '../../components/Stripe';
import QueryInlineError from '../../components/QueryInlineError';
import { useCancellationStateMachine } from './context/cancellationStateMachine';

interface StepCollectPaymentMethodProps {}

const StepCollectPaymentMethod: React.FC<StepCollectPaymentMethodProps> = () => {
    const { onCreditCardSuccess, retainRTMtoD2cParams, heroUserId } = useCancellationStateMachine();
    const { mutateAsync, isPending, isError, error } = useRTMTransfer();

    const onSubmit = (formValues: PaymentUpdateParams) => {
        if (heroUserId) {
            const attributes = {
                ...formValues,
                ...(retainRTMtoD2cParams.mitigation && {
                    months: retainRTMtoD2cParams.mitigation.months,
                    discount_type: retainRTMtoD2cParams.mitigation.discount_type,
                    mitigation_type: retainRTMtoD2cParams.mitigation.mitigation_type
                }),
                ...(retainRTMtoD2cParams.plan && {
                    plan: retainRTMtoD2cParams.plan
                })
            };

            mutateAsync({ ...attributes, hero_user_id: +heroUserId })
                .then(() => onCreditCardSuccess())
                .catch(() => null);
        }
    };

    return isPending ? (
        <InlineLoader />
    ) : (
        <Section noDefaultPadding centered>
            <H role="h6">Collect Payment Method</H>
            <StripeWrapper>
                <StripeForm onSubmit={onSubmit} isSubmitDisabled={isPending} />
            </StripeWrapper>
            {isError ? <QueryInlineError error={error} /> : null}
        </Section>
    );
};

export default StepCollectPaymentMethod;
