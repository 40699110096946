import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { orgMembers } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetOrganizationMembers = (props, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/enrollment/list/`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [orgMembers, props],
        enabled
    });
};
export default useGetOrganizationMembers;
