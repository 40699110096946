import React from 'react';

import { BackofficeOrganizationNoRefillAlert } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../components/Link';
import P from '@hero/ui-kit/typography/P';

type Render = TableColumn<BackofficeOrganizationNoRefillAlert>['render'];

const emailRender: Render = ({ user }) => {
    const value = `${user.email || '-'}`;
    const to = `/users/${user.id}`;
    return <Link to={to}>{value}</Link>;
};

const serialRender: Render = ({ device }) => {
    const value = `${device.serial || '-'}`;
    const to = `/devices/${device.id}`;
    return <Link to={to}>{value}</Link>;
};

const medNameRender: Render = ({ fields }) => {
    return <P noDefaultMargin>{fields.name}</P>;
};

const createdRender: Render = ({ created }) => {
    return <P noDefaultMargin>{created}</P>;
};

const slotRender: Render = ({ fields }) => {
    return <P noDefaultMargin>{fields.slot}</P>;
};

type GetTableColumns = () => TableColumn<BackofficeOrganizationNoRefillAlert>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'email',
        name: 'Email',
        align: 'left',
        headAlign: 'left',
        render: emailRender
    },
    {
        colKey: 'serial',
        name: 'Serial',
        render: serialRender
    },
    {
        colKey: 'med_name',
        name: 'Medication Name',
        render: medNameRender
    },
    {
        colKey: 'created',
        name: 'Respond date',
        render: createdRender
    },
    {
        colKey: 'slot',
        name: 'Slot',
        render: slotRender
    }
];

export default getTableColumns;
