import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from '../../hooks/useSetQueryParams';
// clean non utf8 char because database doesn't allow it
export function cleanString(input) {
    var output = '';
    for (var i = 0; i < input.length; i++) {
        if (input.charCodeAt(i) <= 127) {
            output += input.charAt(i);
        }
    }
    return output;
}
const useSearchForm = (name) => {
    const params = getQueryParams();
    const coercedSearch = String(params[name] || '');
    const setQueryParams = useSetQueryParams();
    const handleSetSearch = (searchFromInput) => {
        const newQueryParams = buildQuery({
            ...params,
            [name]: encodeURIComponent(cleanString(searchFromInput))
        });
        setQueryParams(newQueryParams);
    };
    return [coercedSearch, handleSetSearch];
};
export default useSearchForm;
