import getMemberFullName from './getMemberFullName';
const getDetailViewTitle = (enrollment) => {
    const fullName = getMemberFullName(enrollment);
    const fullNamePart = fullName ? `${fullName} - ` : '';
    const organizationName = enrollment?.organization?.name || '';
    const organizationNamePart = organizationName ? `${organizationName} - ` : '';
    const deviceSerial = enrollment?.member?.devices[0]?.device_serial;
    const deviceSerialPart = `Device: ${deviceSerial || ''}`;
    return organizationNamePart + fullNamePart + deviceSerialPart;
};
export default getDetailViewTitle;
