import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isProspectSignupUpdatedSelector } from '@hero/redux-data/backoffice/prospectSignup/selectors';
import { useNotifications } from '../../context/notification';
const useProspectSignupSuccessAlert = () => {
    const isProspectSignedUp = useSelector(isProspectSignupUpdatedSelector);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isProspectSignedUp) {
            id.current = addNotification({
                type: 'success',
                message: 'Order has been initiated.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isProspectSignedUp]);
};
export default useProspectSignupSuccessAlert;
