import * as React from 'react';
import ReviewEnterprise from './ReviewEnterprise';
import ReviewRefund from './ReviewRefund';
import ReviewFee from './ReviewFee';
import H from '@hero/ui-kit/typography/H';
import {
    CancellationType,
    RefundPreview,
    CancellationTransactionType,
    CancellationFeePreview
} from '@hero/hero-types';

interface TransactionReviewProps {
    isOrganizationMember?: boolean;
    cancellationType: CancellationType | undefined;
    transactionType: CancellationTransactionType | undefined;
    refundPreview: RefundPreview | null;
    feePreview: CancellationFeePreview | null;
}

const TransactionReview: React.FC<TransactionReviewProps> = ({
    isOrganizationMember,
    cancellationType,
    transactionType,
    refundPreview,
    feePreview
}) => {
    if (isOrganizationMember) {
        return <ReviewEnterprise type={cancellationType} />;
    }
    if (transactionType === 'refund') {
        return <ReviewRefund refund={refundPreview} />;
    }
    if (transactionType === 'charge' || transactionType === 'waive') {
        return <ReviewFee fee={feePreview} />;
    }
    if (transactionType === 'none') {
        return (
            <H role="h6" centered>
                No fee or refund
            </H>
        );
    }
    return null;
};

export default TransactionReview;
