import React, { useMemo } from 'react';

import Form from '@hero/ui-kit/inputs/Form';
import {
    setRmaRequestPickUpSchema,
    SetRmaRequestPickUpParams
} from '@hero/validators/forms/backoffice/rmaRequestSchema';

import InlineLoader from '../../components/InlineLoader';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import ReturnShippingFormWrapper from '../forms/shipping/ReturnShippingFormWrapper';
import useCustomerContactDetails from '../utils/useCustomerContactDetails';
import QueryInlineError from '../../components/QueryInlineError';
import useRmaEnums from '../../pages/Rma/utils/useRmaEnums';
import useEnumOptions from '../../pages/Rma/utils/useEnumOptions';
import ProviderApiError from '../../components/ProviderApiError';

const StepShipping: React.FC = () => {
    const {
        isOrganizationMember,
        handleCompleteRma,
        enrollment,
        membership,
        rmaRequest,
        rmaEnums,
        createRmaDraftError,
        isCreateRmaDraftLoading,
        isSetRmaPickupLoading,
        setRmaPickupError
    } = useCancellationStateMachine();

    const contactDetails = useCustomerContactDetails(isOrganizationMember, membership, enrollment);

    const isLoading = isCreateRmaDraftLoading || isSetRmaPickupLoading;

    const { returnLocations } = useRmaEnums(rmaEnums);
    const returnLocationOptions = useEnumOptions(returnLocations);
    const reconextValue = '7';
    const isReconnextEnabled = !!returnLocationOptions.find(([value]) => value === reconextValue);
    const defaultRmaReturnLocation =
        typeof rmaRequest?.return_location === 'number' ? String(rmaRequest.return_location) : '1';

    const defaultValues: Partial<SetRmaRequestPickUpParams> = useMemo(
        () => ({
            return_location: isReconnextEnabled ? reconextValue : defaultRmaReturnLocation,
            return_shipping_option: '1',
            ...contactDetails
        }),
        [rmaRequest, contactDetails]
    );

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={setRmaRequestPickUpSchema}
            defaultValues={defaultValues}
            submitFn={handleCompleteRma}
            validationMode="onTouched"
        >
            <ReturnShippingFormWrapper state={contactDetails?.state} rmaEnums={rmaEnums} />
            {createRmaDraftError ? <QueryInlineError centered error={createRmaDraftError} /> : null}
            {setRmaPickupError ? <QueryInlineError centered error={setRmaPickupError} /> : null}
            {setRmaPickupError ? <ProviderApiError centered error={setRmaPickupError} /> : null}
        </Form>
    );
};

export default StepShipping;
