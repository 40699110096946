import useTheme from '@hero/branding/theme';
import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Form from '@hero/ui-kit/inputs/Form';
import Option from '@hero/ui-kit/inputs/Option';
import Select from '@hero/ui-kit/inputs/Select';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import React from 'react';
import logLevelSchema, { LogLevelParams } from './schema';
import useUpdateDeviceSettings from '../api/useUpdateDeviceSettings';
import P from '@hero/ui-kit/typography/P';
import QueryInlineError from '../../../../components/QueryInlineError';

export type ILoggingLevel = 'debug' | 'info' | 'warning' | 'error' | 'critical';

interface LoggingLevelProps {
    type: 'system' | 'app';
    loggingLevel?: number;
    header?: string;
    deviceId: number;
    onSuccess?: () => void;
}

export const logLevels = [
    {
        value: 0,
        label: 'Debug'
    },
    {
        value: 1,
        label: 'Info'
    },
    {
        value: 2,
        label: 'Warning'
    },
    {
        value: 3,
        label: 'Error'
    },
    {
        value: 4,
        label: 'Critical'
    },
    {
        value: 5,
        label: 'Fatal'
    }
];

const LoggingLevel: React.FC<LoggingLevelProps> = ({
    loggingLevel = 0,
    header = 'System Logging Level',
    deviceId,
    onSuccess,
    type = 'system'
}) => {
    const theme = useTheme();
    const [showEdit, setShowEdit] = React.useState(false);
    const { mutateAsync, error, isSuccess, isPending } = useUpdateDeviceSettings();

    const handleClose = () => {
        setShowEdit(false);
        isSuccess && onSuccess && onSuccess();
    };

    const pillStyle: React.CSSProperties = {
        borderRadius: '0.4rem',
        color: theme.colors.neutrals.textAlpha,
        background: '#DFE1E6',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        paddingLeft: '0.6rem',
        paddingRight: '0.6rem',
        fontSize: '1.2rem'
    };

    const handleSubmit = (attributes: LogLevelParams) => {
        mutateAsync({
            device_id: deviceId,
            settings: {
                ...(type === 'system' && {
                    system_logging_level: attributes.log_level
                }),
                ...(type === 'app' && {
                    application_logging_level: attributes.log_level
                })
            }
        });
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.6rem',
                    height: '2rem'
                }}
            >
                {logLevels.map((logLevel) => (
                    <div
                        key={logLevel.label}
                        style={{
                            ...pillStyle,
                            ...(loggingLevel === logLevel.value && {
                                color: '#DE350B',
                                background: '#FDEBE6'
                            })
                        }}
                    >
                        {logLevel.label}
                    </div>
                ))}
                <Button size="small" onClick={() => setShowEdit(true)}>
                    Edit
                </Button>
            </div>

            <Modal onClose={handleClose} isCancelable externalControls={[showEdit, setShowEdit]}>
                <H role="h4">{header}</H>
                {isSuccess ? (
                    <Section centered>
                        <P>Successfully changed logging level</P>
                        <Button onClick={handleClose} width="full">
                            Ok
                        </Button>
                    </Section>
                ) : (
                    <Form
                        validationSchema={logLevelSchema}
                        defaultValues={{ log_level: 'none' }}
                        submitFn={handleSubmit}
                    >
                        <Section centered noDefaultPadding>
                            <Select
                                name="log_level"
                                displayName="Select Log Level"
                                styles={{ minWidth: '30rem' }}
                                defaultValue={'none'}
                                disabled={isPending}
                            >
                                {logLevels.map((logLevel) => (
                                    <Option
                                        styles={{ textTransform: 'uppercase' }}
                                        key={logLevel.label}
                                        value={`${logLevel.value}`}
                                        labelText={logLevel.label}
                                    />
                                ))}
                            </Select>
                        </Section>

                        <Button type="submit" width="full" disabled={isPending}>
                            Submit
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            width="full"
                            disabled={isPending}
                        >
                            Cancel
                        </Button>
                        {error ? <QueryInlineError error={error} /> : null}
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default LoggingLevel;
