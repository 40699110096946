import React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import * as ReactivationFlowStepComponents from './steps';
import ReactivationFlowProvider, {
    useReactivationFlowContext
} from './context/ReactivationFlowProvider';
import InlineLoader from '../../../../components/InlineLoader';
import { BackofficeMembershipDetails, CancellationDetails } from '@hero/hero-types';

type ReactivationFlowProps = {
    membership: BackofficeMembershipDetails;
    membershipId: number;
    onCompleteFlow?: () => void;
    cancellationDetails?: CancellationDetails;
} & Pick<ModalProps, 'externalControls'>;

const ReactivationFlowSteps: React.FC<ReactivationFlowProps> = ({ externalControls }) => {
    const { reactivationStep, isLoading, onCloseReactivationFlow } = useReactivationFlowContext();
    const ReactivationStepComponent = React.useMemo(() => {
        return ReactivationFlowStepComponents[reactivationStep];
    }, [reactivationStep]);

    const modalType = React.useMemo(() => {
        if (reactivationStep === 'Fail') {
            return 'warn';
        }
        if (reactivationStep === 'Success') {
            return 'success';
        }
        return 'neutral';
    }, [reactivationStep]);

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            type={modalType}
            onClose={onCloseReactivationFlow}
            styles={{ width: '100rem' }}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <H role="h5" noDefaultMargin styles={{ marginBottom: '2rem' }}>
                        Reactivate Membership
                    </H>
                    <ReactivationStepComponent />
                </>
            )}
        </Modal>
    );
};

const ReactivationFlow: React.FC<ReactivationFlowProps> = (props) => {
    return (
        <ReactivationFlowProvider
            membership={props.membership}
            membershipId={props.membershipId}
            externalControls={props.externalControls}
            onCompleteFlow={props.onCompleteFlow}
            cancellationDetails={props.cancellationDetails}
        >
            <ReactivationFlowSteps {...props} />
        </ReactivationFlowProvider>
    );
};

export default ReactivationFlow;
