import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRefundPreview } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetRefundPreview = (props, enabled) => {
    const additional = useQueryRequestParams();
    const id = props.id || props.hero_user_id || props.device_serial;
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/refund-preview`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [cancellationRefundPreview, id],
        enabled
    });
};
export default useGetRefundPreview;
