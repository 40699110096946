import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import schema from '@hero/validators/forms/backoffice/updateOrganization';
import InlineLoader from '../../../components/InlineLoader';
import useListStyles from '../../../hooks/useListStyles';
import DefaultErrorModal from '../../../components/DefaultErrorModal';

import TabBar from '../TabBar';
import useOrganizationDetailsData from './useOrganizationDetailsData';
import DefaultLayout from '../../../components/DefaultLayout';

const OrganizationDetails: React.FC = () => {
    const { isLoading, hasError, errorMessage, name, defaults, handleUpdateOrganization } =
        useOrganizationDetailsData();
    const { headerWrapStyles, innerWrapPadding } = useListStyles();

    return (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
                width="contained"
            >
                <>
                    <div style={headerWrapStyles}>
                        <H role="h4">{name}</H>
                    </div>
                    <TabBar />
                    {isLoading ? (
                        <InlineLoader />
                    ) : (
                        <div style={innerWrapPadding}>
                            <Form
                                validationSchema={schema}
                                submitFn={handleUpdateOrganization}
                                defaultValues={defaults}
                            >
                                <Input name="name" displayName="Name" required />
                                <Button type="submit" width="large">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    )}
                </>
            </Section>
        </DefaultLayout>
    );
};

export default OrganizationDetails;
