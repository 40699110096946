import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";

import omitProp from "@hero/hero-utils/omitProp";

type ViewConfigs = Record<string, object>;

type GetViewConfig = (key: string) => object;

type SetViewConfig = (key: string, config: object) => void;

type ResetViewConfig = (key: string) => void;

type ViewConfigContextType = Readonly<{
  getViewConfig: GetViewConfig;
  setViewConfig: SetViewConfig;
  resetViewConfig: ResetViewConfig;
}>;

const ViewConfigContext = createContext<ViewConfigContextType | undefined>(
  undefined
);

export const useViewConfig = () => {
  const viewConfigContext = useContext(ViewConfigContext);

  if (viewConfigContext === undefined) {
    throw new Error(
      `'useViewConfig' must be used within a 'ViewConfigProvider'`
    );
  }

  return viewConfigContext;
};

type ProviderProps = {
  children: ReactNode;
};

export const ViewConfigProvider: React.FC<ProviderProps> = ({ children }) => {
  const [viewConfigs, setViewConfigs] = useState<ViewConfigs>({});

  const getViewConfig: GetViewConfig = useCallback(
    (key) => ({ ...viewConfigs[key] }),
    [viewConfigs]
  );

  const setViewConfig: SetViewConfig = useCallback((key, config) => {
    setViewConfigs((viewConfigs) => ({ ...viewConfigs, [key]: config }));
  }, []);

  const resetViewConfig: ResetViewConfig = useCallback((key) => {
    setViewConfigs((viewConfigs) => omitProp(viewConfigs, key));
  }, []);

  return (
    <ViewConfigContext.Provider
      value={{ getViewConfig, setViewConfig, resetViewConfig }}
    >
      {children}
    </ViewConfigContext.Provider>
  );
};
