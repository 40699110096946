import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { isMembershipReactivationUpdatedSelector } from "@hero/redux-data/backoffice/membershipReactivation/selectors";
import { useNotifications } from "../../context/notification";
const useMembershipReactivateSuccessAlert = () => {
    const isMembershipReactivated = useSelector(isMembershipReactivationUpdatedSelector);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isMembershipReactivated) {
            id.current = addNotification({
                type: "success",
                message: "Membership has been reactivated.",
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isMembershipReactivated]);
};
export default useMembershipReactivateSuccessAlert;
