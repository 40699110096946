import React, { useCallback, useMemo } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Divider from '@hero/ui-kit/components/Divider';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import { EnterpriseEnrollmentSubmissionParams } from '@hero/validators/forms/backoffice/enterpriseEnrollmentSubmissionSchema';

import FormattedAddress from '../../components/FormattedAddress';

type Props = {
    submittedValues: EnterpriseEnrollmentSubmissionParams | null;
    onConfirm: () => void;
} & Pick<ModalProps, 'externalControls'>;

const ConfirmSignupModal: React.FC<Props> = ({ submittedValues, onConfirm, externalControls }) => {
    const [, setExternalState] = externalControls ?? [];

    const smartPhoneProcessed = useMemo(() => {
        const smartPhoneOption = submittedValues?.has_smartphone;
        return smartPhoneOption === 'iphone'
            ? 'iPhone'
            : smartPhoneOption === 'android'
            ? 'Android'
            : smartPhoneOption === 'no'
            ? 'No / Other'
            : '-';
    }, [submittedValues]);

    const handleCancel = useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const capitalizeInitial = (str: string) =>
        str.length === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <Modal externalControls={externalControls} isCancelable>
            <H role="h4" centered>
                Payor A Sign Up
            </H>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ width: '100%' }}
            >
                <P strong>Invitation Code</P>
                <P>{submittedValues?.invite_code || '-'}</P>
                <P strong>First Name</P>
                <P>{submittedValues?.first_name || '-'}</P>
                <P strong>Last Name</P>
                <P>{submittedValues?.last_name || '-'}</P>
                <P strong>Email Address</P>
                <P>{submittedValues?.email || '-'}</P>
                <P strong>Phone Number</P>
                <P>{submittedValues?.home_phone || '-'}</P>
                <P strong noDefaultMargin>
                    Address
                </P>
                <P noDefaultMargin>
                    <FormattedAddress address={submittedValues} />
                </P>
            </Container>
            <Divider styles={{ width: '100%' }} />
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ width: '100%' }}
            >
                <P strong>Smart Phone</P>
                <P>{smartPhoneProcessed}</P>
                <P strong>Pill Takes Per Day</P>
                <P>{submittedValues?.daily_pills || '-'}</P>
                <P strong>WiFi Access</P>
                <P>
                    {submittedValues?.has_wifi ? capitalizeInitial(submittedValues.has_wifi) : '-'}
                </P>
                <P strong>Need Support</P>
                <P>
                    {submittedValues?.needs_setup_help
                        ? capitalizeInitial(submittedValues.needs_setup_help)
                        : '-'}
                </P>
            </Container>
            <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                <Button width="large" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button width="large" variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </Section>
        </Modal>
    );
};

export default ConfirmSignupModal;
