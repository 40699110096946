import React, { FC, useCallback, useMemo } from 'react';
import Form from '@hero/ui-kit/inputs/Form';
import {
    SetRmaRequestPickUpParams,
    setRmaRequestPickUpSchema
} from '@hero/validators/forms/backoffice/rmaRequestSchema';
import InlineLoader from '../../../components/InlineLoader';
import ReturnShippingFormWrapper from '../../forms/shipping/ReturnShippingFormWrapper';
import useCustomerContactDetails from '../../utils/useCustomerContactDetails';
import { useExchangeContext } from '../context/ExchangeProvider';
import QueryInlineError from '../../../components/QueryInlineError';
import useRmaEnums from '../../../pages/Rma/utils/useRmaEnums';
import useEnumOptions from '../../../pages/Rma/utils/useEnumOptions';
import ProviderApiError from '../../../components/ProviderApiError';

interface OldDeviceProps {
    closeExchangeModal: () => void;
}

export const OldDevice: FC<OldDeviceProps> = () => {
    const {
        onCompleteRma,
        isSetRmaPickupLoading,
        isOrganizationMember,
        rma,
        rmaEnums,
        setRmaPickupError,
        membership,
        enterpriseEnrollment
    } = useExchangeContext();

    const handleCompleteRma = useCallback(
        (formValues: SetRmaRequestPickUpParams) => {
            onCompleteRma(formValues);
        },
        [onCompleteRma]
    );

    const contactDetails = useCustomerContactDetails(
        isOrganizationMember,
        membership,
        enterpriseEnrollment
    );

    const { returnLocations } = useRmaEnums(rmaEnums);
    const returnLocationOptions = useEnumOptions(returnLocations);
    const reconextValue = '7';
    const isReconnextEnabled = !!returnLocationOptions.find(([value]) => value === reconextValue);
    const defaultRmaReturnLocation =
        typeof rma?.return_location === 'number' ? String(rma.return_location) : '1';

    const defaultValues: Partial<SetRmaRequestPickUpParams> = useMemo(
        () => ({
            return_location: isReconnextEnabled ? reconextValue : defaultRmaReturnLocation,
            return_shipping_option: '1',
            ...contactDetails
        }),
        [isReconnextEnabled, contactDetails, defaultRmaReturnLocation]
    );

    return isSetRmaPickupLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={setRmaRequestPickUpSchema}
            submitFn={handleCompleteRma}
            defaultValues={defaultValues}
        >
            <ReturnShippingFormWrapper
                buttonLabel={'Complete Exchange'}
                state={contactDetails?.state}
                rmaEnums={rmaEnums}
            />
            {setRmaPickupError ? <QueryInlineError centered error={setRmaPickupError} /> : null}
            {setRmaPickupError ? <ProviderApiError centered error={setRmaPickupError} /> : null}
        </Form>
    );
};
