import React, { CSSProperties, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import getURLParams from '@hero/hero-utils/URLSearchParams';
import InlineLoader from '../../../components/InlineLoader';
import TabBar from '../TabBar';
import { MembershipRouteParams } from '../types';
import ExchangeRma from './ExchangeRma';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetMembershipDetails from '../api/useGetMembershipDetails';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import useGetDetailViewTitleV2 from '../utils/useGetDetailViewTitleV2';
import useGetRmaExchangeRequest from '../../Rma/api/useGetRmaExchangeRequest';
import useGetRmaEnums from '../../Rma/api/useGetRmaEnums';
import { RmaRequest } from '@hero/hero-types';

const MembershipDeviceExchange: React.FC = () => {
    const { id } = useParams<MembershipRouteParams>();
    const { search } = useLocation();
    const navigate = useNavigate();

    const serial = getURLParams(search, 'serial');
    const userId = getURLParams(search, 'user_id');

    const shouldFetchMembership = !!id || !!userId || !!serial;

    const [rma, setRma] = React.useState<RmaRequest | null>(null);

    const membershipParams = {
        ...(id && { id: +id }),
        ...(userId && { hero_user_id: +userId }),
        ...(serial && { device_serial: serial })
    };

    const { data: membershipResponse, isLoading: isFetchingMembership } = useGetMembershipDetails(
        membershipParams,
        shouldFetchMembership
    );

    const { data: rmaEnumsResponse, isLoading: isFetchingRmaEnums } = useGetRmaEnums(true);

    const rmaEnums = rmaEnumsResponse?.data;

    const {
        mutate,
        data: userDeviceResponse,
        isPending: isFetchingUserDevice
    } = useGetUserDeviceDetails();

    const membership = membershipResponse?.data.data;

    const isFetching = isFetchingUserDevice || isFetchingMembership || isFetchingRmaEnums;

    const {
        data: exchangeRmaResponse,
        error: exchangeRmaErrorResponse,
        refetch: refetchRmaExchange
    } = useGetRmaExchangeRequest(
        { device_serial: membership?.serial ? membership.serial : '' },
        !!membership?.serial
    );

    React.useEffect(() => {
        if (exchangeRmaErrorResponse?.response?.status === 404) {
            setRma(null);
        }

        if (exchangeRmaResponse?.data && exchangeRmaErrorResponse?.response?.status !== 404) {
            setRma(exchangeRmaResponse.data);
        }
    }, [exchangeRmaResponse, exchangeRmaErrorResponse]);

    const handleRefetchRmaExchange = () => {
        refetchRmaExchange();
    };

    const userDetails = userDeviceResponse?.data;

    useEffect(() => {
        if (membership?.serial) {
            mutate({ external_serial: membership.serial });
        }
    }, [membership, mutate]);

    useEffect(() => {
        if (!id && membership?.id) {
            navigate(`/membership/${membership.id}/exchange`, { replace: true });
        }
    }, [navigate, id, membership]);

    const handleBackToList = useCallback(() => {
        navigate('/membership', { replace: true });
    }, [navigate]);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const pageDetails = useMemo(() => {
        if (isFetching) {
            return <InlineLoader />;
        }
        if (!membership) {
            <P>No membership data available.</P>;
        }
        if (membership?.serial && membership?.status !== 'cancelled') {
            return (
                <ExchangeRma
                    deviceSerial={membership.serial}
                    membership={membership}
                    rma={rma}
                    userDetails={userDetails}
                    exchangeRmaError={exchangeRmaErrorResponse}
                    rmaEnums={rmaEnums}
                    refetchRmaExchange={handleRefetchRmaExchange}
                />
            );
        }
        if (membership?.status === 'cancelled') {
            return <P>Membership Cancelled, Exchange is Disabled.</P>;
        }
        return null;
    }, [isFetching, membership, rma, userDetails, exchangeRmaErrorResponse, rmaEnums]);

    const headerTitle = useGetDetailViewTitleV2(userDetails, membership);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{headerTitle}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>{pageDetails}</div>
            </Section>
        </DefaultLayout>
    );
};

export default MembershipDeviceExchange;
