// it would not be a bad ide to get rid of this
// new pages should'nt add to this
export const users = {
    name: 'Users',
    to: '/users'
};
export const devices = {
    name: 'Devices',
    to: '/devices'
};
export const monitoring = {
    name: 'Monitoring',
    to: '/monitoring'
};
export const patients = {
    name: 'Patient Records',
    to: '/patients'
};
export const transferRequests = {
    name: 'Transfer Requests',
    to: '/transfer-requests'
};
