import React from 'react';
import { Route, Routes } from 'react-router-dom';

import OrganizationList from './OrganizationsList';
import OrganizationCreate from './OrganizationCreate';
import OrganizationDetails from './OrganizationDetails';
import OrganizationEnrollment from './OrganizationEnrollment';
import EnterpriseEnrollmentVerification from './EnterpriseEnrollmentVerification';
import EnterpriseMemberList from './EnterpriseMemberList';
import EnterpriseMemberDetails from './EnterpriseMemberDetails';
import EnterpriseMemberDeviceExchange from './EnterpriseMemberDeviceExchange';
import EnterpriseMembershipCancellation from './EnterpriseMembershipCancellation';
import DischargedQueueMemberList from './DischargedQueueMemberList';
import showRTMDischargeFeature from './utils/showDischargeFeature';
import MemberDetailsByMemberId from './EnterpriseMemberDetails/MemberDetailsByMemberId';

const OrganizationRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<OrganizationList />} />
            <Route path="create" element={<OrganizationCreate />} />
            {showRTMDischargeFeature ? (
                <Route path="discharged-members" element={<DischargedQueueMemberList />} />
            ) : null}
            <Route path=":id/enrollment" element={<OrganizationEnrollment />} />
            <Route path=":id/basic-info" element={<OrganizationDetails />} />
            <Route path=":id/members" element={<EnterpriseMemberList />} />
            <Route path=":id/member/:member_id" element={<MemberDetailsByMemberId />} />
            <Route
                path=":id/members/:enrollment_id/details"
                element={<EnterpriseMemberDetails />}
            />
            <Route
                path=":id/members/:enrollment_id/exchange"
                element={<EnterpriseMemberDeviceExchange />}
            />
            <Route
                path=":id/members/:enrollment_id/cancellation"
                element={<EnterpriseMembershipCancellation />}
            />
            <Route path=":id/verification" element={<EnterpriseEnrollmentVerification />} />
        </Routes>
    );
};

export default OrganizationRoutes;
