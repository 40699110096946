import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getCancellationOptions } from '@hero/redux-data/backoffice/cancellationOptions/actionCreators';
import { getEnterpriseCancellationOptions } from '@hero/redux-data/backoffice/enterpriseCancellationOptions/actionCreators';
const useGetCancellationOptions = (isOrganizationMember = false) => {
    const dispatch = useDispatch();
    return useCallback((id) => {
        isOrganizationMember
            ? dispatch(getEnterpriseCancellationOptions({ id }))
            : dispatch(getCancellationOptions({ id }));
    }, [dispatch, isOrganizationMember]);
};
export default useGetCancellationOptions;
