import React from 'react';

import { OrganizationMembership } from '@hero/hero-types';
import Button from '@hero/ui-kit/inputs/Button';
import useToggle from '@hero/ui-kit/hooks/useToggle';

import useDeviceDetailsStyles from '../useDeviceDetailsStyles';
import RemoveFromOrgModal from './RemoveFromOrgModal';
import RemoveTransferFlagModal from './RemoveTransferFlagModal';
import RemoveDeviceModal from './RemoveDeviceModal';
import RebootResetDeviceModal from './RebootResetDeviceModal';

type Props = {
    id: number;
    serial: string;
    organizationMembership: OrganizationMembership | null;
};

const AdditionalControls: React.FC<Props> = ({ id, organizationMembership, serial }) => {
    const { smallButtonMargin, addtionalControlsMargin } = useDeviceDetailsStyles();
    const [
        showRemoveTrasnsferFalgModal,
        setShowRemoveTransferFlagModalOn,
        setShowRemoveTranfserFlagModalOff
    ] = useToggle();
    const [showRemoveFromOrgModal, setShowRemoveFromOrgModalOn, setShowRemoveFromOrgModalOff] =
        useToggle();
    const [showDeleteDeviceModal, setShowDeleteDeviceModalOn, setShowDeleteDeviceModalOff] =
        useToggle();

    const [showDeviceRebootModal, setShowDeviceRebootModalOn, setShowDeviceRebootModalOff] =
        useToggle();

    const [
        showDeviceRebootResetModal,
        setShowDeviceRebootResetModalOn,
        setShowDeviceRebootResetModalOff
    ] = useToggle();

    const handleCloseRebootResetModal = () => {
        setShowDeviceRebootModalOff();
        setShowDeviceRebootResetModalOff();
    };

    return (
        <div style={addtionalControlsMargin}>
            {showDeleteDeviceModal && (
                <RemoveDeviceModal
                    handleClose={setShowDeleteDeviceModalOff}
                    device_id={id}
                    serial={serial}
                />
            )}
            {showRemoveTrasnsferFalgModal && (
                <RemoveTransferFlagModal
                    handleClose={setShowRemoveTranfserFlagModalOff}
                    device_id={id}
                    organization_name={organizationMembership?.organization.name}
                />
            )}
            {showRemoveFromOrgModal && organizationMembership && (
                <RemoveFromOrgModal
                    handleClose={setShowRemoveFromOrgModalOff}
                    device_id={id}
                    organization_name={organizationMembership.organization.name}
                />
            )}
            {showDeviceRebootModal || showDeviceRebootResetModal ? (
                <RebootResetDeviceModal
                    device_id={id}
                    handleClose={handleCloseRebootResetModal}
                    serial={serial}
                    isReset={showDeviceRebootResetModal}
                />
            ) : null}
            <Button size="small" onClick={setShowDeleteDeviceModalOn}>
                Remove Device
            </Button>
            <Button
                size="small"
                onClick={setShowRemoveTransferFlagModalOn}
                styles={smallButtonMargin}
            >
                Remove Transfer Flag
            </Button>
            {organizationMembership && (
                <Button
                    size="small"
                    onClick={setShowRemoveFromOrgModalOn}
                    styles={smallButtonMargin}
                >
                    Remove from Organization
                </Button>
            )}
            <Button size="small" onClick={setShowDeviceRebootModalOn} styles={smallButtonMargin}>
                Reboot Device
            </Button>
            <Button
                size="small"
                onClick={setShowDeviceRebootResetModalOn}
                styles={smallButtonMargin}
            >
                Reboot & Reset Device
            </Button>
        </div>
    );
};

export default AdditionalControls;
