import React, { CSSProperties, useCallback, useEffect } from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import Input from '@hero/ui-kit/inputs/Input';

import SearchButton, { ActionType } from './SearchButton';

type Props = {
    name: string;
    activeSearch: string;
    onSubmit: (params: Record<string, string>) => void;
    displayName?: string;
    placeholder?: string;
};

/**
 * _secondary_ button indicates that input value and currently applied search are equal.
 * (While search still works, in most cases it would return the same results.)
 * More precisely:
 * - if both are empty, secondary _search_ icon is displayed
 * - if both are non-empty and equal, secondary _clear_ icon is displayed
 * (Latter is for convenience, since clearing the search is more often needed than repeating it.)
 *
 * _primary_ button indicates that input value has not yet been applied and thus calls for action.
 * More precisely:
 * - if they are different and input value is non-empty, primary _search_ icon is displayed
 * - if they are different and input value is empty, primary _clear_ icon is displayed
 *
 * Hitting enter always submits current input value (which is the expected behavior).
 * Button acts differently in different cases (which is clearly indicated by the icon displayed):
 * - if search icon is displayed, button submits the current input value
 * - if clear icon is displayed, button first clears the input (if needed) and then submits
 */
const SearchBar: React.FC<Props> = ({
    name,
    activeSearch,
    onSubmit,
    displayName = '',
    placeholder = 'Search...'
}) => {
    const {
        watch,
        setValue,
        reset,
        formState: { submitCount },
        handleSubmit
    } = useFormContext<Record<string, string>>();
    const search = watch(name);

    useEffect(() => {
        // keep input value and currently applied search in sync (upon submit)
        activeSearch !== '' && reset({ [name]: activeSearch });
    }, [submitCount, name, activeSearch, reset]);

    const buttonVariant = search !== activeSearch ? 'primary' : 'secondary';
    const buttonActionType =
        (search === '' && activeSearch === search) || (search !== '' && activeSearch !== search)
            ? 'search'
            : 'clear';

    const wrapperStyles: CSSProperties = { position: 'relative', width: '40rem' };
    const inputStyles: CSSProperties = { paddingRight: '5rem' };
    const buttonStyles: CSSProperties = {
        position: 'absolute',
        top: displayName ? '3.8rem' : '1.4rem',
        right: '1rem',
        width: '3.6rem',
        height: '3.6rem'
    };

    const handleClick = useCallback(
        (actionType: ActionType) => {
            if (actionType === 'clear') {
                setValue(name, '');
            }
            handleSubmit(onSubmit)();
        },
        [name, onSubmit, handleSubmit, setValue]
    );

    return (
        <div style={wrapperStyles}>
            <Input
                name={name}
                displayName={displayName}
                placeholder={placeholder}
                styles={inputStyles}
            />
            <SearchButton
                actionType={buttonActionType}
                onClick={handleClick}
                variant={buttonVariant}
                styles={buttonStyles}
            />
        </div>
    );
};

export default SearchBar;
