import { formatPhoneNumber } from '@hero/hero-utils/phone';
import trimDeep from '@hero/hero-utils/trimDeep';
const repackValuesForSubmit = (formValues) => {
    const { invite_code, first_name, last_name, email, home_phone, address_line_1, address_line_2, city, zip, state, ...rest } = trimDeep(formValues);
    const phone = formatPhoneNumber(home_phone);
    return {
        organization_id: 1, // Payor A hardcoded
        invite_code,
        contact: {
            first_name,
            last_name,
            email,
            cell_phone: phone,
            home_phone: phone,
            address: {
                address_line_1,
                ...(address_line_2 && { address_line_2 }),
                city,
                zip,
                state,
                country: 'US'
            }
        },
        meta_data_info: {
            record_type: 'telemarketing',
            ...rest
        }
    };
};
export default repackValuesForSubmit;
