import React, {
    ReactNode,
    CSSProperties,
    useMemo,
    createContext,
    useContext,
    useCallback
} from 'react';
import Span from '@hero/ui-kit/typography/Span';
import { rmaReturnLocationAddresses } from '../../../../utils/rmaReturnLocationAddresses';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';
import useTheme from '@hero/branding/theme';
import useRmaEnums from '../../utils/useRmaEnums';
import {
    RmaRequest,
    BackofficeMembershipDetails,
    Reasons,
    CancellationType,
    RmaEnums,
    EnterpriseEnrollment,
    BackofficeUserDeviceDetails,
    EnterpriseCancellationDetails,
    CancellationDetails,
    CancellationOptions,
    EnterpriseCancellationOptions
} from '@hero/hero-types';
import useGetRmaFulfillmentTrackingNumber from '../../utils/useGetRmaFulfillmentTrackingNumber';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

type ContextType = {
    id: number;
    isOrganizationMember: boolean;
    rma: RmaRequest | null;
    cancellationTypes: CancellationType[] | [];
    membership?: BackofficeMembershipDetails;
    rmaStatuses?: Record<string, string>;
    rmaReasons?: Reasons;
    returnLocations?: Record<string, string>;
    returnStatuses?: Record<string, string>;
    leftColWrapStyles: CSSProperties;
    rightColWrapStyles: CSSProperties;
    formatLocalDateTime: (date: string | Date | undefined) => string;
    returnAddress: JSX.Element;
    returnLabelLink: JSX.Element;
    labelStyle: CSSProperties;
    valueStyle: CSSProperties;
    trackingNumber: JSX.Element;
    exchangeTrackingNumber: JSX.Element;
    zendeskTicketNumber: JSX.Element;
    replacementBoxTrackingNumber: JSX.Element;
    orderEmail?: string | null;
    activatedAt?: string | null;
    returnedDeviceState: string;
    replacementBoxRequired: string;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    cancellationDetails?: CancellationDetails;
};

const RmaContext = createContext<ContextType | undefined>(undefined);

export const useRmaContext = () => {
    const ctx = useContext(RmaContext);

    if (ctx === undefined) {
        throw new Error(`'useRmaContext' must be used within a 'RmaContextProvider'`);
    }

    return ctx;
};

type IRmaProvider = {
    children: ReactNode;
    rma?: RmaRequest | null;
    isOrganizationMember: boolean;
    rmaEnums?: RmaEnums;
    membership?: BackofficeMembershipDetails;
    enterpriseEnrollment?: EnterpriseEnrollment;
    userDetails?: BackofficeUserDeviceDetails;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    cancellationDetails?: CancellationDetails;
    cancellationOptions?: CancellationOptions;
    orgCancellationOptions?: EnterpriseCancellationOptions;
};

export const RmaProvider = ({
    children,
    rma,
    isOrganizationMember = false,
    membership,
    cancellationOptions,
    orgCancellationOptions,
    rmaEnums,
    enterpriseEnrollment,
    cancellationDetails,
    orgCancellationDetails
}: IRmaProvider) => {
    const { colors } = useTheme();
    const id = rma?.id || 0;

    const regularCancellationTypes = cancellationOptions?.cancellation_types || [];
    const enterpriseCancelationTypes =
        orgCancellationOptions?.cancellation_options.cancellation_types;
    const enrollment = enterpriseEnrollment;
    const { rmaStatuses, rmaReasons, returnLocations, returnStatuses } = useRmaEnums(rmaEnums);
    const fulfillmentExchangeTrackingNumber = useGetRmaFulfillmentTrackingNumber(rma || undefined);

    const cancellationTypes: CancellationType[] = useMemo(() => {
        if (isOrganizationMember) {
            if (orgCancellationDetails?.cancellation?.cancellation_type) {
                return [orgCancellationDetails?.cancellation.cancellation_type];
            }
            return enterpriseCancelationTypes || [];
        }

        if (cancellationDetails?.cancellation?.cancellation_type) {
            return [cancellationDetails.cancellation.cancellation_type];
        }

        return regularCancellationTypes || [];
    }, [
        isOrganizationMember,
        regularCancellationTypes,
        enterpriseCancelationTypes,
        cancellationDetails,
        orgCancellationDetails
    ]);

    const {
        return_location,
        return_label,
        return_tracking_number,
        zendesk_ticket_number,
        exchange_tracking_number,
        device_rma_status,
        send_box,
        box_tracking_url
    } = rma ?? {};

    const { tracking_number } = return_label ?? {};

    const leftColWrapStyles: CSSProperties = {
        gridColumnStart: 1,
        alignItems: 'start'
    };
    const rightColWrapStyles: CSSProperties = {
        gridColumnStart: 2,
        alignItems: 'start'
    };

    const marginBottom = useComponentMargin({ marginBottom: 'regular' });

    const labelStyle: CSSProperties = useMemo(() => {
        return {
            fontWeight: 600,
            fontSize: '12px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            color: colors.neutrals.textBeta,
            ...marginBottom
        };
    }, [colors.neutrals.textBeta, marginBottom]);

    const valueStyle: CSSProperties = useMemo(() => {
        return {
            fontWeight: 600,
            fontSize: '16px',
            color: colors.neutrals.textAlpha
        };
    }, [colors.neutrals.textAlpha]);

    const formatLocalDateTime = (date: string | Date | undefined): string => {
        if (typeof date === 'string') {
            return formatDateTime(normalizeDateString(date));
        }
        if (date instanceof Date) {
            return formatDateTime(date);
        }
        return '-';
    };

    const returnAddress = useMemo(() => {
        const data = return_location && rmaReturnLocationAddresses[return_location];
        if (data) {
            return (
                <>
                    <Span styles={valueStyle}>{data.address}</Span>
                    <Span
                        styles={valueStyle}
                    >{`${data.city}, ${data.state} ${data.zip_code}`}</Span>
                </>
            );
        }
        return <Span styles={valueStyle}>-</Span>;
    }, [return_location, valueStyle]);

    const returnLabelLink = useMemo(() => {
        return return_label?.return_label_url ? (
            <a
                target="_blank"
                rel="noreferrer"
                href={return_label?.return_label_url}
                style={{
                    ...valueStyle,
                    color: colors.brand.primary.alpha,
                    textDecoration: 'underline'
                }}
            >
                {return_label?.label_id}
            </a>
        ) : (
            <Span styles={{ ...valueStyle }}>{'-'}</Span>
        );
    }, [colors.brand.primary.alpha, return_label, valueStyle]);

    const getTrackingField = useCallback(
        (trackingNumberValue: any) => (
            <Span styles={{ ...valueStyle }}>
                {trackingNumberValue ? trackingNumberValue : '-'}
            </Span>
        ),
        [valueStyle]
    );

    const trackingNumber = useMemo(() => {
        return getTrackingField(return_tracking_number || tracking_number);
    }, [tracking_number, return_tracking_number, getTrackingField]);

    const exchangeTrackingNumber = useMemo(() => {
        return getTrackingField(exchange_tracking_number || fulfillmentExchangeTrackingNumber);
    }, [getTrackingField, exchange_tracking_number, fulfillmentExchangeTrackingNumber]);

    const zendeskTicketNumber = useMemo(() => {
        return zendesk_ticket_number ? (
            <a
                target="_blank"
                rel="noreferrer"
                style={{
                    ...valueStyle,
                    color: colors.brand.primary.alpha,
                    textDecoration: 'underline'
                }}
                href={`https://herohealth.zendesk.com/agent/tickets/${zendesk_ticket_number}`}
            >
                {zendesk_ticket_number}
            </a>
        ) : (
            <Span styles={{ ...valueStyle, color: colors.brand.primary.alpha }}>-</Span>
        );
    }, [zendesk_ticket_number, valueStyle, colors]);

    const { order_email, activated_at } = membership ?? {};

    const returnedDeviceState = device_rma_status
        ? device_rma_status.join(',')
        : 'No Abnormalities';

    const replacementBoxRequired = send_box ? 'Yes' : 'No';

    const replacementBoxTrackingNumber = useMemo(() => {
        return box_tracking_url ? (
            <a
                target="_blank"
                rel="noreferrer"
                style={{
                    ...valueStyle,
                    color: colors.brand.primary.alpha,
                    textDecoration: 'underline'
                }}
                href={`${box_tracking_url}`}
            >
                {box_tracking_url}
            </a>
        ) : (
            <Span styles={{ ...valueStyle, color: colors.brand.primary.alpha }}>-</Span>
        );
    }, [box_tracking_url, valueStyle, colors]);

    return (
        <RmaContext.Provider
            value={{
                id,
                isOrganizationMember,
                rma: rma || null,
                cancellationTypes,
                membership,
                rmaStatuses,
                rmaReasons,
                returnLocations,
                leftColWrapStyles,
                rightColWrapStyles,
                formatLocalDateTime,
                returnAddress,
                returnLabelLink,
                labelStyle,
                valueStyle,
                trackingNumber,
                exchangeTrackingNumber,
                zendeskTicketNumber,
                orderEmail: order_email || enrollment?.contact?.email,
                activatedAt: activated_at || enrollment?.member?.activated_at,
                returnStatuses,
                returnedDeviceState,
                replacementBoxTrackingNumber,
                replacementBoxRequired,
                cancellationDetails,
                orgCancellationDetails
            }}
        >
            {children}
        </RmaContext.Provider>
    );
};

export default RmaProvider;
