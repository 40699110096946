import * as React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useGetMembershipInvoices from '../api/useGetMembershipInvoices';
import InlineLoader from '../../../components/InlineLoader';
import Button from '@hero/ui-kit/inputs/Button';
import { formatDateTime } from '@hero/hero-utils/date';
import H from '@hero/ui-kit/typography/H';

type Props = {
    heroUserId: number;
} & Pick<ModalProps, 'externalControls'>;

const InvoicesModal: React.FC<Props> = ({ heroUserId, externalControls }) => {
    const [, setExternalState] = externalControls ?? [];
    const { data: invoices, isLoading } = useGetMembershipInvoices(heroUserId, !!heroUserId);

    const preparedInvoices = invoices?.data.map((invoice) => {
        const preparedAmount = invoice.amount > 0 ? invoice.amount / 100 : 0;

        return {
            ...invoice,
            amount: `${preparedAmount.toFixed(2)}$`,
            invoice_date: invoice.invoice_date ? formatDateTime(invoice.invoice_date) : 'N/A'
        };
    });

    const handleClose = React.useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const padding = '0 6px';

    return (
        <Modal externalControls={externalControls} isCancelable>
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <H centered role="h3">
                        Invoices
                    </H>
                    {preparedInvoices?.length === 0 ? (
                        <p>No invoices found</p>
                    ) : (
                        <table
                            style={{
                                fontSize: '16px',
                                borderCollapse: 'separate',
                                borderSpacing: '1em'
                            }}
                        >
                            <thead>
                                <tr style={{ textAlign: 'left' }}>
                                    <th style={{ padding }}>Invoice Number</th>
                                    <th style={{ padding }}>Invoice Status</th>
                                    <th style={{ padding }}>Amount</th>
                                    <th style={{ padding }}>Invoice Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {preparedInvoices?.map((invoice) => (
                                    <tr style={{ fontSize: '14px' }} key={invoice.invoice_number}>
                                        <td style={{ padding }}>{invoice.invoice_number}</td>
                                        <td style={{ padding, textTransform: 'uppercase' }}>
                                            {invoice.invoice_status}
                                        </td>
                                        <td style={{ padding }}>{invoice.amount}</td>
                                        <td style={{ padding }}>{invoice.invoice_date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <Button onClick={handleClose} variant="outlineSecondary">
                        Close
                    </Button>
                </>
            )}
        </Modal>
    );
};

export default InvoicesModal;
