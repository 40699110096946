import React from 'react';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import useTheme from '@hero/branding/theme';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import Container from '@hero/ui-kit/layout/Container';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import Button from '@hero/ui-kit/inputs/Button';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import { HardshipOffer } from '../../pages/Membership/api/useGetRTMTransferOptions';
import formatDollarAmount from '@hero/hero-utils/currency';
import Input from '@hero/ui-kit/inputs/Input';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';

interface MitigationFormFieldsProps {
    onContinueCancellation?: () => void;
    disabled?: boolean;
    rtmHardshipOffers?: HardshipOffer[];
}

const monthsForDiscount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const monthsForPause = ['1', '2', '3', '4', '5', '6'];

const MitigationFormFields: React.FC<MitigationFormFieldsProps> = ({
    onContinueCancellation,
    disabled = false,
    rtmHardshipOffers
}) => {
    const {
        formState: { isValid }
    } = useFormContext();
    const hardship = useWatch({ name: 'hardship' });
    const { setValue } = useFormContext();
    const { borders, colors } = useTheme();

    const radioStyle: React.CSSProperties = {
        padding: '1rem'
    };

    const border: React.CSSProperties = {
        ...borders.bottom,
        ...borders.variant.secondary,
        borderWidth: 2,
        borderColor: colors.neutrals.borderAlpha
    };

    const padding = useComponentPadding({ padding: 'large' });
    const marginBottom = useComponentMargin({ marginBottom: 'large' });
    const marginBottomRegular = useComponentMargin({ marginBottom: 'regular' });
    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginLeft = useComponentMargin({ marginLeft: 'regular' });

    const getDiscount = (hardshipType: string) => {
        const find = rtmHardshipOffers?.find((offer) => offer.storefront_key === hardshipType);
        return find ? `${formatDollarAmount(find.monthly_fee, true)}` : '';
    };

    React.useEffect(() => {
        const hardshipValues = rtmHardshipOffers?.find(
            (offer) => offer.storefront_key === hardship
        );
        if (hardshipValues) {
            if (hardshipValues.storefront_key === '1-month-free') {
                setValue('months', hardshipValues.months, {
                    shouldValidate: true,
                    shouldDirty: true
                });
            }
            setValue('discount_type', hardshipValues.discount_type, {
                shouldValidate: true,
                shouldDirty: true
            });
            setValue('mitigation_type', hardshipValues.mitigation_type, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [setValue, hardship, rtmHardshipOffers]);

    return (
        <>
            <H role="h6">{`Let's encourage keeping Hero!`}</H>
            {!hardship || hardship === '1-month-free' ? (
                <P>
                    We would really like you to stay as a Hero member. I can offer you 1 month free
                    membership if you would continue using Hero?
                </P>
            ) : null}
            <RadioGroup
                disabled={disabled}
                name="hardship"
                displayName=""
                noBorders
                styles={{ marginBottom: '0' }}
            >
                <Radio
                    id="1-month-free"
                    value="1-month-free"
                    labelText="1 Month FREE subscription"
                    styles={{ ...radioStyle, ...border }}
                />
                <Radio
                    id="mitigation-22"
                    value="mitigation-22"
                    labelText={`(if user says NO) Offer 22% subscription discount (${getDiscount(
                        'mitigation-22'
                    )})`}
                    styles={{ ...radioStyle, ...border }}
                />
                {hardship === 'mitigation-22' ? (
                    <Section noDefaultPadding styles={marginBottom}>
                        <Container
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="large"
                            styles={padding}
                        >
                            <DefaultSelect
                                options={monthsForDiscount.map((number) => ({
                                    value: number,
                                    label: `${number}`
                                }))}
                                name="months"
                                defaultValue={1}
                            />

                            <div style={{ margin: '0.6rem 0' }}>
                                <P strong noDefaultMargin>
                                    Monthly Subscription After Discount
                                </P>
                                <P noDefaultMargin styles={{ marginTop: '1.8rem' }}>{`${getDiscount(
                                    'mitigation-22'
                                )} + tax (if applicable)`}</P>
                            </div>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                )}
                <Radio
                    id="mitigation-33"
                    value="mitigation-33"
                    labelText={`(if user says NO to 22% discount) Offer 33% subscription discount (${getDiscount(
                        'mitigation-33'
                    )})`}
                    styles={{ ...radioStyle, ...border }}
                />
                {hardship === 'mitigation-33' ? (
                    <Section noDefaultPadding styles={marginBottom}>
                        <Container
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="large"
                            styles={padding}
                        >
                            <Input name="months" defaultValue={1} displayName="Number of months" />
                            <div style={{ margin: '0.6rem 0' }}>
                                <P strong noDefaultMargin>
                                    Monthly Subscription After Discount
                                </P>
                                <P noDefaultMargin styles={{ marginTop: '1.8rem' }}>{`${getDiscount(
                                    'mitigation-33'
                                )} + tax (if applicable)`}</P>
                            </div>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                )}
                <Radio
                    id="mitigation-50"
                    value="mitigation-50"
                    labelText={`(if user says NO to 33% discount) Offer 50% subscription discount (${getDiscount(
                        'mitigation-50'
                    )})`}
                    styles={{ ...radioStyle, ...border }}
                />
                {hardship === 'mitigation-50' ? (
                    <Section noDefaultPadding styles={marginBottom}>
                        <Container
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="large"
                            styles={padding}
                        >
                            <DefaultSelect
                                options={monthsForDiscount.map((number) => ({
                                    value: number,
                                    label: `${number}`
                                }))}
                                name="months"
                                defaultValue={1}
                            />
                            <div style={{ margin: '0.6rem 0' }}>
                                <P strong noDefaultMargin>
                                    Monthly Subscription After Discount
                                </P>
                                <P noDefaultMargin styles={{ marginTop: '1.8rem' }}>{`${getDiscount(
                                    'mitigation-50'
                                )} + tax (if applicable)`}</P>
                            </div>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                )}
                <Radio
                    id="mitigation-67"
                    value="mitigation-67"
                    labelText="(if user says NO to 50% discount) Offer 67% subscription discount ($14.99)"
                    styles={{ ...radioStyle, ...border }}
                />
                {hardship === 'mitigation-67' ? (
                    <Section noDefaultPadding styles={marginBottom}>
                        <Container
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="large"
                            styles={padding}
                        >
                            <DefaultSelect
                                options={monthsForDiscount.map((number) => ({
                                    value: number,
                                    label: `${number}`
                                }))}
                                name="months"
                                defaultValue={1}
                            />
                            <div style={{ margin: '0.6rem 0' }}>
                                <P strong noDefaultMargin>
                                    Monthly Subscription After Discount
                                </P>
                                <P noDefaultMargin styles={{ marginTop: '1.8rem' }}>{`${getDiscount(
                                    'mitigation-67'
                                )} + tax (if applicable)`}</P>
                            </div>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                )}
                <Radio
                    id="mitigation-78"
                    value="mitigation-78"
                    labelText="(if user says NO to 67% discount) Offer 77.8% subscription discount ($9.99)"
                    styles={{ ...radioStyle, ...border }}
                />
                {hardship === 'mitigation-78' ? (
                    <Section noDefaultPadding styles={marginBottom}>
                        <Container
                            gridTemplateColumns="1fr 1fr"
                            gridColumnGap="large"
                            styles={padding}
                        >
                            <DefaultSelect
                                options={monthsForDiscount.map((number) => ({
                                    value: number,
                                    label: `${number}`
                                }))}
                                name="months"
                                defaultValue={1}
                            />
                            <div style={{ margin: '0.6rem 0' }}>
                                <P strong noDefaultMargin>
                                    Monthly Subscription After Discount
                                </P>
                                <P noDefaultMargin styles={{ marginTop: '1.8rem' }}>{`${getDiscount(
                                    'mitigation-78'
                                )} + tax (if applicable)`}</P>
                            </div>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                )}
                <Radio
                    id="pause-subscription"
                    value="pause-subscription"
                    labelText="(If user says NO) Pause subscription"
                    styles={radioStyle}
                />
                {hardship === 'pause-subscription' ? (
                    <Section noDefaultPadding styles={marginBottomRegular}>
                        <P styles={marginTop}>
                            I can offer to pause your subscription (and extend annual obligation if
                            applicable):
                        </P>
                        <Container styles={marginLeft}>
                            <Select
                                displayName="Number of months"
                                name="months"
                                defaultValue={monthsForPause[0]}
                                styles={{ width: '30rem' }}
                                optionLabelStyles={{ backgroundColor: 'none' }}
                            >
                                {monthsForPause.map((option) => (
                                    <Option key={option} value={option} labelText={option} />
                                ))}
                            </Select>
                        </Container>
                    </Section>
                ) : (
                    <Radio id="none-2" value="" labelText="" styles={{ display: 'none' }} />
                )}
            </RadioGroup>
            <div style={{ ...border, marginTop: '-0.4rem' }} />
            <Section centered>
                <Button type="submit" width="large" disabled={disabled || !hardship || !isValid}>
                    {disabled ? 'Blocked, already has mitigation' : 'Next'}
                </Button>
                <Button type="button" variant="secondary" onClick={onContinueCancellation}>
                    Continue Cancellation
                </Button>
            </Section>
        </>
    );
};

export default MitigationFormFields;
