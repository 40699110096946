import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { rmaRequest } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetRmaExchangeRequest = (params, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}rma/get-existing-exchange-rma`, {
            ...additional,
            params
        }),
        queryKey: [rmaRequest],
        enabled
    });
};
export default useGetRmaExchangeRequest;
