import React, { useCallback, CSSProperties, useMemo, useState } from 'react';
import Modal from '@hero/ui-kit/components/Modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { voidRmaRequest } from '@hero/redux-data/backoffice/rmaRequest/actionCreators';
import { rmaRequestErrorSelector } from '@hero/redux-data/backoffice/rmaRequest/selectors';
import InlineLoader from '../../../components/InlineLoader';
import * as RmaTypeComponents from './types';
import RmaProvider from './context/RmaProvider';
import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    CancellationDetails,
    CancellationOptions,
    EnterpriseCancellationDetails,
    EnterpriseCancellationOptions,
    EnterpriseEnrollment,
    RmaEnums,
    RmaRequest,
    RmaReturnLocation,
    RmaReturnStatus,
    RmaStatus,
    RmaType
} from '@hero/hero-types';
import Form from '@hero/ui-kit/inputs/Form';
import RmaDetailsManualForm from './RmaDetailsManualForm';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import {
    UpdateRmaRequestParams,
    updateRmaRequestSchema
} from '@hero/validators/forms/backoffice/rmaRequestSchema';
import Button from '@hero/ui-kit/inputs/Button';
import useRmaRequestErrorMessage from '../../../views/utils/useRmaRequestErrorMessage';
import InlineError from '../../../components/InlineError';
import Section from '@hero/ui-kit/layout/Section';
import RmaUpdateConfirmModal from './RmaUpdateConfirmModal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isBackofficeAdminSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import useGetRmaFulfillmentSerialNumber from '../utils/useGetRmaFulfillmentSerialNumber';
import useGetRmaFulfillmentTrackingNumber from '../utils/useGetRmaFulfillmentTrackingNumber';
import H from '@hero/ui-kit/typography/H';
import RmaStatusForm from '../RmaStatusForm';
import useUpdateRMAStatus from '../api/useUpdateRMAStatus';
import { UpdateRmaStatusParams } from '../RmaStatusForm/validator';
import QueryInlineError from '../../../components/QueryInlineError';
import useUpdateRmaRequest from '../api/useUpdateRmaRequest';

type RmaDetailsProps = {
    onCloseModal: () => void;
    isOrganizationMember?: boolean;
    isLoading?: boolean;
    rma?: RmaRequest | null;
    refetchRmaDetailsData?: () => void;
    rmaEnums?: RmaEnums;
    membership?: BackofficeMembershipDetails;
    enterpriseEnrollment?: EnterpriseEnrollment;
    userDetails?: BackofficeUserDeviceDetails;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    cancellationDetails?: CancellationDetails;
    cancellationOptions?: CancellationOptions;
    orgCancellationOptions?: EnterpriseCancellationOptions;
};

type IRmaType = 'Cancellation' | 'Exchange';

const RmaDetails: React.FC<RmaDetailsProps> = ({
    onCloseModal,
    isOrganizationMember = false,
    isLoading = false,
    rma,
    refetchRmaDetailsData,
    rmaEnums,
    membership,
    enterpriseEnrollment,
    userDetails,
    orgCancellationDetails,
    cancellationDetails,
    cancellationOptions,
    orgCancellationOptions
}) => {
    const dispatch = useDispatch();
    const isBackofficeAdmin = useSelector(isBackofficeAdminSelector, shallowEqual);
    const serialNumber = useGetRmaFulfillmentSerialNumber(rma || undefined);
    const fulfillmentExchangeTrackingNumber = useGetRmaFulfillmentTrackingNumber(rma || undefined);
    const { error, errorMessage, originalMessage } = useSelector(
        rmaRequestErrorSelector,
        shallowEqual
    );
    const errorMsg = useRmaRequestErrorMessage(errorMessage, originalMessage);

    const {
        rma_type,
        id,
        rma_status,
        return_label,
        return_tracking_number: defaultReturnTrackingNumber,
        exchange_tracking_number: defaultExchangeTrackingNumber,
        exchange_device_serial: defaultExchangeDeviceSerial,
        send_box,
        return_status
    } = rma ?? {};

    const { tracking_number } = return_label ?? {};

    const [showEditForm, setShowEditForm] = React.useState(false);
    const [showEditRMAStatusForm, setShowEditRMAStatusForm] = React.useState(false);

    const {
        mutate: updateRmaRequest,
        isPending: isChangingRmaRequest,
        isSuccess: isRmaRequestSuccess,
        error: errorUpdateRmaRequest,
        reset: resetUpdateRmaRequest
    } = useUpdateRmaRequest();

    const handleShowEditForm = React.useCallback(() => {
        resetUpdateRmaRequest();
        setShowEditForm(true);
    }, []);

    const handleCloseEditForm = React.useCallback(() => {
        setShowEditForm(false);
    }, []);

    const handleShowEditRMAStatusForm = () => {
        setShowEditRMAStatusForm(true);
    };

    const handleCloseEditRMAStatusForm = () => {
        setShowEditRMAStatusForm(false);
    };

    const defaultValues = useMemo(() => {
        const address = return_label?.contact?.address;
        const home_phone = return_label?.contact?.home_phone;
        return {
            rma_type: rma_type ? `${rma_type}` : '1',
            rma_status: rma_status ? `${rma_status}` : '1',
            return_status: return_status ? `${return_status}` : '1',
            exchange_device_serial: defaultExchangeDeviceSerial || serialNumber,
            return_tracking_number: defaultReturnTrackingNumber || tracking_number,
            exchange_tracking_number:
                defaultExchangeTrackingNumber || fulfillmentExchangeTrackingNumber,
            home_phone,
            address_line_1: address?.address_line_1,
            address_line_2: address?.address_line_2 || undefined,
            city: address?.city,
            state: address?.state,
            zip: address?.zip,
            send_box
        };
    }, [
        rma_type,
        rma_status,
        return_status,
        defaultExchangeDeviceSerial,
        serialNumber,
        tracking_number,
        defaultReturnTrackingNumber,
        defaultExchangeTrackingNumber,
        fulfillmentExchangeTrackingNumber,
        return_label,
        send_box
    ]);

    const innerWrapPadding = useComponentPadding({ padding: 'regular' });

    const innerWrapStyles: CSSProperties = {
        alignSelf: 'stretch',
        ...innerWrapPadding,
        paddingBottom: 0
    };

    const modalStyles: CSSProperties = {
        width: '90rem',
        maxWidth: '90rem',
        padding: 0
    };

    const rmaType: IRmaType = useMemo(() => {
        if (rma_type === 2) {
            return 'Exchange';
        }
        return 'Cancellation';
    }, [rma_type]);

    const RmaTypeDetailsComponent = RmaTypeComponents[rmaType];

    const [showConfirmVoid, setShowConfirmVoid] = useState(false);

    const handleShowConfirmVoid = useCallback(() => {
        setShowConfirmVoid(true);
    }, []);

    const handleCloseConfirmVoid = useCallback(() => {
        setShowConfirmVoid(false);
    }, []);

    const isNotVoid = useMemo(() => {
        return rma_status !== 6;
    }, [rma_status]);

    const canEdit = useMemo<boolean>(() => {
        return !!isBackofficeAdmin;
    }, [isBackofficeAdmin]);

    const handleVoid = useCallback(() => {
        // 6 = void status
        if (isNotVoid) {
            id && dispatch(voidRmaRequest({ id }));
            handleCloseConfirmVoid();
        }
    }, [dispatch, id, handleCloseConfirmVoid, isNotVoid]);

    const [openUpdateConfirmModal, setOpenUpdateConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState<UpdateRmaRequestParams>({
        rma_type: '',
        rma_status: '',
        return_location: '',
        return_status: '',
        return_tracking_number: '',
        exchange_tracking_number: '',
        exchange_device_serial: '',
        address_line_1: '',
        address_line_2: '',
        state: '',
        zip: '',
        city: '',
        send_box: false
    });

    const handleSubmit = useCallback((attributes: UpdateRmaRequestParams) => {
        setFormValues(attributes);
        setOpenUpdateConfirmModal(true);
    }, []);

    const handleConfirm = useCallback(() => {
        const {
            rma_type,
            rma_status,
            return_location,
            return_status,
            return_tracking_number,
            exchange_device_serial,
            exchange_tracking_number,
            send_box,
            ...rest
        } = formValues;
        if (id) {
            const home_phone = return_label?.contact?.home_phone;

            updateRmaRequest({
                id,
                rma_type: +rma_type as RmaType,
                rma_status: +rma_status as RmaStatus,
                return_location: +return_location as RmaReturnLocation,
                return_status: +return_status as RmaReturnStatus,
                return_tracking_number: `${return_tracking_number}`,
                exchange_device_serial,
                exchange_tracking_number,
                send_box,
                home_phone,
                shipping_address: {
                    ...rest
                }
            });
        }
    }, [dispatch, formValues, id, return_label]);

    const handleOnSuccess = () => {
        refetchRmaDetailsData && refetchRmaDetailsData();
        setOpenUpdateConfirmModal(false);
        setShowEditForm(false);
    };

    const {
        mutateAsync,
        isPending: isRmaStatusUpdateLoading,
        isError: isRmaStatusUpdateError,
        error: rmaStatusError
    } = useUpdateRMAStatus();

    const handleEditRmaStatus = React.useCallback(
        (attributes: UpdateRmaStatusParams) => {
            id &&
                mutateAsync({
                    id,
                    rma_status: +attributes.rma_status,
                    return_status: +attributes.return_status
                })
                    .then(() => {
                        refetchRmaDetailsData && refetchRmaDetailsData();
                        setShowEditRMAStatusForm(false);
                    })
                    .catch(() => null);
        },
        [id, mutateAsync]
    );

    const rmaFormSection = useMemo(() => {
        if (!canEdit) {
            return null;
        }

        if (showEditForm) {
            return (
                <Form
                    validationSchema={updateRmaRequestSchema}
                    submitFn={handleSubmit}
                    defaultValues={defaultValues}
                >
                    <H role="h5" centered>
                        Update RMA
                    </H>
                    <RmaDetailsManualForm
                        state={return_label?.contact?.address?.state}
                        rmaEnums={rmaEnums}
                        rma={rma}
                    />
                    <Section centered noDefaultPadding>
                        <Button variant="secondary" onClick={handleCloseEditForm}>
                            Back
                        </Button>
                    </Section>
                    <RmaUpdateConfirmModal
                        rmaEnums={rmaEnums}
                        isChangingRmaRequest={isChangingRmaRequest}
                        formValues={formValues}
                        isExchangeRma={rmaType === 'Exchange'}
                        externalControls={[openUpdateConfirmModal, setOpenUpdateConfirmModal]}
                        onConfirm={handleConfirm}
                        errorUpdateRmaRequest={errorUpdateRmaRequest}
                        isRmaRequestSuccess={isRmaRequestSuccess}
                        onSuccess={handleOnSuccess}
                    />
                </Form>
            );
        }

        if (showEditRMAStatusForm) {
            return (
                <>
                    {isRmaStatusUpdateError ? <QueryInlineError error={rmaStatusError} /> : null}
                    <H role="h5" centered>
                        Update RMA Status
                    </H>
                    {isRmaStatusUpdateLoading ? (
                        <InlineLoader />
                    ) : (
                        <>
                            <RmaStatusForm
                                rma={rma}
                                onSubmit={handleEditRmaStatus}
                                rmaEnums={rmaEnums}
                            />
                            <Section centered noDefaultPadding>
                                <Button variant="secondary" onClick={handleCloseEditRMAStatusForm}>
                                    Back
                                </Button>
                            </Section>
                        </>
                    )}
                </>
            );
        }

        return (
            <Section centered>
                <Button onClick={handleShowEditRMAStatusForm} width={'large'}>
                    Update RMA Status
                </Button>
                <Button onClick={handleShowEditForm} width={'large'}>
                    Edit RMA Details
                </Button>
            </Section>
        );
    }, [
        handleSubmit,
        formValues,
        handleConfirm,
        handleCloseEditForm,
        openUpdateConfirmModal,
        handleEditRmaStatus,
        rmaType,
        canEdit,
        defaultValues,
        showEditForm,
        isChangingRmaRequest,
        handleShowEditForm,
        showEditRMAStatusForm,
        isRmaStatusUpdateLoading,
        errorUpdateRmaRequest,
        handleOnSuccess,
        isRmaRequestSuccess
    ]);

    const voidButtonSection = useMemo(() => {
        return !showEditForm && !showEditRMAStatusForm && isNotVoid ? (
            <Section noDefaultPadding centered>
                <Button
                    width="large"
                    variant="secondary"
                    secondaryBorderColor="primary"
                    onClick={handleShowConfirmVoid}
                >
                    Void RMA
                </Button>
            </Section>
        ) : null;
    }, [handleShowConfirmVoid, isNotVoid, showEditForm, showEditRMAStatusForm]);

    const errorSection = useMemo(() => {
        return error && errorMsg ? <InlineError centered message={errorMsg} /> : null;
    }, [error, errorMsg]);

    return (
        <Modal isCancelable onClose={onCloseModal} styles={modalStyles}>
            {!isLoading && id ? (
                <RmaProvider
                    rma={rma}
                    isOrganizationMember={isOrganizationMember}
                    rmaEnums={rmaEnums}
                    membership={membership}
                    enterpriseEnrollment={enterpriseEnrollment}
                    userDetails={userDetails}
                    orgCancellationDetails={orgCancellationDetails}
                    cancellationDetails={cancellationDetails}
                    cancellationOptions={cancellationOptions}
                    orgCancellationOptions={orgCancellationOptions}
                >
                    <div style={innerWrapStyles}>
                        {!showEditForm && !showEditRMAStatusForm ? (
                            <RmaTypeDetailsComponent />
                        ) : null}
                        {rmaFormSection}
                        {voidButtonSection}
                        {errorSection}
                    </div>
                    <ConfirmationModal
                        externalControls={[showConfirmVoid, setShowConfirmVoid]}
                        title={'Void Rma'}
                        description={'Are you sure you want to void?'}
                        onConfirm={handleVoid}
                    />
                </RmaProvider>
            ) : (
                <InlineLoader />
            )}
        </Modal>
    );
};

export default RmaDetails;
