import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';

import { CancellationOptions, CancellationType, EnterpriseEnrollment } from '@hero/hero-types';
import { cancellationOptionsSelector } from '@hero/redux-data/backoffice/cancellationOptions/selectors';
import {
    enterpriseEnrollmentSelector,
    cancellationTypesSelector,
    cancellationReasonsSelector
} from '@hero/redux-data/backoffice/enterpriseCancellationOptions/selectors';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import { CancelMembershipParams } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import StaticFormElement from '../../components/StaticFormElement';
import getActiveDateFormatted from '../../utils/getActiveDateFormatted';
import useGetAllReasonSubCodes from '../utils/useGetAllReasonSubCodes';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import EncourageHeroFields from './EncourageHeroFields';

type Props = {
    onTypeSelect?: (selectedType: string | undefined) => void;
    isOrganizationMember?: boolean;
    isExchangeFlow?: boolean;
    showEncourageHero?: boolean;
    cancellationOptions?: CancellationOptions;
    enterpriseEnrollment?: EnterpriseEnrollment;
};

const CancellationFormFields: React.FC<Props> = ({
    onTypeSelect,
    isOrganizationMember = false,
    isExchangeFlow = false,
    showEncourageHero = false,
    cancellationOptions,
    enterpriseEnrollment
}) => {
    const options = cancellationOptions || useSelector(cancellationOptionsSelector, shallowEqual);
    const enrollment =
        enterpriseEnrollment || useSelector(enterpriseEnrollmentSelector, shallowEqual);
    const cancellationTypes =
        cancellationOptions?.cancellation_types ||
        useSelector(cancellationTypesSelector, shallowEqual);
    const cancellationReasons =
        cancellationOptions?.reasons || useSelector(cancellationReasonsSelector, shallowEqual);

    const {
        watch,
        reset,
        setValue,
        formState: { isValid }
    } = useFormContext<CancelMembershipParams>();
    const selectedType = watch('cancellation_type');
    const reasonSubCode = useWatch({ name: 'reason_sub_code' });
    const [selectedReason, setSelectedReason] = useState<string | undefined>();
    const [selectedSubReasonName, setSelectedSubReasonName] = useState<string | undefined>();

    const activatedAt = isOrganizationMember
        ? enrollment?.member?.activated_at
        : options?.activated_at;

    const types: CancellationType[] = useMemo(
        () => (isOrganizationMember ? cancellationTypes : options?.cancellation_types) ?? [],
        [isOrganizationMember, options, cancellationTypes]
    );

    const reasonLabel: string = useMemo(() => {
        const reasonsObject = isOrganizationMember ? cancellationReasons : options?.reasons;

        return selectedReason && reasonsObject
            ? reasonsObject[selectedReason]?.description
            : 'Choose Sub-Reason';
    }, [isOrganizationMember, options, cancellationReasons, selectedReason]);

    const subReasons = useGetAllReasonSubCodes(isOrganizationMember, options, cancellationReasons);

    useEffect(() => {
        onTypeSelect && onTypeSelect(selectedType);
    }, [onTypeSelect, selectedType]);

    useEffect(() => {
        if (selectedType === 'ec3' && !selectedReason) {
            setValue('reason_code', '7', {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [selectedType, selectedReason, setValue]);

    useEffect(() => {
        if (selectedReason) {
            setValue('reason_code', selectedReason, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [selectedReason, setValue]);

    useEffect(() => {
        !reasonSubCode && setSelectedReason(undefined);
    }, [reasonSubCode]);

    const handleReset = useCallback(() => {
        reset();
        setSelectedReason(undefined);
        setSelectedSubReasonName(undefined);
    }, [reset]);

    const getTypeLabel = ({ name, description }: Partial<CancellationType>) =>
        `${name}: ${description}`;

    const processedTypes = types.map(({ code, ...rest }) => (
        <Option key={code} value={code} labelText={getTypeLabel(rest)} />
    ));

    const cancellationTypeLabel = useMemo(() => {
        return types.length === 1 ? getTypeLabel(types[0]) : '-';
    }, [types]);

    const cancellationSection = useMemo(() => {
        return (
            <>
                {processedTypes.length > 1 ? (
                    <Select
                        name="cancellation_type"
                        displayName="Cancellation Type"
                        visibleLimit={5}
                        noExtraMargin
                    >
                        {processedTypes}
                    </Select>
                ) : (
                    <StaticFormElement label="Cancellation Type">
                        {cancellationTypeLabel}
                    </StaticFormElement>
                )}
                <StaticFormElement label="Plan Active Date">
                    {getActiveDateFormatted(activatedAt)}
                </StaticFormElement>
            </>
        );
    }, [processedTypes, cancellationTypeLabel, activatedAt]);

    const handleSubReasonSelection = useCallback(
        (value?: string) => {
            setSelectedSubReasonName(value);
            const findSubReasonCode = subReasons.find((item) => value === item[1]);
            if (findSubReasonCode) {
                const data = findSubReasonCode[0].split('-');
                setSelectedReason(`${data[0]}`);

                setValue('reason_sub_code', data[1], {
                    shouldValidate: true,
                    shouldDirty: true
                });
            }
        },
        [setValue, subReasons]
    );

    const subReasonLabels = useMemo(() => {
        return subReasons.map((reason) => reason[1]);
    }, [subReasons]);

    return (
        <>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                {!isExchangeFlow ? cancellationSection : null}
                <Combobox
                    name="subcode"
                    displayName="Sub-Reason"
                    suggestions={subReasonLabels}
                    onSelection={handleSubReasonSelection}
                    noExtraMargin
                />
                <StaticFormElement label="Reason for Cancellation">{reasonLabel}</StaticFormElement>
                <Input name="zendesk_ticket_number" displayName="Zendesk Ticket #" />
            </Container>
            <TextArea rows={4} resize="none" name="notes" displayName="Notes (Optional)" />
            {showEncourageHero ? (
                <EncourageHeroFields selectedSubReason={selectedSubReasonName} />
            ) : null}
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>
                <Button width="large" variant="secondary" onClick={handleReset}>
                    Reset
                </Button>
            </Section>
        </>
    );
};

export default CancellationFormFields;
