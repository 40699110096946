import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    signUpProspect,
    prospectSignupReset
} from '@hero/redux-data/backoffice/prospectSignup/actionCreators';
import {
    getProspectSignupSurveyOptions,
    prospectSignupSurveyOptionsReset
} from '@hero/redux-data/backoffice/prospectSignupSurveyOptions/actionCreators';
import {
    isProspectSignupLoadingSelector,
    isProspectSignupUpdatedSelector
} from '@hero/redux-data/backoffice/prospectSignup/selectors';
import usePrevious from '@hero/react-hooks/usePrevious';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import Form from '@hero/ui-kit/inputs/Form';
import H from '@hero/ui-kit/typography/H';
import prospectSignupSchema, {
    ProspectSignupParams
} from '@hero/validators/forms/backoffice/prospectSignupSchema';

import ConfirmSignupModal from './ConfirmSignupModal';
import SignupFormFields from './SignupFormFields';
import repackValuesForSubmit from './utils/repackValuesForSubmit';
import { heardFromDefault } from './HeardFromInputs';
import DefaultLayout from '../../../components/DefaultLayout';

const ProspectSignup: React.FC = () => {
    const dispatch = useDispatch();
    const isSigningUpProspect = useSelector(isProspectSignupLoadingSelector);
    const isProspectSignedUp = useSelector(isProspectSignupUpdatedSelector);
    const wasProspectSignedUp = usePrevious(isProspectSignedUp);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [submittedValues, setSubmittedValues] = useState<ProspectSignupParams | null>(null);

    const defaultValues: Partial<ProspectSignupParams> = useMemo(
        () =>
            submittedValues
                ? { ...submittedValues }
                : {
                      confirmation_channel: 'customer_email'
                  },
        [submittedValues]
    );

    useEffect(() => {
        dispatch(getProspectSignupSurveyOptions());

        return () => {
            dispatch(prospectSignupReset());
            dispatch(prospectSignupSurveyOptionsReset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!wasProspectSignedUp && isProspectSignedUp) {
            setSubmittedValues(null);
        }
    }, [wasProspectSignedUp, isProspectSignedUp]);

    const handleSubmit = useCallback((formValues: ProspectSignupParams) => {
        const { heard_from, ...rest } = formValues;
        const attributes = {
            ...rest,
            heard_from: heard_from === heardFromDefault ? 'Not selected' : heard_from
        };
        setSubmittedValues(attributes);
        setConfirmationModalOpen(true);
    }, []);

    const handleReset = useCallback(() => {
        setSubmittedValues(null);
    }, []);

    const handleConfirm = useCallback(() => {
        submittedValues && dispatch(signUpProspect(repackValuesForSubmit(submittedValues)));
        setConfirmationModalOpen(false);
    }, [dispatch, submittedValues]);

    return isSigningUpProspect ? (
        <Loader />
    ) : (
        <DefaultLayout>
            {confirmationModalOpen && (
                <ConfirmSignupModal
                    submittedValues={submittedValues}
                    onConfirm={handleConfirm}
                    externalControls={[confirmationModalOpen, setConfirmationModalOpen]}
                />
            )}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h5">Order Form</H>
                <Form
                    validationSchema={prospectSignupSchema}
                    defaultValues={defaultValues}
                    submitFn={handleSubmit}
                    validationMode="onTouched"
                >
                    <SignupFormFields isPrepopulated={!!submittedValues} onReset={handleReset} />
                </Form>
            </Section>
        </DefaultLayout>
    );
};

export default ProspectSignup;
