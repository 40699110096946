import { useMemo } from 'react';
const useGetRmaFulfillmentTrackingNumber = (rma) => {
    return useMemo(() => {
        const { fulfillment_request } = rma ?? {};
        const { fulfillments } = fulfillment_request ?? {};
        if (fulfillments?.length && fulfillments[0].tracking_number) {
            return fulfillments[0].tracking_number;
        }
        return undefined;
    }, [rma]);
};
export default useGetRmaFulfillmentTrackingNumber;
