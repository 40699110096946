import trimDeep from '@hero/hero-utils/trimDeep';
const getRestockingCheckForSubmit = ({ eligible_for_restock }) => eligible_for_restock === 'yes' ? 2 : eligible_for_restock === 'no' ? 1 : 0;
const repackValuesForSubmit = (formValues, restockingCheck) => {
    const { rma_type, comments, rma_status, reason_code, order_source, order_number, customer_name, device_serial, refund_status, return_status, reason_sub_code, return_location, exchange_source, zendesk_ticket_number, return_tracking_number, exchange_device_serial, exchange_tracking_number, exchange_fulfilment_date } = trimDeep(formValues);
    return {
        order_number,
        customer_name,
        zendesk_ticket_number,
        return_tracking_number,
        reason_code: +reason_code,
        comments: comments || undefined,
        rma_type: +rma_type,
        reason_sub_code: +reason_sub_code,
        ...(device_serial && { device_serial }),
        order_source: +order_source,
        return_location: +return_location,
        exchange_device_serial: exchange_device_serial || null,
        exchange_tracking_number: exchange_tracking_number || null,
        exchange_fulfilment_date: exchange_fulfilment_date || null,
        ...(rma_status && { rma_status: +rma_status }),
        return_status: return_status ? +return_status : undefined,
        refund_status: refund_status ? +refund_status : undefined,
        exchange_source: exchange_source ? +exchange_source : null,
        ...(restockingCheck && { reprocessing_check: getRestockingCheckForSubmit(restockingCheck) })
    };
};
export const repackValuesForCreate = (formValues, restockingCheck) => {
    return repackValuesForSubmit(formValues, restockingCheck);
};
