import React, { CSSProperties } from 'react';

import { CancellationDetails } from '@hero/hero-types';
import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import Container from '@hero/ui-kit/layout/Container';
import Span from '@hero/ui-kit/typography/Span';
import P from '@hero/ui-kit/typography/P';
import A from '@hero/ui-kit/typography/A';

type Props = {
    data: CancellationDetails;
};

const DetailsSectionCancellation: React.FC<Props> = ({ data }) => {
    const {
        cancellation: { cancellation_type, zendesk_ticket_number },
        subscription
    } = data ?? {};

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    const { name = '-', description = '-' } = cancellation_type ?? {};

    return (
        <>
            <P strong>
                <Span styles={sectionLabelStyles}>Cancellation</Span>
                {` - ${name}: ${description}`}
            </P>
            <Container gridTemplateColumns="2fr 3fr 1fr 3fr">
                <P strong>Date</P>
                <P>{formatShortDate(normalizeDateString(subscription.cancelled_at))}</P>
                <P strong>Ticket #</P>
                <P>
                    {zendesk_ticket_number ? (
                        <A
                            to={`https://herohealth.zendesk.com/agent/tickets/${zendesk_ticket_number}`}
                        >
                            {zendesk_ticket_number}
                        </A>
                    ) : (
                        '-'
                    )}
                </P>
            </Container>
        </>
    );
};

export default DetailsSectionCancellation;
