import { enterpriseMemberDetailsSelector } from '@hero/redux-data/backoffice/enterpriseMemberDetails/selectors';
import { ToBeCancelledParams } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import { AxiosError } from 'axios';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useGetCancellationOptions from '../../../../../hooks/useGetCancellationOptions';
import useCancellationRequestCreate from '../../../DischargedQueueMemberList/api/useCancellationRequestCreate';

export type ToBeCancelledFlowSteps = 'Details' | 'Confirm' | 'Success';

type ContextType = {
    flowStep: ToBeCancelledFlowSteps;
    changeFlowStep: (flowStep: ToBeCancelledFlowSteps) => void;
    onCompleteCallback?: () => void;
    handleDetailsConfirm: (formValues: ToBeCancelledParams) => void;
    isSubmitting: boolean;
    handleSubmit: () => void;
    error?: AxiosError | null;
};

const ToBeCancelledFlowContext = React.createContext<ContextType | undefined>(undefined);

export const useToBeCancelledFlowContext = () => {
    const ctx = React.useContext(ToBeCancelledFlowContext);

    if (ctx === undefined) {
        throw new Error(
            `'useToBeCancelledFlowContext' must be used within a 'ToBeCancelledFlowContextProvider'`
        );
    }

    return ctx;
};

type IToBeCancelledFlowProvider = {
    children: React.ReactNode;
    onCompleteCallback?: () => void;
};

export const ToBeCancelledFlowProvider = ({
    children,
    onCompleteCallback
}: IToBeCancelledFlowProvider) => {
    const [flowStep, setToBeCancelledFlowStep] = React.useState<ToBeCancelledFlowSteps>('Details');
    const [attributes, setAttributes] = React.useState<ToBeCancelledParams | undefined>();
    const enrollment = useSelector(enterpriseMemberDetailsSelector, shallowEqual);
    const getCancellationOptions = useGetCancellationOptions(true);
    const id = enrollment?.id;
    const { mutateAsync, isPending, error } = useCancellationRequestCreate();

    const changeFlowStep = React.useCallback((step: ToBeCancelledFlowSteps) => {
        setToBeCancelledFlowStep(step);
    }, []);

    const handleCancellationFormShow = React.useCallback(() => {
        id && getCancellationOptions(id);
    }, [getCancellationOptions, id]);

    React.useEffect(() => {
        flowStep === 'Details' && handleCancellationFormShow();
    }, [flowStep, handleCancellationFormShow]);

    const handleDetailsConfirm = React.useCallback(
        (formValues: ToBeCancelledParams) => {
            setAttributes(formValues);
            changeFlowStep('Confirm');
        },
        [changeFlowStep]
    );

    const handleSubmit = () => {
        if (enrollment?.external_user_id && enrollment?.organization?.id) {
            mutateAsync({
                organization_id: enrollment?.organization.id,
                reason: attributes?.reason || '',
                external_user_id: enrollment.external_user_id,
                comment: attributes?.notes,
                zendesk_ticket_number: attributes?.zendesk_ticket_number
            })
                .then(() => changeFlowStep('Success'))
                .catch(() => null);
        }
    };

    return (
        <ToBeCancelledFlowContext.Provider
            value={{
                flowStep,
                changeFlowStep,
                onCompleteCallback,
                handleDetailsConfirm,
                isSubmitting: isPending,
                handleSubmit,
                error
            }}
        >
            {children}
        </ToBeCancelledFlowContext.Provider>
    );
};

export default ToBeCancelledFlowProvider;
