import React, { useMemo, CSSProperties } from 'react';
import { noop } from 'ts-essentials';

import Form from '@hero/ui-kit/inputs/Form';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import pageSizeSchema from '@hero/validators/forms/backoffice/pageSizeSchema';

import limitOptions from './limitOptions';
import usePageLimit from './usePageLimit';

type Props = {
    displayName?: string;
    noMargin?: boolean;
    defaultLimit?: string;
};

const PageSizeForm: React.FC<Props> = ({ displayName = '', noMargin, defaultLimit }) => {
    const [limit, handleSetPageLimit] = usePageLimit(defaultLimit);
    const defaultValues = useMemo(() => ({ limit: String(limit) }), [limit]);

    const processedOptions = limitOptions.map((option) => (
        <Option key={option} value={String(option)} labelText={String(option)} />
    ));

    const selectStyles: CSSProperties = useMemo(
        () => ({ width: '15rem', ...(noMargin && { margin: 0 }) }),
        [noMargin]
    );

    return (
        <Form validationSchema={pageSizeSchema} defaultValues={defaultValues} submitFn={noop}>
            <Select
                name="limit"
                displayName={displayName}
                defaultValue={defaultValues.limit}
                onChange={handleSetPageLimit}
                styles={selectStyles}
                noExtraMargin
            >
                {processedOptions}
            </Select>
        </Form>
    );
};

export default PageSizeForm;
