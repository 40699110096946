import getQueryParams from '@hero/hero-utils/getQueryParams';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationNoRefillAlerts } from '@hero/redux-data/backoffice/organizationNoRefillAlerts/actionCreators';
import { areOrgNoRefillAlertsLoadingSelector, orgNoRefillAlertListSelector, orgNoRefillAlertPaginationSelector } from '@hero/redux-data/backoffice/organizationNoRefillAlerts/selectors';
import usePagerProps from '../../../hooks/usePagerProps';
import getTableColumns from './getTableColumns';
const useMonitoringRunningLowData = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(areOrgNoRefillAlertsLoadingSelector, shallowEqual);
    const adherenceNoRefillList = useSelector(orgNoRefillAlertListSelector, shallowEqual);
    const paginationData = useSelector(orgNoRefillAlertPaginationSelector, shallowEqual);
    const pagerProps = usePagerProps(paginationData);
    const tableColumns = useMemo(() => getTableColumns(), []);
    const { offset, limit = 20, organization_id, membership_key } = getQueryParams();
    useEffect(() => {
        dispatch(fetchOrganizationNoRefillAlerts({
            offset,
            limit,
            ...(organization_id && { organization_id: +organization_id }),
            ...(membership_key && { membership_key })
        }));
    }, [dispatch, offset, limit, organization_id, membership_key]);
    return { dispatch, isLoading, adherenceNoRefillList, pagerProps, tableColumns };
};
export default useMonitoringRunningLowData;
