const useGetReturnLocations = (rmaEnums) => {
    const locations = rmaEnums?.return_location || {};
    return Object.entries(locations).reduce((acc, [key, item]) => {
        if (!item?.disabled) {
            acc = { ...acc, [key]: item?.value };
        }
        return acc;
    }, {});
};
export default useGetReturnLocations;
