import getQueryParams from '@hero/hero-utils/getQueryParams';
import { useMemo, useEffect } from 'react';
import usePagerProps from '../../../hooks/usePagerProps';
import getTableColumns from './getTableColumns';
import useGetOrganizations from '../api/useGetOrganizations';
const useOrganizationListData = () => {
    const { offset, limit = 1000 } = getQueryParams();
    const { data: organizationListResponse, mutate: getOrganizationList, isPending: isLoading } = useGetOrganizations();
    const organizationList = organizationListResponse?.data?.objects || [];
    const paginationData = {
        total_count: organizationListResponse?.data.total_count || 0,
        offset: organizationListResponse?.data.offset || 0,
        limit: organizationListResponse?.data.limit || 0
    };
    const pagerProps = usePagerProps(paginationData);
    const tableColumns = useMemo(() => getTableColumns(), []);
    useEffect(() => {
        getOrganizationList({ offset, limit });
    }, [getOrganizationList, offset, limit]);
    return { isLoading, organizationList, pagerProps, tableColumns };
};
export default useOrganizationListData;
