import React, { ReactText } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import Input, { Props as InputProps } from '@hero/ui-kit/inputs/Input';

import StaticFormElement from '../StaticFormElement';

type Props = {
    staticValue?: ReactText | null;
    isEditable?: boolean;
} & UiComponentProps &
    Pick<InputProps, 'name' | 'displayName' | 'placeholder' | 'type' | 'disabled'>;

const StaticOrInput: React.FC<Props> = ({
    staticValue,
    isEditable = false,
    displayName,
    ...rest
}) =>
    isEditable ? (
        <Input displayName={displayName} {...rest} />
    ) : (
        <StaticFormElement label={displayName}>
            {staticValue || typeof staticValue === 'number' ? staticValue : '-'}
        </StaticFormElement>
    );

export default StaticOrInput;
