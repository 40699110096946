import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../../components/Link';
import { MembershipTableDataObject } from './repackTableData';

type Render = TableColumn<MembershipTableDataObject>['render'];

const emailRender: Render = ({ id, order_email }) => (
    <Link to={`/membership/${id}/details`}>{order_email || '(no email)'}</Link>
);

const serialRender: Render = ({ serial }) =>
    serial ? <Link to={`/devices/serial/${serial}`}>{serial}</Link> : '-';

type GetTableColumns = () => TableColumn<MembershipTableDataObject>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'order_email',
        name: 'Email',
        align: 'left',
        headAlign: 'left',
        render: emailRender
    },
    {
        colKey: 'serial',
        name: 'Serial #',
        render: serialRender
    },
    {
        colKey: 'activation_type',
        name: 'Activation\nType'
    },
    {
        colKey: 'status',
        name: 'Membership\nStatus'
    },
    {
        colKey: 'plan',
        name: 'Subscription\nPlan'
    },
    {
        colKey: 'activated_at',
        name: 'Active\nDate',
        isSortable: true
    }
];

export default getTableColumns;
