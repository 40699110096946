import React from 'react';

import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import H from '@hero/ui-kit/typography/H';
import { usStates } from '@hero/hero-utils/address/states';

import AddressCombobox from '../../../components/AddressCombobox';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import { SetRmaRequestPickUpParams } from '@hero/validators/forms/backoffice/rmaRequestSchema';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';

type Props = {
    defaultState?: string;
    onVoid?: () => void;
};

const NewDeviceForm: React.FC<Props> = ({ onVoid }) => {
    const {
        formState: { isValid }
    } = useFormContext<SetRmaRequestPickUpParams>();

    return (
        <>
            <H role="h6">Ship to:</H>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                <AddressCombobox displayAddressName="Street Address" />
                <Input name="address_line_2" displayName="Street Address 2 (Optional)" />
                <Input name="city" displayName="City" />
                <DefaultSelect
                    options={usStates.map(({ code, name }) => ({ value: code, label: name }))}
                    name="state"
                    displayName="State"
                />
                <Input name="zip" displayName="Zip Code" />
                <Input name="home_phone" displayName="Phone Number" />
            </Container>
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>
                <Button width="large" onClick={onVoid} variant="secondary">
                    Delete Draft RMA
                </Button>
            </Section>
        </>
    );
};

export default NewDeviceForm;
