import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import editTrackingNumberSchema, {
    EditTrackingNumberParams
} from '@hero/validators/forms/backoffice/editTrackingNumberSchema';
import {
    updateMembershipFulFillment,
    resetMembershipFulFillment
} from '@hero/redux-data/backoffice/membershipUpdateFulfillment/actionCreators';
import {
    isUpdateMembershipFulfillmentLoadingSelector,
    updateMembershipFulfillmentErrorSelector,
    isUpdateMembershipFulfillmentUpdatedSelector
} from '@hero/redux-data/backoffice/membershipUpdateFulfillment/selectors';
import InlineLoader from '../../../components/InlineLoader';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import P from '@hero/ui-kit/typography/P';
import useTheme from '@hero/branding/theme';

type EditTrackingNumberModalProps = {
    onConfirm: () => void;
    orderId?: number;
} & Pick<ModalProps, 'externalControls'>;

const EditTrackingNumberModal: React.FC<EditTrackingNumberModalProps> = ({
    externalControls,
    orderId,
    onConfirm
}) => {
    const [, setExternalState] = externalControls ?? [];
    const dispatch = useDispatch();
    const theme = useTheme();
    const isLoading = useSelector(isUpdateMembershipFulfillmentLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isUpdateMembershipFulfillmentUpdatedSelector, shallowEqual);

    const { error, errorMessage } = useSelector(
        updateMembershipFulfillmentErrorSelector,
        shallowEqual
    );

    const handleCancel = React.useCallback(() => {
        if (isUpdated) {
            onConfirm();
        }
        setExternalState && setExternalState(false);
        dispatch(resetMembershipFulFillment());
    }, [setExternalState, dispatch, isUpdated, onConfirm]);

    const onSubmit = React.useCallback(
        (attributes: EditTrackingNumberParams) => {
            const { tracking_code } = attributes;

            orderId && dispatch(updateMembershipFulFillment({ order_id: orderId, tracking_code }));
        },
        [dispatch, orderId]
    );

    const marginTop = useComponentMargin({ marginTop: 'regular' });

    const steps = React.useMemo(() => {
        if (isLoading) {
            return <InlineLoader />;
        }

        if (error || isUpdated) {
            const message = error
                ? errorMessage || 'Something went wrong. Please contact support.'
                : 'Great! Tracking information updated.';

            return (
                <Section noDefaultPadding centered styles={{ width: '48rem' }}>
                    <P
                        centered
                        size="large"
                        strong
                        styles={{
                            color: error
                                ? theme.colors.status.fail.alpha
                                : theme.colors.status.success.alpha
                        }}
                    >
                        {message}
                    </P>
                    <Button
                        width="large"
                        variant="secondary"
                        onClick={handleCancel}
                        styles={marginTop}
                    >
                        Close
                    </Button>
                </Section>
            );
        }

        return (
            <Form
                validationSchema={editTrackingNumberSchema}
                submitFn={onSubmit}
                render={(_, formState) => (
                    <Section noDefaultPadding centered styles={{ width: '48rem' }}>
                        <Input name="tracking_code" displayName="Tracking Number" />

                        <div style={{ ...marginTop }}>
                            <Button width="large" type="submit" disabled={!formState.isValid}>
                                Edit
                            </Button>
                            <Button width="large" variant="secondary" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </Section>
                )}
            />
        );
    }, [onSubmit, handleCancel, isLoading, marginTop, error, errorMessage, isUpdated, theme]);

    const header = React.useMemo(() => {
        if (error) {
            return 'Failed';
        }
        if (isUpdated) {
            return 'Success';
        }
        return 'Edit Tracking Number';
    }, [error, isUpdated]);

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            onClose={handleCancel}
            type={error ? 'warn' : 'neutral'}
        >
            <H role="h4" centered>
                {header}
            </H>
            {steps}
        </Modal>
    );
};

export default EditTrackingNumberModal;
