import React, { useCallback } from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import Button from '@hero/ui-kit/inputs/Button';

import useStyles from './useStyles';

type Props = {
    onReset: () => void;
};

const Control: React.FC<Props> = ({ onReset }) => {
    const { reset } = useFormContext();
    const handleReset = useCallback(() => {
        reset({ confirmation_channel: 'customer_email' });
        onReset();
    }, [reset, onReset]);
    const { controlWrapStyles, resetButtonMargin } = useStyles();

    return (
        <>
            <div style={controlWrapStyles}>
                <Button width="large" type="submit">
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                    Reset
                </Button>
            </div>
        </>
    );
};

export default Control;
