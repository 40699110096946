import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import changeEmailSchema, { ChangeEmailParams } from './schema';
import useUpdateUserEmail from '../../api/useUpdateUserEmail';
import { useSelector } from 'react-redux';
import { isAppSupportAdminAdminSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserDeviceDetails } from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';
import QueryInlineError from '../../../../components/QueryInlineError';

interface EmailChangeProps {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
}

const EmailChange: React.FC<EmailChangeProps> = ({ email, firstName, lastName, id }) => {
    const dispatch = useDispatch();
    const [showEdit, setShowEdit] = React.useState(false);
    const isAppSupportAdmin = useSelector(isAppSupportAdminAdminSelector);
    const { user_id } = useParams();

    const handleClose = () => {
        setShowEdit(false);
        user_id && dispatch(getUserDeviceDetails({ user_id: +user_id }));
    };
    const { mutate, isError, isPending, isSuccess, error } = useUpdateUserEmail(id || 0);

    const handleSubmit = (attributes: ChangeEmailParams) => {
        if (id) {
            mutate(attributes);
        }
    };

    const name = React.useMemo(() => {
        if (firstName || lastName) {
            return `${firstName} ${lastName}`;
        }

        return '-';
    }, [firstName, lastName]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '1rem',
                    height: '2rem'
                }}
            >
                <P noDefaultMargin>{email || '-'}</P>
                <div title={!isAppSupportAdmin ? 'Only app support admin can change email' : ''}>
                    <Button
                        size="small"
                        onClick={() => setShowEdit(true)}
                        disabled={!isAppSupportAdmin}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <Modal
                type={isSuccess ? 'success' : 'neutral'}
                onClose={handleClose}
                isCancelable
                externalControls={[showEdit, setShowEdit]}
            >
                {isSuccess ? (
                    <>
                        <P centered size="large" strong>
                            {`Successfully changed the email for ${name}`}
                        </P>
                        <Button onClick={handleClose} width="full">
                            Close
                        </Button>
                    </>
                ) : (
                    <>
                        <H centered role="h5">{`Change the email for ${name}`}</H>
                        <P>{`Previous email: ${email}`}</P>
                        <Form validationSchema={changeEmailSchema} submitFn={handleSubmit}>
                            <Section centered noDefaultPadding>
                                <Input
                                    name="email"
                                    displayName="New Email Address"
                                    placeholder="Enter new email"
                                    styles={{ width: '30rem' }}
                                />
                            </Section>
                            <Button type="submit" width="full" disabled={isPending}>
                                Update
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                width="full"
                                disabled={isPending}
                            >
                                Cancel
                            </Button>
                            {isError ? <QueryInlineError centered error={error} /> : null}
                        </Form>
                    </>
                )}
            </Modal>
        </>
    );
};

export default EmailChange;
