import React, { CSSProperties } from 'react';

import { CancellationDetails } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';

type Props = {
    data: CancellationDetails;
};

const DetailsSectionReason: React.FC<Props> = ({ data }) => {
    const {
        cancellation: { reason, notes }
    } = data;

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    return (
        <>
            <P strong styles={sectionLabelStyles}>
                Reason of Cancellation
            </P>
            <Container gridTemplateColumns="2fr 7fr">
                <P strong>Reason</P>
                <P>{reason.description}</P>
                <P strong>Notes</P>
                <P>{notes}</P>
            </Container>
        </>
    );
};

export default DetailsSectionReason;
