import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import envVars from '../../../constants/envVars';
import { blacklistedEmailsKey } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useCheckIfEmailBlacklisted = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_CLOUD_HOSTNAME}backoffice/user-email-blacklist/`, { ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [blacklistedEmailsKey] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useCheckIfEmailBlacklisted;
