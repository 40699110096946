import React, { CSSProperties, useCallback } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import addNoteSchema, { SaveNoteParams } from './schema';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import P from '@hero/ui-kit/typography/P';
import { AxiosError } from 'axios';
import QueryInlineError from '../../../../../components/QueryInlineError';

type Props = {
    onSubmit: (formValues: SaveNoteParams) => void;
    onClose?: () => void;
    description?: string;
    note?: string;
    error: AxiosError<unknown, any> | null;
    isSuccess?: boolean;
    isLoading?: boolean;
} & Pick<ModalProps, 'externalControls'>;

const SaveNoteModal: React.FC<Props> = ({
    onSubmit,
    externalControls,
    onClose,
    description = '',
    note = '',
    error,
    isLoading = false
}) => {
    const [open, setExternalState] = externalControls ?? [];
    const [height, setHeight] = React.useState(16);
    const element = React.useRef<HTMLTextAreaElement>(null);

    const handleClose = useCallback(() => {
        setExternalState && setExternalState(false);
        onClose && onClose();
    }, [setExternalState, onClose]);

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    React.useEffect(() => {
        if (element.current && open) {
            setHeight(element.current.scrollHeight);
        }
    }, [element.current, open]);

    return (
        <Modal externalControls={externalControls} isCancelable onClose={handleClose}>
            <H role="h4" centered>
                {note ? 'Edit Note' : 'Add Note'}
            </H>
            <P strong size="large">
                {description}
            </P>
            <strong>Note: </strong>
            <textarea
                ref={element}
                style={{
                    minWidth: '48rem',
                    background: 'transparent',
                    marginBottom: '2rem',
                    minHeight: `${height}px`
                }}
                disabled
                value={note}
            />
            <Form validationSchema={addNoteSchema} submitFn={onSubmit}>
                <Section noDefaultPadding centered styles={innerWrapperStyles}>
                    <TextArea name="note" displayName="New Note" styles={{ height: '20rem' }} />
                    <div style={{ marginTop: '1.2rem' }}>
                        <Button width="large" type="submit" disabled={isLoading}>
                            Save
                        </Button>
                        <Button
                            width="large"
                            variant="secondary"
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </div>
                </Section>
            </Form>
            {error ? <QueryInlineError centered error={error} /> : null}
        </Modal>
    );
};

export default SaveNoteModal;
