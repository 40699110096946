import React from 'react';
import TabBar from '../TabBar';
import Section from '@hero/ui-kit/layout/Section';
import UserHeader from '../UserHeader';
import { shallowEqual, useSelector } from 'react-redux';
import { userDeviceDetailsSelector } from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import H from '@hero/ui-kit/typography/H';
import CaregiverDetails from './CaregiverDetails';
import DefaultLayout from '../../../../components/DefaultLayout';

const UserCaregiver: React.FC = () => {
    const data = useSelector(userDeviceDetailsSelector, shallowEqual);
    const { user, device } = data ?? {};
    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <UserHeader />
                <TabBar />
                {device?.caregivers?.length ? (
                    <Section
                        subtheme={['regular', 'background']}
                        paddingVertical="small"
                        paddingHorizontal="regular"
                    >
                        <>
                            <H role="h4">Their Caregiver(s)</H>
                            {device.caregivers.map((item) => (
                                <CaregiverDetails
                                    userRole={
                                        item.is_read_only
                                            ? 'Caregiver Non-Admin'
                                            : 'Caregiver Admin'
                                    }
                                    firstName={item.caregiver_name}
                                    lastName={item.caregiver_last_name}
                                    userId={item.caregiver_user_id}
                                    email={item.caregiver_email}
                                    phone={item.phone || undefined}
                                />
                            ))}
                        </>
                    </Section>
                ) : null}
                {user?.caregiver_accounts?.length ? (
                    <Section
                        subtheme={['regular', 'background']}
                        paddingVertical="small"
                        paddingHorizontal="regular"
                    >
                        <>
                            <H role="h4">Caregiver For</H>
                            {user.caregiver_accounts.map((item) => (
                                <CaregiverDetails
                                    firstName={item.owner_first_name}
                                    lastName={item.owner_last_name}
                                    userId={item.owner_id}
                                    email={item.owner_email}
                                    phone={item.phone || undefined}
                                />
                            ))}
                        </>
                    </Section>
                ) : null}
            </Section>
        </DefaultLayout>
    );
};

export default UserCaregiver;
