import React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';
import useGetOrganizationMemberRtmElectionDetails from '../../api/useGetOrganizationMemberRtmElectionDetails';
import InlineLoader from '../../../../components/InlineLoader';
import QueryInlineError from '../../../../components/QueryInlineError';
import H from '@hero/ui-kit/typography/H';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';

type RtmElectionInfoProps = {
    member_id: number;
} & Pick<ModalProps, 'externalControls'>;

const RtmElectionInfo: React.FC<RtmElectionInfoProps> = ({ externalControls, member_id }) => {
    const [, setExternalState] = externalControls ?? [];
    const {
        data: details,
        isLoading,
        error,
        isError
    } = useGetOrganizationMemberRtmElectionDetails({ member_id });

    const handleCloseModal = () => {
        typeof setExternalState === 'function' && setExternalState(false);
    };

    const modalPadding = useSectionPadding({ padding: 'regular' });

    const modalStyles: React.CSSProperties = {
        width: '100rem',
        ...modalPadding
    };

    const scenarios = {
        1: 'Terminated Provider Member',
        2: 'Rejected Lead',
        3: 'Pending Enrollment',
        4: 'Expired Lead'
    };

    const isElectionCompleted =
        !details?.data.member_rtm_election && details?.data.last_completed_rtm_election;

    return (
        <Modal
            width="page"
            externalControls={externalControls}
            type={'neutral'}
            styles={modalStyles}
            noDefaultPadding
            isCancelable
            onClose={handleCloseModal}
        >
            <>
                <H role="h4" centered>
                    Member RTM Election
                </H>
                {isLoading ? <InlineLoader /> : null}
                {!isError && !isElectionCompleted && details?.data.member_rtm_election ? (
                    <Section centered>
                        <P strong>
                            Current status: {scenarios[details.data.member_rtm_election.scenario]}
                        </P>
                        {details.data.member_rtm_election.scenario === 2 ? (
                            <>
                                <P strong>
                                    Rejected by Provider:{' '}
                                    {details.data.member_rtm_election.elected_provider_name}
                                </P>
                                <P strong>
                                    Rejected reason:{' '}
                                    {details.data.member_rtm_election.rejected_reason}
                                </P>
                            </>
                        ) : null}
                        {details.data.member_rtm_election.scenario === 3 ? (
                            <>
                                <P strong>
                                    Provider:{' '}
                                    {details.data.member_rtm_election.elected_provider_name}
                                </P>
                                <P strong>
                                    Provider phone:{' '}
                                    {details.data.member_rtm_election.elected_provider_phone}
                                </P>
                            </>
                        ) : null}
                        {details.data.member_rtm_election.scenario === 4 ? (
                            <>
                                <P strong>
                                    Expired by provider:{' '}
                                    {details.data.member_rtm_election.elected_provider_name}
                                </P>
                            </>
                        ) : null}
                    </Section>
                ) : null}
                {!isError &&
                isElectionCompleted &&
                details?.data.last_completed_rtm_election &&
                details.data.last_completed_rtm_election.elected_provider_name ? (
                    <Section centered>
                        <P strong>
                            Completed by provider:{' '}
                            {details.data.last_completed_rtm_election.elected_provider_name}
                        </P>
                    </Section>
                ) : null}

                {!isError &&
                !isLoading &&
                !details?.data.member_rtm_election &&
                !details?.data.last_completed_rtm_election ? (
                    <Section centered>
                        <P strong>Member not in election</P>
                    </Section>
                ) : null}
                {error ? <QueryInlineError centered error={error} /> : null}
                <Section centered>
                    <Button variant="outlineSecondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Section>
            </>
        </Modal>
    );
};

export default RtmElectionInfo;
