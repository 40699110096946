import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { devicesKey } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useUpdateDeviceSettings = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.patch(`${envVars.API_CLOUD_HOSTNAME}backoffice/device-settings/`, attributes, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [devicesKey] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useUpdateDeviceSettings;
