import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { prospectSignupPartialErrorsSelector } from '@hero/redux-data/backoffice/prospectSignup/selectors';
import { useNotifications } from '../../context/notification';
const useProspectPromoCodeWarnAlert = () => {
    const partialErrors = useSelector(prospectSignupPartialErrorsSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (partialErrors.includes('invalid_discount_code')) {
            id.current = addNotification({ type: 'warn', message: 'Promo code not applied.' });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, partialErrors]);
};
export default useProspectPromoCodeWarnAlert;
