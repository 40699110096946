import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchUsers } from '@hero/redux-data/backoffice/users/actionCreators';
import { areUsersLoadingSelector, usersSelector, usersPaginationSelector } from '@hero/redux-data/backoffice/users/selectors';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import usePagerProps from '../../../hooks/usePagerProps';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import getTableComuns from './getTableColumns';
const useUsersListData = () => {
    const dispatch = useDispatch();
    const isFetchingUsers = useSelector(areUsersLoadingSelector, shallowEqual);
    const users = useSelector(usersSelector, shallowEqual);
    const paginationData = useSelector(usersPaginationSelector, shallowEqual);
    const [sort, handleSort] = useTableSortQuery();
    const pagerProps = usePagerProps(paginationData);
    const { offset = 0, limit = 20, email } = getQueryParams();
    const tableColumns = useMemo(() => getTableComuns(), []);
    useEffect(() => {
        dispatch(fetchUsers({ offset, limit, email }));
    }, [dispatch, offset, limit, email]);
    return {
        dispatch,
        isFetchingUsers,
        users,
        sort,
        handleSort,
        pagerProps,
        tableColumns
    };
};
export default useUsersListData;
