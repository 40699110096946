import React from 'react';

import { Variant } from '@hero/branding/types';
import { UiComponentProps, IconComponentProps, ButtonType } from '@hero/ui-kit/types';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';

type Props = {
    icon: React.FC<IconComponentProps>;
    onClick: () => void;
    alt?: string;
    type?: ButtonType;
    variant?: Variant;
    disabled?: boolean;
} & UiComponentProps;

const ActionButton: React.FC<Props> = ({ icon: Icon, ...rest }) => (
    <CircularButton size="small" {...rest}>
        <Icon type="utility" size="small" />
    </CircularButton>
);

export default ActionButton;
