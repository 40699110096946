import { formatDateTime } from '@hero/hero-utils/date';
import getUserAdherenceStateLabel from './getUserAdherenceStateLabel';
import getUserAdherenceTypeLabel from './getUserAdherenceTypeLabel';
const repackTableData = (adherence) => adherence.map((item) => ({
    id: item.id,
    type: getUserAdherenceTypeLabel(item.type),
    scheduled: item.scheduled_datetime ? formatDateTime(item.scheduled_datetime) : '-',
    actual: item.actual_datetime ? formatDateTime(item.actual_datetime) : '-',
    status: getUserAdherenceStateLabel(item.state)
}));
export default repackTableData;
