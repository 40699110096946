import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useMainSearch = (search, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}backoffice/search/?search=${search}`, {
            ...additional
        }),
        queryKey: ['main-search', search],
        enabled
    });
};
export default useMainSearch;
