import React from 'react';
import { Cancellation } from '@hero/hero-types';
import formatDollarAmount from '@hero/hero-utils/currency';
import P from '@hero/ui-kit/typography/P';

interface SuccessTransactionReportProps {
    cancellationData: Cancellation | undefined;
}

const SuccessTransactionReport: React.FC<SuccessTransactionReportProps> = ({
    cancellationData
}) => {
    const transaction = React.useMemo(() => {
        return cancellationData?.cancellation_type.transaction;
    }, [cancellationData?.cancellation_type.transaction]);

    const totalRefund = cancellationData?.refund_amount;
    const totalRefundFormatted =
        typeof totalRefund === 'number' ? formatDollarAmount(totalRefund, true) : '-';
    const totalFee = cancellationData?.cancellation_fee?.amount;
    const totalFeeFormatted =
        typeof totalFee === 'number' ? formatDollarAmount(totalFee, true) : '-';

    const fieldStyles: React.CSSProperties = {
        inlineSize: '260px',
        overflowWrap: 'break-word'
    };

    if (transaction === 'refund') {
        return (
            <>
                <P noDefaultMargin strong>
                    Total Refund
                </P>
                <P noDefaultMargin styles={fieldStyles} size="small">
                    {totalRefundFormatted}
                </P>
            </>
        );
    }
    if (transaction === 'charge') {
        return (
            <>
                <P strong>Cancellation Fee</P>
                <P noDefaultMargin styles={fieldStyles} size="small">
                    {totalFeeFormatted}
                </P>
            </>
        );
    }
    if (transaction === 'waive') {
        return (
            <>
                <P noDefaultMargin strong>
                    Cancellation Fee
                </P>
                <P
                    noDefaultMargin
                    styles={fieldStyles}
                    size="small"
                >{`${totalFeeFormatted} (Waived)`}</P>
            </>
        );
    }
    return null;
};

export default SuccessTransactionReport;
