import React, { useMemo } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import Select, { Props as SelectProps } from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';

import StaticFormElement from '../StaticFormElement';

type Props = {
    options: [string, string][];
    staticValue?: string | null;
    isEditable?: boolean;
} & UiComponentProps &
    Pick<SelectProps, 'name' | 'displayName' | 'visibleLimit' | 'disabled'>;

const StaticOrSelect: React.FC<Props> = ({
    options,
    staticValue,
    isEditable = false,
    displayName,
    visibleLimit = 5,
    ...rest
}) => {
    const processedOptions = useMemo(
        () =>
            options.map(([value, label]) => <Option key={value} value={value} labelText={label} />),
        [options]
    );

    const staticOption = useMemo(() => {
        const [id, label] = options.find(([value]) => value === staticValue) ?? [];
        return {
            label: label ?? '-',
            id: id ?? undefined
        };
    }, [options, staticValue]);

    return isEditable ? (
        <Select
            displayName={displayName}
            visibleLimit={visibleLimit}
            defaultValue={staticOption.id}
            noExtraMargin
            {...rest}
        >
            {processedOptions}
        </Select>
    ) : (
        <StaticFormElement label={displayName}>{staticOption.label}</StaticFormElement>
    );
};

export default StaticOrSelect;
