import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { enterpriseEnrollmentSubmissionErrorSelector } from '@hero/redux-data/backoffice/enterpriseEnrollmentSubmission/selectors';
import { useNotifications } from '../../context/notification';
const useEnterpriseEnrollmentSubmitFailAlert = () => {
    const { error, originalMessage } = useSelector(enterpriseEnrollmentSubmissionErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage?.toLowerCase() === 'cannot submit enrollment - invalid state'
                    ? 'Submission for this contact has probably already been made.'
                    : originalMessage?.replace(/[_-]/g, ' ') || 'Error submitting enrollment.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, originalMessage]);
};
export default useEnterpriseEnrollmentSubmitFailAlert;
