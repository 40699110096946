const getRtmStatus = (status) => {
    if (status === 'APPROVED') {
        return 'Approved';
    }
    if (status === 'CREATED') {
        return 'Created';
    }
    if (status === 'DECLINED') {
        return 'Declined';
    }
    if (status === 'EXPIRED') {
        return 'Expired';
    }
    if (status === 'EXPORTED') {
        return 'Exported';
    }
    if (status === 'NOT_FOUND') {
        return 'Not Found';
    }
    return 'N/A';
};
export default getRtmStatus;
