import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';
import MenuIcon from '@hero/ui-kit/icons/utility/MenuIcon'; // Nothing better for now...
import XIcon from '@hero/ui-kit/icons/utility/XIcon';

import envVars from '../../../../constants/envVars';
import Link from '../../../../components/Link';
import ActionButton from '../../../../components/ActionButton';
import { MembershipAlertTableDataObject } from './repackTableData';

export type ActionHandler = (subscription_id: number) => void;

type Render = TableColumn<MembershipAlertTableDataObject>['render'];
type GetActionRender = (actionHandler: ActionHandler) => Render;

const emailRender: Render = ({ subscription_id, customer_email }) => (
    <Link to={`/membership/${subscription_id}/details`}>{customer_email || '(no email)'}</Link>
);

const serialRender: Render = ({ device_serial_number }) =>
    device_serial_number ? (
        <Link to={`/devices/serial/${device_serial_number}`}>{device_serial_number}</Link>
    ) : (
        '-'
    );

const customerIdRender: Render = ({ stripe_customer_id }) =>
    stripe_customer_id ? (
        <Link to={`${envVars.STRIPE_DASHBOARD_BASE_URL}/customers/${stripe_customer_id}`}>
            {stripe_customer_id}
        </Link>
    ) : (
        '-'
    );

const getNoteRender: GetActionRender =
    (actionHandler) =>
    ({ subscription_id, notes }) =>
        (
            <ActionButton
                icon={MenuIcon}
                onClick={() => actionHandler(subscription_id)}
                alt={notes ? 'Edit Note' : 'Add Note'}
                variant={notes ? 'primary' : 'secondary'}
            />
        );

const getDismissRender: GetActionRender =
    (actionHandler) =>
    ({ subscription_id, outreach_day }, { isRowHovered }) =>
        (
            <ActionButton
                icon={XIcon}
                onClick={() => actionHandler(subscription_id)}
                alt="Dismiss Alert"
                variant="primary"
                styles={{ opacity: isRowHovered ? 1 : 0 }}
                disabled={outreach_day === 21}
            />
        );

type GetTableColumns = (
    noteActionHandler: ActionHandler,
    dismissActionHandler: ActionHandler
) => TableColumn<MembershipAlertTableDataObject>[];
const getTableColumns: GetTableColumns = (noteActionHandler, dismissActionHandler) => [
    {
        colKey: 'customer_email',
        name: 'Email',
        align: 'left',
        headAlign: 'left',
        render: emailRender
    },
    {
        colKey: 'device_serial_number',
        name: 'Serial #',
        render: serialRender
    },
    {
        colKey: 'stripe_customer_id',
        name: 'Customer ID',
        render: customerIdRender
    },
    {
        colKey: 'plan',
        name: 'Plan'
    },
    {
        colKey: 'subscription_activated_at',
        name: 'Active\nDate'
    },
    {
        colKey: 'invoice_amount',
        name: 'Invoice\nAmount',
        isSortable: true
    },
    {
        colKey: 'past_due',
        name: 'Past\nDue',
        isSortable: true
    },
    {
        colKey: 'payment_failed_at',
        name: 'Payment\nFailed',
        isSortable: true
    },
    {
        colKey: 'notes',
        name: '',
        width: '2.4rem',
        render: getNoteRender(noteActionHandler)
    },
    {
        colKey: 'dismiss',
        name: '',
        width: '2.4rem',
        render: getDismissRender(dismissActionHandler)
    }
];

export default getTableColumns;
