import React from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import {
    CreateRmaRequestParams,
    UpdateRmaRequestParams
} from '@hero/validators/forms/backoffice/createRmaReqestSchema';

import StaticOrTextArea from '../../../components/StaticOrTextArea';

type Props = {
    isEditMode?: boolean;
};

const RmaRequestFormFieldsExtras: React.FC<Props> = ({ isEditMode = false }) => {
    const { watch } = useFormContext<CreateRmaRequestParams | UpdateRmaRequestParams>();
    const comments = watch('comments');

    return (
        <StaticOrTextArea
            name="comments"
            displayName="Comments"
            staticValue={comments}
            isEditable={isEditMode}
        />
    );
};

export default RmaRequestFormFieldsExtras;
