import React, { CSSProperties, useCallback } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import H from '@hero/ui-kit/typography/H';
import addCreditSchema, {
    AddCreditParams
} from '@hero/validators/forms/backoffice/addCreditSchema';

type Props = {
    onSubmit: (formValues: AddCreditParams) => void;
} & Pick<ModalProps, 'externalControls'>;

const AddCreditModal: React.FC<Props> = ({ onSubmit, externalControls }) => {
    const [, setExternalState] = externalControls ?? [];

    const handleCancel = useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    return (
        <Modal externalControls={externalControls} isCancelable>
            <H role="h4" centered>
                Add Credit
            </H>
            <Form validationSchema={addCreditSchema} submitFn={onSubmit}>
                <Section noDefaultPadding centered styles={innerWrapperStyles}>
                    <Input
                        type="number"
                        step={0.01}
                        name="amount"
                        displayName="Credit Amount ($)"
                    />
                    <Input name="note" displayName="Note" />
                    <div style={{ marginTop: '1.2rem' }}>
                        <Button width="large" type="submit">
                            Add
                        </Button>
                        <Button width="large" variant="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                </Section>
            </Form>
        </Modal>
    );
};

export default AddCreditModal;
