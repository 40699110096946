import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';

import ButtonLink from '../../../../components/ButtonLink';
import { EnterpriseEnrollmentTableDataObject } from './repackTableData';

export type ActionHandler = (id: number) => void;

type Render = TableColumn<EnterpriseEnrollmentTableDataObject>['render'];
type GetActionRender = (actionHandler: ActionHandler) => Render;

const getInviteCodeRender: GetActionRender =
    (actionHandler) =>
    ({ id, invite_code }) =>
        (
            <ButtonLink alt="View Details" onClick={() => actionHandler(id)}>
                {invite_code}
            </ButtonLink>
        );

type GetTableColumns = (
    viewActionHandler: ActionHandler
) => TableColumn<EnterpriseEnrollmentTableDataObject>[];
const getTableColumns: GetTableColumns = (viewActionHandler) => [
    {
        colKey: 'invite_code',
        name: 'Invitation Code',
        align: 'left',
        headAlign: 'left',
        render: getInviteCodeRender(viewActionHandler)
    },
    {
        colKey: 'email',
        name: 'Email'
    },
    {
        colKey: 'phone',
        name: 'Phone #'
    },
    {
        colKey: 'first_name',
        name: 'First Name'
    },
    {
        colKey: 'last_name',
        name: 'Last Name'
    },
    {
        colKey: 'completed_at',
        name: 'Enrollment\nComplete Date'
    }
];

export default getTableColumns;
