import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
const useDeviceListFilteringStyles = () => {
    const eyebrowStyles = useComponentPadding({ padding: 'regular' });
    const headerWrapStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const searchWrapStyles = {
        display: 'flex',
        alignItems: 'flex-start'
    };
    return { eyebrowStyles, headerWrapStyles, searchWrapStyles };
};
export default useDeviceListFilteringStyles;
