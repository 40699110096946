import * as React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import InlineLoader from '../../../components/InlineLoader';
import Button from '@hero/ui-kit/inputs/Button';
import { formatDateTime } from '@hero/hero-utils/date';
import H from '@hero/ui-kit/typography/H';
import useGetMembershipChanges, {
    BackofficeMembershipTransfer
} from '../api/useGetMembershipChanges';
import Link from '../../../components/Link';

type Props = {
    heroUserId: number;
    membershipId: number;
} & Pick<ModalProps, 'externalControls'>;

type TransferChangeProps = {
    membershipId: number;
    transfer: BackofficeMembershipTransfer;
};

const padding = '0 6px';

const TransferLink: React.FC<{
    link?: string;
    label: string;
}> = ({ link, label }) => {
    return link ? (
        <Link styles={{ textDecoration: 'underline' }} to={link}>
            {label}
        </Link>
    ) : (
        <div>{label}</div>
    );
};

const TransferChange: React.FC<TransferChangeProps> = ({ transfer, membershipId }) => {
    if (transfer.from_subscription && transfer.to_subscription) {
        return (
            <tr>
                <td style={{ padding }}>D2C to D2C Transfer</td>
                <td style={{ padding }}>{transfer.source}</td>
                <td style={{ padding }}>
                    <TransferLink
                        link={
                            transfer.from_subscription.id !== membershipId
                                ? `/membership/${transfer.from_subscription.id}/details`
                                : undefined
                        }
                        label={transfer?.from_subscription?.order_email}
                    />
                </td>
                <td style={{ padding }}>
                    <TransferLink
                        link={
                            transfer.to_subscription.id !== membershipId
                                ? `/membership/${transfer.to_subscription.id}/details`
                                : undefined
                        }
                        label={transfer?.to_subscription?.order_email}
                    />
                </td>
                <td style={{ padding }}>
                    {transfer?.created_at ? formatDateTime(transfer.created_at) : 'N/A'}
                </td>
            </tr>
        );
    }

    if (transfer.from_subscription && transfer.to_member) {
        return (
            <tr>
                <td style={{ padding }}>D2C To Org Transfer</td>
                <td style={{ padding }}>{transfer.source}</td>
                <td style={{ padding }}>
                    <TransferLink
                        link={
                            transfer.from_subscription.id !== membershipId
                                ? `/membership/${transfer.from_subscription.id}/details`
                                : undefined
                        }
                        label={transfer?.from_subscription?.order_email}
                    />
                </td>
                <td style={{ padding }}>
                    <TransferLink
                        link={`/organizations/${transfer.to_member.organization.id}/member/${transfer.to_member.id}`}
                        label={transfer?.to_member?.contact.email}
                    />
                </td>
                <td style={{ padding }}>
                    {transfer?.created_at ? formatDateTime(transfer.created_at) : 'N/A'}
                </td>
            </tr>
        );
    }

    if (transfer.from_member && transfer.to_subscription) {
        return (
            <tr>
                <td style={{ padding }}>Org To D2C Transfer</td>
                <td style={{ padding }}>{transfer.source}</td>
                <td style={{ padding }}>
                    <TransferLink
                        link={`/organizations/${transfer.from_member.organization.id}/member/${transfer.from_member.id}`}
                        label={transfer?.from_member?.contact.email}
                    />
                </td>
                <td style={{ padding }}>
                    <TransferLink
                        link={
                            transfer.to_subscription.id !== membershipId
                                ? `/membership/${transfer.to_subscription.id}/details`
                                : undefined
                        }
                        label={transfer?.to_subscription?.order_email}
                    />
                </td>

                <td style={{ padding }}>
                    {transfer?.created_at ? formatDateTime(transfer.created_at) : 'N/A'}
                </td>
            </tr>
        );
    }

    if (transfer.from_member && transfer.to_member) {
        return (
            <tr>
                <td style={{ padding }}>Org To Org Transfer</td>
                <td style={{ padding }}>{transfer.source}</td>
                <td style={{ padding }}>
                    <TransferLink
                        link={`/organizations/${transfer.from_member.organization.id}/member/${transfer.from_member.id}`}
                        label={transfer?.from_member?.contact.email}
                    />
                </td>
                <td style={{ padding }}>
                    <td style={{ padding }}>
                        <TransferLink
                            link={`/organizations/${transfer.to_member.organization.id}/member/${transfer.to_member.id}`}
                            label={transfer?.to_member?.contact.email}
                        />
                    </td>
                </td>

                <td style={{ padding }}>
                    {transfer?.created_at ? formatDateTime(transfer.created_at) : 'N/A'}
                </td>
            </tr>
        );
    }

    return (
        <tr>
            <td style={{ padding }}>{'Something is wrong, case not covered'}</td>
        </tr>
    );
};

const MembershipChangesModal: React.FC<Props> = ({
    heroUserId,
    externalControls,
    membershipId
}) => {
    const [, setExternalState] = externalControls ?? [];

    const { data: changes, isLoading } = useGetMembershipChanges(heroUserId, !!heroUserId);

    const handleClose = React.useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            styles={{ width: '110rem', maxWidth: '110rem' }}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <H centered role="h3">
                        Subscription Transfers
                    </H>
                    {changes?.data?.length === 0 ? (
                        <p>No invoices found</p>
                    ) : (
                        <table
                            style={{
                                fontSize: '16px',
                                borderCollapse: 'separate',
                                borderSpacing: '1em'
                            }}
                        >
                            <thead>
                                <tr style={{ textAlign: 'left' }}>
                                    <th style={{ padding }}>Type</th>
                                    <th style={{ padding }}>Source</th>
                                    <th style={{ padding }}>From</th>
                                    <th style={{ padding }}>To</th>
                                    <th style={{ padding }}>Date</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '14px' }}>
                                {changes?.data?.map((change) => (
                                    <TransferChange transfer={change} membershipId={membershipId} />
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Button onClick={handleClose} variant="outlineSecondary">
                        Close
                    </Button>
                </>
            )}
        </Modal>
    );
};

export default MembershipChangesModal;
