import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { membershipPlanErrorSelector } from '@hero/redux-data/backoffice/membershipPlan/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipPlanUpdateFailAlert = () => {
    const { error, errorMessage } = useSelector(membershipPlanErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: errorMessage || 'Error updating subscription plan.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage]);
};
export default useMembershipPlanUpdateFailAlert;
