import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MembershipList from './MembershipList';
import MembershipDetails from './MembershipDetails';
import MembershipDeviceExchange from './MembershipDeviceExchange';
import MembershipCancellation from './MembershipCancellation';
import MembershipMonitoring from './MembershipMonitoring';
import RTMDetails from './RTM';
import { useDispatch } from 'react-redux';
import { membershipDetailsReset } from '@hero/redux-data/backoffice/membershipDetails/actionCreators';

const MembershipRoutes: React.FC = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(membershipDetailsReset());
        };
    }, [dispatch]);

    return (
        <Routes>
            <Route path="monitoring" element={<MembershipMonitoring />} />
            <Route path="list" element={<MembershipList />} />
            <Route path="details" element={<MembershipDetails />} />
            <Route path="exchange" element={<MembershipDeviceExchange />} />
            <Route path="cancellation" element={<MembershipCancellation />} />
            <Route path=":id/details" element={<MembershipDetails />} />
            <Route path=":id/exchange" element={<MembershipDeviceExchange />} />
            <Route path=":id/cancellation" element={<MembershipCancellation />} />
            <Route path=":id/rtm" element={<RTMDetails />} />
            <Route path="/" element={<Navigate to="/membership/list" replace />} />
        </Routes>
    );
};

export default MembershipRoutes;
