import React from 'react';
import Container from '@hero/ui-kit/layout/Container';
import A from '@hero/ui-kit/typography/A';
import P from '@hero/ui-kit/typography/P';

type Props = {
    userRole?: string;
    firstName?: string;
    email?: string;
    userId?: number;
    phone?: string;
    lastName?: string;
};

const CaregiverDetails: React.FC<Props> = ({
    userRole,
    firstName,
    lastName,
    email,
    userId,
    phone
}) => {
    const emailTemp = React.useMemo(() => {
        if (email && userId) {
            return <A to={`/users/${userId}`}>{email}</A>;
        }
        if (!userId && email) {
            return email;
        }
        return '-';
    }, [userId, email]);

    return (
        <Container gridTemplateColumns="2fr 7fr">
            {userRole ? (
                <>
                    <P strong>TYPE (ROLE)</P>
                    <P>{userRole}</P>
                </>
            ) : null}
            <P strong>FIRST NAME</P>
            <P>{firstName || '-'}</P>
            <P strong>LAST NAME</P>
            <P>{lastName || '-'}</P>
            <P strong>EMAIL</P>
            <P>{emailTemp}</P>
            <P strong>PHONE NUMBER</P>
            <P>{phone || '-'}</P>
        </Container>
    );
};

export default CaregiverDetails;
