import React from 'react';

// TODO Following import is here just to serve as a reminder.
// It will be needed once we refactor the entire BO and get rid of PostCSS completely.
// For now, font files and @font-face rules are copied localy in `./general-styles/fonts`:
// import 'branding/font/gilroy.css';

import csp from '@hero/hero-csp/backofficeCsp';
import useMetaTag from '@hero/react-hooks/useMetaTag';

import useApiAlerts from './hooks/useApiAlerts';

const App: React.FC = () => {
    const metaConfig = React.useMemo(
        () => ({
            httpEquiv: 'content-security-policy',
            content: csp
        }),
        []
    );

    useMetaTag(metaConfig);
    useApiAlerts();

    return null;
};

export default App;
