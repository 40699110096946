import React from 'react';

import SearchForm from '../../../../components/SearchForm';
import PageSizeForm from '../../../../components/PageSizeForm';
import FiltersToggle from '../../../../components/FiltersToggle';
import useFilters from '../../../../hooks/useFiltersToggle';

import RmaFilterForm from './RmaFilterForm';
import useRmaListHeaderStyles from './useRmaListHeaderStyles';

const RmaListHeader: React.FC = () => {
    const [areFiltersExpanded, toggleFiltersExpansion] = useFilters();

    const { headerWrapStyles, searchWrapStyles, filterToggleStyles } = useRmaListHeaderStyles();

    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <SearchForm placeholder="Search by RMA #, order #, serial # or customer name" />
                    <FiltersToggle
                        isExpanded={areFiltersExpanded}
                        onClick={toggleFiltersExpansion}
                        styles={filterToggleStyles}
                    />
                </div>
                <PageSizeForm />
            </div>
            {areFiltersExpanded && <RmaFilterForm />}
        </>
    );
};

export default RmaListHeader;
