import React, { ReactNode, createContext, useContext, useReducer, useCallback } from 'react';

type Notification = { id: string; config: unknown };

type AddNotification = (config: unknown) => string;

type RemoveNotification = (id: string) => void;

type NotificationContextType = Readonly<{
    notifications: Notification[];
    addNotification: AddNotification;
    removeNotification: RemoveNotification;
}>;

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotifications = () => {
    const notificationContext = useContext(NotificationContext);

    if (notificationContext === undefined) {
        throw new Error(`'useNotifications' must be used within a 'NotificationProvider'`);
    }

    return notificationContext;
};

type AddNotificationAction = { type: 'ADD'; notification: Notification };

type RemoveNotificationAction = { type: 'REMOVE'; id: string };

type NotificationAction = AddNotificationAction | RemoveNotificationAction;

const notificationReducer = (notifications: Notification[], action: NotificationAction) => {
    switch (action.type) {
        case 'ADD':
            return [...notifications, action.notification];
        case 'REMOVE':
            return notifications.filter((notification) => notification.id !== action.id);
        default:
            return notifications;
    }
};

type ProviderProps = {
    children: ReactNode;
};

export const NotificationProvider: React.FC<ProviderProps> = ({ children }) => {
    const [notifications, dispatch] = useReducer(notificationReducer, []);

    const addNotification: AddNotification = useCallback((config) => {
        const id = Math.random().toString(36).substring(2);
        const notification: Notification = { id, config };

        dispatch({ type: 'ADD', notification });

        return id;
    }, []);

    const removeNotification: RemoveNotification = useCallback((id) => {
        dispatch({ type: 'REMOVE', id });
    }, []);

    return (
        <NotificationContext.Provider
            value={{ notifications, addNotification, removeNotification }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
