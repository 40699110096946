import React from 'react';

import SearchForm from '../../../../components/SearchForm';
import PageSizeForm from '../../../../components/PageSizeForm';

import { headerWrapStyles, searchWrapStyles } from './styles';

const UsersListFiltering: React.FC = () => {
    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <SearchForm name="email" placeholder="Search by email" />
                </div>
                <PageSizeForm defaultLimit="20" />
            </div>
        </>
    );
};

export default UsersListFiltering;
