import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../../components/Link';
import { DischargedQueueMemberDataObject } from './repackTableData';
import MemberActions from '../MemberActions';
import MenuIcon from '@hero/ui-kit/icons/utility/MenuIcon';
import P from '@hero/ui-kit/typography/P';
import ActionButton from '../../../../components/ActionButton';

type Render = TableColumn<DischargedQueueMemberDataObject>['render'];

const getEmailRender: Render = ({ email, organization_id, enrollment_id }) => {
    return enrollment_id ? (
        <Link to={`/organizations/${organization_id}/members/${enrollment_id}/details`}>
            {email || '(no email)'}
        </Link>
    ) : (
        <P noDefaultMargin> {email || '(no email)'}</P>
    );
};

export type ActionHandler = (id: number) => void;
type RefetchCancellationRequests = () => void;
type GetActionRender = (actionHandler: ActionHandler) => Render;
type GetActionsRender = (refetchCancellationRequests: RefetchCancellationRequests) => Render;

const getNoteRender: GetActionRender =
    (actionHandler) =>
    ({ id, note }) =>
        (
            <ActionButton
                icon={MenuIcon}
                onClick={() => actionHandler(id)}
                alt={note ? 'Edit Note' : 'Add Note'}
                variant={note ? 'primary' : 'secondary'}
            />
        );

const getActionsRender: GetActionsRender = (refetchCancellationRequests) => (member) => {
    return (
        <MemberActions member={member} refetchCancellationRequests={refetchCancellationRequests} />
    );
};

type GetTableColumns = (
    noteActionHandler: ActionHandler,
    refetchCancellationRequests: RefetchCancellationRequests
) => TableColumn<DischargedQueueMemberDataObject>[];

const getTableColumns: GetTableColumns = (noteActionHandler, refetchCancellationRequests) => [
    {
        colKey: 'actions',
        name: 'Actions',
        align: 'left',
        headAlign: 'left',
        render: getActionsRender(refetchCancellationRequests)
    },
    {
        colKey: 'status',
        name: 'Status'
    },
    {
        colKey: 'organization',
        name: 'Organization'
    },
    {
        colKey: 'email',
        name: 'Primary User\nEmail',
        render: getEmailRender
    },
    {
        colKey: 'name',
        name: 'Primary User\nName'
    },
    {
        colKey: 'phone_number',
        name: 'Primary User\nPhone Number'
    },
    {
        colKey: 'caregiver_name',
        name: 'Caregiver Admin\nName'
    },
    {
        colKey: 'caregiver_phone_number',
        name: 'Caregiver Admin\nPhone Number'
    },
    {
        colKey: 'discharge_reason',
        name: 'Discharge\nReason'
    },
    {
        colKey: 'date_discharged',
        name: 'Date\nDischarged'
    },
    {
        colKey: 'note',
        name: '',
        width: '2.4rem',
        render: getNoteRender(noteActionHandler)
    }
];

export default getTableColumns;
