import React, { CSSProperties, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    createRmaRequest,
    rmaRequestSaveReset
} from '@hero/redux-data/backoffice/rmaRequestSave/actionCreators';
import { getRmaEnums, rmaEnumsReset } from '@hero/redux-data/backoffice/rmaEnums/actionCreators';
import {
    isRmaRequestSaveLoadingSelector,
    isRmaRequestSaveUpdatedSelector
} from '@hero/redux-data/backoffice/rmaRequestSave/selectors';
import { areRmaEnumsLoadingSelector } from '@hero/redux-data/backoffice/rmaEnums/selectors';
import { deviceRestockingCheckSelector } from '@hero/redux-data/backoffice/deviceRestockingCheck/selectors';
import { emailSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import usePrevious from '@hero/react-hooks/usePrevious';
import Section from '@hero/ui-kit/layout/Section';
import Divider from '@hero/ui-kit/components/Divider';
import Loader from '@hero/ui-kit/components/Loader';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import {
    createRmaRequestSchema,
    CreateRmaRequestParams
} from '@hero/validators/forms/backoffice/createRmaReqestSchema';
import InlineLoader from '../../../components/InlineLoader';
import RmaRequestFormFieldsBasics from '../formSections/RmaRequestFormFieldsBasics';
import RmaRequestFormFieldsReturn from '../formSections/RmaRequestFormFieldsReturn';
import RmaRequestFormFieldsExchange from '../formSections/RmaRequestFormFieldsExchange';
import RmaRequestFormFieldsExtras from '../formSections/RmaRequestFormFieldsExtras';
import { repackValuesForCreate } from '../utils/repackValuesForSubmit';
import DefaultLayout from '../../../components/DefaultLayout';

type LocationState = {
    from?: string;
};

const RmaCreate: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const isSavingRma = useSelector(isRmaRequestSaveLoadingSelector);
    const isRmaSaved = useSelector(isRmaRequestSaveUpdatedSelector);
    const isFetchingEnums = useSelector(areRmaEnumsLoadingSelector);
    const restockingCheckResult = useSelector(deviceRestockingCheckSelector, shallowEqual);
    const loggedInUserEmail = useSelector(emailSelector);
    const wasRmaSaved = usePrevious(isRmaSaved);

    const isFetching = isFetchingEnums;

    const [selectedType, setSelectedType] = useState<string | undefined>();

    const isMissingCheck = selectedType !== '2' && !restockingCheckResult;

    // initial status is always `approved` (api default), for now...
    const defaultValues = useMemo(() => ({ rma_status: '5' }), []);

    useEffect(() => {
        dispatch(getRmaEnums({ filtered: true }));

        // we cannot do the following reset on unmount, since success alert
        // needs to be displayed on another page (newly created rma details)
        dispatch(rmaRequestSaveReset());

        return () => {
            dispatch(rmaEnumsReset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!wasRmaSaved && isRmaSaved) {
            navigate('/rma');
        }
    }, [navigate, wasRmaSaved, isRmaSaved]);

    const handleTypeSelect = useCallback((selectedType: string | undefined) => {
        setSelectedType(selectedType);
    }, []);

    const handleSubmit = useCallback(
        (formValues: CreateRmaRequestParams) => {
            !isMissingCheck &&
                dispatch(
                    createRmaRequest(repackValuesForCreate(formValues, restockingCheckResult))
                );
        },
        [dispatch, isMissingCheck, restockingCheckResult]
    );

    const handleCancel = useCallback(() => {
        const locationState = location.state as LocationState;
        navigate(locationState?.from || '/rma');
    }, [navigate, location]);

    const handleGoToList = useCallback(() => {
        navigate('/rma');
    }, [navigate]);

    const formWrapPadding = useComponentPadding({ paddingTop: 'small', paddingBottom: 'large' });
    const resetButtonMargin = useComponentMargin({ marginLeft: 'regular' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const formWrapStyles: CSSProperties = {
        width: '92.4rem',
        ...formWrapPadding
    };
    const controlWrapStyles: CSSProperties = {
        display: 'flex',
        marginTop: '1.2rem'
    };

    const now = new Date();

    return (
        <DefaultLayout>
            {isSavingRma && <Loader />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">Create RMA</H>
                    <Button variant="secondary" onClick={handleGoToList} noDefaultMargin>
                        Go To List
                    </Button>
                </div>
                <div style={formWrapStyles}>
                    {isFetching ? (
                        <InlineLoader />
                    ) : (
                        <Form
                            validationSchema={createRmaRequestSchema}
                            submitFn={handleSubmit}
                            defaultValues={{ rma_status: '5' }}
                        >
                            <RmaRequestFormFieldsBasics
                                onTypeSelect={handleTypeSelect}
                                initialValues={defaultValues}
                                createdAt={now}
                                updatedAt={now}
                                createdBy={loggedInUserEmail}
                                isEditMode
                            />
                            <Divider />
                            <RmaRequestFormFieldsReturn
                                isMissingCheck={isMissingCheck}
                                isEditMode
                            />
                            {selectedType === '2' && (
                                <>
                                    <Divider />
                                    <RmaRequestFormFieldsExchange isEditMode />
                                </>
                            )}
                            <Divider />
                            <RmaRequestFormFieldsExtras isEditMode />
                            <div style={controlWrapStyles}>
                                <Button width="large" type="submit">
                                    Save
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleCancel}
                                    styles={resetButtonMargin}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default RmaCreate;
