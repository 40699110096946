import * as yup from 'yup';
const monitoringFilterSchema = yup
    .object()
    .shape({
    filter_key: yup.string().trim(),
    organization_id: yup.string().trim(),
    membership_key: yup.string().trim()
})
    .defined();
export default monitoringFilterSchema;
