import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import H from '@hero/ui-kit/typography/H';

import TabBar from '../TabBar';

import { EnterpriseMemberTableDataObject } from './utils/repackTableData';
import EnterpriseMemberListFiltering from './EnterpriseMemberListFiltering';
import useEnterpriseMembersListStyles from './useEnterpriseMemberListStyles';
import useEnterpriseMembersListData from './useEnterpriseMemberListData';
import DefaultLayout from '../../../components/DefaultLayout';

const EnterpriseMemberList: React.FC = () => {
    const {
        organization,
        tableColumns,
        tableData,
        isFetchingMembers,
        sort,
        handleSort,
        pagerProps
    } = useEnterpriseMembersListData();
    const { innerWrapPadding, tableWrapStyles } = useEnterpriseMembersListStyles();

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h5">{organization?.name || 'Organization'}</H>
                <TabBar />
                <div style={innerWrapPadding}>
                    <EnterpriseMemberListFiltering />
                    <div style={tableWrapStyles}>
                        <Table<EnterpriseMemberTableDataObject>
                            columns={tableColumns}
                            data={tableData}
                            rowKey="id"
                            emptyValues="-"
                            isLoading={isFetchingMembers}
                            sortColumn={sort.sort_by}
                            sortDirection={sort.sort_dir}
                            onColumnSort={handleSort}
                            pagerProps={pagerProps}
                        />
                    </div>
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default EnterpriseMemberList;
