import React from 'react';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import H from '@hero/ui-kit/typography/H';

export const ConfirmCard = () => {
    const { goToFOP, onCloseReactivationFlow, cardDetails, onConfirmCard } =
        useReactivationFlowContext();

    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginLeft = useComponentMargin({ marginLeft: 'regular' });

    return (
        <>
            <H role="h6">Confirm credit card on file or add new credit card</H>
            <Section noDefaultPadding styles={{ textAlign: 'left', ...marginTop }} width="full">
                <P size="large" noDefaultMargin>
                    CARD OWNER
                </P>
                <P size="large" strong noDefaultMargin>
                    {cardDetails.name}
                </P>
                <P size="large" noDefaultMargin styles={marginTop}>
                    CREDIT CARD
                </P>
                <P size="large" strong noDefaultMargin>
                    {cardDetails.last4digits}
                </P>
                <P size="large" noDefaultMargin styles={marginTop}>
                    CARD EXPIRATION DATE
                </P>
                <P size="large" strong noDefaultMargin>
                    {`${cardDetails.expirationMonth} / ${cardDetails.expirationYear}`}
                </P>
                <P size="large" noDefaultMargin styles={marginTop}>
                    CARD TYPE
                </P>
                <P size="large" strong noDefaultMargin>
                    {cardDetails.cardType}
                </P>
            </Section>
            <Section centered noDefaultPadding>
                <div style={{ display: 'flex', alignContent: 'center', ...marginTop }}>
                    <Button onClick={onConfirmCard}>Confirm</Button>
                    <Button
                        variant="secondary"
                        onClick={onCloseReactivationFlow}
                        styles={marginLeft}
                    >
                        Cancel Reactivation
                    </Button>
                </div>
                <Button
                    onClick={goToFOP}
                    variant="inverted"
                    size="regular"
                    styles={marginTop}
                    noDefaultMargin
                >
                    Add new credit card
                </Button>
            </Section>
        </>
    );
};
