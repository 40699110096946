import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchDevices } from '@hero/redux-data/backoffice/devices/actionCreactors';
import { areDevicesLoadingSelector, deviceSelector, devicesPaginationSelector } from '@hero/redux-data/backoffice/devices/selectors';
import useLocalization from '@hero/localization';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import usePagerProps from '../../../hooks/usePagerProps';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import getTableComuns from './getTableColumns';
const useDevicesListData = () => {
    const dispatch = useDispatch();
    const { formatElapsedTime } = useLocalization();
    const isFetchingDevices = useSelector(areDevicesLoadingSelector, shallowEqual);
    const devices = useSelector(deviceSelector, shallowEqual);
    const processedDevices = useMemo(() => devices.map(({ last_online, ...rest }) => ({
        ...rest,
        last_online: formatElapsedTime(last_online)
    })), [devices, formatElapsedTime]);
    const paginationData = useSelector(devicesPaginationSelector, shallowEqual);
    const [sort, handleSort] = useTableSortQuery();
    const pagerProps = usePagerProps(paginationData);
    const { offset = 0, limit = 20, serial, email } = getQueryParams();
    const tableColumns = useMemo(() => getTableComuns(), []);
    useEffect(() => {
        dispatch(fetchDevices({ offset, limit, serial, email }));
    }, [dispatch, offset, limit, serial, email]);
    return {
        dispatch,
        isFetchingDevices,
        devices: processedDevices,
        sort,
        handleSort,
        pagerProps,
        tableColumns
    };
};
export default useDevicesListData;
