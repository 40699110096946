import React from 'react';
import * as yup from 'yup';

import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';

import { useNewProviderElection } from '../context/NewProviderElectionProvider';

type Props = {
    closeModal?: () => void;
};

type FormFieldsProps = {
    defaultState?: string;
    onVoid?: () => void;
};

const formSchema = yup
    .object()
    .shape({
        user_role: yup.lazy((val) =>
            !val
                ? yup.string().nullable().required('User role field is required')
                : yup.string().oneOf(['pu', 'cg'], 'Invalid application submit')
        ) as unknown as yup.StringSchema<'pu' | 'cg', object>
    })
    .defined();

export type FormParams = yup.InferType<typeof formSchema>;

const FormFields: React.FC<FormFieldsProps> = () => {
    const { handleResetForm } = useNewProviderElection();
    const {
        formState: { isValid, isDirty },
        reset
    } = useFormContext<FormParams>();

    const resetForm = React.useCallback(() => {
        reset();
        handleResetForm();
    }, [reset, handleResetForm]);

    return (
        <>
            <H role="h6">Who is filling out this enrollment?</H>

            <Section noDefaultPadding>
                <RadioGroup name="user_role" displayName="" noBorders>
                    <Radio
                        id="user_role_pu"
                        value="pu"
                        labelText="I am speaking with Primary User"
                    />
                    <Radio
                        id="user_role_cg"
                        value="cg"
                        labelText="I am speaking with a Caregiver"
                    />
                </RadioGroup>
            </Section>

            <Section centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>

                {isDirty && (
                    <Button width="large" variant="secondary" onClick={resetForm}>
                        Reset
                    </Button>
                )}
            </Section>
        </>
    );
};

const UserRole: React.FC<Props> = () => {
    const { changeStep, handleSetFormValues } = useNewProviderElection();

    const handleNext = React.useCallback(
        (formValues: FormParams) => {
            handleSetFormValues(formValues);
            changeStep('Insurance');
        },
        [changeStep]
    );

    return (
        <Section noDefaultPadding>
            <Section noDefaultPadding marginTop="regular">
                <Form submitFn={handleNext} validationSchema={formSchema}>
                    <FormFields />
                </Form>
            </Section>
        </Section>
    );
};

export default UserRole;
