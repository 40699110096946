import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Form from '@hero/ui-kit/inputs/Form';
import planPickerSchema from '../schema';
import PlanPicker from '../../PlanPicker';

interface ReactivateProps {
    hideDetails?: boolean;
}

export const Reactivate: React.FC<ReactivateProps> = ({ hideDetails = false }) => {
    const { onReactivate, reactivateDetails, storeFrontKey } = useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'large' });

    // https://herohealth.atlassian.net/browse/HWM-18050
    const preparePlans = React.useMemo(() => {
        return [
            'basic-extended-trial' as const,
            'new-bundle-1-year' as const,
            'new-bundle-2-year' as const,
            'new-bundle-3-year' as const
        ];
    }, [storeFrontKey]);

    return (
        <Form
            validationSchema={planPickerSchema}
            submitFn={onReactivate}
            defaultValues={{ plan: 'basic-extended-trial' }}
            render={(_, formState) => {
                return (
                    <>
                        {!hideDetails ? (
                            <>
                                <H role="h6" centered>
                                    {reactivateDetails.header}
                                </H>
                                <Section
                                    noDefaultPadding
                                    styles={{ textAlign: 'left', ...marginTop }}
                                    width="full"
                                    centered
                                >
                                    <P size="large" noDefaultMargin>
                                        {reactivateDetails.message}
                                    </P>
                                </Section>
                            </>
                        ) : null}

                        <Section centered noDefaultPadding paddingTop="regular">
                            <PlanPicker filterPlans={preparePlans} />
                        </Section>

                        <Section centered>
                            <Button width="large" type="submit" disabled={!formState.isValid}>
                                Reactivate Membership
                            </Button>
                        </Section>
                    </>
                );
            }}
        />
    );
};
