import React from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { deviceDetailsSelector } from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import emailValidator from '@hero/validators/email';
import phoneValidator from '@hero/validators/libphonenumber';

import { useNewProviderElection } from '../context/NewProviderElectionProvider';

type Props = {
    closeModal?: () => void;
};

const formSchemaPU = yup
    .object()
    .shape({
        email: yup.lazy((val) =>
            !val ? yup.string().trim().required('Email is required') : emailValidator
        ),
        phone_number: yup.lazy((val) =>
            !val ? yup.string().trim().required('Phone number is required') : phoneValidator
        ) as unknown as yup.StringSchema<string, object>
    })
    .defined();

const formSchemaCG = formSchemaPU.concat(
    yup
        .object()
        .shape({
            first_name: yup.string().trim().required('First name is required'),
            last_name: yup.string().trim().required('Last name is required')
        })
        .defined()
);

export type FormParams = yup.InferType<typeof formSchemaPU> | yup.InferType<typeof formSchemaCG>;

const FormFields: React.FC = () => {
    const { formValues, handleResetForm } = useNewProviderElection();
    const {
        formState: { isValid }
    } = useFormContext<FormParams>();

    return (
        <>
            <Section noDefaultPadding>
                {formValues.user_role === 'cg' && (
                    <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                        <Input name="first_name" displayName="Contact person first name" />
                        <Input name="last_name" displayName="Contact person last name" />
                    </Container>
                )}

                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <Input
                        name="email"
                        displayName={`${formValues.user_role === 'cg' ? 'Contact person' : 'Primary user'} email`}
                    />
                    <Input
                        name="phone_number"
                        displayName={`${formValues.user_role === 'cg' ? 'Contact person' : 'Primary user'} phone number`}
                    />
                </Container>
            </Section>

            <Section centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Submit
                </Button>

                <Button width="large" variant="secondary" onClick={handleResetForm}>
                    Reset
                </Button>
            </Section>
        </>
    );
};

const Submit: React.FC<Props> = () => {
    const { enrollment, formValues, handleComplete } = useNewProviderElection();
    const deviceDetails = useSelector(deviceDetailsSelector);

    const handleNext = React.useCallback(
        (values: FormParams) => {
            handleComplete && handleComplete(values);
        },
        [handleComplete]
    );

    return (
        <Section noDefaultPadding>
            <Section noDefaultPadding marginTop="regular">
                <H role="h5">Submit application</H>
                <P>
                    Ask the person on the phone to confirm their email and phone number where they
                    can be reached by the provider to complete their enrollment.
                </P>

                <Form
                    submitFn={handleNext}
                    validationSchema={formValues.user_role === 'cg' ? formSchemaCG : formSchemaPU}
                    defaultValues={
                        formValues.user_role === 'cg'
                            ? {
                                  first_name:
                                      deviceDetails?.caregivers[0]?.caregiver_first_name || '',
                                  last_name:
                                      deviceDetails?.caregivers[0]?.caregiver_last_name || '',
                                  email: deviceDetails?.caregivers[0]?.caregiver_email || '',
                                  phone_number: deviceDetails?.caregivers[0]?.phone || ''
                              }
                            : {
                                  email: enrollment.member?.contact?.email,
                                  phone_number:
                                      enrollment.member?.contact?.home_phone ||
                                      enrollment.member?.contact?.cell_phone
                              }
                    }
                >
                    <FormFields />
                </Form>
            </Section>
        </Section>
    );
};

export default Submit;
