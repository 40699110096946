import { useMemo } from 'react';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useSectionMargin from '@hero/ui-kit/hooks/useSectionMargin';
const useDeviceDetailsStyles = () => {
    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });
    const headerButtonMargin = useComponentMargin({ marginVertical: 'none' });
    const smallButtonMargin = useComponentMargin({ marginLeft: 'small' });
    const addtionalControlsMargin = useSectionMargin({ marginVertical: 'regular' });
    const headerWrapStyles = useMemo(() => ({
        display: 'flex',
        flexDirection: 'column'
    }), []);
    const contentStyles = useMemo(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }), []);
    return {
        innerWrapPadding,
        contentStyles,
        headerWrapStyles,
        headerButtonMargin,
        smallButtonMargin,
        addtionalControlsMargin
    };
};
export default useDeviceDetailsStyles;
