import * as React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import P from '@hero/ui-kit/typography/P';
import { runDeviceRestockingCheck } from '@hero/redux-data/backoffice/deviceRestockingCheck/actionCreators';
import {
    isDeviceRestockingCheckLoadingSelector,
    deviceRestockingCheckSelector
} from '@hero/redux-data/backoffice/deviceRestockingCheck/selectors';
import Button from '@hero/ui-kit/inputs/Button';
import useRmaEnums from '../utils/useRmaEnums';

interface RestockingCheckProps {
    deviceSerial?: string | null;
    reprocessingCheck?: number | null;
}

const checkResultValues = {
    yes: 'Pass - Return to Sonic',
    no: 'Fail - Return to Rush Order',
    unknown: 'Unknown'
};

const RestockingCheck: React.FC<RestockingCheckProps> = ({ deviceSerial, reprocessingCheck }) => {
    const dispatch = useDispatch();
    const isRunningCheck = useSelector(isDeviceRestockingCheckLoadingSelector);
    const checkResult = useSelector(deviceRestockingCheckSelector, shallowEqual);

    const restockingWrapStyles: React.CSSProperties = {
        display: 'flex',
        alignItems: 'flex-start'
    };
    const buttonStyles: React.CSSProperties = {
        marginRight: '3.6rem'
    };

    const handleRunCheck = React.useCallback(() => {
        deviceSerial && dispatch(runDeviceRestockingCheck({ serial: deviceSerial }));
    }, [dispatch, deviceSerial]);

    const { reprocessingCheckValues } = useRmaEnums();

    const restockingCheckLabel = React.useMemo(() => {
        if (isRunningCheck) {
            return 'Checking...';
        }
        if (checkResult?.eligible_for_restock) {
            return checkResultValues[checkResult.eligible_for_restock];
        }
        return reprocessingCheck && reprocessingCheckValues
            ? reprocessingCheckValues[reprocessingCheck]
            : 'Not Complete';
    }, [isRunningCheck, checkResult, reprocessingCheck, reprocessingCheckValues]);

    return (
        <div style={{ ...restockingWrapStyles }}>
            <Button
                size="small"
                onClick={handleRunCheck}
                noDefaultMargin
                disabled={isRunningCheck || !deviceSerial}
                styles={buttonStyles}
            >
                Run Now
            </Button>
            <P noDefaultMargin>{restockingCheckLabel}</P>
        </div>
    );
};

export default RestockingCheck;
