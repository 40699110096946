import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { deleteDevice } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';

type Props = {
    handleClose: () => void;
    device_id: number;
    serial: string;
};

const RemoveDeviceModal: React.FC<Props> = ({ handleClose, device_id, serial }) => {
    const dispatch = useDispatch();
    const handleDeleteDevice = useCallback(
        () => dispatch(deleteDevice({ id: device_id })),
        [dispatch, device_id]
    );

    return (
        <Modal onClose={handleClose} isCancelable>
            <H role="h4">Remove Device</H>
            <P>Delete this device</P>
            <P>{serial}</P>
            <Button onClick={handleDeleteDevice}>Remove device</Button>
        </Modal>
    );
};

export default RemoveDeviceModal;
