import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import Form from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import schema from '@hero/validators/forms/backoffice/enrollmentCreate';

import InlineLoader from '../../../components/InlineLoader';
import DefaultErrorModal from '../../../components/DefaultErrorModal';
import DefaultSuccessModal from '../../../components/DefaultSuccessModal';
import useListStyles from '../../../hooks/useListStyles';

import TabBar from '../TabBar';
import useOrganizationEnrollmentData from './useOrganizationEnrollmentData';
import DefaultLayout from '../../../components/DefaultLayout';

const OrganizationEnrollment: React.FC = () => {
    const {
        isLoading,
        isUpdated,
        hasError,
        errorMessage,
        handleEnrollmentCreate,
        handleEnrollmentReset
    } = useOrganizationEnrollmentData();
    const { headerWrapStyles, innerWrapPadding } = useListStyles();

    return isLoading ? (
        <InlineLoader />
    ) : (
        <DefaultLayout>
            {hasError && (
                <DefaultErrorModal errorMessage={errorMessage} onClose={handleEnrollmentReset} />
            )}
            {isUpdated && (
                <DefaultSuccessModal onClose={handleEnrollmentReset}>
                    Enrollment created.
                </DefaultSuccessModal>
            )}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
                width="contained"
            >
                <div style={headerWrapStyles}>
                    <H role="h4">Add Device</H>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    <Form validationSchema={schema} submitFn={handleEnrollmentCreate}>
                        <Input name="external_user_id" displayName="External user ID" required />
                        <Input
                            name="device_external_serial"
                            displayName="External device serial"
                            required
                        />
                        <Button type="submit" width="large">
                            Submit
                        </Button>
                    </Form>
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default OrganizationEnrollment;
