import React from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import * as ChargeFlowStepComponents from './steps';
import ChargeFlowProvider, { useChargeFlowContext } from './context/ChargeFlowProvider';
import { Cancellation, FeeType } from '@hero/hero-types';
import InlineLoader from '../../../../components/InlineLoader';

type ChargeFlowProps = {
    chargeContext?: FeeType;
    membershipId: number;
    cancellation?: Cancellation;
    onCompleteFlow?: () => void;
} & Pick<ModalProps, 'externalControls'>;

const ChargeFlowSteps: React.FC<ChargeFlowProps> = ({
    externalControls,
    chargeContext = 'unreturned_device_fee'
}) => {
    const { chargeStep, isLoading, closeChargeModal, errorData } = useChargeFlowContext();
    const ChargeStepComponent = React.useMemo(() => {
        return ChargeFlowStepComponents[chargeStep];
    }, [chargeStep]);

    const modalType = React.useMemo(() => {
        if (chargeStep === 'Fail' || chargeStep === 'Confirm') {
            return 'warn';
        }
        if (chargeStep === 'Success') {
            return 'success';
        }
        return 'neutral';
    }, [chargeStep]);

    const chargeHeader = React.useMemo(() => {
        if (chargeStep === 'Fail') {
            return errorData.previewError ? 'Preview Failed' : 'Charge Failed';
        }
        if (chargeStep === 'Success') {
            return 'Attempt Outcome';
        }
        if (chargeContext === 'cancellation_fee') {
            return 'Cancellation Fee Retry';
        }
        return 'Unreturn Device Fee Retry';
    }, [chargeStep, chargeContext, errorData]);

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            type={modalType}
            onClose={closeChargeModal}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <H role="h4" noDefaultMargin styles={{ marginBottom: '2rem' }}>
                        {chargeHeader}
                    </H>
                    <ChargeStepComponent />
                </>
            )}
        </Modal>
    );
};

const ChargeFlow: React.FC<ChargeFlowProps> = (props) => {
    return (
        <ChargeFlowProvider
            membershipId={props.membershipId}
            chargeContext={props.chargeContext}
            externalControls={props.externalControls}
            onCompleteFlow={props.onCompleteFlow}
            cancellation={props.cancellation}
        >
            <ChargeFlowSteps {...props} />
        </ChargeFlowProvider>
    );
};

export default ChargeFlow;
