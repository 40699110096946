import * as yup from 'yup';
export const updateRmaStatusSchema = yup
    .object()
    .shape({
    rma_status: yup
        .string()
        .nullable()
        .required('RMA status is required')
        .matches(/^\d+$/, 'Must be an integer'),
    return_status: yup
        .string()
        .nullable()
        .required('Return status is required')
        .matches(/^\d+$/, 'Must be an integer')
})
    .defined();
