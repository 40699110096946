import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

const DisabledCancel: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
    return (
        <>
            <P size="large" strong centered>
                Cancelation not allowed. Member is on a Legacy membership plan.
            </P>
            <Section centered>
                <Button variant="outlineSecondary" onClick={onClose}>
                    Close
                </Button>
            </Section>
        </>
    );
};

export default DisabledCancel;
