import React, { CSSProperties, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

import {
    runDeviceRestockingCheck,
    deviceRestockingCheckReset
} from '@hero/redux-data/backoffice/deviceRestockingCheck/actionCreators';
import { isDeviceRestockingCheckLoadingSelector } from '@hero/redux-data/backoffice/deviceRestockingCheck/selectors';
import Container from '@hero/ui-kit/layout/Container';
import Button from '@hero/ui-kit/inputs/Button';
import {
    CreateRmaRequestParams,
    UpdateRmaRequestParams
} from '@hero/validators/forms/backoffice/createRmaReqestSchema';

import StaticFormElement from '../../../components/StaticFormElement';
import StaticOrInput from '../../../components/StaticOrInput';
import StaticOrSelect from '../../../components/StaticOrSelect';
import useRmaEnums from '../utils/useRmaEnums';
import useRestockingCheckResult from '../utils/useRestockingCheckResult';

type Props = {
    isMissingCheck?: boolean;
    isEditMode?: boolean;
};

const RmaRequestFormFieldsReturn: React.FC<Props> = ({
    isMissingCheck = false,
    isEditMode = false
}) => {
    const dispatch = useDispatch();
    const isRunningCheck = useSelector(isDeviceRestockingCheckLoadingSelector);

    const { returnLocationOptions, returnStatusOptions } = useRmaEnums();
    const { restockingCheckLabel, suggestedReturnLocation, isForRestock } =
        useRestockingCheckResult();

    const {
        watch,
        setValue,
        formState: { isSubmitted }
    } = useFormContext<CreateRmaRequestParams | UpdateRmaRequestParams>();
    const [rma_type, device_serial, return_tracking_number, return_location, return_status] = watch(
        ['rma_type', 'device_serial', 'return_tracking_number', 'return_location', 'return_status']
    );

    const filteredReturnLocationOptions = React.useMemo(() => {
        const isDisabled = isForRestock === 'no' || isForRestock === 'unknown';
        if (isDisabled) {
            return returnLocationOptions.filter((locationOption) => locationOption[0] !== '6');
        }
        return returnLocationOptions;
    }, [isForRestock, returnLocationOptions]);

    useEffect(
        () => () => {
            dispatch(deviceRestockingCheckReset());
        },
        [dispatch, isEditMode]
    );

    useEffect(() => {
        suggestedReturnLocation &&
            !return_location &&
            setValue('return_location', suggestedReturnLocation, {
                shouldValidate: true,
                shouldDirty: true
            });
    }, [setValue, suggestedReturnLocation, return_location]);

    const handleRunCheck = useCallback(() => {
        device_serial && dispatch(runDeviceRestockingCheck({ serial: device_serial }));
    }, [dispatch, device_serial]);

    const leftColWrapStyles: CSSProperties = {
        gridColumnStart: 1,
        alignItems: 'start'
    };
    const rightColWrapStyles: CSSProperties = {
        gridColumnStart: 2,
        alignItems: 'start'
    };
    const restockingWrapStyles: CSSProperties = {
        gridColumnStart: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const buttonStyles: CSSProperties = {
        marginTop: '3.6rem'
    };

    return (
        <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
            {rma_type !== '2' && ( // not exchange
                <div style={{ ...restockingWrapStyles }}>
                    <StaticFormElement
                        label="Restocking Check"
                        hasAlert={isMissingCheck && isSubmitted}
                    >
                        {restockingCheckLabel}
                    </StaticFormElement>
                    {isEditMode && (
                        <Button
                            size="small"
                            onClick={handleRunCheck}
                            noDefaultMargin
                            styles={buttonStyles}
                            disabled={isRunningCheck || !device_serial}
                        >
                            Run Now
                        </Button>
                    )}
                </div>
            )}
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="return_tracking_number"
                    displayName="Tracking #"
                    staticValue={return_tracking_number}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                <StaticOrSelect
                    name="return_location"
                    displayName="Return Location"
                    options={filteredReturnLocationOptions}
                    staticValue={return_location}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                <StaticOrSelect
                    name="return_status"
                    displayName="Return Status"
                    options={returnStatusOptions}
                    staticValue={return_status}
                    isEditable={isEditMode}
                />
            </Container>
        </Container>
    );
};

export default RmaRequestFormFieldsReturn;
