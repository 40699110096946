import React, { CSSProperties, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { membershipAlertSelector } from '@hero/redux-data/backoffice/membershipAlerts/selectors';
import Modal from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import H from '@hero/ui-kit/typography/H';
import alertNoteSchema, {
    AlertNoteParams
} from '@hero/validators/forms/backoffice/alertNoteSchema';

type Props = {
    onConfirm: (subscription_id: number, notes: string) => void;
    onCancel: () => void;
    alertId: number;
};

const AlertNoteModal: React.FC<Props> = ({ onConfirm, onCancel, alertId }) => {
    const alert = useSelector(membershipAlertSelector(alertId), shallowEqual);
    const notes = alert?.notes || '';

    const handleConfirm = useCallback(
        ({ notes }: AlertNoteParams) => {
            onConfirm(alertId, notes);
        },
        [onConfirm, alertId]
    );

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    return alert ? (
        <Modal onClose={onCancel} isCancelable>
            <H role="h4" centered>
                {notes ? 'Edit Note' : 'Add Note'}
            </H>
            <Form
                validationSchema={alertNoteSchema}
                defaultValues={{ notes }}
                submitFn={handleConfirm}
            >
                <Section noDefaultPadding centered styles={innerWrapperStyles}>
                    <TextArea name="notes" displayName="Note" rows={8} resize="none" />
                    <div style={{ marginTop: '1.2rem' }}>
                        <Button width="large" type="submit">
                            Save
                        </Button>
                        <Button width="large" variant="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                    </div>
                </Section>
            </Form>
        </Modal>
    ) : null;
};

export default AlertNoteModal;
