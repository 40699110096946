import { useMemo } from 'react';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
const useEnrollmentVerificationStyles = () => {
    const innerWrapPadding = useComponentPadding({ paddingVertical: 'regular' });
    const tableWrapStyles = {
        overflowX: 'auto'
    };
    const headerWrapStyles = useMemo(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }), []);
    return { innerWrapPadding, tableWrapStyles, headerWrapStyles };
};
export default useEnrollmentVerificationStyles;
