import { useRef, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { isRmaRequestSaveUpdatedSelector, rmaRequestSaveSelector, } from "@hero/redux-data/backoffice/rmaRequestSave/selectors";
import { useNotifications } from "../../context/notification";
const useRmaSaveSuccessAlert = () => {
    const isRmaSaved = useSelector(isRmaRequestSaveUpdatedSelector);
    const rmaSaveResult = useSelector(rmaRequestSaveSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isRmaSaved) {
            id.current = addNotification({
                type: "success",
                message: rmaSaveResult
                    ? "RMA has been created."
                    : "RMA has been updated.",
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isRmaSaved, rmaSaveResult]);
};
export default useRmaSaveSuccessAlert;
