import React, { CSSProperties } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';

const List: React.FC<UiComponentProps & { children?: React.ReactNode }> = ({
    children,
    styles
}) => {
    const { typography } = useTheme();

    const mergedStyles: CSSProperties = {
        margin: 0,
        padding: 0,
        ...typography.paragraph.large,
        ...typography.defaultMarginBottom,
        ...styles
    };

    return <ul style={mergedStyles}>{children}</ul>;
};

export default List;
