import React from 'react';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import { useCancellationStateMachine } from './context/cancellationStateMachine';

interface RTMtoD2CSuccessReportProps {
    onClose?: () => void;
}

const planMessages = {
    'basic-extended-trial': 'Monthly at $44.99 per month, with 12 months minimum',
    annual: 'Annual ($419.88 billed today). This is our most popular plan, that equals $34.99 per month',
    biannual:
        '24 months ($719.76 billed today). This is our best value plan, that equals $29.99 per month'
};

const RTMtoD2CSuccessReport: React.FC<RTMtoD2CSuccessReportProps> = ({ onClose }) => {
    const marginTop = useComponentMargin({ marginTop: 'large' });
    const { retainRTMtoD2cParams } = useCancellationStateMachine();

    const userMessage = React.useMemo(() => {
        if (retainRTMtoD2cParams?.plan) {
            return (
                planMessages[retainRTMtoD2cParams.plan as keyof typeof planMessages] ||
                'Plan not supported'
            );
        }

        const months = retainRTMtoD2cParams?.mitigation?.months || 2;
        const mitigationMessages = {
            '1-month-free': 'with 1 free month of membership',
            'discount-subscription-50': `with 50% discount for the next ${months} months`,
            'mitigation-50': `with 50% discount for the next ${months} months`,
            'discount-subscription-67': `with almost 70% discount for the next ${months} months`,
            'mitigation-67': `with almost 70% discount for the next ${months} months`,
            'pause-subscription': `with pause for the next ${months} months`
        };

        return (
            mitigationMessages[
                retainRTMtoD2cParams?.mitigation?.hardship as keyof typeof mitigationMessages
            ] || 'Mitigation not supported'
        );
    }, [retainRTMtoD2cParams]);

    return (
        <>
            <Section noDefaultPadding paddingHorizontal="large">
                <H role="h5">Tell the member:</H>
                <P size="large">
                    <strong>Thank you</strong> for staying with Hero!
                </P>
                <P noDefaultMargin>We have updated your subscription:</P>
                <P size="large" strong>
                    {userMessage}
                </P>
                <P size="large">
                    We're always here to help -{' '}
                    <strong>improving your health and well-being</strong> is our goal.
                </P>
            </Section>
            <Section noDefaultPadding centered styles={marginTop}>
                <Button width="large" onClick={onClose}>
                    Ok
                </Button>
            </Section>
        </>
    );
};

export default RTMtoD2CSuccessReport;
