import React from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import InlineLoader from '../../../../../components/InlineLoader';
import { useToBeCancelledFlowContext } from '../context/ToBeCancelledProvider';
import P from '@hero/ui-kit/typography/P';
import QueryInlineError from '../../../../../components/QueryInlineError';

interface ConfirmProps {
    closeFlowModal: () => void;
}

export const Confirm: React.FC<ConfirmProps> = () => {
    const { changeFlowStep, isSubmitting, handleSubmit, error } = useToBeCancelledFlowContext();

    const handleBack = React.useCallback(() => {
        changeFlowStep('Details');
    }, [changeFlowStep]);

    return isSubmitting ? (
        <InlineLoader />
    ) : (
        <Container>
            <P centered size="large">
                Are you sure you want to forward this request to trusted provider to discharge
                patient?
            </P>
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" onClick={handleSubmit}>
                    Confirm
                </Button>
                <Button width="large" onClick={handleBack} variant="secondary">
                    Dismiss
                </Button>
            </Section>
            {error ? <QueryInlineError centered error={error} /> : null}
        </Container>
    );
};
