import React from 'react';
import TabBar from './TabBar';
import Section from '@hero/ui-kit/layout/Section';
import UserHeader from './UserHeader';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    userDeviceDetailsSelector,
    areUserDeviceDetailsLoadingSelector,
    userDetailsEnabledFeaturesSelector
} from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import {
    isUserSetBlockedStatusLoadingSelector,
    isUserSetBlockedStatusUpdatedSelector,
    userSetBlockedStatusErrorSelector
} from '@hero/redux-data/backoffice/userSetBlockedStatus/selectors';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import { userSetEnabledFeatures } from '@hero/redux-data/backoffice/userSetEnabledFeatures/actionCreators';
import { userSetEnabledFeaturesErrorSelector } from '@hero/redux-data/backoffice/userSetEnabledFeatures/selectors';
import YesNoOptions from '../../../components/YesNoOptions';
import Modal from '@hero/ui-kit/components/Modal';
import {
    userResendActivationCode,
    userResendActivationCodeReset
} from '@hero/redux-data/backoffice/userResendActivationCode/actionCreators';
import A from '@hero/ui-kit/typography/A';
import InlineLoader from '../../../components/InlineLoader';
import {
    setUserBlockedStatus,
    userSetBlockedStatusReset
} from '@hero/redux-data/backoffice/userSetBlockedStatus/actionCreators';
import { getUserDeviceDetails } from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';
import {
    isUserResendActivationCodeLoadingSelector,
    isUserResendActivationCodeUpdatedSelector,
    userResendActivationCodeErrorSelector
} from '@hero/redux-data/backoffice/userResendActivationCode/selectors';
import useTheme from '@hero/branding/theme';
import EmailChange from './EmailChange';
import DefaultLayout from '../../../components/DefaultLayout';

const Feature: React.FC<{
    name: string;
    value: boolean;
    onClick?: (name: string, value: boolean) => void;
}> = ({ name, value, onClick }) => {
    const handleClick = React.useCallback(
        (newValue: boolean) => {
            onClick && onClick(name, newValue);
        },
        [name, onClick]
    );

    return (
        <Container gridTemplateColumns="2fr 7fr">
            <P strong style={{ textTransform: 'uppercase' }}>
                {name.replace('_', ' ')}
            </P>
            <YesNoOptions handleToggle={handleClick} predicate={value} />
        </Container>
    );
};

const UserDetails: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const data = useSelector(userDeviceDetailsSelector, shallowEqual);
    const isUserLoading = useSelector(areUserDeviceDetailsLoadingSelector, shallowEqual);
    const enabledFeatures = useSelector(userDetailsEnabledFeaturesSelector, shallowEqual);
    const isUserSetBlockedStatusLoading = useSelector(
        isUserSetBlockedStatusLoadingSelector,
        shallowEqual
    );
    const isUserSetBlockedStatusUpdated = useSelector(
        isUserSetBlockedStatusUpdatedSelector,
        shallowEqual
    );
    const { error: isUserSetBlockedStatusError, errorMessage: userSetBlockedStatusErrorMessage } =
        useSelector(userSetBlockedStatusErrorSelector, shallowEqual);
    const isUserResendActivationCodeLoading = useSelector(
        isUserResendActivationCodeLoadingSelector,
        shallowEqual
    );
    const isUserResendActivationCodeUpdated = useSelector(
        isUserResendActivationCodeUpdatedSelector,
        shallowEqual
    );
    const {
        error: isUserResendActivationCodeError,
        errorMessage: userResendActivationCodeErrorMessage
    } = useSelector(userResendActivationCodeErrorSelector, shallowEqual);
    const {
        error: isUserSetEnabledFeaturesError,
        errorMessage: userSetEnabledFeaturesErrorMessage
    } = useSelector(userSetEnabledFeaturesErrorSelector, shallowEqual);

    const { user, device, role_change_options } = data ?? {};

    const userRole = React.useMemo(() => {
        const isOwner = user?.is_owner;
        const isReadOnlyOwner = user?.is_read_only_owner;
        const caregivers = user?.caregiver_accounts || [];
        const userId = user?.id;

        const caregiver = caregivers.find((item) => item.caregiver_user_id === userId);

        let role = '';

        if (isOwner) {
            role = `Primary User ${isReadOnlyOwner ? 'Non-Admin' : 'Admin'}`;
        }
        if (caregiver) {
            role = `${role}${role.length ? ' and ' : ''}Caregiver ${
                caregiver.is_read_only ? 'Non-Admin' : 'Admin'
            }`;
        }

        return role;
    }, [user]);

    const deviceStatus = React.useMemo(() => {
        const date = new Date();
        let currentDate =
            date.getFullYear() +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            ('0' + date.getDate()).slice(-2) +
            ('0' + date.getHours()).slice(-2) +
            ('0' + date.getMinutes()).slice(-2);

        let lastLogin = user?.last_access_time;

        let _lastLogin = lastLogin ? lastLogin.replace(/\D/g, '').substring(0, 12) : 0;

        return +currentDate - +_lastLogin < 15 ? 'Online' : 'Offline';
    }, [user]);

    const enabledFeaturesPrepared = React.useMemo(() => {
        return Object.entries(enabledFeatures || {});
    }, [enabledFeatures]);

    const handleEnableFeatures = React.useCallback(
        (name: string, value: boolean) => {
            user?.id &&
                user?.enabled_features &&
                dispatch(
                    userSetEnabledFeatures({
                        enabled_features: { ...user.enabled_features, [name]: value },
                        id: user.id
                    })
                );
        },
        [user, dispatch]
    );

    const [showResendModal, setShowResendModal] = React.useState(false);

    const handleCloseResendModal = React.useCallback(() => {
        setShowResendModal(false);
        dispatch(userResendActivationCodeReset());
    }, [dispatch]);

    const handleOpenResendModal = React.useCallback(() => {
        setShowResendModal(true);
    }, []);

    const [showUnblockModal, setShowUnblockModal] = React.useState(false);

    const handleCloseUnblockModal = React.useCallback(() => {
        setShowUnblockModal(false);
        dispatch(userSetBlockedStatusReset());
        user?.id && dispatch(getUserDeviceDetails({ user_id: user.id }));
    }, [dispatch, user]);

    const handleOpenUnblockModal = React.useCallback(() => {
        setShowUnblockModal(true);
    }, []);

    const handleResendActivationCode = React.useCallback(() => {
        user?.id && dispatch(userResendActivationCode({ id: user.id }));
    }, [user, dispatch]);

    const handleUnblockAccount = React.useCallback(() => {
        user?.id && dispatch(setUserBlockedStatus({ id: user.id, blocked_status: 0 as const }));
    }, [user, dispatch]);

    const isUserBlocked = React.useMemo(() => {
        return [1, 2].includes(user?.blocked_status || 0);
    }, [user]);

    const isLoading = isUserLoading;

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <UserHeader />
                <TabBar />
                {isLoading ? (
                    <InlineLoader />
                ) : (
                    <Section subtheme={['regular', 'background']} paddingVertical="regular">
                        <>
                            {isUserBlocked ? (
                                <Section
                                    subtheme={['status', 'warn', 'alpha']}
                                    paddingVertical="regular"
                                    marginBottom="regular"
                                    centered
                                >
                                    <H role="h6">THIS ACCOUNT HAS BEEN LOCKED OUT</H>
                                    <Button onClick={handleOpenUnblockModal}>
                                        Unblock Account
                                    </Button>
                                </Section>
                            ) : null}
                            <Container gridTemplateColumns="2fr 7fr">
                                <P strong>RESEND CONFIRMATION EMAIL</P>
                                <div>
                                    <Button
                                        size="small"
                                        styles={{ margin: 0 }}
                                        onClick={handleOpenResendModal}
                                    >
                                        Resend
                                    </Button>
                                </div>
                                <P strong>TYPE (ROLE)</P>
                                <P>{userRole}</P>
                                <P strong>FIRST NAME</P>
                                <P>{user?.first_name || '-'}</P>
                                <P strong>LAST NAME</P>
                                <P>{user?.last_name || '-'}</P>
                                {!user?.activated &&
                                role_change_options?.primary_user_is_placeholder ? (
                                    <>
                                        <P strong styles={{ color: 'red' }}>
                                            NOTE
                                        </P>
                                        <P strong styles={{ color: 'red' }}>
                                            THIS E-MAIL IS A PLACEHOLDER
                                        </P>
                                    </>
                                ) : null}
                                <P strong>EMAIL</P>
                                <EmailChange
                                    id={user?.id}
                                    email={user?.email}
                                    firstName={user?.first_name}
                                    lastName={user?.last_name}
                                />
                                <P strong>PHONE NUMBER</P>
                                <P>{user?.mobile || '-'}</P>
                                <P strong>ID</P>
                                <P>{user?.id || '-'}</P>
                                <P strong>ACTIVATED</P>
                                <P>{user?.activated || '-'}</P>
                                <P strong>MEMBERSHIP</P>
                                <P>
                                    {user?.id ? (
                                        <A
                                            to={`/membership/details?user_id=${user.id}${
                                                device?.external_serial
                                                    ? `&serial=${device.external_serial}`
                                                    : ''
                                            }`}
                                        >
                                            view details
                                        </A>
                                    ) : (
                                        '-'
                                    )}
                                </P>
                                <P strong>DEVICE</P>
                                <P>
                                    {device?.id ? (
                                        <A to={`/devices/${device.id}`}>{device?.serial || '-'}</A>
                                    ) : (
                                        device?.serial || '-'
                                    )}
                                </P>
                                <P strong>DEVICE STATUS</P>
                                <P>{deviceStatus}</P>
                                <P strong>ZENDESK CODE</P>
                                <P>{user?.zendesk_code || '-'}</P>
                            </Container>
                            <div>
                                {enabledFeaturesPrepared.map(([name, value]) => (
                                    <Feature
                                        key={`feature-${name}`}
                                        name={name}
                                        value={value}
                                        onClick={handleEnableFeatures}
                                    />
                                ))}
                                {isUserSetEnabledFeaturesError ? (
                                    <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                                        {userSetEnabledFeaturesErrorMessage}
                                    </P>
                                ) : null}
                            </div>
                            <Container gridTemplateColumns="2fr 7fr">
                                <P strong>DETAILS</P>
                                <P>
                                    <A to={`/patients/${user?.id}`}>View account</A>
                                </P>
                            </Container>
                        </>
                    </Section>
                )}
            </Section>
            {showResendModal ? (
                <Modal onClose={handleCloseResendModal} isCancelable>
                    <H role="h4">Resend confirmation email to user</H>
                    <P>{user?.email || '-'}</P>
                    <Button
                        disabled={isUserResendActivationCodeLoading}
                        onClick={handleResendActivationCode}
                    >
                        Resend
                    </Button>
                    {isUserResendActivationCodeUpdated && !isUserResendActivationCodeError ? (
                        <P>Email confirmation was sent successfully</P>
                    ) : null}
                    {isUserResendActivationCodeError ? (
                        <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                            {userResendActivationCodeErrorMessage}
                        </P>
                    ) : null}
                </Modal>
            ) : null}
            {showUnblockModal ? (
                <Modal onClose={handleCloseUnblockModal} isCancelable>
                    <H centered role="h6">{`Are you sure you want to unblock ${
                        user?.email || '-'
                    }?`}</H>
                    <Button disabled={isUserSetBlockedStatusLoading} onClick={handleUnblockAccount}>
                        Unblock
                    </Button>
                    {isUserSetBlockedStatusUpdated && !isUserSetBlockedStatusError ? (
                        <P>User unblocked successfully</P>
                    ) : null}
                    {isUserSetBlockedStatusError ? (
                        <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                            {userSetBlockedStatusErrorMessage}
                        </P>
                    ) : null}
                </Modal>
            ) : null}
        </DefaultLayout>
    );
};

export default UserDetails;
