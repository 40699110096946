import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import H from '@hero/ui-kit/typography/H';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import { DischargedQueueMemberDataObject } from './utils/repackTableData';
import useDischargedQueueMemberListData from './useDischargedQueueMemberListData';
import { Organization } from '@hero/hero-types';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationList } from '@hero/redux-data/backoffice/oldOrganizationList/actionCreators';
import { organizationListSelector } from '@hero/redux-data/backoffice/oldOrganizationList/selectors';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import dischargedQueueMemberFilterSchema from './utils/schema';
import Form from '@hero/ui-kit/inputs/Form';
import { SaveNoteParams } from './MemberActions/Note/schema';
import SaveNoteModal from './MemberActions/Note/SaveNoteModal';
import useCancellationRequestUpdate from './api/useCancellationRequestUpdate';
import DischargedQueueFilter from './filter';
import { useSearchParams } from 'react-router-dom';
import DefaultLayout from '../../../components/DefaultLayout';
import InlineLoader from '../../../components/InlineLoader';

interface DischargedQueueMemberListTableParams {
    organizations?: Organization[];
}

const DischargedQueueMemberListTable: React.FC<DischargedQueueMemberListTableParams> = ({
    organizations = []
}) => {
    const [showAddNoteModal, setShowAddNoteModal] = React.useState(false);
    const [userId, setUserId] = React.useState<number | undefined>();
    const { mutate, isPending, isSuccess, error } = useCancellationRequestUpdate();
    const [searchParams] = useSearchParams();

    const status = React.useMemo(() => {
        return searchParams.get('status') || undefined;
    }, [searchParams]);

    const defaultOrg = React.useMemo(() => {
        return organizations.find((org) => org.name.toLowerCase() === 'assure');
    }, [organizations]);

    const [organizationId, setOrganizationId] = React.useState<string | undefined>(
        defaultOrg ? `${defaultOrg.id}` : undefined
    );

    const openNoteModal = (id: number) => {
        setUserId(id);
        setShowAddNoteModal(true);
    };

    const { tableColumns, tableData, isFetchingMembers, sort, handleSort, pagerProps } =
        useDischargedQueueMemberListData(
            organizationId ? +organizationId : 1,
            openNoteModal,
            status
        );

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'small' });

    const handleOrgIdChange = (organization_id: string) => {
        setOrganizationId(organization_id);
    };

    const handleAddNote = (attributes: SaveNoteParams) => {
        const member = tableData.find((member) => member.id === userId);
        if (member) {
            mutate({
                comment: attributes.note,
                memberCancellationId: member.membership_cancellation_id
            });
        }
    };

    const member = React.useMemo(() => {
        return tableData.find((member) => member.id === userId);
    }, [userId, tableData]);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h5">{'Discharged Member List'}</H>
                <Form
                    validationSchema={dischargedQueueMemberFilterSchema}
                    defaultValues={{ organization_id: organizationId }}
                    submitFn={() => null}
                >
                    <Select
                        id="org-select"
                        name="organization_id"
                        displayName="Organizations"
                        onChange={handleOrgIdChange}
                        defaultValue={organizationId ? `${organizationId}` : undefined}
                        styles={{ width: '40rem' }}
                    >
                        {organizations.map((org) => (
                            <Option key={org.id} value={`${org.id}`} labelText={org.name} />
                        ))}
                    </Select>
                </Form>
                <DischargedQueueFilter />
                <div style={innerWrapPadding}>
                    <div
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <Table<DischargedQueueMemberDataObject>
                            columns={tableColumns}
                            data={tableData}
                            rowKey="membership_cancellation_id"
                            emptyValues="-"
                            isLoading={isFetchingMembers}
                            sortColumn={sort.sort_by}
                            sortDirection={sort.sort_dir}
                            onColumnSort={handleSort}
                            pagerProps={pagerProps}
                        />
                    </div>
                </div>
            </Section>
            <SaveNoteModal
                externalControls={[showAddNoteModal, setShowAddNoteModal]}
                onSubmit={handleAddNote}
                onClose={() => setShowAddNoteModal(false)}
                description={member ? `${member.name} ${member.email}` : ''}
                note={member?.note || ''}
                error={error}
                isLoading={isPending}
                isSuccess={isSuccess}
            />
        </DefaultLayout>
    );
};

const DischargedQueueMemberList: React.FC = () => {
    const dispatch = useDispatch();
    const organizationList = useSelector(organizationListSelector);

    React.useEffect(() => {
        organizationList.length === 0 &&
            dispatch(
                getOrganizationList({
                    limit: 1000
                })
            );
    }, [dispatch, organizationList]);

    return organizationList.length === 0 ? (
        <InlineLoader />
    ) : (
        <DischargedQueueMemberListTable organizations={organizationList} />
    );
};

export default DischargedQueueMemberList;
