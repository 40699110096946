import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import MedsErrorIllustration from '@hero/ui-kit/graphics/Illustrations/MedsErrorIllustration';

interface Props {
    search: string;
    searchSupportsLabel?: string;
    hideSearchLabel?: boolean;
}

const NoResults: React.FC<Props> = ({
    search,
    searchSupportsLabel = 'Email, Serial #, and External ID',
    hideSearchLabel = false
}) => {
    return (
        <Section centered>
            {!hideSearchLabel ? (
                <P
                    centered
                    strong
                    noDefaultMargin
                    styles={{ fontSize: '2.4rem', paddingBottom: '1.2rem' }}
                >
                    Sorry, we were not able to find any match for {`"${search}"`}
                </P>
            ) : null}

            <P centered noDefaultMargin styles={{ paddingBottom: '3rem' }}>
                Remember search supports:
                <strong> {searchSupportsLabel}</strong>
            </P>
            <MedsErrorIllustration styles={{ width: '48rem' }} />
        </Section>
    );
};

export default NoResults;
