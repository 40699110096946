import React, { CSSProperties, useMemo } from 'react';

import useTheme from '@hero/branding/theme';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

type Props = {
    step: number;
    label: string;
    isCurrent?: boolean;
    inverted?: boolean;
};

const Breadcrumb: React.FC<Props> = ({ step, label, isCurrent = false, inverted = false }) => {
    const { borders, colors, typography } = useTheme();

    const wrapMargin = useComponentMargin({ margin: 'small' });

    const wrapStyles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '8rem',
        ...wrapMargin
    };

    const borderStyle = useMemo(() => {
        if (inverted) {
            return borders.variant.inverted;
        }
        if (isCurrent) {
            return { borderColor: colors.brand.primary.beta };
        }
        return borders.variant.secondary;
    }, [borders, inverted, isCurrent]);

    const circleStyles: CSSProperties = {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '3.6rem',
        height: '3.6rem',
        marginBottom: '0.4rem',
        ...borders.base,
        ...borders.shape.pill,
        ...borderStyle,
        ...(inverted && isCurrent && { borderColor: colors.neutrals.textAlpha }),
        ...typography.paragraph.regular,
        ...typography.strong,
        backgroundColor: colors.neutrals.background,
        ...(isCurrent && {
            backgroundColor: inverted ? colors.neutrals.textAlpha : colors.brand.primary.beta,
            color: inverted ? colors.neutrals.background : colors.neutrals.textAlpha
        })
    };

    return (
        <li style={wrapStyles}>
            <div style={circleStyles}>{step}</div>
            <Eyebrow centered type={inverted ? 'inverted' : 'regular'}>
                {label}
            </Eyebrow>
        </li>
    );
};

export default Breadcrumb;
