const getStatusLabel = (status) => {
    switch (status) {
        case 'not-activated':
            return 'Not Activated Yet';
        case 'active':
            return 'Active';
        case 'prepaid-expired':
            return 'Prepaid Expired';
        case 'payment-failed':
            return 'Payment Failed';
        case 'locked':
            return 'Locked';
        case 'cancelled':
            return 'Cancelled';
        default:
            return '-';
    }
};
export default getStatusLabel;
