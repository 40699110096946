import React, { FC, useEffect, ReactElement, JSXElementConstructor } from 'react';

import useGetRmaDetails from '../api/useGetRmaDetails';
import useGetEnterpriseMemberDetails from '../../Organizations/api/useGetEnterpriseMemberDetails';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import useGetMembershipDetails from '../../Membership/api/useGetMembershipDetails';
import useGetRmaEnums from '../api/useGetRmaEnums';
import useGetCancellationEnterpriseMembershipDetails from '../../Organizations/api/useGetCancellationEnterpriseMembershipDetails';
import useGetCancellationDetails from '../../Membership/api/useGetCancellationDetails';
import useGetCancellationOptions from '../../Membership/api/useGetCancellationOptions';
import useGetEnterpriseCancellationOptions from '../../Organizations/api/useGetEnterpriseCancellationOptions';

type RmaDetailsDataProviderV2Props = {
    rmaId: number;
    enrollmentId?: number;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    isOrganizationMember?: boolean;
    hasUserDeviceDetails?: boolean;
};

const RmaDetailsDataProviderV2: FC<RmaDetailsDataProviderV2Props> = ({
    rmaId,
    children,
    isOrganizationMember = false,
    hasUserDeviceDetails = false,
    enrollmentId
}) => {
    const {
        data: rmaDetails,
        isFetching: isRmaDetailsQueryLoading,
        isFetching,
        refetch: refetchRmaDetails
    } = useGetRmaDetails({ id: rmaId });

    const rma = rmaDetails?.data;

    const { device_serial, order_number, order_source } = rma ?? {};

    const {
        data: membershipResponse,
        isFetching: isLoadingMembershipDetails,
        isError: isMembershipError
    } = useGetMembershipDetails(
        { device_serial: device_serial ? device_serial : '' },
        !!device_serial
    );

    const membership = device_serial ? membershipResponse?.data?.data : undefined;

    const hasMembershipDetails = !!membership?.id && !isMembershipError;

    // order_number is member id in enrollment member details, order source === 5 means that RMA is for org member
    const canFetchEnterpriseMemberDetails = enrollmentId
        ? !isLoadingMembershipDetails && !hasMembershipDetails
        : !isLoadingMembershipDetails &&
          !hasMembershipDetails &&
          !!order_number &&
          order_source === 5;

    const {
        data: enterpriseMemberResponse,
        isFetching: isLoadingEnterpriseMemberDetails,
        isError: isEnterpriseMemberError
    } = useGetEnterpriseMemberDetails(
        {
            ...(!enrollmentId && { member_id: order_number ? +order_number : 0 }),
            ...(enrollmentId && { id: enrollmentId })
        },
        canFetchEnterpriseMemberDetails
    );

    const enterpriseMember = canFetchEnterpriseMemberDetails
        ? enterpriseMemberResponse?.data
        : undefined;

    const {
        mutate: getUserDeviceDetails,
        data: userDetailsResponse,
        isPending: isLoadingUserDeviceDetails
    } = useGetUserDeviceDetails();

    const userDetails = userDetailsResponse?.data;

    useEffect(() => {
        if (!hasUserDeviceDetails) {
            if (membership?.hero_user_id) {
                getUserDeviceDetails({ user_id: +membership.hero_user_id });
            } else if (!membership?.hero_user_id && membership?.serial) {
                getUserDeviceDetails({ external_serial: membership.serial });
            }
        }
    }, [membership, hasUserDeviceDetails]);

    const { data: rmaEnumsResponse, isLoading: isRmaEnumsLoading } = useGetRmaEnums(true);

    const rmaEnums = rmaEnumsResponse?.data;

    const hasMembershipId = membership && !isMembershipError ? membership.id > 0 : false;

    const { data: cancellationDetailsResponse, isFetching: isCancellationDetailsLoading } =
        useGetCancellationDetails(
            { id: membership?.id },
            hasMembershipId && membership?.status === 'cancelled' && !isLoadingMembershipDetails
        );

    const hasEnrollmentId =
        canFetchEnterpriseMemberDetails && enterpriseMember && !isEnterpriseMemberError
            ? enterpriseMember.id > 0
            : false;
    const isEnrollmentCancelled = enterpriseMember?.member?.status === 'CANCELLED';

    const { data: orgCancelDetailsResponse, isFetching: isFetchingCancelDetails } =
        useGetCancellationEnterpriseMembershipDetails(
            { id: enterpriseMember?.id || 0 },
            hasEnrollmentId && isEnrollmentCancelled && !isLoadingEnterpriseMemberDetails
        );

    const orgCancellationDetails = hasEnrollmentId ? orgCancelDetailsResponse?.data : undefined;
    const cancellationDetails = hasMembershipId
        ? cancellationDetailsResponse?.data.data
        : undefined;

    const { data: cancellationOptionsResponse, isFetching: isCancellationOptionsLoading } =
        useGetCancellationOptions(
            { id: membership?.id },
            hasMembershipId && membership?.status === 'active' && !isLoadingMembershipDetails
        );

    const cancellationOptions = hasMembershipId
        ? cancellationOptionsResponse?.data.data
        : undefined;

    const { data: orgCancellationOptionsResponse, isFetching: isOrgCancellationOptionsLoading } =
        useGetEnterpriseCancellationOptions(
            { id: enterpriseMember?.id || 0 },
            hasEnrollmentId && !isEnrollmentCancelled && !isLoadingEnterpriseMemberDetails
        );

    const orgCancellationOptions = hasEnrollmentId
        ? orgCancellationOptionsResponse?.data
        : undefined;

    const refetchRmaDetailsData = () => {
        refetchRmaDetails();
    };

    const isFetchingRmaDetails = isRmaDetailsQueryLoading && isFetching;

    const isLoading =
        isFetchingRmaDetails ||
        isLoadingMembershipDetails ||
        isLoadingEnterpriseMemberDetails ||
        isRmaEnumsLoading ||
        isLoadingUserDeviceDetails ||
        isFetchingCancelDetails ||
        isCancellationDetailsLoading ||
        isCancellationOptionsLoading ||
        isOrgCancellationOptionsLoading;

    return React.cloneElement(children, {
        isLoading,
        deviceSerial: device_serial,
        isOrganizationMember: !!enterpriseMember?.id || isOrganizationMember,
        rma,
        refetchRmaDetailsData,
        membership,
        enterpriseEnrollment: enterpriseMember,
        userDetails,
        rmaEnums,
        orgCancellationDetails,
        cancellationDetails,
        cancellationOptions,
        orgCancellationOptions
    });
};

export default RmaDetailsDataProviderV2;
