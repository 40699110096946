import React from 'react';

import useToggle from '@hero/ui-kit/hooks/useToggle';
import { DeviceDetailsResponse } from '@hero/hero-types';
import Button from '@hero/ui-kit/inputs/Button';
import A from '@hero/ui-kit/typography/A';

import { DetailLabel, Detail } from '../../../../components/Details';

import useDeviceDetailsStyles from '../useDeviceDetailsStyles';
import ActivateMembershipModal from './ActivateMembershipModal';

type Props = {
    isBackofficeAdmin: boolean;
    deviceDetails: DeviceDetailsResponse['device'];
    userDetails: DeviceDetailsResponse['user'];
};

const MemebershipDetails: React.FC<Props> = ({ isBackofficeAdmin, deviceDetails, userDetails }) => {
    const [showModal, setShowModalOn, setShowModalOff] = useToggle();
    const { smallButtonMargin } = useDeviceDetailsStyles();

    const to = React.useMemo(() => {
        const path = '/membership/details';

        if (deviceDetails?.external_serial && !userDetails?.id) {
            return `${path}?serial=${deviceDetails.external_serial}`;
        }

        if (!deviceDetails?.external_serial && userDetails?.id) {
            return `${path}?user_id=${userDetails.id}`;
        }

        if (deviceDetails?.external_serial && userDetails?.id) {
            return `${path}?serial=${deviceDetails.external_serial}&user_id=${userDetails.id}`;
        }

        return path;
    }, [userDetails, deviceDetails]);

    return (
        <>
            {showModal && (
                <ActivateMembershipModal
                    external_serial={deviceDetails.external_serial || ''}
                    hero_user_id={deviceDetails.hero_user_id}
                    handleClose={setShowModalOff}
                />
            )}
            <DetailLabel>Membership</DetailLabel>
            <Detail>
                {deviceDetails.serial ? <A to={to}>view details</A> : '-'}
                {isBackofficeAdmin && (
                    <Button
                        size="small"
                        onClick={setShowModalOn}
                        disabled={!deviceDetails.external_serial}
                        styles={smallButtonMargin}
                    >
                        Activate Membership
                    </Button>
                )}
            </Detail>
        </>
    );
};

export default MemebershipDetails;
