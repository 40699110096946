import axios from 'axios';

import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';

type Payload = {
    user_id: number;
    is_caregiver: boolean;
    insurance_type: string;
    insurance_policy_id?: string;
    insurance_carrier_by_user?: string;
    medigap_insurance_name?: string;
    medigap_insurance_policy_id?: string;
};

export type Response = {
    data: {
        lead_id: string;
        providers: {
            provider_id: string;
            provider_name: string;
            provider_description: string;
            provider_url: string;
            provider_logo: string;
            provider_app_logo_light: string;
            provider_app_logo_dark: string;
            signature_flow: boolean;
            election_survey: string;
        }[];
    };
};

const useElectionSubmitLead = () => {
    const params = useQueryRequestParams();

    return (payload: Payload) => {
        return axios.post<Response>(
            `${envVars.API_CLOUD_HOSTNAME}backoffice/election-submit-lead/`,
            payload,
            params
        );
    };
};

export default useElectionSubmitLead;
