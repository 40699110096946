import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isMembershipAlertUpdatedSelector, membershipAlertSelector } from '@hero/redux-data/backoffice/membershipAlert/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipAlertSaveSuccessAlert = () => {
    const isAlertSaved = useSelector(isMembershipAlertUpdatedSelector);
    const alert = useSelector(membershipAlertSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isAlertSaved) {
            id.current = addNotification({
                type: 'success',
                message: alert
                    ? 'Changes have been saved.'
                    : 'Alert has been dismissed and outreach recorded.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isAlertSaved, alert]);
};
export default useMembershipAlertSaveSuccessAlert;
