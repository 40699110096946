import { useEffect } from 'react';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import { useSelector } from 'react-redux';

import {
    arePlaceDetailsUpdatedSelector,
    streetAddressSelector,
    citySelector,
    stateCodeSelector,
    zipCodeSelector
} from '@hero/redux-data/googlePlaceDetails/selectors';

import usePrevious from '@hero/react-hooks/usePrevious';

type Props = {
    isPrepopulated: boolean;
};

const IsolateEffects: React.FC<Props> = ({ isPrepopulated }) => {
    const { setValue, reset } = useFormContext();
    const isAddressFetched = useSelector(arePlaceDetailsUpdatedSelector);
    const streetAddress = useSelector(streetAddressSelector);
    const city = useSelector(citySelector);
    const state = useSelector(stateCodeSelector);
    const zip = useSelector(zipCodeSelector);
    const wasAddressFetched = usePrevious(isAddressFetched);

    // make sure form actually gets cleared if it receives empty default values
    useEffect(() => {
        !isPrepopulated && reset({ confirmation_channel: 'customer_email' });
    }, [isPrepopulated, reset]);

    useEffect(() => {
        if (!wasAddressFetched && isAddressFetched) {
            streetAddress &&
                setValue('address_line_1', streetAddress, {
                    shouldValidate: true,
                    shouldDirty: true
                });
            city && setValue('city', city, { shouldValidate: true, shouldDirty: true });
            state && setValue('state', state, { shouldValidate: true, shouldDirty: true });
            zip && setValue('zip', zip, { shouldValidate: true, shouldDirty: true });
        }
    }, [city, isAddressFetched, setValue, state, streetAddress, wasAddressFetched, zip]);
    return null;
};

export default IsolateEffects;
