import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import DeviceDetailsPage from './DeviceDetails';

import HazardousDrugs from './RefurbishmentDetails/HazardousDrugs';
import DevicesList from './DevicesList';
import NotImplemented from './RefurbishmentDetails/NotImplemented';
import ChangeRole from './DeviceDetails/ChangeRole';

const RedirectToDetails = () => {
    const { device_id } = useParams<{ device_id: string }>();

    return <Navigate to={`/devices/${device_id}/details`} />;
};

const DeviceRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<DevicesList />} />
            <Route path=":device_id/hazardous-drugs" element={<HazardousDrugs />} />
            <Route path=":device_id/refurbishment" element={<HazardousDrugs />} />
            <Route path=":device_id/change-role" element={<ChangeRole />} />
            <Route path=":device_id/restocking" element={<NotImplemented />} />
            <Route path=":device_id/reprocessing" element={<NotImplemented />} />
            <Route path=":device_id" element={<RedirectToDetails />} />
            <Route path=":device_id/details" element={<DeviceDetailsPage />} />
            <Route path="serial/:serial" element={<DeviceDetailsPage />} />
        </Routes>
    );
};

export default DeviceRoutes;
