import React, { FC, CSSProperties, useMemo, useCallback } from 'react';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';
import ModalFlowHeader from '../../components/ModalFlowHeader';
import { useExchangeContext } from './context/ExchangeProvider';
import * as ExchangeStepComponents from './steps';
import InlineLoader from '../../components/InlineLoader';

type ExchangeStepsModalProps = { isLoading?: boolean } & Pick<ModalProps, 'externalControls'>;

const ExchangeStepsModal: FC<ExchangeStepsModalProps> = ({
    externalControls,
    isLoading = false
}) => {
    const { exchangeStep, changeExchangeStep, onCompleteCallback, onCloseModal, eyebrow } =
        useExchangeContext();
    const [, setExternalState] = externalControls ?? [];

    const modalType: ModalProps['type'] = useMemo(() => {
        if (exchangeStep === 'NewDeviceConfirm') {
            return 'warn';
        }
        if (exchangeStep === 'Success') {
            return 'success';
        }
        return 'neutral';
    }, [exchangeStep]);

    const currentStep = useMemo(() => {
        if (exchangeStep === 'NewDevice' || exchangeStep === 'NewDeviceConfirm') {
            return 2;
        }
        if (exchangeStep === 'OldDevice') {
            return 3;
        }
        return 1;
    }, [exchangeStep]);

    const steps = useMemo(() => {
        if (exchangeStep === 'Success') {
            return undefined;
        }
        return ['Details', 'New Device', 'Old Device'];
    }, [exchangeStep]);

    const heading = useMemo(() => {
        if (exchangeStep === 'Success') {
            return 'Exchange Created';
        }
        return 'Exchange';
    }, [exchangeStep]);

    const modalPadding = useSectionPadding({ padding: 'regular' });
    const innerWrapPadding = useComponentPadding({ padding: 'regular' });

    const modalStyles: CSSProperties = {
        width: '100rem',
        ...modalPadding
    };

    const innerWrapStyles: CSSProperties = {
        alignSelf: 'stretch',
        ...innerWrapPadding
    };

    const ExchangeStepComponent = useMemo(() => {
        return ExchangeStepComponents[exchangeStep];
    }, [exchangeStep]);

    const closeExchangeModal = useCallback(() => {
        changeExchangeStep('Details');
        onCloseModal && onCloseModal();
        typeof setExternalState === 'function' && setExternalState(false);
        if (exchangeStep === 'Success') {
            onCompleteCallback && onCompleteCallback();
        }
    }, [setExternalState, changeExchangeStep, onCompleteCallback, exchangeStep]);

    return (
        <Modal
            width="page"
            externalControls={externalControls}
            type={modalType}
            styles={modalStyles}
            noDefaultPadding
            isCancelable
            onClose={closeExchangeModal}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <ModalFlowHeader
                        heading={heading}
                        eyebrow={eyebrow}
                        steps={steps}
                        currentStep={currentStep}
                    />
                    <div style={innerWrapStyles}>
                        <ExchangeStepComponent closeExchangeModal={closeExchangeModal} />
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ExchangeStepsModal;
