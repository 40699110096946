import React, { useMemo } from 'react';

import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';

import InlineLoader from '../../components/InlineLoader';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import TransactionReview from './TransactionReview';
import useGetCancellationFeePreview from '../../pages/Membership/api/useGetCancellationFeePreview';
import useGetRefundPreview from '../../pages/Membership/api/useGetRefundPreview';
import QueryInlineError from '../../components/QueryInlineError';

const StepReview: React.FC = () => {
    const {
        isOrganizationMember,
        cancellationType,
        handleReviewConfirm,
        membership,
        enrollment,
        isCancelling,
        cancellingError
    } = useCancellationStateMachine();

    const id = isOrganizationMember ? enrollment?.id : membership?.id;

    const transactionType = cancellationType?.transaction;

    const fetchCancellationFeePreview = ['charge', 'waive'].includes(transactionType || '');
    const fetchRefundFeePreview = transactionType === 'refund';

    const {
        data: cancellationFeeResponse,
        isLoading: isCancellationFeeLoading,
        error: cancellationFeeError
    } = useGetCancellationFeePreview(
        { id, cancellation_type: cancellationType?.code },
        fetchCancellationFeePreview
    );

    const {
        data: refundFeeResponse,
        isLoading: isRefundFeeLoading,
        error: refundFeeError
    } = useGetRefundPreview({ id }, fetchRefundFeePreview);

    const feePreview = cancellationFeeResponse?.data.data;
    const refundPreview = refundFeeResponse?.data.data;

    const isLoading = isCancellationFeeLoading || isRefundFeeLoading || isCancelling;

    const buttonLabel = useMemo(() => {
        if (transactionType === 'refund') {
            return 'Confirm Refund';
        }
        if (transactionType === 'charge') {
            return 'Confirm Charge';
        }
        return 'Confirm Cancel';
    }, [transactionType]);

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Section centered noDefaultPadding>
            <TransactionReview
                isOrganizationMember={isOrganizationMember}
                cancellationType={cancellationType}
                refundPreview={refundPreview || null}
                feePreview={feePreview || null}
                transactionType={transactionType}
            />
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" variant="inverted" onClick={handleReviewConfirm}>
                    {buttonLabel}
                </Button>
            </Section>
            {refundFeeError ? <QueryInlineError centered error={refundFeeError} /> : null}
            {cancellationFeeError ? (
                <QueryInlineError centered error={cancellationFeeError} />
            ) : null}
            {cancellingError ? <QueryInlineError centered error={cancellingError} /> : null}
        </Section>
    );
};

export default StepReview;
