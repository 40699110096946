import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';

import Wrapper from './Wrapper';

const NotImplemented: React.FC = () => (
    <Wrapper>
        <Section paddingHorizontal="none">
            <P>Not implemented yet...</P>
        </Section>
    </Wrapper>
);

export default NotImplemented;
