import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { userDevice } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetUserDeviceDetails = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_CLOUD_HOSTNAME}backoffice/details/`, { ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [userDevice] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useGetUserDeviceDetails;
