import React, { CSSProperties } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

import Breadcrumb from './Breadcrumb';

type Props = {
    steps: string[];
    currentStep?: number; // 1-based index
    inverted?: boolean;
} & UiComponentProps;

const Breadcrumbs: React.FC<Props> = ({ steps, currentStep, inverted = false, styles }) => {
    const current =
        currentStep && currentStep >= 1 && currentStep <= steps.length ? currentStep : 1;

    const wrapMargin = useComponentMargin({ marginTop: 'small' });

    const mergedStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        ...wrapMargin,
        ...styles
    };

    return steps.length > 0 ? (
        <ol style={mergedStyles}>
            {steps.map((label, idx) => (
                <Breadcrumb
                    key={idx}
                    step={idx + 1}
                    label={label}
                    isCurrent={idx + 1 === current}
                    inverted={inverted}
                />
            ))}
        </ol>
    ) : null;
};

export default Breadcrumbs;
