import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from '../../hooks/useSetQueryParams';
const usePageLimit = (defaultLimit = '10') => {
    const { limit = defaultLimit, ...rest } = getQueryParams();
    const setQueryParams = useSetQueryParams();
    const handleSetPageLimit = (limitFromInput) => {
        const newQueryParams = buildQuery({
            ...rest,
            offset: 0,
            limit: limitFromInput
        });
        setQueryParams(newQueryParams);
    };
    return [+limit, handleSetPageLimit];
};
export default usePageLimit;
