import React from 'react';

import { OrganizationAdherenceAlert } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../components/Link';
import P from '@hero/ui-kit/typography/P';
import ToLocalTime from '../../../utils/toLocalTime';

type Render = TableColumn<OrganizationAdherenceAlert>['render'];

const count = (arr: number[]) => {
    return arr.reduce((acc, item) => acc + item, 0);
};

const nameRender: Render = ({ user, device }) => {
    const value = `${user.email || '-'} SN:${device.serial || '-'}`;
    const to = user?.id ? `/users/${user.id}` : `/devices/${device.id}`;
    return <Link to={to}>{value}</Link>;
};

const timeRender: Render = ({ created }) => {
    return <P noDefaultMargin>{created ? ToLocalTime(created, 'MM/DD HH:mm') : '-'}</P>;
};

const notTaken1dRender: Render = ({ fields }) => {
    const { not_taken_doses_1d_fut, not_taken_doses_1d_in, not_taken_doses_1d_out } = fields;

    const customArray1d = [not_taken_doses_1d_in, not_taken_doses_1d_out, not_taken_doses_1d_fut];

    return <P noDefaultMargin>{customArray1d.join()}</P>;
};

const notTaken7dRender: Render = ({ fields }) => {
    const { not_taken_doses_7d_in, not_taken_doses_7d_out, not_taken_doses_7d_fut } = fields;

    const count7d = count([
        not_taken_doses_7d_fut || 0,
        not_taken_doses_7d_in || 0,
        not_taken_doses_7d_out || 0
    ]);

    return <P noDefaultMargin>{count7d}</P>;
};

const notTaken30dRender: Render = ({ fields }) => {
    const { not_taken_doses_30d_fut, not_taken_doses_30d_in, not_taken_doses_30d_out } = fields;

    const count30d = count([
        not_taken_doses_30d_fut || 0,
        not_taken_doses_30d_in || 0,
        not_taken_doses_30d_out || 0
    ]);

    return <P noDefaultMargin>{count30d}</P>;
};

const adherence7dRender: Render = ({ fields }) => {
    const { adherence_rate_7d } = fields;

    return <P noDefaultMargin>{`${parseFloat(`${adherence_rate_7d}`).toFixed(2)}%`}</P>;
};

const adherence30dRender: Render = ({ fields }) => {
    const { adherence_rate_30d } = fields;

    return <P noDefaultMargin>{`${parseFloat(`${adherence_rate_30d}`).toFixed(2)}%`}</P>;
};

const adherenceOverallRender: Render = ({ fields }) => {
    const { adherence_rate_overall } = fields;

    return <P noDefaultMargin>{`${parseFloat(`${adherence_rate_overall}`).toFixed(2)}%`}</P>;
};

type GetTableColumns = () => TableColumn<OrganizationAdherenceAlert>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'name',
        name: 'Email Serial#',
        align: 'left',
        headAlign: 'left',
        render: nameRender
    },
    {
        colKey: 'time',
        name: 'Time',
        render: timeRender
    },
    {
        colKey: 'not_taken_1d',
        name: 'Not Taken 1d',
        render: notTaken1dRender
    },
    {
        colKey: 'not_taken_7d',
        name: 'Not Taken 7d',
        render: notTaken7dRender
    },
    {
        colKey: 'not_taken_30d',
        name: 'Not Taken 30d',
        render: notTaken30dRender
    },
    {
        colKey: 'adherence_7d',
        name: 'Adherence 7d',
        render: adherence7dRender
    },
    {
        colKey: 'adherence_30d',
        name: 'Adherence 30d',
        render: adherence30dRender
    },
    {
        colKey: 'adherence_overall',
        name: 'Adherence Overall',
        render: adherenceOverallRender
    }
];

export default getTableColumns;
