import React, { CSSProperties } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import Eyebrow from '@hero/ui-kit/typography/Eyebrow';
import P from '@hero/ui-kit/typography/P';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

type Props = {
    label?: string;
    hasAlert?: boolean;
    children?: React.ReactNode;
} & UiComponentProps;

const StaticFormElement: React.FC<Props> = ({ label, hasAlert = false, styles, children }) => {
    const padding = useComponentPadding({ paddingVertical: 'small' });
    const margin = useComponentMargin({ marginTop: 'small' });
    const iconMargin = useComponentMargin({ marginLeft: 'small' });

    const iconStyles: CSSProperties = {
        verticalAlign: 'bottom',
        ...iconMargin
    };

    return (
        <div style={styles}>
            {label && <Eyebrow>{label}</Eyebrow>}
            <P strong styles={{ ...margin, ...padding }}>
                {children}
                {hasAlert && <AlertIcon size="utility" styles={iconStyles} />}
            </P>
        </div>
    );
};

export default StaticFormElement;
