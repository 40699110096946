import React from 'react';
import Divider from '@hero/ui-kit/components/Divider';
import useTheme from '@hero/branding/theme';
import ButtonLink from '../ButtonLink';

interface SearchItemProps {
    icon?: React.ReactElement;
    name: string;
    disabled?: boolean;
    onSelect: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchItem: React.FC<SearchItemProps> = ({ icon, name, onSelect, disabled }) => {
    const theme = useTheme();
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                {icon || null}
                <ButtonLink
                    alt={`link to ${name}`}
                    onClick={onSelect}
                    styles={{ padding: 0, textAlign: 'left' }}
                    disabled={disabled}
                >
                    {name}
                </ButtonLink>
            </div>
            <Divider styles={{ borderColor: theme.colors.neutrals.borderBeta }} />
        </>
    );
};

export default React.memo(SearchItem);
