import React, { CSSProperties } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';

const ListItem: React.FC<UiComponentProps & { children?: React.ReactNode }> = ({
    children,
    styles
}) => {
    const mergedStyles: CSSProperties = {
        margin: 0,
        padding: 0,
        ...styles
    };

    return <li style={mergedStyles}>{children}</li>;
};

export default ListItem;
