import React, { FC, useCallback, useMemo } from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import InlineLoader from '../../../components/InlineLoader';
import { useExchangeContext } from '../context/ExchangeProvider';
import QueryInlineError from '../../../components/QueryInlineError';

interface NewDeviceConfirmProps {
    closeExchangeModal: () => void;
}

export const NewDeviceConfirm: FC<NewDeviceConfirmProps> = () => {
    const {
        changeExchangeStep,
        shippingAddress,
        onNewAddressConfirm,
        isExchangeFulfillmentLoading,
        exchangeFulfillmentError
    } = useExchangeContext();

    const handleNext = useCallback(() => {
        onNewAddressConfirm();
    }, [onNewAddressConfirm]);

    const handleBack = useCallback(() => {
        changeExchangeStep('NewDevice');
    }, [changeExchangeStep]);

    const secondAddress = useMemo(() => {
        const address2 = shippingAddress?.address_line_2;
        if (address2) {
            return (
                <>
                    {address2}
                    <br />
                </>
            );
        }
        return null;
    }, [shippingAddress?.address_line_2]);

    return isExchangeFulfillmentLoading ? (
        <InlineLoader />
    ) : (
        <Container>
            <H centered role="h6">
                Confirm Address with Member on phone
            </H>
            <Section noDefaultPadding centered styles={{ fontSize: '20px' }}>
                {shippingAddress.address_line_1}
                <br />
                {secondAddress}
                {`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`} <br />
                {shippingAddress.home_phone}
            </Section>
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" onClick={handleNext}>
                    Confirm Address
                </Button>
                <Button width="large" onClick={handleBack} variant="secondary">
                    Update Address
                </Button>
                {exchangeFulfillmentError && (
                    <QueryInlineError centered error={exchangeFulfillmentError} />
                )}
            </Section>
        </Container>
    );
};
