import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMitigationList, mitigationListReset } from '@hero/redux-data/backoffice/mitigationList/actionCreators';
import { saveMitigation, saveMitigationReset } from '@hero/redux-data/backoffice/mitigationSave/actionCreators';
const useSubscriptionMitigationActions = () => {
    const dispatch = useDispatch();
    const getSubscriptionMitigation = useCallback((subscriptionId) => {
        dispatch(fetchMitigationList({ subscription_id: subscriptionId }));
    }, [dispatch]);
    const saveSubscriptionMitigation = useCallback((attributes) => {
        dispatch(saveMitigation(attributes));
    }, [dispatch]);
    useEffect(() => {
        return () => {
            dispatch(mitigationListReset());
            dispatch(saveMitigationReset());
        };
    }, [dispatch]);
    return {
        getSubscriptionMitigation,
        saveSubscriptionMitigation
    };
};
export default useSubscriptionMitigationActions;
