import React from 'react';
import * as yup from 'yup';

import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Input from '@hero/ui-kit/inputs/Input';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import { alphanumericNotRequired } from '@hero/validators/alphanumeric';

import { useNewProviderElection } from '../context/NewProviderElectionProvider';

type Props = {
    closeModal?: () => void;
};

type FormFieldsProps = {
    defaultState?: string;
    onVoid?: () => void;
};

const formSchema = yup
    .object()
    .shape({
        insurance_type: yup.lazy((val) =>
            !val
                ? yup.string().nullable().required('User role field is required')
                : yup
                      .string()
                      .oneOf(
                          ['ORIGINAL_MEDICARE', 'MEDICARE_ADVANTAGE', 'NOT_SURE'],
                          'Invalid application submit'
                      )
        ) as unknown as yup.StringSchema<
            'ORIGINAL_MEDICARE' | 'MEDICARE_ADVANTAGE' | 'NOT_SURE',
            object
        >,
        insurance_policy_id: alphanumericNotRequired,
        insurance_name: alphanumericNotRequired,
        medigap_insurance_name: alphanumericNotRequired,
        medigap_insurance_policy_id: alphanumericNotRequired
    })
    .defined();

export type FormParams = yup.InferType<typeof formSchema>;

const FormFields: React.FC<FormFieldsProps> = () => {
    const { handleResetForm } = useNewProviderElection();

    const {
        formState: { isValid },
        watch
    } = useFormContext<FormParams>();

    const insurance_type = watch('insurance_type');

    return (
        <>
            <H role="h6">Insurance details</H>

            <Section noDefaultPadding>
                <RadioGroup name="insurance_type" displayName="" noBorders>
                    <Radio
                        id="original_medicare"
                        value="ORIGINAL_MEDICARE"
                        labelText="Original Medicare"
                    />
                    {insurance_type === 'ORIGINAL_MEDICARE' ? (
                        <Section noDefaultPadding>
                            <Input name="insurance_policy_id" displayName="Medicare Member ID" />
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    name="medigap_insurance_name"
                                    displayName="Medigap Insurance Company"
                                />
                                <Input
                                    name="medigap_insurance_policy_id"
                                    displayName="Medigap Member ID"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}

                    <Radio
                        id="medicare_advantage"
                        value="MEDICARE_ADVANTAGE"
                        labelText="Medicare Advantage"
                    />
                    {insurance_type === 'MEDICARE_ADVANTAGE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    name="insurance_name"
                                    displayName="Medicare Advantage Insurance Company"
                                />
                                <Input
                                    name="insurance_policy_id"
                                    displayName="Medicare Advantage Member ID"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-2" value="" labelText="" styles={{ display: 'none' }} />
                    )}

                    <Radio id="not_sure" value="NOT_SURE" labelText="Not sure" />
                    {insurance_type === 'NOT_SURE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input name="insurance_name" displayName="Insurance Company" />
                                <Input name="insurance_policy_id" displayName="Member ID" />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-3" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                </RadioGroup>
            </Section>

            <Section centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>

                <Button width="large" variant="secondary" onClick={handleResetForm}>
                    Reset
                </Button>
            </Section>
        </>
    );
};

const Insurance: React.FC<Props> = () => {
    const { handleSubmitLead } = useNewProviderElection();

    const handleNext = React.useCallback(
        (formValues: FormParams) => {
            handleSubmitLead && handleSubmitLead(formValues);
        },
        [handleSubmitLead]
    );

    return (
        <Section noDefaultPadding>
            <Section noDefaultPadding marginTop="regular">
                <Form submitFn={handleNext} validationSchema={formSchema}>
                    <FormFields />
                </Form>
            </Section>
        </Section>
    );
};

export default Insurance;
