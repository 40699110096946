import React from 'react';
import * as styles from './style.module.css';
import InlineLoader from '../InlineLoader';
import NotificationArea from '../NotificationArea';
import Navigation from '../Navigation';
import { useSelector } from 'react-redux';
import { getLoggedUserSelector } from '@hero/redux-data/backoffice/userStatus/selectors';

const DefaultLayout: React.FC<{ children?: React.ReactNode; style?: React.CSSProperties }> = ({
    children,
    style
}) => {
    const user = useSelector(getLoggedUserSelector);
    const isLoading = user?.isLoading || false;
    const userId = user?.status?.user_id || 0;
    return (
        <div className={styles.container} style={style}>
            <div className={styles.contentWrap}>
                <Navigation />

                <div className={`${styles.mainContent} ${styles.mainContentModern}`}>
                    {isLoading || userId === 0 ? (
                        <InlineLoader />
                    ) : (
                        <>
                            <div className={styles.notificationsWrap}>
                                <NotificationArea />
                            </div>
                            <div className={styles.contentInnerWrap}>{children}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DefaultLayout;
