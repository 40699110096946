import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchMembershipAlerts, membershipAlertsReset } from '@hero/redux-data/backoffice/membershipAlerts/actionCreators';
import { saveMembershipAlertNote, dismissMembershipAlert, membershipAlertReset } from '@hero/redux-data/backoffice/membershipAlert/actionCreators';
import { areMembershipAlertsLoadingSelector, membershipAlertPaginationSelector, membershipAlertListSelector } from '@hero/redux-data/backoffice/membershipAlerts/selectors';
import { isMembershipAlertLoadingSelector, isMembershipAlertUpdatedSelector, membershipAlertSelector } from '@hero/redux-data/backoffice/membershipAlert/selectors';
import usePrevious from '@hero/react-hooks/usePrevious';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import usePagerProps from '../../../hooks/usePagerProps';
import useSetQueryParams from '../../../hooks/useSetQueryParams';
import getTableColumns from './utils/getTableColumns';
import repackTableData from './utils/repackTableData';
import buildQuery from '@hero/hero-utils/buildQueryParams';
const useMembershipMonitoringData = () => {
    const dispatch = useDispatch();
    const setQueryParams = useSetQueryParams();
    const isFetchingList = useSelector(areMembershipAlertsLoadingSelector);
    const alertList = useSelector(membershipAlertListSelector, shallowEqual);
    const paginationData = useSelector(membershipAlertPaginationSelector, shallowEqual);
    const isSavingAlert = useSelector(isMembershipAlertLoadingSelector);
    const isAlertSaved = useSelector(isMembershipAlertUpdatedSelector);
    const alert = useSelector(membershipAlertSelector, shallowEqual);
    const wasAlertSaved = usePrevious(isAlertSaved);
    const [sort, handleSort] = useTableSortQuery();
    const { search } = getQueryParams();
    const processedHandleSort = (sort_by, sort_dir) => {
        if (sort_by === 'past_due') {
            handleSort('payment_failed_at', sort.sort_dir === 'ASC' ? 'DESC' : 'ASC');
        }
        else {
            handleSort(sort_by, sort_dir);
        }
    };
    const tableData = useMemo(() => repackTableData(alertList), [alertList]);
    const pagerProps = usePagerProps(paginationData);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const apiSort = useMemo(() => {
        const { sort_by, sort_dir } = sort;
        if (sort_by === 'past_due') {
            return {
                sort_by: 'payment_failed_at',
                sort_dir: sort_dir === 'ASC' ? 'DESC' : 'ASC'
            };
        }
        else if (sort_by === 'created_at') {
            return {
                sort_by: 'payment_failed_at',
                sort_dir: 'DESC'
            };
        }
        else {
            return sort;
        }
    }, [sort]);
    const query = window.location.search;
    const fetchAlerts = useCallback(() => {
        dispatch(fetchMembershipAlerts(query));
    }, [dispatch, query]);
    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);
    useEffect(() => {
        // execute _only_ if an alert has just been dismissed
        if (!wasAlertSaved && isAlertSaved && !alert) {
            const { offset, total_count } = pagerProps;
            // (note: total_count is already decremented within reducer)
            if (total_count > offset) {
                // there were more results on the page - just force the refetch
                fetchAlerts();
            }
            else if (offset > 0) {
                // not the first page - go to the first page (fetch kicks in automatically)
                setQueryParams(buildQuery({ ...(search && { search }) }));
            }
            else if (search !== '') {
                // no more results with current filter - reset search (fetch kicks in automatically)
                setQueryParams('');
            }
            else {
                // basically no more alerts, but force refetch just for the case
                fetchAlerts();
            }
        }
    }, [wasAlertSaved, isAlertSaved, alert, search, fetchAlerts, pagerProps, setQueryParams]);
    useEffect(() => () => {
        dispatch(membershipAlertsReset());
        dispatch(membershipAlertReset());
    }, [dispatch]);
    const handleSaveNoteAction = useCallback((subscription_id) => {
        setSelectedAlert({ subscription_id, action: 'update' });
    }, []);
    const handleDismissAction = useCallback((subscription_id) => {
        setSelectedAlert({ subscription_id, action: 'dismiss' });
    }, []);
    const handleSaveNoteConfirm = useCallback((subscription_id, notes) => {
        dispatch(saveMembershipAlertNote({ subscription_id, notes: notes.trim() }));
        setSelectedAlert(null);
    }, [dispatch]);
    const handleDismissConfirm = useCallback((subscription_id) => {
        dispatch(dismissMembershipAlert({ subscription_id }));
        setSelectedAlert(null);
    }, [dispatch]);
    const handleActionCancel = useCallback(() => {
        setSelectedAlert(null);
    }, []);
    const tableColumns = useMemo(() => getTableColumns(handleSaveNoteAction, handleDismissAction), [handleSaveNoteAction, handleDismissAction]);
    return {
        dispatch,
        isSavingAlert,
        isFetchingList,
        selectedAlert,
        handleSaveNoteConfirm,
        handleActionCancel,
        handleDismissConfirm,
        tableColumns,
        tableData,
        sort: apiSort,
        handleSort: processedHandleSort,
        pagerProps
    };
};
export default useMembershipMonitoringData;
