import React from 'react';
import ToBeCancelledProvider from './context/ToBeCancelledProvider';
import ToBeCancelledStepsModal from './ToBeCancelledStepsModal';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';

type ExchangeFlowProps = {
    onCompleteCallback?: () => void;
} & Pick<ModalProps, 'externalControls'>;

const ToBeCancelledFlow: React.FC<ExchangeFlowProps> = ({
    externalControls,
    onCompleteCallback
}) => {
    return (
        <ToBeCancelledProvider onCompleteCallback={onCompleteCallback}>
            <ToBeCancelledStepsModal externalControls={externalControls} />
        </ToBeCancelledProvider>
    );
};

export default ToBeCancelledFlow;
