import { useMemo } from 'react';
const useGetAllReasonSubCodes = (isOrganizationMember = false, options, cancellationReasons) => {
    return useMemo(() => {
        const allReasons = isOrganizationMember ? cancellationReasons : options?.reasons;
        if (allReasons) {
            let allReasonsSubcodes = [];
            for (const property in allReasons) {
                allReasonsSubcodes.push({ [property]: allReasons[property].sub_codes });
            }
            return allReasonsSubcodes.reduce((acc, item) => {
                for (const reasonId in item) {
                    const reasonSubCodes = item[reasonId];
                    for (const subCodeId in reasonSubCodes) {
                        acc.push([
                            `${reasonId}-${subCodeId}`,
                            `${reasonSubCodes[subCodeId]} (${allReasons[reasonId].description})`
                        ]);
                    }
                }
                return acc;
            }, []);
        }
        return [];
    }, [cancellationReasons, options, isOrganizationMember]);
};
export default useGetAllReasonSubCodes;
