import { useMemo } from 'react';
import usePagerClickQuery from './usePagerClickQuery';
const usePagerProps = (paginationData, padding = 3) => {
    const handlePagination = usePagerClickQuery();
    const total_count = paginationData.total_count || 0;
    return useMemo(() => ({
        handleClick: handlePagination,
        ...paginationData,
        total_count,
        padding
    }), [paginationData, handlePagination, padding, total_count]);
};
export default usePagerProps;
