import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { enterpriseEnrollmentsErrorSelector } from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/selectors';
import { useNotifications } from '../../context/notification';
const useEnterpriseEnrollmentListFailAlert = () => {
    const { error, originalMessage } = useSelector(enterpriseEnrollmentsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage?.replace(/[_-]/g, ' ') ||
                    'Error fetching organization enrollments.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, originalMessage]);
};
export default useEnterpriseEnrollmentListFailAlert;
