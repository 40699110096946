import { formatPhoneNumber } from '@hero/hero-utils/phone';
import trimDeep from '@hero/hero-utils/trimDeep';
const repackValuesForSubmit = (formValues) => {
    const { first_name, last_name, email, home_phone, address_line_1, address_line_2, city, zip, state, discount_code, plan, heard_from, podcast_name, other, confirmation_channel } = trimDeep(formValues);
    const phone = formatPhoneNumber(home_phone);
    return {
        shipping_contact: {
            first_name,
            last_name,
            email,
            phone,
            address: {
                address_line_1,
                ...(address_line_2 && { address_line_2 }),
                city,
                zip,
                state,
                country: 'US'
            }
        },
        email,
        plan: {
            id: plan
        },
        ...(discount_code && {
            discount: {
                code: discount_code
            }
        }),
        survey: {
            heard_from: heard_from.toLowerCase() === 'other' ? other : heard_from,
            ...(heard_from.toLowerCase().includes('podcast') && {
                podcast_name: podcast_name.toLowerCase() === 'other' ? other : podcast_name
            })
        },
        confirmation_channel
    };
};
export default repackValuesForSubmit;
