import React from 'react';
import { OrganizationAdherenceAlert } from '@hero/hero-types';
import Header from '../Header';
import useMonitoringAdherenceAlertsData from './useMonitoringAdherenceAlertsData';
import Table from '@hero/ui-kit/tables/Table';
import Section from '@hero/ui-kit/layout/Section';
import Filter from '../Filter';
import DefaultLayout from '../../../components/DefaultLayout';

const AdherenceMonitoringPage: React.FC = () => {
    const { isLoading, adherenceAlertList, pagerProps, tableColumns } =
        useMonitoringAdherenceAlertsData();

    return (
        <DefaultLayout>
            <Section>
                <Header title="Adherence Monitoring" />
                <Filter hideAllFilterOption />
                <Table<OrganizationAdherenceAlert>
                    columns={tableColumns}
                    data={adherenceAlertList}
                    rowKey="id"
                    emptyValues="-"
                    isLoading={isLoading}
                    pagerProps={pagerProps}
                />
            </Section>
        </DefaultLayout>
    );
};

export default AdherenceMonitoringPage;
