import React, { CSSProperties, ReactText } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';
import withHover, { HoverProps } from '@hero/ui-kit/hocs/withHover';
import withFocus, { FocusProps } from '@hero/ui-kit/hocs/withFocus';
import { ClickableOpacity } from '@hero/ui-kit/inputs/ClickableOpacity';
import Span from '@hero/ui-kit/typography/Span';

type Props = {
    children: ReactText;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    alt: string;
    disabled?: boolean;
} & HoverProps &
    FocusProps &
    UiComponentProps;

export const ButtonLink: React.FC<Props> = ({
    children,
    isHovered,
    isFocused,
    disabled = false,
    ...rest
}) => {
    const { typography, colors } = useTheme();

    const labelStyles: CSSProperties = {
        ...typography.link.regular,
        ...((isHovered || isFocused) && { color: colors.brand.primary.alpha }),
        border: 'none'
    };

    return (
        <ClickableOpacity disabled={disabled} isHovered={isHovered} isFocused={isFocused} {...rest}>
            <Span styles={labelStyles}>{children}</Span>
        </ClickableOpacity>
    );
};

export default withFocus(withHover(ButtonLink));
