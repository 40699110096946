import React from 'react';
import H from '@hero/ui-kit/typography/H';
import Divider from '@hero/ui-kit/components/Divider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useTheme from '@hero/branding/theme';

interface SearchHeaderProps {
    name: string;
    show?: boolean;
}

const SearchHeader: React.FC<SearchHeaderProps> = ({ name, show = false }) => {
    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginBottom = useComponentMargin({ marginBottom: 'regular' });
    const theme = useTheme();
    return show ? (
        <>
            <H styles={{ ...marginTop, marginBottom: '1rem' }} role="h6" noDefaultMargin>
                {name}
            </H>
            <Divider
                noDefaultMargin
                styles={{
                    borderColor: theme.colors.neutrals.borderAlpha,
                    ...marginBottom
                }}
            />
        </>
    ) : null;
};

export default SearchHeader;
