import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { rmaRequest } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetRmaDetails = (props, enabled) => {
    const additional = useQueryRequestParams();
    const { id, rma_status, rma_type, device_serial } = props;
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}rma/request/`, {
            ...additional,
            params: {
                ...(id && { id }),
                ...((rma_status || rma_status === 0) && { rma_status }),
                ...(rma_type && { rma_type }),
                ...(device_serial && { device_serial })
            }
        }),
        queryKey: [rmaRequest, id],
        enabled
    });
};
export default useGetRmaDetails;
