import React from 'react';

import { BoUserDetails } from '@hero/hero-types';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import H from '@hero/ui-kit/typography/H';

import InlineLoader from '../../../components/InlineLoader';
import useListStyles from '../../../hooks/useListStyles';

import UsersListFiltering from './UsersListFiltering';
import useUsersListData from './useUsersListData';
import DefaultLayout from '../../../components/DefaultLayout';

const UsersList: React.FC = () => {
    const { isFetchingUsers, users, sort, handleSort, pagerProps, tableColumns } =
        useUsersListData();
    const { headerWrapStyles, innerWrapPadding, tableWrapStyles } = useListStyles();

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">User Lookup</H>
                </div>
                <div style={innerWrapPadding}>
                    {isFetchingUsers ? (
                        <InlineLoader />
                    ) : (
                        <>
                            <UsersListFiltering />
                            <div style={tableWrapStyles}>
                                <Table<BoUserDetails>
                                    columns={tableColumns}
                                    data={users}
                                    rowKey="id"
                                    emptyValues="-"
                                    isLoading={isFetchingUsers}
                                    sortColumn={sort.sort_by}
                                    sortDirection={sort.sort_dir}
                                    onColumnSort={handleSort}
                                    pagerProps={pagerProps}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default UsersList;
