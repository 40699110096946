import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { devicesKey } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useUpdateUserRole = (device_id) => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_CLOUD_HOSTNAME}backoffice/app-support/role-change/`, { device_id, ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [devicesKey] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useUpdateUserRole;
