import React from 'react';
import { useParams } from 'react-router-dom';

import TabGroup from '@hero/ui-kit/components/TabGroup';
import Tab from '@hero/ui-kit/components/Tab';

import { UserRouteParams } from '../types';

const TabBar: React.FC = () => {
    const { user_id } = useParams<UserRouteParams>();

    return user_id ? (
        <TabGroup fullWidth>
            <Tab to={`/users/${user_id}/details`}>Account Details</Tab>
            <Tab to={`/users/${user_id}/caregiver`}>Caregiver</Tab>
            <Tab to={`/users/${user_id}/notifications`}>Notifications</Tab>
            <Tab to={`/users/${user_id}/adherence`}>Adherence</Tab>
        </TabGroup>
    ) : null;
};

export default TabBar;
