import getQueryParams from '@hero/hero-utils/getQueryParams';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationAdherenceAlerts } from '@hero/redux-data/backoffice/organizationAdherenceAlerts/actionCreators';
import { areOrgAdherenceAlertsLoadingSelector, orgAdherenceAlertListSelector, orgAdherenceAlertPaginationSelector } from '@hero/redux-data/backoffice/organizationAdherenceAlerts/selectors';
import usePagerProps from '../../../hooks/usePagerProps';
import getTableColumns from './getTableColumns';
const useMonitoringAdherenceAlertsData = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(areOrgAdherenceAlertsLoadingSelector, shallowEqual);
    const adherenceAlertList = useSelector(orgAdherenceAlertListSelector, shallowEqual);
    const paginationData = useSelector(orgAdherenceAlertPaginationSelector, shallowEqual);
    const pagerProps = usePagerProps(paginationData);
    const tableColumns = useMemo(() => getTableColumns(), []);
    const { offset, limit = 20, organization_id, membership_key } = getQueryParams();
    useEffect(() => {
        dispatch(fetchOrganizationAdherenceAlerts({
            offset,
            limit,
            ...(organization_id && { organization_id: +organization_id }),
            ...(membership_key && { membership_key })
        }));
    }, [dispatch, offset, limit, organization_id, membership_key]);
    return { dispatch, isLoading, adherenceAlertList, pagerProps, tableColumns };
};
export default useMonitoringAdherenceAlertsData;
