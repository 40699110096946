import { useState, useCallback } from 'react';
import { usStates } from '@hero/hero-utils/address/states';
const initialValues = {
    name_on_card: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    zip_code: ''
};
export const findState = (value) => {
    return usStates.find((usState) => {
        return value.length === 2
            ? usState.code.toLowerCase() === value.toLowerCase()
            : usState.name.toLowerCase() === value.toLowerCase();
    });
};
const isInvalidState = (value) => {
    const state = findState(value);
    return state ? false : true;
};
const useAdditionalFields = () => {
    const [values, setValue] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const handleChange = useCallback((e) => {
        e.preventDefault();
        const { name, value } = e.currentTarget;
        setValue((values) => ({ ...values, [name]: value }));
        name !== 'address2' &&
            setErrors((errors) => ({ ...errors, [name]: value ? '' : 'This field is required' }));
    }, []);
    const validate = useCallback(() => {
        const errors = Object.entries(values).reduce((acc, [key, val]) => ({
            ...acc,
            ...(key === 'province' && isInvalidState(val) ? { [key]: 'Invalid state' } : {}),
            ...(key === 'zip_code' && (!/^\d+$/.test(val) || val.length !== 5)
                ? { [key]: 'Invalid zip code' }
                : {}),
            ...(key !== 'address2' && val.trim() === '' && { [key]: 'This field is required' })
        }), {});
        const hasErrors = Object.values(errors).some((err) => !!err);
        setErrors(errors);
        return hasErrors;
    }, [values]);
    return { values, errors, handleChange, validate };
};
export default useAdditionalFields;
