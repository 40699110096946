import React, { useCallback } from 'react';

import { PaymentUpdateParams } from '@hero/hero-types';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';

import { StripeWrapper, StripeForm } from '../../../components/Stripe';
import { shallowEqual, useSelector } from 'react-redux';
import { isUserPaymentMethodLoadingSelector } from '@hero/redux-data/backoffice/userPaymentMethod/selectors';
import InlineLoader from '../../../components/InlineLoader';

type Props = {
    onSubmit: (formValues: PaymentUpdateParams) => void;
} & Pick<ModalProps, 'externalControls'>;

const EditPaymentModal: React.FC<Props> = ({ onSubmit, externalControls }) => {
    const [, setExternalState] = externalControls ?? [];
    const isLoading = useSelector(isUserPaymentMethodLoadingSelector, shallowEqual);

    const handleCancel = useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    return (
        <Modal externalControls={externalControls} isCancelable>
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <H role="h4" centered>
                        Update Payment Method
                    </H>
                    <StripeWrapper>
                        <StripeForm onSubmit={onSubmit} onCancel={handleCancel} />
                    </StripeWrapper>
                </>
            )}
        </Modal>
    );
};

export default EditPaymentModal;
