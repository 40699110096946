import React, { CSSProperties, ReactText } from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import A from '@hero/ui-kit/typography/A';

type Props = {
    children: ReactText;
    to: string;
} & UiComponentProps;

const Link: React.FC<Props> = ({ children, styles, ...rest }) => {
    const mergedStyles: CSSProperties = {
        border: 'none',
        ...styles
    };

    return (
        <A styles={mergedStyles} {...rest}>
            {children}
        </A>
    );
};

export default Link;
