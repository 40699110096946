import React, { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import Container from '@hero/ui-kit/layout/Container';
import Button from '@hero/ui-kit/inputs/Button';
import usePrevious from '@hero/react-hooks/usePrevious';
import {
    CreateRmaRequestParams,
    UpdateRmaRequestParams
} from '@hero/validators/forms/backoffice/createRmaReqestSchema';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';

import StaticFormElement from '../../../components/StaticFormElement';
import StaticOrInput from '../../../components/StaticOrInput';
import StaticOrSelect from '../../../components/StaticOrSelect';
import useRmaEnums from '../utils/useRmaEnums';
import { RmaRouteParams } from '../types';

type Props = {
    initialValues?: Partial<CreateRmaRequestParams | UpdateRmaRequestParams>;
    onTypeSelect: (selectedType: string | undefined) => void;
    createdAt?: string | Date;
    updatedAt?: string | Date;
    createdBy?: string;
    isEditMode?: boolean;
    onEditSwitch?: () => void;
    voidForbidden?: boolean;
};

const RmaRequestFormFieldsBasics: React.FC<Props> = ({
    onTypeSelect,
    createdAt,
    initialValues,
    updatedAt,
    createdBy,
    isEditMode = false,
    onEditSwitch,
    voidForbidden = false
}) => {
    const { id } = useParams<RmaRouteParams>();

    const {
        rmaTypeOptions,
        rmaStatusOptions,
        refundStatusOptions,
        orderSourceOptions,
        rmaReasonOptions,
        rmaSubReasonOptions
    } = useRmaEnums();

    const { reset, setValue } = useFormContext<CreateRmaRequestParams | UpdateRmaRequestParams>();

    const reason_code = useWatch({ name: 'reason_code' });
    const customer_name = useWatch({ name: 'customer_name' });
    const device_serial = useWatch({ name: 'device_serial' });
    const rma_type = useWatch({ name: 'rma_type' });
    const rma_status = useWatch({ name: 'rma_status' });
    const refund_status = useWatch({ name: 'refund_status' });
    const order_source = useWatch({ name: 'order_source' });
    const order_number = useWatch({ name: 'order_number' });
    const zendesk_ticket_number = useWatch({ name: 'zendesk_ticket_number' });
    const reason_sub_code = useWatch({ name: 'reason_sub_code' });

    const prev_reason_code = usePrevious(reason_code);

    const rmaStatusFilteredOptions = useMemo(() => {
        const noVoid = isEditMode && voidForbidden;
        return rmaStatusOptions.filter(([key]) => !noVoid || key !== '6');
    }, [isEditMode, voidForbidden, rmaStatusOptions]);

    useEffect(() => {
        onTypeSelect(rma_type);
    }, [onTypeSelect, rma_type]);

    useEffect(() => {
        if (initialValues?.customer_name?.length) {
            reset(initialValues);
        }
    }, [initialValues, reset]);

    useEffect(() => {
        // normally we need to clear `reason_sub_code` when `reason_code` selection changes,
        // but we want to make an exception for initial change when previously empty,
        // in order to preserve programmatically set `initialValues`
        prev_reason_code && reason_code !== prev_reason_code && setValue('reason_sub_code', '');
    }, [reason_code, prev_reason_code, setValue]);

    const formatLocalDateTime = (date: string | Date | undefined): string =>
        !date
            ? '-'
            : typeof date === 'string'
            ? formatDateTime(normalizeDateString(date))
            : formatDateTime(date);

    const leftColWrapStyles: CSSProperties = {
        gridColumnStart: 1,
        alignItems: 'start'
    };
    const rightColWrapStyles: CSSProperties = {
        gridColumnStart: 2,
        alignItems: 'start'
    };
    const editButtonStyles: CSSProperties = {
        gridColumnStart: 2,
        gridRowStart: 1,
        alignSelf: 'start',
        justifySelf: 'end'
    };

    return (
        <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="customer_name"
                    displayName="Customer Name"
                    staticValue={customer_name}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="device_serial"
                    displayName="Device Serial #"
                    staticValue={device_serial}
                    isEditable={isEditMode && !id}
                />
            </Container>
            <Container styles={leftColWrapStyles}>
                <StaticOrSelect
                    name="rma_type"
                    displayName="RMA Type"
                    options={rmaTypeOptions}
                    staticValue={rma_type}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={leftColWrapStyles}>
                <StaticOrSelect
                    name="rma_status"
                    displayName="RMA Status"
                    options={rmaStatusFilteredOptions}
                    staticValue={rma_status}
                    isEditable={isEditMode && !!id}
                />
            </Container>
            {rma_type === '1' && ( // cancellation
                <Container styles={leftColWrapStyles}>
                    <StaticOrSelect
                        name="refund_status"
                        displayName="Refund Status"
                        options={refundStatusOptions}
                        staticValue={refund_status}
                        isEditable={isEditMode}
                    />
                </Container>
            )}
            <Container styles={leftColWrapStyles}>
                <StaticOrSelect
                    name="order_source"
                    displayName="Source"
                    options={orderSourceOptions}
                    staticValue={order_source}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={leftColWrapStyles}>
                <StaticOrInput
                    name="order_number"
                    displayName="Order #"
                    staticValue={order_number}
                    isEditable={isEditMode}
                />
            </Container>
            {!isEditMode && (
                <Button size="small" onClick={onEditSwitch} styles={editButtonStyles}>
                    Edit
                </Button>
            )}
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                <StaticFormElement label="Created Date">
                    {formatLocalDateTime(createdAt)}
                </StaticFormElement>
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                <StaticFormElement label="Created By">{createdBy || '-'}</StaticFormElement>
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 3 }}>
                <StaticFormElement label="Last Updated">
                    {formatLocalDateTime(updatedAt)}
                </StaticFormElement>
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 4 }}>
                <StaticOrInput
                    name="zendesk_ticket_number"
                    displayName="Zendesk Ticket #"
                    staticValue={zendesk_ticket_number}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 5 }}>
                <StaticOrSelect
                    name="reason_code"
                    displayName="Reason"
                    options={rmaReasonOptions}
                    staticValue={reason_code}
                    isEditable={isEditMode}
                />
            </Container>
            <Container styles={{ ...rightColWrapStyles, gridRowStart: 6 }}>
                <StaticOrSelect
                    name="reason_sub_code"
                    displayName="Sub-Reason"
                    options={rmaSubReasonOptions[reason_code] ?? []}
                    staticValue={reason_sub_code}
                    disabled={!reason_code}
                    isEditable={isEditMode}
                />
            </Container>
        </Container>
    );
};

export default RmaRequestFormFieldsBasics;
