import * as yup from 'yup';
const planOffersSchema = yup
    .object()
    .shape({
    plan_offers: yup
        .mixed()
        .required('Plan offer is required')
        .oneOf(['biannual', 'annual', 'basic-extended-trial'], 'Please select option')
})
    .defined();
export default planOffersSchema;
