import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    getPlaceSuggestions,
    placeSuggestionsReset
} from '@hero/redux-data/googlePlaceSuggestions/actionCreators';
import {
    getPlaceDetails,
    placeDetailsReset
} from '@hero/redux-data/googlePlaceDetails/actionCreators';
import { areProspectSignupSurveyOptionsLoadingSelector } from '@hero/redux-data/backoffice/prospectSignupSurveyOptions/selectors';
import { placeSuggestionsRepackedSelector } from '@hero/redux-data/googlePlaceSuggestions/selectors';
import Container from '@hero/ui-kit/layout/Container';
import Input from '@hero/ui-kit/inputs/Input';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import { usStates } from '@hero/hero-utils/address/states';
import useGoogleMaps from '@hero/react-hooks/useGoogleMaps';

import InlineLoader from '../../../components/InlineLoader';
import envVars from '../../../constants/envVars';
import HeardFromInputs from './HeardFromInputs';
import Control from './Control';
import IsolateEffects from './IsolateEffects';
import useStyles from './useStyles';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

type Props = {
    isPrepopulated: boolean;
    onReset: () => void;
};

const SignupFormFields: React.FC<Props> = ({ isPrepopulated, onReset }) => {
    const dispatch = useDispatch();
    const isFetchingSurveyOptions = useSelector(
        areProspectSignupSurveyOptionsLoadingSelector,
        shallowEqual
    );
    const addressSuggestions = useSelector(placeSuggestionsRepackedSelector, shallowEqual);

    const suggestionLabels = useMemo(
        () => addressSuggestions.map(({ label }) => label),
        [addressSuggestions]
    );

    const isGoogleApiReady = useGoogleMaps({ apiKey: envVars.GOOGLE_MAPS_KEY });

    useEffect(
        () => () => {
            dispatch(placeSuggestionsReset());
            dispatch(placeDetailsReset());
        },
        [dispatch]
    );

    const handleFetchAddressSuggestions = useCallback(
        (input: string) => {
            if (input !== '') {
                isGoogleApiReady && dispatch(getPlaceSuggestions({ input }));
            } else {
                dispatch(placeSuggestionsReset());
            }
        },
        [dispatch, isGoogleApiReady]
    );

    const handleAddressSelection = useCallback(
        (value: string) => {
            const address = addressSuggestions.find(({ label }) => label === value);
            const placeId = address?.placeId;
            if (placeId) {
                isGoogleApiReady && dispatch(getPlaceDetails({ placeId }));
            } else {
                dispatch(placeDetailsReset());
            }
        },
        [dispatch, isGoogleApiReady, addressSuggestions]
    );

    const { formWrapStyles, selectStyles } = useStyles();

    const { reset } = useFormContext();

    const handleReset = useCallback(() => {
        onReset && onReset();
        reset({
            first_name: '',
            last_name: '',
            email: '',
            home_phone: '',
            confirmation_channel: 'customer_email',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: undefined,
            plan: undefined,
            zip: '',
            discount_code: '',
            heard_from: '',
            podcast_name: ''
        });
    }, [onReset, reset]);

    return (
        <div style={formWrapStyles}>
            <IsolateEffects isPrepopulated={isPrepopulated} />
            {isFetchingSurveyOptions ? (
                <InlineLoader />
            ) : (
                <>
                    <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                        <Input name="first_name" displayName="First Name" />
                        <Input name="last_name" displayName="Last Name" />
                        <Input name="email" displayName="Email Address" type="email" />
                        <Input name="home_phone" displayName="Phone Number" />
                        <Combobox
                            name="address_line_1"
                            displayName="Street Address"
                            suggestions={suggestionLabels}
                            handleFetchSuggestions={handleFetchAddressSuggestions}
                            onSelection={handleAddressSelection}
                            noExtraMargin
                        />
                        <Input name="address_line_2" displayName="Street Address Line 2" />
                    </Container>
                    <Container gridTemplateColumns="1fr 1fr 1fr" gridColumnGap="regular">
                        <Input name="city" displayName="City" />
                        <Select name="state" displayName="State" visibleLimit={5} noExtraMargin>
                            {usStates.map(({ code, name }) => (
                                <Option key={code} value={code} labelText={name} />
                            ))}
                        </Select>
                        <Input name="zip" displayName="Zip Code" />
                    </Container>
                    <Input name="discount_code" displayName="Promo Code" />
                    <Select
                        name="plan"
                        displayName="Membership"
                        styles={selectStyles}
                        noExtraMargin
                    >
                        <Option
                            value="basic-extended-trial"
                            labelText="Monthly $44.99 / no commitment"
                        />
                        <Option value="annual" labelText="Annual $34.99 / 12 month commitment" />
                        <Option
                            value="biannual"
                            labelText="24 Months $29.99 / 24 month commitment"
                        />
                    </Select>
                    <HeardFromInputs />
                    <RadioGroup
                        name="confirmation_channel"
                        displayName="Send Email Confirmation To"
                    >
                        <Radio id="customer_email" value="customer_email" labelText="Customer" />
                        <Radio id="agent_email" value="agent_email" labelText="Me" />
                    </RadioGroup>
                    <Control onReset={handleReset} />
                </>
            )}
        </div>
    );
};

export default React.memo(SignupFormFields, (prevProps, nextProps) => {
    return (
        prevProps.isPrepopulated === nextProps.isPrepopulated &&
        prevProps.onReset === nextProps.onReset
    );
});
