import React from 'react';
import RmaDetails from '../../pages/Rma/RmaDetails';
import CancellationFlow from '../../views/CancellationFlow';
import ExchangeFlow from '../../views/ExchangeFlow';
import useGetRmaDetails from '../../pages/Rma/api/useGetRmaDetails';
import RmaDetailsDataProviderV2 from '../../pages/Rma/RmaDetails/RmaDetailsDataProviderV2';
import useGetRmaEnums from '../../pages/Rma/api/useGetRmaEnums';

interface RmaDetailsOnSearchProps {
    rmaId: number;
    onCloseDetails: () => void;
}

const RmaDetailsOnSearch: React.FC<RmaDetailsOnSearchProps> = ({ rmaId, onCloseDetails }) => {
    const [openCancellationFlow, setOpenCancellationFlow] = React.useState(true);
    const [openExchangeFlow, setOpenExchangeFlow] = React.useState(true);

    const { data, isLoading } = useGetRmaDetails({ id: rmaId }, true);
    const { data: rmaEnumsResponse } = useGetRmaEnums(true);
    const rmaEnums = rmaEnumsResponse?.data;

    const rma = data?.data;

    const handleCloseModal = () => {
        onCloseDetails();
    };

    const isCancellationFlowRma = rma && rma.rma_status === 0 && rma.rma_type === 1;
    const isExchangeFlowRma = rma && rma.rma_status === 0 && rma.rma_type === 2;

    return (
        <>
            {!isCancellationFlowRma && !isExchangeFlowRma ? (
                <RmaDetails
                    rmaEnums={rmaEnums}
                    rma={rma}
                    onCloseModal={handleCloseModal}
                    isLoading={isLoading}
                />
            ) : null}
            {isCancellationFlowRma ? (
                <RmaDetailsDataProviderV2
                    rmaId={rmaId}
                    // onUnmount={onUnmount}
                >
                    <CancellationFlow
                        onCloseModal={handleCloseModal}
                        isCreateNewRma
                        externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                        disableCreateRma
                        // onCompleteCallback={fetchRmaList}
                    />
                </RmaDetailsDataProviderV2>
            ) : null}
            {isExchangeFlowRma ? (
                <RmaDetailsDataProviderV2 rmaId={rmaId}>
                    <ExchangeFlow
                        onCloseModal={handleCloseModal}
                        externalControls={[openExchangeFlow, setOpenExchangeFlow]}
                        // onCompleteCallback={fetchRmaList}
                    />
                </RmaDetailsDataProviderV2>
            ) : null}
        </>
    );
};

export default RmaDetailsOnSearch;
