import useTheme from '@hero/branding/theme';
import React from 'react';
import SplashView from '@hero/ui-kit/components/SplashView';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import SearchIcon from '@hero/ui-kit/icons/utility/SearchIcon';
import UserIcon from '@hero/ui-kit/icons/utility/UserIcon';
import TruckIcon from '@hero/ui-kit/icons/branding/TruckIcon';
import DeviceIcon from '@hero/ui-kit/icons/branding/DeviceIcon';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import XButton from '@hero/ui-kit/inputs/XButton';
import Container from '@hero/ui-kit/layout/Container';
import SearchHeader from './SearchHeader';
import SearchItem from './SearchItem';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import P from '@hero/ui-kit/typography/P';
import NoResults from './NoResults';
import useMainSearch from './api/useMainSearch';
import { RmaType } from '@hero/hero-types';
import { DeviceSearch, MemberRole, MembershipSearch, RMASearch, UserSearch } from './types';
import InlineLoader from '../InlineLoader';
import { cleanString } from '../SearchForm/useSearchForm';
import RmaDetailsOnSearch from './RmaDetailsOnSearch';
import { useNavigate } from 'react-router-dom';
import QueryInlineError from '../QueryInlineError';

type MainSearchProps = {
    className?: string;
    searchText?: string;
    centerSearchModal?: boolean;
    modalWidth?: number;
    modalHeight?: number;
};

// 1 - Cancellation, 2 - Exchange, 3 - Misc. Return
const getRmaTypeLabel = (rmaType: RmaType | null) => {
    if (rmaType === 1) {
        return 'Cancellation';
    }

    if (rmaType === 2) {
        return 'Exchange with';
    }

    if (rmaType === 3) {
        return 'Misc. Return';
    }

    return 'Unknown';
};

const getUserRoleLabel = (role: MemberRole | null) => {
    if (role === 'CAREGIVER_ADMIN') {
        return 'CGA';
    }

    if (role === 'CAREGIVER_READ_ONLY') {
        return 'CGR';
    }

    if (role === 'PRIMARY_USER_ADMIN') {
        return 'PUA';
    }

    if (role === 'PRIMARY_USER_READ_ONLY') {
        return 'PUR';
    }

    return 'Unknown';
};

const getTextLabel = (text?: string | null) => text || 'N/A';

const MainSearch: React.FC<MainSearchProps> = ({
    className = '',
    searchText = 'Search',
    centerSearchModal = false,
    modalWidth = 48,
    modalHeight = 60
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [rmaId, setRmaId] = React.useState<number | undefined>(undefined);
    const btnWrapperRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = React.useState('');
    const { data, refetch, isLoading, fetchStatus, error, isError } = useMainSearch(
        encodeURIComponent(cleanString(searchTerm.trim())),
        false
    );

    const memberships = data?.data.memberships;
    const devices = data?.data.devices;
    const rmaList = data?.data.rma_list;
    const users = data?.data.users;

    const handleClick = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
        setSearchTerm('');
    }, []);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event?.key === 'Escape') {
                handleClose();
            }

            if (event.key === 'Enter') {
                refetch();
            }
        },
        [handleClose]
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setSearchTerm(text);
    };

    const margin = useComponentMargin({ marginHorizontal: 'regular' });
    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const padding = useComponentPadding({ paddingHorizontal: 'regular' });

    const searchWrapperStyles: React.CSSProperties = {
        width: `${modalWidth}rem`,
        maxHeight: `${modalHeight}rem`,

        backgroundColor: theme.colors.neutrals.background,
        position: 'absolute',
        ...(!centerSearchModal && {
            left: btnWrapperRef.current?.offsetLeft ? btnWrapperRef.current?.offsetLeft - 150 : 0
        }),
        top: btnWrapperRef.current?.offsetTop || 0,
        borderRadius: '2rem',
        zIndex: 100000
    };

    const searchInputStyles: React.CSSProperties = {
        border: 'none',
        outline: 'none'
    };

    const searchResultStyles: React.CSSProperties = {
        overflowY: 'auto',
        maxHeight: `${modalHeight - 8}rem`,
        margin: '1rem',
        ...padding
    };

    const handleSetRma = (id: number) => {
        setRmaId(id);
        setOpen(false);
    };

    const handleSelectMembership = (membership: MembershipSearch) => {
        if (membership.is_organization_member && membership.organization_id) {
            navigate(
                `/organizations/${membership.organization_id}/members/${membership.membership_id}/details`
            );
            setOpen(false);
        }

        if (!membership.is_organization_member && membership.membership_id) {
            navigate(`/membership/${membership.membership_id}/details`);
            setOpen(false);
        }
    };

    const handleSelectDevice = (device: DeviceSearch) => {
        if (device.device_id) {
            navigate(`/devices/${device.device_id}/details`);
            setOpen(false);
        }
    };

    const handleSelectRma = (rma: RMASearch) => {
        if (rma.rma_id) {
            handleSetRma(rma.rma_id);
            setOpen(false);
        }
    };

    const handleSelectUser = (user: UserSearch) => {
        if (user.user_id) {
            navigate(`/users/${user.user_id}/details`);
            setOpen(false);
        }
    };

    const hasResults = devices?.length || memberships?.length || rmaList?.length || users?.length;
    const hasData = devices || memberships || rmaList || users;

    return (
        <>
            <div ref={btnWrapperRef}>
                <ClickableOpacity alt="search" onClick={handleClick} className={className}>
                    <SearchIcon size="small" />
                    <P
                        noDefaultMargin
                        styles={{
                            fontStyle: 'italic',
                            fontWeight: 400,
                            color: 'rgba(145, 145, 145, 1)'
                        }}
                    >
                        {searchText}
                    </P>
                </ClickableOpacity>
            </div>
            {open ? (
                <>
                    <SplashView type="modal" isModal isOpenModal={open} />
                    <div style={searchWrapperStyles}>
                        <Container
                            gridTemplateColumns="1fr 9fr 1fr"
                            styles={{ alignItems: 'center', ...margin, ...marginTop }}
                        >
                            <SearchIcon size="utility" />
                            <input
                                style={searchInputStyles}
                                onChange={handleOnChange}
                                autoFocus
                                onKeyDown={handleKeyDown}
                            />
                            <XButton
                                styles={{ textAlign: 'right' }}
                                alt="close"
                                size="utility"
                                onClick={handleClose}
                            />
                        </Container>

                        <div style={searchResultStyles}>
                            {hasResults ? (
                                <>
                                    <SearchHeader name="Membership" show={!!memberships?.length} />
                                    {memberships?.map((membership, index) => {
                                        const disabled = membership.is_organization_member
                                            ? !membership.is_organization_member
                                            : !membership.membership_id;

                                        const name = `${getTextLabel(membership.serial)} - ${
                                            membership.is_organization_member ? 'ORG' : 'D2C'
                                        } - ${getTextLabel(membership.email)}`;

                                        return (
                                            <SearchItem
                                                key={`${index}-membership-${membership.serial}`}
                                                icon={<UserIcon size="utility" type="brand" />}
                                                name={name}
                                                onSelect={() => handleSelectMembership(membership)}
                                                disabled={disabled}
                                            />
                                        );
                                    })}
                                    <SearchHeader name="Device" show={!!devices?.length} />
                                    {devices?.map((device, index) => {
                                        return (
                                            <SearchItem
                                                key={`${index}-rma-${device.serial}`}
                                                icon={<DeviceIcon size="utility" />}
                                                name={`${getTextLabel(device.serial)}${!device.device_id ? ' Not fulfilled' : ''}`}
                                                onSelect={() => handleSelectDevice(device)}
                                                disabled={!device.device_id}
                                            />
                                        );
                                    })}
                                    <SearchHeader name="RMA" show={!!rmaList?.length} />
                                    {rmaList?.map((rma, index) => {
                                        return (
                                            <SearchItem
                                                key={`${index}-rma-${rma.serial}`}
                                                icon={<TruckIcon size="utility" />}
                                                name={`${rma.serial} - ${getRmaTypeLabel(
                                                    rma.rma_type
                                                )} ${rma.rma_type === 2 ? getTextLabel(rma.exchange_serial) : ''}`}
                                                onSelect={() => handleSelectRma(rma)}
                                                disabled={!rma.rma_id}
                                            />
                                        );
                                    })}
                                    <SearchHeader name="Users" show={!!users?.length} />
                                    {users?.map((user, index) => {
                                        const name =
                                            user.first_name && user.last_name
                                                ? `${user.first_name} ${user.last_name}`
                                                : 'N/A';

                                        return (
                                            <SearchItem
                                                key={`${index}-user-${user.serial}`}
                                                icon={<UserIcon size="utility" type="brand" />}
                                                name={`${name} - ${getUserRoleLabel(user.role)} - ${getTextLabel(
                                                    user.serial
                                                )}${!user.user_id ? ' Not fulfilled' : ''}`}
                                                onSelect={() => handleSelectUser(user)}
                                                disabled={!user.user_id}
                                            />
                                        );
                                    })}
                                </>
                            ) : null}

                            {hasData && !hasResults && !isError ? (
                                <NoResults search={searchTerm} />
                            ) : null}
                            {!hasData && !hasResults && !isError ? (
                                <P
                                    noDefaultMargin
                                    strong
                                    styles={{ marginTop: '1rem' }}
                                    size="small"
                                    centered
                                >
                                    You can search by Email, Serial, external user id
                                </P>
                            ) : null}
                            {fetchStatus !== 'idle' && isLoading ? <InlineLoader /> : null}
                            {isError ? (
                                <QueryInlineError
                                    styles={{ marginTop: '2.4rem' }}
                                    centered
                                    error={error}
                                />
                            ) : null}
                        </div>
                    </div>
                </>
            ) : null}
            {rmaId ? (
                <RmaDetailsOnSearch rmaId={rmaId} onCloseDetails={() => setRmaId(undefined)} />
            ) : null}
        </>
    );
};

export default MainSearch;
