import React from 'react';
import Divider from '@hero/ui-kit/components/Divider';
import DetailsSectionCancellation from './DetailsSectionCancellation';
import DetailsSectionTransaction from './DetailsSectionTransaction';
import DetailsSectionReason from './DetailsSectionReason';
import UnreturnedDeviceFee from '../UnreturnedDeviceFee';
import { dayDiff } from '@hero/hero-utils/date';
import DetailsCollectionAgency from './DetailsCollectionAgency';
import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    CancellationDetails,
    CancellationOptions,
    RmaEnums
} from '@hero/hero-types';

type Props = {
    membership?: BackofficeMembershipDetails;
    rmaEnums?: RmaEnums;
    cancellationDetails: CancellationDetails;
    cancellationOptions?: CancellationOptions;
    userDetails?: BackofficeUserDeviceDetails;
    onRefetchCancellationDetails: () => void;
};

const MembershipCancellationDetails: React.FC<Props> = ({
    cancellationDetails,
    onRefetchCancellationDetails,
    membership,
    cancellationOptions,
    userDetails,
    rmaEnums
}) => {
    const wrapStyles: React.CSSProperties = {
        maxWidth: '120rem'
    };

    const isRetryActive = React.useMemo<boolean>(() => {
        const isSendToCollectionAgency =
            cancellationDetails?.cancellation?.sent_to_collection_agency || false;

        return !isSendToCollectionAgency;
    }, [cancellationDetails]);

    const isRetryCancellationFeeActive = React.useMemo<boolean>(() => {
        const cancellationFeeOutstanding =
            cancellationDetails?.cancellation?.cancellation_fee_outstanding || 0;
        return isRetryActive && cancellationFeeOutstanding > 0;
    }, [cancellationDetails, isRetryActive]);

    const isRetryUnreturnedDeviceFeeActive = React.useMemo<boolean>(() => {
        const cancelledAt = cancellationDetails?.subscription?.cancelled_at;
        const cancelledDaysDiff = cancelledAt ? dayDiff(Date(), cancelledAt) : 0;
        const isNotShippedReturnStatus = cancellationDetails?.rma?.return_status === 1;
        const unreturnedDeviceFeeOutstanding =
            cancellationDetails?.cancellation?.unreturned_device_fee_outstanding || 0;

        return (
            isRetryActive &&
            cancelledDaysDiff >= 30 &&
            isNotShippedReturnStatus &&
            unreturnedDeviceFeeOutstanding > 0
        );
    }, [isRetryActive, cancellationDetails]);

    return (
        <div style={wrapStyles}>
            <>
                <DetailsSectionCancellation data={cancellationDetails} />
                <Divider />
                <DetailsSectionTransaction
                    membership={membership}
                    rmaEnums={rmaEnums}
                    data={cancellationDetails}
                    isRetryActive={isRetryCancellationFeeActive}
                    fetchCancellationDetails={onRefetchCancellationDetails}
                    onCompleteFlow={onRefetchCancellationDetails}
                    cancellationOptions={cancellationOptions}
                    userDetails={userDetails}
                />
                <Divider />
                <UnreturnedDeviceFee
                    data={cancellationDetails}
                    onCompleteFlow={onRefetchCancellationDetails}
                    isRetryActive={isRetryUnreturnedDeviceFeeActive}
                />
                <Divider />
                <DetailsCollectionAgency data={cancellationDetails} />
                <Divider />
                <DetailsSectionReason data={cancellationDetails} />
            </>
        </div>
    );
};

export default MembershipCancellationDetails;
