import React, { CSSProperties } from 'react';

import { EnterpriseEnrollment, Cancellation } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import A from '@hero/ui-kit/typography/A';

type Props = {
    enrollment: EnterpriseEnrollment;
    cancellation: Cancellation;
};

const DetailsSectionTransfer: React.FC<Props> = ({ enrollment, cancellation }) => {
    const email = enrollment.member?.contact?.email;
    const subscription_id = cancellation.subscription_id;

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    return (
        <Container gridTemplateColumns="5fr 4fr">
            <div>
                <P strong styles={sectionLabelStyles}>
                    Transfer
                </P>
                <Container gridTemplateColumns="2fr 3fr">
                    <P strong>New B2C Profile</P>
                    <P>
                        {subscription_id ? (
                            <A to={`/membership/${subscription_id}/details`}>
                                {email || '(no email)'}
                            </A>
                        ) : (
                            email || '-'
                        )}
                    </P>
                </Container>
            </div>
        </Container>
    );
};

export default DetailsSectionTransfer;
