import React, { useCallback, useMemo } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import { ProspectSignupParams } from '@hero/validators/forms/backoffice/prospectSignupSchema';

import FormattedAddress from '../../../components/FormattedAddress';

type Props = {
    submittedValues: ProspectSignupParams | null;
    onConfirm: () => void;
} & Pick<ModalProps, 'externalControls'>;

const ConfirmSignupModal: React.FC<Props> = ({ submittedValues, onConfirm, externalControls }) => {
    const [, setExternalState] = externalControls ?? [];

    const planProcessed = useMemo(() => {
        const planOption = submittedValues?.plan;
        if (planOption === 'annual') {
            return 'Annual $34.99 / 12 month commitment';
        }
        if (planOption === 'biannual') {
            return '24 Months $29.99 / 24 month commitment';
        }
        return 'Monthly $44.99 / no commitment';
    }, [submittedValues]);

    const surveyProcessed = useMemo(() => {
        const { heard_from, podcast_name, other } = submittedValues || {};
        const line1 = heard_from?.toLowerCase() === 'other' ? other : heard_from;
        const line2 = heard_from?.toLowerCase().includes('podcast')
            ? podcast_name?.toLowerCase() === 'other'
                ? other
                : podcast_name
            : undefined;

        return line1 ? (
            <>
                {line1}
                {line2 && <br />}
                {line2}
            </>
        ) : (
            '-'
        );
    }, [submittedValues]);

    const confirmationChannelProcessed = useMemo(() => {
        const confirmationChannelOption = submittedValues?.confirmation_channel;
        return confirmationChannelOption === 'customer_email'
            ? 'Customer'
            : confirmationChannelOption === 'agent_email'
            ? 'Me'
            : '-';
    }, [submittedValues]);

    const handleCancel = useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    return (
        <Modal externalControls={externalControls} isCancelable>
            <H role="h4" centered>
                Order Confirmation
            </H>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ width: '100%' }}
            >
                <P strong>First Name</P>
                <P>{submittedValues?.first_name || '-'}</P>
                <P strong>Last Name</P>
                <P>{submittedValues?.last_name || '-'}</P>
                <P strong>Email Address</P>
                <P>{submittedValues?.email || '-'}</P>
                <P strong>Phone Number</P>
                <P>{submittedValues?.home_phone || '-'}</P>
                <P strong>Address</P>
                <P>
                    <FormattedAddress address={submittedValues} />
                </P>
                <P strong>Promo Code</P>
                <P>{submittedValues?.discount_code || '-'}</P>
                <P strong>Membership</P>
                <P>{planProcessed}</P>
                <P strong>How They Heard About Us</P>
                <P>{surveyProcessed}</P>
                <P strong>Send Email Confirmation To</P>
                <P>{confirmationChannelProcessed}</P>
            </Container>
            <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                <Button width="large" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button width="large" variant="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </Section>
        </Modal>
    );
};

export default ConfirmSignupModal;
