import React, { FC } from 'react';
import NewProviderElectionProvider from './context/NewProviderElectionProvider';
import NewProviderElectionStepsModal from './NewProviderElectionStepsModal';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import { EnterpriseEnrollment } from '@hero/hero-types';

type Props = {
    isLoading?: boolean;
    onCompleteCallback?: () => void;
    onCloseModal?: () => void;
    enrollment: EnterpriseEnrollment;
} & Pick<ModalProps, 'externalControls'>;

const NewProviderElection: FC<Props> = ({
    enrollment,
    externalControls,
    isLoading,
    onCloseModal
}) => {
    return (
        <NewProviderElectionProvider onCloseModal={onCloseModal} enrollment={enrollment}>
            <NewProviderElectionStepsModal
                externalControls={externalControls}
                isLoading={isLoading}
            />
        </NewProviderElectionProvider>
    );
};

export default NewProviderElection;
