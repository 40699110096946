import React from 'react';

import { Schedule } from '@hero/hero-types';
import Section from '@hero/ui-kit/layout/Section';

import { DetailsContainer, DetailLabel, Detail } from '../../../../components/Details';

type Props = {
    schedule: Schedule;
};

const PillScheduleDetails: React.FC<Props> = ({ schedule }) => (
    <Section border marginTop="regular">
        <DetailsContainer>
            {schedule.dow && (
                <>
                    <DetailLabel>Days</DetailLabel>
                    <Detail>{schedule.dow}</Detail>
                </>
            )}
            {schedule.every_x_days && (
                <>
                    <DetailLabel>Days</DetailLabel>
                    <Detail>{schedule.every_x_days}</Detail>
                </>
            )}
            {schedule.next_date && (
                <>
                    <DetailLabel>Days</DetailLabel>
                    <Detail>{schedule.next_date}</Detail>
                </>
            )}
            <DetailLabel>Time</DetailLabel>
            <Detail>{schedule.times.join(', ')}</Detail>
            <DetailLabel>Dosage</DetailLabel>
            <Detail>{schedule.qty}</Detail>
        </DetailsContainer>
    </Section>
);

export default PillScheduleDetails;
