import React, { CSSProperties, useState, useCallback } from 'react';

import {
    EnterpriseEnrollment,
    Cancellation,
    RmaRequest,
    RmaEnums,
    EnterpriseCancellationDetails,
    EnterpriseCancellationOptions
} from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import CancellationFlow from '../../../../views/CancellationFlow';
import RmaDetails from '../../../Rma/RmaDetails';
import useRmaEnums from '../../../Rma/utils/useRmaEnums';
import { shallowEqual, useSelector } from 'react-redux';
import { isEnterpriseMembershipCancellationUpdatedSelector } from '@hero/redux-data/backoffice/enterpriseMembershipCancellation/selectors';
import RmaDetailsDataProviderV2 from '../../../Rma/RmaDetails/RmaDetailsDataProviderV2';

type Props = {
    enrollment: EnterpriseEnrollment;
    cancellation: Cancellation;
    rma?: RmaRequest | null;
    fetchEnterpriseCancellationDetails?: () => void;
    rmaEnums?: RmaEnums;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    orgCancellationOptions?: EnterpriseCancellationOptions;
};

const DetailsSectionRma: React.FC<Props> = ({
    enrollment,
    rmaEnums,
    rma,
    fetchEnterpriseCancellationDetails,
    orgCancellationDetails,
    orgCancellationOptions
}) => {
    const { rmaStatuses } = useRmaEnums(rmaEnums);

    const isCancelDone = useSelector(
        isEnterpriseMembershipCancellationUpdatedSelector,
        shallowEqual
    );

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };
    const buttonStyles: CSSProperties = {
        verticalAlign: 'top',
        marginTop: '-0.4rem'
    };

    const [openCancellationFlow, setOpenCancellationFlow] = useState(false);
    const [openRmaDetails, setOpenRmaDetails] = useState(false);

    React.useEffect(() => {
        if (isCancelDone) {
            setOpenCancellationFlow(false);
            window?.location?.reload();
        }
    }, [isCancelDone]);

    const handleOpenCancellationFlow = useCallback(() => {
        setOpenCancellationFlow(true);
    }, []);

    const handleOpenRmaDetails = useCallback(() => {
        rma?.rma_type === 1 && rma?.rma_status === 0
            ? handleOpenCancellationFlow()
            : setOpenRmaDetails(true);
    }, [handleOpenCancellationFlow, rma]);

    const handleCloseRmaDetails = useCallback(() => {
        setOpenRmaDetails(false);
        fetchEnterpriseCancellationDetails && fetchEnterpriseCancellationDetails();
    }, [fetchEnterpriseCancellationDetails]);

    return (
        <Container gridTemplateColumns="5fr 4fr">
            <Container gridTemplateColumns="2fr 3fr">
                <P strong styles={sectionLabelStyles}>
                    RMA
                </P>
                <P>
                    {rma?.rma_status === 0 || rma === null ? (
                        <Button
                            size="small"
                            onClick={handleOpenCancellationFlow}
                            noDefaultMargin
                            styles={buttonStyles}
                        >
                            Create RMA
                        </Button>
                    ) : null}
                </P>
                <P strong>RMA #</P>
                <P>
                    {rma && rma.rma_status !== 0 && rma.id > 0 ? (
                        <Button size="small" onClick={handleOpenRmaDetails} noDefaultMargin>
                            {rma.id}
                        </Button>
                    ) : (
                        'N/A'
                    )}
                </P>
                <P strong>Status</P>
                <P>{rma?.rma_status && rmaStatuses ? rmaStatuses[rma.rma_status] : 'N/A'}</P>
            </Container>
            {openCancellationFlow ? (
                <CancellationFlow
                    isOrganizationMember
                    isCreateNewRma
                    externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                    onCompleteCallback={fetchEnterpriseCancellationDetails}
                    enterpriseEnrollment={enrollment}
                    rmaEnums={rmaEnums}
                    orgCancellationOptions={orgCancellationOptions}
                    orgCancellationDetails={orgCancellationDetails}
                />
            ) : null}
            {rma && rma.id > 0 && openRmaDetails ? (
                <RmaDetailsDataProviderV2
                    rmaId={rma.id}
                    enrollmentId={enrollment.id}
                    isOrganizationMember
                >
                    <RmaDetails isOrganizationMember onCloseModal={handleCloseRmaDetails} />
                </RmaDetailsDataProviderV2>
            ) : null}
        </Container>
    );
};

export default DetailsSectionRma;
