import { useSelector, shallowEqual } from 'react-redux';
import { membershipDetailsSelector } from '@hero/redux-data/backoffice/membershipDetails/selectors';
import { enterpriseMemberDetailsSelector } from '@hero/redux-data/backoffice/enterpriseMemberDetails/selectors';
const getParam = (param1, param2, isOrganizationMember = false) => (isOrganizationMember ? param1 : param2);
const useCustomerContactDetails = (isOrganizationMember = false, membershipData, enrollmentData) => {
    const membership = membershipData || useSelector(membershipDetailsSelector, shallowEqual);
    const enrollment = enrollmentData || useSelector(enterpriseMemberDetailsSelector, shallowEqual);
    const enrollmentAddress = enrollment?.member?.contact?.address || enrollment?.contact?.address;
    const membershipAddress = membership?.order_address;
    const addressLine1 = getParam(enrollmentAddress?.address_line_1, membershipAddress?.address1, isOrganizationMember);
    const addressLine2 = getParam(enrollmentAddress?.address_line_2, membershipAddress?.address2, isOrganizationMember);
    const city = getParam(enrollmentAddress?.city, membershipAddress?.city, isOrganizationMember);
    const state = getParam(enrollmentAddress?.state, membershipAddress?.province, isOrganizationMember);
    const zip = getParam(enrollmentAddress?.zip, membershipAddress?.zip_code, isOrganizationMember);
    const phone = isOrganizationMember
        ? enrollment?.member?.contact?.home_phone ||
            enrollment?.member?.contact?.cell_phone ||
            enrollment?.contact?.home_phone
        : membership?.order_address?.phone;
    return {
        ...(addressLine1 && { address_line_1: addressLine1 }),
        ...(addressLine2 && { address_line_2: addressLine2 }),
        ...(city && { city }),
        ...(state && { state }),
        ...(zip && { zip }),
        ...(phone && { home_phone: phone })
    };
};
export default useCustomerContactDetails;
