import React, { useCallback } from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';
import ButtonLink from '../../../../components/ButtonLink';
import { RmaTableDataObject } from './useTableDataRepack';

type ActionData = {
    id: number;
    rma_status: string | null;
    rma_type: string | null;
};
export type ActionHandler = (data: ActionData) => void;
type Render = TableColumn<RmaTableDataObject>['render'];

type GetActionRender = (actionHandler: ActionHandler) => Render;

const getViewDetailsRender: GetActionRender =
    (actionHandler) =>
    ({ id, rma_status, rma_type }) => {
        const handleClick = useCallback(() => {
            actionHandler({ id, rma_status, rma_type });
        }, [id, rma_status, rma_type]);

        return (
            <ButtonLink alt="View Details" onClick={handleClick}>
                {id}
            </ButtonLink>
        );
    };

type GetTableColumns = (viewActionHandler: ActionHandler) => TableColumn<RmaTableDataObject>[];
const getTableColumns: GetTableColumns = (viewActionHandler) => [
    {
        colKey: 'id',
        name: 'RMA #',
        align: 'left',
        headAlign: 'left',
        render: getViewDetailsRender(viewActionHandler)
    },
    {
        colKey: 'customer_name',
        name: 'Customer\nName'
    },
    {
        colKey: 'rma_type',
        name: 'RMA\nType',
        isSortable: true
    },
    {
        colKey: 'rma_status',
        name: 'RMA\nStatus',
        isSortable: true
    },
    {
        colKey: 'return_status',
        name: 'Return\nStatus',
        isSortable: true
    },
    {
        colKey: 'refund_status',
        name: 'Refund\nStatus',
        isSortable: true
    },
    {
        colKey: 'created_at',
        name: 'Created\nDate',
        isSortable: true
    },
    {
        colKey: 'last_updated_at',
        name: 'Last\nUpdated',
        isSortable: true
    },
    {
        colKey: 'created_by',
        name: 'Created By'
    }
];

export default getTableColumns;
