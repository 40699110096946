import React, { CSSProperties, FC, useCallback, useMemo } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';

import InlineLoader from '../../components/InlineLoader';
import ModalFlowHeader from '../../components/ModalFlowHeader';
import useModalFlowEyebrow from '../../hooks/useModalFlowEyebrow';
import { useNewProviderElection } from './context/NewProviderElectionProvider';
import ElectionStep from './steps/Election';
import InsuranceStep from './steps/Insurance';
import UserRoleStep from './steps/UserRole';
import SubmitStep from './steps/Submit';
import SuccessStep from './steps/Success';

type Props = { isLoading?: boolean } & Pick<ModalProps, 'externalControls'>;

const NewProviderElectionStepsModal: FC<Props> = ({ externalControls, isLoading = false }) => {
    const { step, changeStep, onCloseModal } = useNewProviderElection();
    const [, setExternalState] = externalControls ?? [];
    const eyebrow = useModalFlowEyebrow(true);

    const modalType: ModalProps['type'] = useMemo(
        () => (step === 'Success' ? 'success' : 'neutral'),
        [step]
    );

    const currentStep = useMemo(() => {
        switch (step) {
            case 'Insurance':
                return 2;
            case 'Election':
                return 3;
            case 'Submit':
                return 4;
            default:
                return 1;
        }
    }, [step]);

    const steps = useMemo(() => {
        return step !== 'Success' ? ['PU/CG', 'Insurance', 'Election', 'Submit'] : undefined;
    }, [step]);

    const modalPadding = useSectionPadding({ padding: 'regular' });
    const innerWrapPadding = useComponentPadding({ padding: 'regular' });

    const modalStyles: CSSProperties = {
        width: '100rem',
        ...modalPadding
    };

    const innerWrapStyles: CSSProperties = {
        alignSelf: 'stretch',
        ...innerWrapPadding
    };

    const StepComponent = useMemo(() => {
        switch (step) {
            case 'Insurance':
                return InsuranceStep;
            case 'Election':
                return ElectionStep;
            case 'Submit':
                return SubmitStep;
            case 'Success':
                return SuccessStep;
            default:
                return UserRoleStep;
        }
    }, [step]);

    const closeModal = useCallback(() => {
        onCloseModal && onCloseModal();
        typeof setExternalState === 'function' && setExternalState(false);
    }, [setExternalState, changeStep, step]);

    return (
        <Modal
            width="page"
            externalControls={externalControls}
            type={modalType}
            styles={modalStyles}
            noDefaultPadding
            isCancelable
            onClose={closeModal}
        >
            {isLoading ? (
                <InlineLoader />
            ) : (
                <>
                    <ModalFlowHeader
                        heading="Election Flow"
                        eyebrow={eyebrow}
                        steps={steps}
                        currentStep={currentStep}
                    />
                    <div style={innerWrapStyles}>
                        <StepComponent closeModal={closeModal} />
                    </div>
                </>
            )}
        </Modal>
    );
};

export default NewProviderElectionStepsModal;
