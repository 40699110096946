import React from 'react';

import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

const SideBySideComparisonHeader: React.FC = () => {
    const padding = useComponentPadding({ paddingVertical: 'small', paddingHorizontal: 'regular' });

    return (
        <>
            <P noDefaultMargin strong styles={{ ...padding, gridColumnStart: 2 }}>
                Organization Submitted Details
            </P>
            <P noDefaultMargin strong styles={{ ...padding, gridColumnStart: 3 }}>
                Contact Submitted Details
            </P>
        </>
    );
};

export default SideBySideComparisonHeader;
