import useTheme from '@hero/branding/theme';
import React from 'react';
import P from '@hero/ui-kit/typography/P';
import { StripeForm, StripeWrapper } from '../../../../../components/Stripe';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';

export const FOP = () => {
    const { onCreditCardUpdate, onCreditCardCancel, updateCardErrorMessage } =
        useReactivationFlowContext();
    const { colors } = useTheme();
    return (
        <>
            <StripeWrapper>
                <StripeForm onSubmit={onCreditCardUpdate} onCancel={onCreditCardCancel} />
            </StripeWrapper>

            <P role="alert" centered noDefaultMargin styles={{ color: colors.status.fail.alpha }}>
                {updateCardErrorMessage}
            </P>
        </>
    );
};
