import * as React from 'react';
import H from '@hero/ui-kit/typography/H';
import { usStates } from '@hero/hero-utils/address/states';
import Container from '@hero/ui-kit/layout/Container';
import Input from '@hero/ui-kit/inputs/Input';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import AddressCombobox from '../../../components/AddressCombobox';

interface PickupLocationProps {
    state?: string;
    headerText?: string;
    hidePhoneField?: boolean;
}

const PickupLocation: React.FC<PickupLocationProps> = ({
    headerText = 'Pickup location',
    hidePhoneField = false
}) => {
    return (
        <>
            <H role="h6" styles={{ marginTop: '1.2rem', marginBottom: '1.4rem' }}>
                {headerText}
            </H>
            <StandaloneCheckbox
                name="send_box"
                labelText="Replacement box required"
                styles={{ marginBottom: '1.2rem' }}
            />
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                <AddressCombobox displayAddressName="Street Address" />
                <Input name="address_line_2" displayName="Street Address 2 (Optional)" />
                <Input name="city" displayName="City" />
                <DefaultSelect
                    options={usStates.map(({ code, name }) => ({ value: code, label: name }))}
                    name="state"
                    displayName="State"
                />
                <Input name="zip" displayName="Zip Code" />
                {!hidePhoneField ? <Input name="home_phone" displayName="Phone Number" /> : null}
            </Container>
        </>
    );
};

export default PickupLocation;
