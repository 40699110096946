import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Section from '@hero/ui-kit/layout/Section';
import TabBar from '../TabBar';
import { MembershipRouteParams } from '../types';
import { getMembershipDetails } from '@hero/redux-data/backoffice/membershipDetails/actionCreators';
import Form from '@hero/ui-kit/inputs/Form';
import RTMFormFields from './RTMFormFields';
import rtmMembershipSchema, {
    RtmMembershipParams
} from '@hero/validators/forms/backoffice/rtmMembershipSchema';
import {
    areMembershipDetailsLoadingSelector,
    membershipDetailsSelector,
    membershipDetailsErrorSelector
} from '@hero/redux-data/backoffice/membershipDetails/selectors';
import InlineLoader from '../../../components/InlineLoader';
import { getUserDeviceDetails } from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';
import {
    areUserDeviceDetailsLoadingSelector,
    userDeviceDetailsSelector,
    userDeviceDetailsErrorSelector
} from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import { getOrganizationList } from '@hero/redux-data/backoffice/oldOrganizationList/actionCreators';
import {
    isOrganizationListLoadingSelector,
    organizationListSelector,
    organizationListErrorSelector
} from '@hero/redux-data/backoffice/oldOrganizationList/selectors';
import {
    rtmSaveErrorSelector,
    rtmSaveSelector
} from '@hero/redux-data/backoffice/rtmSave/selectors';
import { rtmStatusDetailsErrorSelector } from '@hero/redux-data/backoffice/rtmStatus/selectors';
import P from '@hero/ui-kit/typography/P';
import useTheme from '@hero/branding/theme';
import useGetDetailViewTitle from '../utils/useGetDetailViewTitle';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import {
    getRtmStatusDetails,
    rmaRequestDetailsReset
} from '@hero/redux-data/backoffice/rtmStatus/actionCreators';
import { rtmSave, rtmSaveReset } from '@hero/redux-data/backoffice/rtmSave/actionCreators';
import { CaregiverAccount } from '@hero/hero-types';
import Modal from '@hero/ui-kit/components/Modal';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import DefaultLayout from '../../../components/DefaultLayout';

const ErrorComponent: React.FC<{ showError?: boolean; errorMessage?: string }> = ({
    showError = false,
    errorMessage = 'Something went wrong, please contact support.'
}) => {
    const theme = useTheme();

    if (!showError) {
        return null;
    }

    return (
        <P size="large" strong noDefaultMargin styles={{ color: theme.colors.status.fail.alpha }}>
            {errorMessage}
        </P>
    );
};

const RTMPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams<MembershipRouteParams>();
    const [selectedOrgId, setSelectedOrgId] = React.useState(0);

    // loading
    const isMembershipLoading = useSelector(areMembershipDetailsLoadingSelector, shallowEqual);
    const isOrganizationsLoading = useSelector(isOrganizationListLoadingSelector, shallowEqual);
    const isUserDetailsLoading = useSelector(areUserDeviceDetailsLoadingSelector, shallowEqual);

    // data
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const userDetails = useSelector(userDeviceDetailsSelector, shallowEqual);
    const organizations = useSelector(organizationListSelector, shallowEqual);
    const rtmSaveData = useSelector(rtmSaveSelector, shallowEqual);

    // errors
    const { error: isFetchOrgError, errorMessage: fetchOrgErrorMessage } = useSelector(
        organizationListErrorSelector,
        shallowEqual
    );
    const { error: isFetchUserError, errorMessage: fetchUserErrorMessage } = useSelector(
        userDeviceDetailsErrorSelector,
        shallowEqual
    );
    const { error: isFetchMembershipError, errorMessage: fetchMembershipErrorMessage } =
        useSelector(membershipDetailsErrorSelector, shallowEqual);
    const { error: isFetchRtmStatusError, errorMessage: fetchRtmStatusErrorMessage } = useSelector(
        rtmStatusDetailsErrorSelector,
        shallowEqual
    );
    const { error: isRtmSaveError, errorMessage: rtmSaveErrorMessage } = useSelector(
        rtmSaveErrorSelector,
        shallowEqual
    );

    React.useEffect(() => {
        id && dispatch(getMembershipDetails({ id: +id }));
    }, [id, dispatch]);

    React.useEffect(() => {
        const user_id = membership?.hero_user_id ? +membership.hero_user_id : undefined;

        if (user_id) {
            dispatch(
                getUserDeviceDetails({
                    user_id
                })
            );
        }
    }, [membership, dispatch]);

    React.useEffect(() => {
        dispatch(getOrganizationList({}));
    }, [dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(rtmSaveReset());
            dispatch(rmaRequestDetailsReset());
        };
    }, [dispatch]);

    const isUserOwnerAdmin: boolean = React.useMemo(() => {
        return userDetails?.user?.is_owner && !userDetails.user.is_read_only_owner ? true : false;
    }, [userDetails]);

    const caregiverAdmin: CaregiverAccount | undefined = React.useMemo(() => {
        if (isUserOwnerAdmin) {
            return undefined;
        }
        const deviceCaregivers = userDetails?.device?.caregivers || [];

        if (deviceCaregivers.length > 0 && !deviceCaregivers[0].is_read_only) {
            return deviceCaregivers[0];
        }

        const caregivers = userDetails?.user?.caregiver_accounts || [];
        const userId = userDetails?.user?.id;
        const userCaregiver = caregivers.find((item) => item.caregiver_user_id === userId);

        if (userCaregiver && !userCaregiver.is_read_only) {
            return userCaregiver;
        }

        return undefined;
    }, [userDetails, isUserOwnerAdmin]);

    const defaultValues = React.useMemo(() => {
        const ownerCountry = membership?.address?.province || undefined;

        return {
            application_submit: isUserOwnerAdmin ? ('myself' as const) : ('loved_one' as const),
            new_dispenser: membership?.status === 'cancelled' ? ('yes' as const) : ('no' as const),
            agreement: false,
            first_name: isUserOwnerAdmin
                ? userDetails?.user?.first_name || ''
                : caregiverAdmin?.caregiver_first_name || '',
            last_name: isUserOwnerAdmin
                ? userDetails?.user?.last_name || ''
                : caregiverAdmin?.caregiver_last_name || '',
            email: isUserOwnerAdmin
                ? userDetails?.user?.email || ''
                : caregiverAdmin?.caregiver_email || '',
            phone: membership?.address?.phone || undefined,
            state: ownerCountry,
            organization_id: undefined,
            is_tcpa_opt_in: false
        };
    }, [userDetails, membership, isUserOwnerAdmin, caregiverAdmin]);

    const heroUserId: number = React.useMemo(() => {
        if (!isUserOwnerAdmin && caregiverAdmin) {
            return caregiverAdmin.caregiver_user_id;
        }
        return userDetails?.user?.id || 0;
    }, [userDetails, isUserOwnerAdmin, caregiverAdmin]);

    const selectedOrgName = React.useMemo(() => {
        if (selectedOrgId > 0) {
            const selectedOrg = organizations.find((org) => org.id === selectedOrgId);
            return selectedOrg?.name;
        }
        return undefined;
    }, [organizations, selectedOrgId]);

    const handleBackToList = React.useCallback(() => {
        navigate('/membership', { replace: true });
    }, [navigate]);

    const handleOrgChange = React.useCallback(
        (orgId: string) => {
            setSelectedOrgId(+orgId);
            if (membership?.order_id) {
                dispatch(
                    getRtmStatusDetails({ order_id: membership.order_id, organization_id: +orgId })
                );
            }
        },
        [membership, dispatch]
    );

    const handleSubmit = React.useCallback(
        (attributes: RtmMembershipParams) => {
            if (membership?.order_id) {
                const formattedPhone = formatPhoneNumber(attributes.phone);
                const data = {
                    first_name: attributes.first_name || undefined,
                    last_name: attributes.last_name || undefined,
                    state: attributes.state,
                    is_caregiver: attributes.application_submit === 'loved_one',
                    needs_fulfillment: attributes.new_dispenser === 'yes',
                    contact_email: attributes.email,
                    contact_phone: formattedPhone,
                    hero_user_id: heroUserId,
                    organization_id: +attributes.organization_id,
                    order_id: membership.order_id,
                    is_tcpa_opt_in: attributes.is_tcpa_opt_in
                };

                dispatch(rtmSave(data));
            }
        },
        [membership, heroUserId, dispatch]
    );

    const handleConfirmModal = React.useCallback(() => {
        dispatch(rtmSaveReset());
        if (selectedOrgId && membership?.order_id) {
            dispatch(
                getRtmStatusDetails({
                    order_id: membership.order_id,
                    organization_id: selectedOrgId
                })
            );
        }
    }, [dispatch, selectedOrgId, membership]);

    const headerTitle = useGetDetailViewTitle();

    const headerWrapStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const isLoading = isMembershipLoading || isOrganizationsLoading || isUserDetailsLoading;

    return (
        <DefaultLayout>
            {isLoading ? (
                <InlineLoader />
            ) : (
                <Section
                    subtheme={['regular', 'background']}
                    paddingVertical="small"
                    paddingHorizontal="regular"
                >
                    <div style={headerWrapStyles}>
                        <H role="h5">{headerTitle}</H>
                        <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                            Back To List
                        </Button>
                    </div>
                    <TabBar />
                    <Form
                        submitFn={handleSubmit}
                        validationSchema={rtmMembershipSchema}
                        defaultValues={defaultValues}
                    >
                        <RTMFormFields
                            organizations={organizations}
                            onOrgChange={handleOrgChange}
                            selectedOrgName={selectedOrgName}
                            isMembershipActive={membership?.status === 'active'}
                            defaultState={defaultValues.state}
                        />
                    </Form>
                    <ErrorComponent
                        showError={isFetchOrgError}
                        errorMessage={fetchOrgErrorMessage || undefined}
                    />
                    <ErrorComponent
                        showError={isFetchUserError}
                        errorMessage={fetchUserErrorMessage || undefined}
                    />
                    <ErrorComponent
                        showError={isFetchMembershipError}
                        errorMessage={fetchMembershipErrorMessage || undefined}
                    />
                    <ErrorComponent
                        showError={isFetchRtmStatusError}
                        errorMessage={fetchRtmStatusErrorMessage || undefined}
                    />
                    <ErrorComponent
                        showError={isRtmSaveError}
                        errorMessage={rtmSaveErrorMessage || undefined}
                    />
                    <ErrorComponent
                        showError={!membership?.order_id}
                        errorMessage={
                            'Membership account does not have order id. Please contact support.'
                        }
                    />
                    {rtmSaveData?.successful ? (
                        <Modal>
                            <Section noDefaultPadding centered>
                                <P size="large" strong noDefaultMargin>
                                    {`User with email: ${defaultValues.email} successfully enrolled`}
                                </P>
                                <Button onClick={handleConfirmModal}>Confirm</Button>
                            </Section>
                        </Modal>
                    ) : null}
                </Section>
            )}
        </DefaultLayout>
    );
};

export default RTMPage;
