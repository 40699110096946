import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUserCreditSavedSelector } from '@hero/redux-data/backoffice/userCredit/selectors';
import { useNotifications } from '../../context/notification';
const useUserCreditAddSuccessAlert = () => {
    const isCreditAdded = useSelector(isUserCreditSavedSelector);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isCreditAdded) {
            id.current = addNotification({ type: 'success', message: 'Credit has been added.' });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isCreditAdded]);
};
export default useUserCreditAddSuccessAlert;
