import { verifyGeneral, verifyPhone, verifyAddress, verifyState } from '@hero/hero-utils/verifyPersonalData';
const usePersonalDataVerification = (organizationSubmitted, contactSubmitted) => {
    let hasDiscrepancyInFirstName = false;
    let hasDiscrepancyInLastName = false;
    let hasDiscrepancyInEmailAddress = false;
    let hasDiscrepancyInPhoneNumber = false;
    let hasDiscrepancyInStreetAddress = false;
    let hasDiscrepancyInCity = false;
    let hasDiscrepancyInState = false;
    let hasDiscrepancyInZipCode = false;
    if (organizationSubmitted?.first_name && contactSubmitted?.first_name) {
        hasDiscrepancyInFirstName = !verifyGeneral(contactSubmitted.first_name, organizationSubmitted.first_name);
    }
    if (organizationSubmitted?.last_name && contactSubmitted?.last_name) {
        hasDiscrepancyInLastName = !verifyGeneral(contactSubmitted.last_name, organizationSubmitted.last_name);
    }
    if (organizationSubmitted?.email && contactSubmitted?.email) {
        hasDiscrepancyInEmailAddress = !verifyGeneral(contactSubmitted.email, organizationSubmitted.email);
    }
    const organizationSubmittedPhones = [
        ...(organizationSubmitted?.home_phone ? [organizationSubmitted.home_phone] : []),
        ...(organizationSubmitted?.cell_phone ? [organizationSubmitted.cell_phone] : [])
    ];
    const contactSubmittedPhones = [
        ...(contactSubmitted?.home_phone ? [contactSubmitted.home_phone] : []),
        ...(contactSubmitted?.cell_phone ? [contactSubmitted.cell_phone] : [])
    ];
    if (organizationSubmittedPhones.length > 0 && contactSubmittedPhones.length > 0) {
        hasDiscrepancyInPhoneNumber = contactSubmittedPhones.every((contactSubmittedPhone) => organizationSubmittedPhones.every((organizationSubmittedPhone) => !verifyPhone(contactSubmittedPhone, organizationSubmittedPhone)));
    }
    if (organizationSubmitted?.address?.address_line_1 &&
        contactSubmitted?.address?.address_line_1) {
        hasDiscrepancyInStreetAddress = !verifyAddress(contactSubmitted.address.address_line_1, organizationSubmitted.address.address_line_1);
        if (organizationSubmitted.address.address_line_2 &&
            contactSubmitted.address.address_line_2) {
            hasDiscrepancyInStreetAddress = !verifyGeneral(contactSubmitted.address.address_line_2, organizationSubmitted.address.address_line_2);
        }
    }
    if (organizationSubmitted?.address?.city && contactSubmitted?.address?.city) {
        hasDiscrepancyInCity = !verifyGeneral(contactSubmitted.address.city, organizationSubmitted.address.city);
    }
    if (organizationSubmitted?.address?.state && contactSubmitted?.address?.state) {
        hasDiscrepancyInState = !verifyState(contactSubmitted.address.state, organizationSubmitted.address.state);
    }
    if (organizationSubmitted?.address?.zip && contactSubmitted?.address?.zip) {
        hasDiscrepancyInZipCode = !verifyGeneral(contactSubmitted.address.zip, organizationSubmitted.address.zip);
    }
    return {
        hasDiscrepancyInFirstName,
        hasDiscrepancyInLastName,
        hasDiscrepancyInEmailAddress,
        hasDiscrepancyInPhoneNumber,
        hasDiscrepancyInStreetAddress,
        hasDiscrepancyInCity,
        hasDiscrepancyInState,
        hasDiscrepancyInZipCode
    };
};
export default usePersonalDataVerification;
