import React, { useCallback } from 'react';

import { Variant } from '@hero/branding/types';
import { UiComponentProps, ButtonType } from '@hero/ui-kit/types';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import SearchIcon from '@hero/ui-kit/icons/utility/SearchIcon';
import XIcon from '@hero/ui-kit/icons/utility/XIcon';

export type ActionType = 'search' | 'clear';

type Props = {
    actionType: ActionType;
    onClick: (actionType: ActionType) => void;
    alt?: string;
    type?: ButtonType;
    variant?: Variant;
} & UiComponentProps;

const SearchButton: React.FC<Props> = ({ actionType, onClick, ...rest }) => {
    const handleClick = useCallback(() => {
        onClick(actionType);
    }, [actionType, onClick]);

    return (
        <CircularButton size="small" onClick={handleClick} {...rest}>
            {actionType === 'clear' ? <XIcon size="small" /> : <SearchIcon size="small" />}
        </CircularButton>
    );
};

export default SearchButton;
