import React from 'react';

import Eyebrow from '@hero/ui-kit/typography/Eyebrow';

import SearchForm from '../../../components/SearchForm';
import PageSizeForm from '../../../components/PageSizeForm';

import useDeviceListFilteringStyles from './useDeviceListFilteringStyles';

const DeviceListFiltering: React.FC = () => {
    const { eyebrowStyles, headerWrapStyles, searchWrapStyles } = useDeviceListFilteringStyles();
    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <SearchForm name="serial" placeholder="Search by device serial #" />
                    <Eyebrow styles={eyebrowStyles}>or</Eyebrow>
                    <SearchForm name="email" placeholder="Search by email" />
                </div>
                <PageSizeForm defaultLimit="20" />
            </div>
        </>
    );
};

export default DeviceListFiltering;
