import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { organizationErrorSelector } from '@hero/redux-data/backoffice/organization/selectors';
import { useNotifications } from '../../context/notification';
const useOrganizationFailAlert = () => {
    const { error, originalMessage } = useSelector(organizationErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage?.replace(/[_-]/g, ' ') || 'Error fetching organization.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, originalMessage]);
};
export default useOrganizationFailAlert;
