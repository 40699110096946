import React, { CSSProperties } from 'react';

import useTheme from '@hero/branding/theme';
import { UiComponentProps } from '@hero/ui-kit/types';

const Detail: React.FC<UiComponentProps & { children?: React.ReactNode }> = ({
    children,
    styles
}) => {
    const { typography } = useTheme();

    const mergedStyles: CSSProperties = {
        ...typography.paragraph.regular,
        gridColumnStart: 2,
        ...styles
    };

    return <dd style={mergedStyles}>{children}</dd>;
};

export default Detail;
