// import React from 'react';

import { BackofficeOrganizationDeviceMonitoring } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

type GetTableColumns = () => TableColumn<BackofficeOrganizationDeviceMonitoring>[];
const getTableColumns: GetTableColumns = () => [
    {
        colKey: 'email',
        name: 'Email',
        align: 'left',
        headAlign: 'left'
    },
    {
        colKey: 'serial',
        name: 'Serial'
    },
    {
        colKey: 'status',
        name: 'Status'
    },
    {
        colKey: 'slot',
        name: 'Slot'
    },
    {
        colKey: 'shipped',
        name: 'Shipped'
    },
    {
        colKey: 'delivered',
        name: 'Delivered'
    },
    {
        colKey: 'paired',
        name: 'Paired'
    },
    {
        colKey: 'configured',
        name: 'Configured'
    },
    {
        colKey: 'last_online',
        name: 'Last Online',
        isSortable: true
    },
    {
        colKey: 'offline',
        name: 'Offline'
    }
];

export default getTableColumns;
