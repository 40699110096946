import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { subscriptionChanges } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetMembershipChanges = (hero_user_id, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/member/subscription-changes`, {
            ...additional,
            params: { hero_user_id }
        }),
        queryKey: [subscriptionChanges, hero_user_id],
        enabled
    });
};
export default useGetMembershipChanges;
