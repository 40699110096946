import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { NotificationProvider } from './context/notification';
import { ViewConfigProvider } from './context/viewConfig';
import { MediaQueryContextProvider } from '@hero/react-hooks/mediaQuery';

type Props = {
    store: Store<any, any>;
    children?: React.ReactNode;
};

const Wrappers: React.FC<Props> = ({ store, children }) => (
    <Provider store={store}>
        <NotificationProvider>
            <MediaQueryContextProvider>
                <ViewConfigProvider>{children}</ViewConfigProvider>
            </MediaQueryContextProvider>
        </NotificationProvider>
    </Provider>
);

export default Wrappers;
