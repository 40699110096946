// 1 - Rush Order, 5 - Hayward Office, 3 - NYC Office, 6 - Sonic, others are disabled for now
export const rmaReturnLocationAddresses = {
    '1': {
        name: 'Rush Order',
        address: '6205 Engel Way',
        city: 'Gilroy',
        state: 'CA',
        country: 'US',
        zip_code: '95020'
    },
    '2': undefined, // disabled
    '3': {
        name: 'NYC Office',
        address: '90 Broad St',
        city: 'New York',
        state: 'NY',
        country: 'US',
        zip_code: '10004'
    },
    '4': undefined, // disabled
    '5': {
        name: 'Hayward Office',
        address: '6205 Engel Way',
        city: 'Gilroy',
        state: 'CA',
        country: 'US',
        zip_code: '95020'
    },
    '6': {
        name: 'Sonic Warehouse, c/o Hero Health',
        address: '6205 Engel Way',
        city: 'Gilroy',
        state: 'CA',
        country: 'US',
        zip_code: '95020'
    },
    '7': {
        name: 'Reconext USA',
        address: '1784 Pan American Street',
        city: 'Calexico',
        state: 'CA',
        country: 'US',
        zip_code: '92231'
    }
};
