import React from 'react';
import suppressionEmailSchema, { SuppressionEmailParams } from './schema';
import Input from '@hero/ui-kit/inputs/Input';
import Button from '@hero/ui-kit/inputs/Button';
import Form from '@hero/ui-kit/inputs/Form';

interface SuppressionEmailFormProps {
    btnLabel?: string;
    isLoading?: boolean;
    onSubmit: (attributes: SuppressionEmailParams) => void;
}

const SuppressionEmailForm: React.FC<SuppressionEmailFormProps> = ({
    btnLabel = 'Submit',
    isLoading = false,
    onSubmit
}) => {
    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.currentTarget.value.length === 0 && event.code === 'Space') {
            event.preventDefault();
        }
    };
    return (
        <Form validationSchema={suppressionEmailSchema} submitFn={onSubmit}>
            <Input
                name="email"
                displayName="Check if email is blacklisted"
                placeholder="Enter email"
                styles={{ minWidth: '48rem' }}
                onKeyDown={handleKeydown}
            />
            <Button type="submit" width="full" disabled={isLoading}>
                {btnLabel}
            </Button>
        </Form>
    );
};

export default SuppressionEmailForm;
