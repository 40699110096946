import * as yup from 'yup';
const planPickerSchema = yup
    .object()
    .shape({
    plan: yup.lazy((val) => !val
        ? yup.string().nullable().notRequired()
        : yup
            .string()
            .oneOf([
            'basic',
            'basic-extended-trial',
            'annual',
            'commitment-monthly-12months',
            'commitment-monthly-24months',
            'biannual',
            'unlimited-free',
            'legacy-unlimited-free',
            'new-bundle-1-year',
            'new-bundle-2-year',
            'new-bundle-3-year'
        ], 'Invalid plan'))
})
    .defined();
export default planPickerSchema;
