import React from 'react';
import { noop } from 'ts-essentials';

import Form from '@hero/ui-kit/inputs/Form';
import Select from '@hero/ui-kit/inputs/Select';
import Button from '@hero/ui-kit/inputs/Button';
import rmaFilterSchema from '@hero/validators/forms/backoffice/rmaFilterSchema';

import useResetFiltersQuery from '../../../../hooks/useResetFiltersQuery';
import useFilterQuery from '../../../../hooks/useFilterQuery';

import useRmaOptions from './useRmaOptions';
import useRmaFilterStyles from './useRmaFilterFormStyles';

const RmaFilterForm: React.FC = () => {
    const [rma_type, handleRmaTypeChange] = useFilterQuery('rma_type');
    const [rma_status, handleRmaStatusChange] = useFilterQuery('rma_status');
    const [return_status, handleReturnStatusChange] = useFilterQuery('return_status');
    const [refund_status, handleRefundStatusChange] = useFilterQuery('refund_status');
    const { rmaTypes, rmaStatuses, refundStatuses, returnStatuses } = useRmaOptions();
    const activeFilters = { rma_status, rma_type, return_status, refund_status };
    const handleReset = useResetFiltersQuery();
    const { filterStyles, filterWrapStyles, resetButtonMargin, resetButtonWrapMargin } =
        useRmaFilterStyles();

    return (
        <Form validationSchema={rmaFilterSchema} defaultValues={activeFilters} submitFn={noop}>
            <div style={filterWrapStyles}>
                <Select
                    name="rma_type"
                    displayName="RMA Type"
                    defaultValue={rma_type}
                    onChange={handleRmaTypeChange}
                    styles={filterStyles}
                    noExtraMargin
                >
                    {rmaTypes}
                </Select>
                <Select
                    name="rma_status"
                    displayName="RMA Status"
                    defaultValue={rma_status}
                    onChange={handleRmaStatusChange}
                    styles={filterStyles}
                    noExtraMargin
                >
                    {rmaStatuses}
                </Select>
                <Select
                    name="return_status"
                    displayName="Return Status"
                    defaultValue={return_status}
                    onChange={handleReturnStatusChange}
                    styles={filterStyles}
                    noExtraMargin
                >
                    {returnStatuses}
                </Select>
                <Select
                    name="refund_status"
                    displayName="Refund Status"
                    defaultValue={refund_status}
                    onChange={handleRefundStatusChange}
                    styles={filterStyles}
                    noExtraMargin
                >
                    {refundStatuses}
                </Select>
                <div style={resetButtonWrapMargin}>
                    <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                        Reset
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default RmaFilterForm;
