import React, { FC, CSSProperties } from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import DeviceReturnTips from '../../CancellationFlow/DeviceReturnTips';
import { useExchangeContext } from '../context/ExchangeProvider';
import useGetReturnLocations from '../../../hooks/useGetReturnLocations';

interface SuccessProps {
    closeExchangeModal: () => void;
}

export const Success: FC<SuccessProps> = ({ closeExchangeModal }) => {
    const { isOrganizationMember, membership, enterpriseEnrollment, rma, userDetails, rmaEnums } =
        useExchangeContext();

    const returnLocations = useGetReturnLocations(rmaEnums);
    const { return_location, return_label, fulfillment_request } = rma ?? {};
    const { tracking_number } = return_label ?? {};

    const firstName = isOrganizationMember
        ? enterpriseEnrollment?.member?.contact?.first_name ||
          enterpriseEnrollment?.contact?.first_name
        : userDetails?.user?.first_name;
    const lastName = isOrganizationMember
        ? enterpriseEnrollment?.member?.contact?.last_name ||
          enterpriseEnrollment?.contact?.last_name
        : userDetails?.user?.last_name;
    const email = isOrganizationMember
        ? enterpriseEnrollment?.member?.contact?.email || enterpriseEnrollment?.contact?.email
        : membership?.order_email;

    const wrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'center'
    };

    const detailsStyles: CSSProperties = {
        flexBasis: '60%'
    };

    const fieldStyles: CSSProperties = {
        inlineSize: '260px',
        overflowWrap: 'break-word'
    };

    return (
        <Section noDefaultPadding>
            <div style={wrapStyles}>
                <Container
                    gridTemplateColumns="1fr 2fr"
                    gridColumnGap="small"
                    styles={detailsStyles}
                >
                    <P strong noDefaultMargin>
                        First Name
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {firstName || '-'}
                    </P>
                    <P strong noDefaultMargin>
                        Last Name
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {lastName || '-'}
                    </P>
                    <P strong noDefaultMargin>
                        Email
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {email || '-'}
                    </P>
                    <P noDefaultMargin strong>
                        Return Location
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {return_location && returnLocations
                            ? returnLocations[return_location]
                            : '-'}
                    </P>
                    <P noDefaultMargin strong>
                        Return Tracking #
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {tracking_number ? `${tracking_number}` : '-'}
                    </P>
                    <P noDefaultMargin strong>
                        Exchange Order #
                    </P>
                    <P noDefaultMargin size="small" styles={fieldStyles}>
                        {fulfillment_request?.rush_order_id
                            ? `${fulfillment_request.rush_order_id}`
                            : '-'}
                    </P>
                </Container>
                <DeviceReturnTips isExchange />
            </div>
            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" variant="inverted" onClick={closeExchangeModal}>
                    Ok
                </Button>
            </Section>
        </Section>
    );
};
