import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
const useMembershipMonitoringStyles = () => {
    const innerWrapPadding = useComponentPadding({ paddingVertical: 'regular' });
    const headerWrapStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const tableWrapStyles = {
        overflowX: 'auto'
    };
    return { innerWrapPadding, headerWrapStyles, tableWrapStyles };
};
export default useMembershipMonitoringStyles;
