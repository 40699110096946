import { Organization } from '@hero/hero-types';
import { usStates } from '@hero/hero-utils/address/states';
import React from 'react';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Option from '@hero/ui-kit/inputs/Option';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Select from '@hero/ui-kit/inputs/Select';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import Container from '@hero/ui-kit/layout/Container';
import H from '@hero/ui-kit/typography/H';
import StaticFormElement from '../../../components/StaticFormElement';
import {
    isRtmStatusDetailsLoadingSelector,
    rtmStatusDetailsSelector
} from '@hero/redux-data/backoffice/rtmStatus/selectors';
import { shallowEqual, useSelector } from 'react-redux';
import getRtmStatus from './utils/getRtmStatus';
import { formatDateTime } from '@hero/hero-utils/date';
import { isRtmSaveLoadingSelector } from '@hero/redux-data/backoffice/rtmSave/selectors';
import P from '@hero/ui-kit/typography/P';

type Props = {
    organizations?: Organization[];
    onOrgChange?: (selectedValue: string) => void;
    defaultOrgId?: string;
    selectedOrgName?: string;
    isMembershipActive?: boolean;
    defaultState?: string;
};

const RTMFormFields: React.FC<Props> = ({
    organizations = [],
    onOrgChange,
    defaultOrgId,
    selectedOrgName,
    isMembershipActive = false,
    defaultState
}) => {
    const { formState } = useFormContext();
    const isRtmStatusLoading = useSelector(isRtmStatusDetailsLoadingSelector, shallowEqual);
    const rtmStatus = useSelector(rtmStatusDetailsSelector, shallowEqual);
    const isRtmFormSaving = useSelector(isRtmSaveLoadingSelector, shallowEqual);

    const selectStyles: React.CSSProperties = {
        minWidth: '40rem'
    };

    const marginTop = useComponentMargin({ marginTop: 'large' });

    const isAgreed = useWatch({ name: 'agreement' });

    const rtmStatusLabel = React.useMemo(() => {
        return getRtmStatus(rtmStatus?.status);
    }, [rtmStatus]);

    const rtmStatusOrgStatusLabel = React.useMemo(() => {
        if (rtmStatus?.created_at) {
            return `${rtmStatusLabel} Created At: ${formatDateTime(rtmStatus.created_at)}`;
        }
        return rtmStatusLabel;
    }, [rtmStatusLabel, rtmStatus]);

    const isFormDisabled: boolean = React.useMemo(() => {
        return rtmStatus?.status === 'APPROVED' || rtmStatus?.status === 'CREATED';
    }, [rtmStatus]);

    const isFormBtnDisabled: boolean = React.useMemo(() => {
        return (
            !formState.isValid ||
            !isAgreed ||
            isFormDisabled ||
            rtmStatusLabel === 'N/A' ||
            isRtmFormSaving
        );
    }, [isAgreed, formState, isFormDisabled, rtmStatusLabel, isRtmFormSaving]);

    const modifiedUsStates = React.useMemo(() => {
        if (selectedOrgName?.toLocaleLowerCase() === 'assure') {
            // https://docs.google.com/spreadsheets/d/1vwdfNULRiD8QwhffvLpjT7U6Ho6Wj3PazGa_d8RirTo/edit#gid=0
            const unsupportedStates = ['FM', 'MH', 'MP', 'PW', 'AS'];
            return usStates.filter((usStateItem) => !unsupportedStates.includes(usStateItem.code));
        }
        return usStates;
    }, [selectedOrgName]);

    return (
        <div style={{ width: '90rem', ...marginTop }}>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                <Select
                    name="organization_id"
                    displayName="Organization"
                    styles={selectStyles}
                    onChange={onOrgChange}
                    noExtraMargin
                    defaultValue={defaultOrgId}
                >
                    {organizations.map((org) => (
                        <Option key={org.id} value={`${org.id}`} labelText={org.name} />
                    ))}
                </Select>
                <StaticFormElement label="Status">
                    {isRtmStatusLoading ? 'Loading...' : rtmStatusOrgStatusLabel}
                </StaticFormElement>
            </Container>
            <H role="h6">Customer contract information</H>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                <Input name="first_name" displayName="First Name" disabled={isFormDisabled} />
                <Input name="last_name" displayName="Last Name" disabled={isFormDisabled} />
            </Container>
            <Input name="email" displayName="Email *" disabled={isFormDisabled} />
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                <Select
                    name="state"
                    displayName="State *"
                    styles={selectStyles}
                    noExtraMargin
                    disabled={isFormDisabled}
                    defaultValue={defaultState}
                >
                    {modifiedUsStates.map((usState) => (
                        <Option
                            key={`${usState.code}-${usState.name}`}
                            value={usState.code}
                            labelText={usState.name}
                        />
                    ))}
                </Select>
                <Input name="phone" displayName="Phone *" disabled={isFormDisabled} />
            </Container>
            <RadioGroup
                name="new_dispenser"
                displayName="Needs New Hero Dispenser?"
                noBorders
                disabled={isFormDisabled || isMembershipActive}
            >
                <Radio id="new_dispenser_yes" value="yes" labelText="Yes" />
                <Radio id="new_dispenser_no" value="no" labelText="No" />
            </RadioGroup>
            <RadioGroup
                name="application_submit"
                displayName="Who is this application submitted for?"
                noBorders
                disabled={isFormDisabled}
            >
                <Radio id="myself" value="myself" labelText="Myself" />
                <Radio id="loved_one" value="loved_one" labelText="A loved one" />
            </RadioGroup>
            <StandaloneCheckbox
                disabled={isFormDisabled}
                name="agreement"
                labelText="Customer agrees to Hero's processing and sharing of personal data recorder in this form with Assure"
            />
            <StandaloneCheckbox
                styles={{ padding: 0, height: '3rem' }}
                disabled={isFormDisabled}
                name="is_tcpa_opt_in"
                labelText="We recommended opting in to text messages from Assure Health for a faster enrollment process."
            />
            <P styles={{ marginLeft: '4rem' }}>
                <strong>Customer agrees</strong> to receive text messages and phone calls from
                trusted providers, including for updates to enrollment status, marketing, and
                appointment reminders. By opting in to receive SMS messages,{' '}
                <strong>customer acknowledges</strong> that message and data rates may apply.{' '}
                <strong>Customer was made aware</strong> that message frequency varies and that they
                can opt out by responding STOP to an sms message at any time.
            </P>
            <Button type="submit" disabled={isFormBtnDisabled}>
                Submit
            </Button>
        </div>
    );
};

export default RTMFormFields;
