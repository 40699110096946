import React from 'react';
import Header from '../Header';
import useMonitoringRunningLowData from './useMonitoringRunningLowData';
import Table from '@hero/ui-kit/tables/Table';
import { BackofficeOrganizationNoRefillAlert } from '@hero/hero-types';
import Section from '@hero/ui-kit/layout/Section';
import Filter from '../Filter';
import DefaultLayout from '../../../components/DefaultLayout';

interface RunningLowPageProps {}

const RunningLowPage: React.FC<RunningLowPageProps> = () => {
    const { isLoading, adherenceNoRefillList, pagerProps, tableColumns } =
        useMonitoringRunningLowData();
    return (
        <DefaultLayout>
            <Section>
                <Header title="Running Low Monitoring" />
                <Filter hideAllFilterOption />
                <Table<BackofficeOrganizationNoRefillAlert>
                    columns={tableColumns}
                    data={adherenceNoRefillList}
                    rowKey="id"
                    emptyValues="-"
                    isLoading={isLoading}
                    pagerProps={pagerProps}
                />
            </Section>
        </DefaultLayout>
    );
};

export default RunningLowPage;
