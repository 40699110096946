import React from 'react';

import { Address, ContactAddress } from '@hero/hero-types';

import { isContactAddress } from './typeGuards';

type Props = {
    address: Partial<Address | ContactAddress> | null | undefined;
};

const FormattedAddress: React.FC<Props> = ({ address }) => {
    if (!address) return <>-</>;

    const addressLine1 = isContactAddress(address) ? address.address_line_1 : address.address1;
    const addressLine2 = isContactAddress(address) ? address.address_line_2 : address.address2;
    const city = address.city;
    const province = isContactAddress(address) ? address.state : address.province;
    const zipCode = isContactAddress(address) ? address.zip : address.zip_code;

    let line1: string[] = [];
    let line2: string[] = [];

    if (addressLine1) line1 = [...line1, addressLine1];
    if (addressLine2) line1 = [...line1, addressLine2];
    if (city) line2 = [...line2, city];
    if (province || zipCode) line2 = [...line2, `${province || ''} ${zipCode || ''}`.trim()];

    return line1.length === 0 && line2.length === 0 ? (
        <>-</>
    ) : (
        <>
            {line1.join(', ')}
            {line1.length > 0 && line2.length > 0 && <br />}
            {line2.join(', ')}
        </>
    );
};

export default FormattedAddress;
