import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import {
    BackofficeMembershipDetails,
    CancellationDetails,
    PaymentUpdateParams
} from '@hero/hero-types';
import formatDollarAmount from '@hero/hero-utils/currency';
import { updateUserPaymentMethod } from '@hero/redux-data/backoffice/userPaymentMethod/actionCreators';
import {
    isUserPaymentMethodLoadingSelector,
    userPaymentMethodErrorSelector
} from '@hero/redux-data/backoffice/userPaymentMethod/selectors';
import { formatDate } from '@hero/hero-utils/date';
import {
    getMembershipReactivationDetails,
    membershipReactivationDetailsReset
} from '@hero/redux-data/backoffice/membershipReactivationDetails/actionCreators';
import {
    isMembershipReactivationDetailsLoadingSelector,
    membershipReactivationDetailsSelector,
    membershipReactivationDetailsErrorSelector
} from '@hero/redux-data/backoffice/membershipReactivationDetails/selectors';
import {
    membershipReactivationRefundChargeReset,
    reactivateRefundChargeMembership
} from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/actionCreators';
import {
    isMembershipReactivationRefundChargeLoadingSelector,
    membershipReactivationRefundChargeErrorSelector,
    isMembershipReactivationRefundChargeSuccess
} from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/selectors';
import {
    membershipReactivationReset,
    reactivateMembership
} from '@hero/redux-data/backoffice/membershipReactivation/actionCreators';
import {
    isMembershipReactivationLoadingSelector,
    membershipReactivationErrorSelector,
    membershipReactivationNewIdSelector
} from '@hero/redux-data/backoffice/membershipReactivation/selectors';
import { useNavigate } from 'react-router-dom';
import { PlanPickerParams } from '../schema';

type ReactivationSteps =
    | 'ConfirmCard'
    | 'Details'
    | 'FOP'
    | 'Reactivate'
    | 'RefundCharge'
    | 'Success'
    | 'Fail';

type ReactivationFlowContextType = {
    onCloseReactivationFlow: () => void;
    goToFOP: () => void;
    onConfirmCard: () => void;
    onCreditCardCancel: () => void;
    detailsBtnConfirm: () => void;
    onCreditCardUpdate: (formValues: PaymentUpdateParams) => void;
    reactivationStep: ReactivationSteps;
    isLoading: boolean;
    onReactivate: (attributes: PlanPickerParams) => void;
    onRefundCharge: () => void;
    updateCardErrorMessage: string;
    obligationEndDate?: string;
    refundChargeDetails: {
        details: string;
        btnLabel: string;
        header: string;
    };
    planDetails: {
        prepaidUntil: string;
        planActiveDate: string;
        planCancelledDate: string;
        planReactivationDate: string;
        membershipOwedToDate: string;
        membershipPaidToDate: string;
        initiationFeePayed: string;
        initiationFeeOwed: string;
        cancellationFeePaid: string;
        difference: string;
        isRefund: boolean;
    };
    cardDetails: {
        last4digits: string;
        expirationYear: string;
        expirationMonth: string;
        name: string;
        cardType: string;
    };
    successDetails: {
        list: Array<string>;
    };
    failDetails: {
        header: string;
        message: string;
    };
    reactivateDetails: {
        header: string;
        message: string;
    };
    detailsBtnLabel: string;
    storeFrontKey: string;
    isReactivationRefundChargeError: boolean;
};

const ReactivationFlowContext = React.createContext<ReactivationFlowContextType | undefined>(
    undefined
);

export const useReactivationFlowContext = () => {
    const ctx = React.useContext(ReactivationFlowContext);

    if (ctx === undefined) {
        throw new Error(
            `'useReactivationFlowContext' must be used within a 'ReactivationFlowContextProvider'`
        );
    }

    return ctx;
};

const getLabel = (label: string | number | undefined | null): string => (label ? `${label}` : '-');

type IReactivationFlowProvider = {
    membershipId: number;
    children: React.ReactNode;
    onCompleteFlow?: () => void;
    cancellationDetails?: CancellationDetails;
    membership: BackofficeMembershipDetails;
} & Pick<ModalProps, 'externalControls'>;

const ReactivationFlowProvider = ({
    membershipId,
    children,
    externalControls,
    onCompleteFlow,
    cancellationDetails,
    membership
}: IReactivationFlowProvider) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [, setExternalState] = externalControls ?? [];

    const [reactivationStep, setReactivationStep] =
        React.useState<ReactivationSteps>('ConfirmCard');

    // data
    const membershipReactivationDetails = useSelector(
        membershipReactivationDetailsSelector,
        shallowEqual
    );
    const isReactivationRefundChargeSuccess = useSelector(
        isMembershipReactivationRefundChargeSuccess,
        shallowEqual
    );
    const membershipReactivationNewId = useSelector(
        membershipReactivationNewIdSelector,
        shallowEqual
    );

    // loading
    const isUpdatingPaymentMethod = useSelector(isUserPaymentMethodLoadingSelector, shallowEqual);
    const isMembershipReactivationDetailsLoading = useSelector(
        isMembershipReactivationDetailsLoadingSelector,
        shallowEqual
    );
    const isMembershipReactivationRefundChargeLoading = useSelector(
        isMembershipReactivationRefundChargeLoadingSelector,
        shallowEqual
    );
    const isMembershipReactivationLoading = useSelector(
        isMembershipReactivationLoadingSelector,
        shallowEqual
    );

    // errors
    const {
        error: isCreditCardError,
        errorMessage: creditCardErrorMessage,
        originalMessage: originalCreditCardErrorMessage
    } = useSelector(userPaymentMethodErrorSelector, shallowEqual);
    const { error: isReactivationDetailsError, errorMessage: reactivationDetailsErrorMessage } =
        useSelector(membershipReactivationDetailsErrorSelector, shallowEqual);
    const {
        error: isReactivationRefundChargeError,
        errorMessage: reactivationRefundChargeErrorMessage
    } = useSelector(membershipReactivationRefundChargeErrorSelector, shallowEqual);
    const { error: isReactivationError, errorMessage: reactivationErrorMessage } = useSelector(
        membershipReactivationErrorSelector,
        shallowEqual
    );

    const isLoading =
        isUpdatingPaymentMethod ||
        isMembershipReactivationDetailsLoading ||
        isMembershipReactivationRefundChargeLoading ||
        isMembershipReactivationLoading;

    const isError =
        isReactivationDetailsError || isReactivationRefundChargeError || isReactivationError;

    React.useEffect(() => {
        dispatch(getMembershipReactivationDetails({ id: membershipId }));
    }, [membershipId, dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(membershipReactivationDetailsReset());
            dispatch(membershipReactivationRefundChargeReset());
            dispatch(membershipReactivationReset());
        };
    }, [dispatch]);

    React.useEffect(() => {
        isReactivationRefundChargeSuccess && setReactivationStep('Reactivate');
    }, [isReactivationRefundChargeSuccess]);

    React.useEffect(() => {
        isError && setReactivationStep('Fail');
    }, [isError]);

    React.useEffect(() => {
        membershipReactivationNewId && setReactivationStep('Success');
    }, [membershipReactivationNewId]);

    const onCloseReactivationFlow = React.useCallback(() => {
        if (membershipReactivationNewId) {
            navigate(`/membership/${membershipReactivationNewId}/details`, { replace: true });
        }
        setReactivationStep('ConfirmCard');
        setExternalState && setExternalState(false);
        onCompleteFlow && onCompleteFlow();
    }, [onCompleteFlow, setExternalState, membershipReactivationNewId, navigate]);

    const goToFOP = React.useCallback(() => {
        setReactivationStep('FOP');
    }, []);

    const onReactivate = React.useCallback(
        ({ plan }: PlanPickerParams) => {
            const isBundle = [
                'new-bundle-1-year',
                'new-bundle-2-year',
                'new-bundle-3-year'
            ].includes(plan);

            dispatch(reactivateMembership({ id: membershipId, ...(isBundle && { bundle: plan }) }));
        },
        [dispatch, membershipId]
    );

    const onRefundCharge = React.useCallback(() => {
        dispatch(reactivateRefundChargeMembership({ id: membershipId }));
    }, [dispatch, membershipId]);

    const onConfirmCard = React.useCallback(() => {
        setReactivationStep('Details');
    }, []);

    const onCreditCardUpdate = React.useCallback(
        (formValues: PaymentUpdateParams) => {
            membershipId && dispatch(updateUserPaymentMethod({ id: membershipId, ...formValues }));
        },
        [dispatch, membershipId]
    );

    const onCreditCardCancel = React.useCallback(() => {
        setReactivationStep('Details');
    }, []);

    const isRefund = React.useMemo(() => {
        return membershipReactivationDetails?.action?.toLowerCase() === 'refund';
    }, [membershipReactivationDetails]);

    const amountToBill = React.useMemo(() => {
        return membershipReactivationDetails?.difference || 0;
    }, [membershipReactivationDetails]);

    const planDetails = React.useMemo(() => {
        return {
            prepaidUntil: membership?.prepaid_end_date
                ? formatDate(membership.prepaid_end_date)
                : 'N/A',
            planActiveDate: membership?.activated_at ? formatDate(membership.activated_at) : '-',
            planCancelledDate: cancellationDetails?.subscription?.cancelled_at
                ? formatDate(cancellationDetails.subscription.cancelled_at)
                : '-',
            planReactivationDate: formatDate(new Date()),
            membershipOwedToDate: formatDollarAmount(
                membershipReactivationDetails?.membership_owed || 0,
                true
            ),
            membershipPaidToDate: formatDollarAmount(
                membershipReactivationDetails?.membership_payed || 0,
                true
            ),
            cancellationFeePaid: formatDollarAmount(
                membershipReactivationDetails?.cancellation_fee_payed || 0,
                true
            ),
            difference: formatDollarAmount(membershipReactivationDetails?.difference || 0, true),
            initiationFeePayed: formatDollarAmount(
                membershipReactivationDetails?.initiation_fee_payed || 0,
                true
            ),
            initiationFeeOwed: formatDollarAmount(
                membershipReactivationDetails?.initiation_fee_owed || 0,
                true
            ),
            isRefund
        };
    }, [membership, cancellationDetails, membershipReactivationDetails, isRefund]);

    const cardDetails = React.useMemo(() => {
        return {
            last4digits: getLabel(membership?.payment_card?.last4),
            expirationYear: getLabel(membership?.payment_card?.expire_year),
            expirationMonth: getLabel(membership?.payment_card?.expire_month),
            name: getLabel(membership?.payment_card?.name_on_card),
            cardType: getLabel(membership?.payment_card?.type)
        };
    }, [membership]);

    const successDetails = React.useMemo(() => {
        return {
            list: [
                `Amount ${isRefund ? 'refunded' : 'charged'}`,
                `RMA was voided`,
                `Confirmation email was sent`
            ]
        };
    }, [isRefund]);

    const failDetails = React.useMemo(() => {
        return {
            header: 'Something went wrong',
            message:
                reactivationRefundChargeErrorMessage ||
                reactivationDetailsErrorMessage ||
                reactivationErrorMessage ||
                'Contact support.'
        };
    }, [
        reactivationDetailsErrorMessage,
        reactivationRefundChargeErrorMessage,
        reactivationErrorMessage
    ]);

    const updateCardErrorMessage = React.useMemo<string>(() => {
        const cardError = creditCardErrorMessage || originalCreditCardErrorMessage;
        return isCreditCardError && cardError ? cardError : '';
    }, [creditCardErrorMessage, originalCreditCardErrorMessage, isCreditCardError]);

    const detailsBtnConfirm = React.useCallback(() => {
        setReactivationStep(amountToBill === 0 ? 'Reactivate' : 'RefundCharge');
    }, [amountToBill]);

    const detailsBtnLabel = React.useMemo(() => {
        if (amountToBill === 0) {
            return 'Reactivate Membership';
        }

        return isRefund ? 'Refund Membership' : 'Charge Membership';
    }, [isRefund, amountToBill]);

    const refundChargeDetails = React.useMemo(() => {
        const formatAmountToBill = formatDollarAmount(amountToBill, true);
        const label = isRefund ? 'Refund' : 'Charge';
        return {
            details: `${label} ${formatAmountToBill}`,
            btnLabel: `${label} Membership`,
            header: `${label} ${membership?.order_email || '-'}`
        };
    }, [amountToBill, membership, isRefund]);

    const reactivateDetails = React.useMemo(() => {
        return {
            header: `Amount ${formatDollarAmount(
                membershipReactivationDetails?.difference || 0,
                true
            )} successfully ${isRefund ? 'refunded' : 'charged'}`,
            message: 'Please reactivate membership clicking on the button below.'
        };
    }, [membershipReactivationDetails, isRefund]);

    return (
        <ReactivationFlowContext.Provider
            value={{
                isLoading,
                reactivationStep,
                onCloseReactivationFlow,
                onCreditCardUpdate,
                onCreditCardCancel,
                goToFOP,
                onReactivate,
                onRefundCharge,
                onConfirmCard,
                planDetails,
                cardDetails,
                successDetails,
                failDetails,
                updateCardErrorMessage,
                detailsBtnLabel,
                detailsBtnConfirm,
                reactivateDetails,
                refundChargeDetails,
                obligationEndDate: membership?.obligation_end_date,
                storeFrontKey: membership?.current_plan?.storefront_key || '-',
                isReactivationRefundChargeError
            }}
        >
            {children}
        </ReactivationFlowContext.Provider>
    );
};

export default ReactivationFlowProvider;
