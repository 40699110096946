import React from 'react';
import Form from '@hero/ui-kit/inputs/Form';
import { toBeCancelledSchema } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import { shallowEqual, useSelector } from 'react-redux';
import InlineLoader from '../../../../../components/InlineLoader';
import { useToBeCancelledFlowContext } from '../context/ToBeCancelledProvider';
import { areEnterpriseCancellationOptionsLoadingSelector } from '@hero/redux-data/backoffice/enterpriseCancellationOptions/selectors';
import DetailsForm from './DetailsForm';

interface DetailsProps {
    closeFlowModal: () => void;
}

export const Details: React.FC<DetailsProps> = () => {
    const { handleDetailsConfirm } = useToBeCancelledFlowContext();

    const isCancellationOptionsLoading = useSelector(
        areEnterpriseCancellationOptionsLoadingSelector,
        shallowEqual
    );

    return isCancellationOptionsLoading ? (
        <InlineLoader />
    ) : (
        <Form validationSchema={toBeCancelledSchema} submitFn={handleDetailsConfirm}>
            <DetailsForm />
        </Form>
    );
};
