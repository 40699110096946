import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
const repackTableData = (enrollments) => enrollments.map(({ id, invite_code, member }) => ({
    id,
    invite_code,
    email: member?.contact?.email || null,
    phone: member?.contact?.cell_phone
        ? formatPhoneNumber(member.contact.cell_phone, 'international')
        : member?.contact?.home_phone
            ? formatPhoneNumber(member.contact.home_phone, 'international')
            : null,
    first_name: member?.contact?.first_name || null,
    last_name: member?.contact?.last_name || null,
    completed_at: member?.created_at
        ? formatShortDate(normalizeDateString(member.created_at))
        : null
}));
export default repackTableData;
