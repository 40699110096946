import React, { CSSProperties, useCallback } from 'react';

import { CancellationDetails } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Modal from '@hero/ui-kit/components/Modal';
import { dayDiff } from '@hero/hero-utils/date';
import { useDispatch } from 'react-redux';
import { sendToCollectionAgency } from '@hero/redux-data/backoffice/cancellationDetails/actionCreators';

type Props = {
    data: CancellationDetails;
};

const DetailsCollectionAgency: React.FC<Props> = ({ data }) => {
    const { cancellation, rma, subscription } = data;

    const marginBottom = useComponentMargin({ marginBottom: 'regular' });
    const dispatch = useDispatch();

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

    const handleOpenConfirmModal = React.useCallback(() => {
        setOpenConfirmModal(true);
    }, []);

    const isEligibleForCollection = React.useMemo<boolean>(() => {
        const cancellationFeeOutstanding = cancellation.cancellation_fee_outstanding || 0;
        const unreturnedDeviceFeeOutstanding = cancellation.unreturned_device_fee_outstanding || 0;
        const isNotShipped = rma?.return_status === 1;
        const cancelledAt = subscription?.cancelled_at;
        const cancelledDaysDiff = cancelledAt ? dayDiff(Date(), cancelledAt) : 0;

        return (
            (cancellationFeeOutstanding > 0 ||
                (isNotShipped && unreturnedDeviceFeeOutstanding > 0)) &&
            cancelledDaysDiff >= 60
        );
    }, [rma, subscription, cancellation]);

    const status = React.useMemo<string>(() => {
        if (cancellation?.sent_to_collection_agency) {
            return 'Sent to Collection';
        }

        if (isEligibleForCollection) {
            return 'Eligible for Collection';
        }

        return 'N/A';
    }, [cancellation, isEligibleForCollection]);

    const handleConfirm = useCallback(() => {
        dispatch(sendToCollectionAgency({ subscription_id: subscription.id }));
        setOpenConfirmModal(false);
    }, [dispatch, subscription]);

    return (
        <>
            <P noDefaultMargin strong styles={{ ...sectionLabelStyles, ...marginBottom }}>
                Collection Agency
            </P>
            <Container gridTemplateColumns="2fr 7fr">
                <P noDefaultMargin strong>
                    Status
                </P>
                <div
                    style={{ display: 'inline-flex', alignItems: 'center', alignContent: 'center' }}
                >
                    <P noDefaultMargin>{status}</P>
                    <Button
                        disabled={
                            !isEligibleForCollection || cancellation.sent_to_collection_agency
                        }
                        size="small"
                        noDefaultMargin
                        styles={{ marginLeft: '1.75rem' }}
                        onClick={handleOpenConfirmModal}
                    >
                        Send to Collection Agency
                    </Button>
                </div>
            </Container>
            <Modal isCancelable externalControls={[openConfirmModal, setOpenConfirmModal]}>
                <>
                    <P strong size="large">
                        Please confirm member was transfered to collection agency.
                    </P>
                    <P strong size="large">
                        This step cannot be undone.
                    </P>
                    <Button width="large" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </>
            </Modal>
        </>
    );
};

export default DetailsCollectionAgency;
