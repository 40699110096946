import React, { useMemo } from 'react';

import { CancellationFeePreview } from '@hero/hero-types';
import { formatDollarAmount } from '@hero/hero-utils/currency';
import Container from '@hero/ui-kit/layout/Container';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

type Props = {
    fee: CancellationFeePreview | null;
};

const ReviewFee: React.FC<Props> = ({ fee }) => {
    const margin = useComponentMargin({ marginTop: 'regular' });

    const feeLabel = useMemo(() => {
        return `Fee to be ${fee?.waive ? 'waived' : 'charged'}`;
    }, [fee]);

    const subscriptionLabel = useMemo(() => {
        return `Subscription - ${fee?.months} ${fee?.months === 1 ? 'Month' : 'Months'}`;
    }, [fee]);

    return fee ? (
        <div>
            <H role="h6" centered>
                {feeLabel}
            </H>
            <Container gridTemplateColumns="auto min-content" gridColumnGap="regular">
                <P noDefaultMargin>{subscriptionLabel}</P>
                <P noDefaultMargin styles={{ textAlign: 'right' }}>
                    {formatDollarAmount(fee?.base_amount, true)}
                </P>
                {fee.discount > 0 && (
                    <>
                        <P noDefaultMargin>Discount - 50%</P>
                        <P noDefaultMargin styles={{ textAlign: 'right' }}>
                            {formatDollarAmount(-fee?.discount, true)}
                        </P>
                    </>
                )}
                {fee.credit > 0 && (
                    <>
                        <P>Available Credit</P>
                        <P styles={{ textAlign: 'right' }}>
                            {formatDollarAmount(-fee?.credit, true)}
                        </P>
                    </>
                )}
                <P strong styles={margin}>
                    Total
                </P>
                <P strong styles={{ textAlign: 'right', ...margin }}>
                    {formatDollarAmount(fee?.final_amount, true)}
                </P>
            </Container>
            <P centered styles={margin}>
                Any applicable taxes are NOT included in above preview.
            </P>
        </div>
    ) : null;
};

export default ReviewFee;
