import React, { CSSProperties } from 'react';

import { EnterpriseEnrollment, Cancellation } from '@hero/hero-types';
import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import Container from '@hero/ui-kit/layout/Container';
import Span from '@hero/ui-kit/typography/Span';
import P from '@hero/ui-kit/typography/P';
import A from '@hero/ui-kit/typography/A';

type Props = {
    enrollment: EnterpriseEnrollment;
    cancellation: Cancellation;
};

const DetailsSectionCancellation: React.FC<Props> = ({ enrollment, cancellation }) => {
    const { cancellation_type, zendesk_ticket_number } = cancellation;

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    const cancellationTypeDesc = cancellation_type
        ? `${cancellation_type?.name}: ${cancellation_type?.description}`
        : 'Unknown';

    return (
        <>
            <P strong>
                <Span styles={sectionLabelStyles}>Cancellation</Span>
                {` - ${cancellationTypeDesc}`}
            </P>
            <Container gridTemplateColumns="2fr 3fr 1fr 3fr">
                <P strong>Date</P>
                <P>
                    {enrollment.member?.cancelled_at
                        ? formatShortDate(normalizeDateString(enrollment.member.cancelled_at))
                        : '-'}
                </P>
                <P strong>Ticket #</P>
                <P>
                    {zendesk_ticket_number ? (
                        <A
                            to={`https://herohealth.zendesk.com/agent/tickets/${zendesk_ticket_number}`}
                        >
                            {zendesk_ticket_number}
                        </A>
                    ) : (
                        '-'
                    )}
                </P>
            </Container>
        </>
    );
};

export default DetailsSectionCancellation;
