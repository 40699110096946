import React, { useCallback } from 'react';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

import Button from '@hero/ui-kit/inputs/Button';

type Props = {
    predicate: boolean;
    handleToggle: (state: boolean) => void;
};

const YesNoOptions: React.FC<Props> = ({ predicate, handleToggle }) => {
    const yesButtonMargin = useComponentMargin({ marginVertical: 'none' });
    const noButtonMargin = useComponentMargin({ marginLeft: 'small', marginVertical: 'none' });
    const handleYesClick = useCallback(() => handleToggle(true), [handleToggle]);
    const handleNoClick = useCallback(() => handleToggle(false), [handleToggle]);

    return (
        <div>
            <Button
                onClick={handleYesClick}
                variant={predicate ? 'primary' : 'outline'}
                size="small"
                styles={yesButtonMargin}
            >
                Yes
            </Button>
            <Button
                onClick={handleNoClick}
                variant={!predicate ? 'primary' : 'outline'}
                size="small"
                styles={noButtonMargin}
            >
                No
            </Button>
        </div>
    );
};

export default YesNoOptions;
