import React, { useMemo } from 'react';
import P from '@hero/ui-kit/typography/P';

const heroFailure = (
    <P>
        I'm sorry to hear that you're having difficulties with your Hero device.
        <br />
        <br />
        We want to resolve your problem as quickly as possible, so you can get the most out of your
        Hero membership. If we determine that it's a mechanical or technical performance issue with
        the dispenser, then that would warrant a replacement.
        <br />
        <br />
        I'd like to know more about the issue so we can either fix or replace your device
    </P>
);

const useEncourageHeroSubReasonNoteMap = (
    subReason?: string,
    defaultNote = 'Select sub-reason please'
): JSX.Element | null => {
    return useMemo(() => {
        if (!subReason) {
            return <P>{defaultNote}</P>;
        }

        if (
            subReason.length > 0 &&
            [
                'Hero Not Powering Up',
                'Hardware Failure',
                'Button Issues',
                'Volume Issue',
                'Screen Issue',
                'False Low Pill Alerts',
                'Rebooting Issues',
                'Notifications',
                'Speaker Volume'
            ].includes(subReason)
        ) {
            return heroFailure;
        }

        if (subReason === 'Login Issue') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device. Would
                    you like me to help you with that?
                </P>
            );
        }

        if (subReason === 'Unaware of Membership Model') {
            return (
                <P>
                    We definitely don't want the monthly subscription to feel like a burden. If you
                    are looking to reduce the cost of a subscription, there are several options
                    available: <br />
                    Want 3 months of free membership? Refer a friend! <br />
                    For each friend who joins Hero, you both get 3 months of free membership! <br />
                    Did you know your subscription might be covered by Medicare? If you'd like, I
                    can submit eligibility request for you immediately.
                </P>
            );
        }

        if (subReason === 'High Monthly Fee') {
            return (
                <P>
                    We definitely don't want the subscription to feel like a burden. If you are
                    looking to reduce the cost of a subscription, there are several options
                    available: <br />
                    Want 3 months of free membership? Refer a friend! For each friend who joins
                    Hero, you both get 3 months of free membership! <br />
                    Did you know your subscription might be covered by Medicare? If you'd like, I
                    can submit eligibility request for you immediately.
                </P>
            );
        }

        if (subReason === 'Received as Gift') {
            return (
                <P>
                    We're sorry to hear that gifting Hero did not work out for you. We understand
                    that behavior change takes time, patience, and consistency which is why our
                    experienced Live Support Team is here for you, and we'd love to set up a call to
                    hear more about your specific needs. We'd love to provide assistance with the
                    setup via phone or video chat.
                </P>
            );
        }

        if (subReason === 'Shipping Delay') {
            return (
                <P>
                    We need to apologize for not being there when you were expecting us but we are
                    experiencing a slight delay in shipping due to a high volume of orders over the
                    past few weeks. We're working hard to catch up with all of our members. It goes
                    without saying that we will place your order on high priority so that it ships
                    with the next batch of units. As soon as your Hero is out the door, we'll send
                    you an email. <br /> Once again please accept our apologies and let us know if
                    there is anything else we can assist with at the moment.
                </P>
            );
        }

        if (subReason === 'Not Enough Capacity') {
            return (
                <P>
                    We are sorry to hear that Hero does not have a large enough pill capacity for
                    you at the moment. We are working hard on making the dispenser smaller in size
                    and with an even bigger pill capacity but we still have some time to go. Did you
                    know that you can add 10 additional medications to your schedule, marked as
                    stored "Outside Of Hero"? Additionally, if you would like to order another Hero,
                    you can use your own referral link and get three months off each device. If it's
                    easier, I'm happy to apply the credit for you!
                </P>
            );
        }

        if (subReason === 'Wi-Fi Requirement') {
            return (
                <P>
                    Yes, Hero requires Wi-Fi connection to set up, operate 24/7, and notify you if
                    you do not adhere to your medication schedule. If you're having issues
                    connecting your Hero, we're happy to help.
                </P>
            );
        }

        if (subReason === 'Multiple User Support') {
            return (
                <P>
                    Hero has been designed to be used by only a single user, due to the risk of
                    cross-contamination of various medications loaded into the Hero Pill Dispenser.
                    <br />
                    <br />
                    If you decide to order an additional Hero, we can offer you three months free
                    for each active subscription. Also, did you know your subscription might be
                    covered by Medicare? If you'd like, I can submit eligibility request for you
                    immediately.
                </P>
            );
        }

        if (subReason === 'Too Complex') {
            return (
                <P>
                    We are very interested in understanding why you found Hero too complex? Behavior
                    change takes time, patience, and consistency. Hero comes with a 90-day risk-free
                    trial, which gives you and your loved one the time to form a new routine. We'd
                    love to provide assistance with the setup via phone or video chat.
                </P>
            );
        }

        if (subReason === 'Dispenser Too Big') {
            return (
                <P>
                    I'm sorry to hear that Hero's size doesn't meet your expectations. We are
                    working hard on making the dispenser smaller in size and with an even bigger
                    pill capacity but we still have some time to go. Until that happens, many
                    customers say they found the perfect spot for their Hero on their kitchen
                    counters, by their coffeemakers, since they're similar in size.
                </P>
            );
        }

        if (subReason === 'User Passed Away') {
            return (
                <P>
                    From everyone at Hero, we wanted to extend our deepest sympathy to you and your
                    family. Let me expedite this for you.
                </P>
            );
        }

        if (subReason === 'Too Noisy') {
            return (
                <P>
                    The smart dispenser naturally makes noise while dispensing - that said, here are
                    a few things that you can do to minimize noise: did you remove the travel bar
                    from the back of device? Would you check if your dispenser's cartridges are
                    properly installed?
                </P>
            );
        }

        if (subReason === 'Moving to Assisted Living Facility') {
            return (
                <P>
                    From all of us at Hero, we want to extend a heartfelt send-off as you prepare to
                    relocate your loved one to a new home. We know that making decisions around
                    health is not always easy, but did you know that many nursing facilities allow
                    you to have a Hero? Medication management services cost hundreds a month, a cost
                    that could be saved with Hero. As you take this next step, feel free to reach
                    out - we are here 24/7! Did you know your subscription might be covered by
                    Medicare? If you'd like, I can submit eligibility request for you immediately.
                </P>
            );
        }

        if (subReason === 'No Half Pills') {
            return (
                <P>
                    We recommend that half pills are placed into an empty capsule before storing it
                    inside the device. You can ask your pharmacist to make capsules for your
                    specific dose. If you prefer, you can store half pills outside of Hero smart
                    dispenser and the Hero app will remind you to take these medications, and track
                    your adherence, just as with medication stored inside Hero.
                </P>
            );
        }

        if (subReason === 'Dispense Failures') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device.
                    <br />
                    <br />
                    I'd like to know more - if we determine that it's a mechanical or technical
                    performance issue with the dispenser, then that would warrant a replacement.
                </P>
            );
        }

        if (subReason === 'Hospitalized') {
            return (
                <P>
                    I'm sorry to hear that and hope you have a speedy recovery.
                    <br />
                    <br />
                    We understand that these are strenuous times and that is why we have a special
                    program to pause your membership. Let us know if this would help you in this
                    situation.
                </P>
            );
        }

        if (subReason === 'Memory Impairment') {
            return (
                <P>
                    I'm sorry to hear that. <br />
                    Indeed, Hero is not recommended for persons who are not capable of verifying the
                    accuracy of each dispensed dose. Did you know you can dispense pills remotely
                    now? This feature is a great tool for caregivers, who may want to have more
                    remote control over their loved one's adherence and to make sure that their
                    pills are dispensed no matter what.
                </P>
            );
        }

        if (subReason === 'Setup Issues') {
            return (
                <P>
                    I can help you right now or any time that is more convenient - our experienced
                    Live Support Team is here for you, and we'd love to set up a call and walk you
                    through setting up your smart dispenser step-by-step. You'll be up and running
                    in no time!
                </P>
            );
        }

        if (subReason === 'Physical Impairment') {
            return (
                <P>
                    I'm sorry to hear that.
                    <br />
                    Did you know you can dispense pills remotely now? This feature is a great tool
                    for caregivers, who may want to have more remote control over their loved one's
                    adherence and to make sure that their pills are dispensed no matter what.
                </P>
            );
        }

        if (subReason === 'Security Features') {
            return (
                <P>
                    {
                        'Did you know that you can enable a "digital lock" for the Hero Device menu screens.  This will require anyone accessing the Hero Device menus to enter a passcode. When the Menu access passcode is enabled the only action that can take place on the Hero Device without a passcode is the scheduled dose dispense. This setting is available via the app under More > Notifications & Preferences > Caregiver & Member preferences'
                    }
                </P>
            );
        }

        if (subReason === 'Wi-Fi Issues') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device. We want
                    to resolve your problem as quickly as possible, so you can get the most out of
                    your Hero membership. If we determine that it's a mechanical or technical
                    performance issue with the dispenser, then that would warrant a replacement. I
                    will immediately escalate this to our Tech Support Team so they can scan the
                    network and hopefully fix the problem. Let us know if there is anything else we
                    can assist with at the moment.
                </P>
            );
        }

        if (subReason === 'Too Slow') {
            return (
                <P>
                    Did you know you can now dispense remotely with the Hero app and stay seated
                    while you finish your morning coffee or even help a loved one dispense a dose?
                    This feature is only available to Admins. Make sure Remote Dispense is ON via
                    the Enable Features section in the More tab. Slide to dispense will then be
                    available 30 mins prior to a scheduled dose.
                </P>
            );
        }

        if (subReason === 'Visual Impairment') {
            return (
                <P>
                    I'm sorry to hear that.
                    <br />
                    <br />
                    Did you know pills can be dispensed remotely now? This feature is a great tool
                    for caregivers, who may want to have more remote control over their loved one’s
                    adherence and to make sure that their pills are dispensed no matter what.
                </P>
            );
        }

        if (subReason === 'App Crashing') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device.
                    <br />
                    <br />
                    We want to resolve your problem as quickly as possible, so you can get the most
                    out of your Hero membership. If we determine that it's a mechanical or technical
                    performance issue with the dispenser, then that would warrant a replacement.
                    <br />
                    <br />
                    I'd like to know more about the issue so please provide me with as much
                    information as possible
                </P>
            );
        }

        if (subReason === 'Transport Damage') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device.
                    <br />
                    <br />
                    Transport damage would warrant a replacement.
                    <br />
                    <br />
                    I'd like to know more about the issue so I can process the exchange
                </P>
            );
        }

        if (subReason === 'HSA/FSA Issue') {
            return (
                <P>
                    I'm sorry to hear you're having issues.
                    <br />
                    <br />
                    Have you spoken to our billing department? They can provide you with a
                    customized invoice. Also, did you know your subscription might be covered by
                    Medicare? If you'd like, I can submit eligibility request for you immediately.
                </P>
            );
        }

        if (subReason === 'More Schedule Flexibility') {
            return (
                <P>
                    Hero is designed for complex schedules: Hero can store up to 10 medications
                    inside of it, and can alert you to take up to 10 medications that you store
                    outside of Hero. Add each medication one at a time, and the app will create
                    those schedules for you.
                    <br />
                    <br />
                    Granted, there are some requirements, we do not support and for the
                    inconvenience while we improve, I can offer you:
                </P>
            );
        }

        if (subReason === 'Screen Brightness') {
            return (
                <P>
                    I'm sorry to hear that you're having difficulties with your Hero device.
                    <br />
                    <br />
                    Did you know you can set brightness via Main menu {'>'} Device Settings
                    {' >'} Preferences {'>'} Set brightness
                    <br />
                    <br />
                    We want to resolve your problem as quickly as possible, so you can get the most
                    out of your Hero membership. If we determine that it's a mechanical or technical
                    performance issue with the dispenser, then that would warrant a replacement.
                    <br />
                    <br />
                    I'd like to know more about the issue so we can either fix or replace your
                    device
                </P>
            );
        }

        if (subReason === 'No Automated Dispensing') {
            return (
                <P>
                    We recommend our new feature - remote dispensing. You can dispense from the app
                    while still in bed, and the dose will be dispensed by the time you walk to Hero
                    (or brew your coffee).
                </P>
            );
        }

        if (subReason === 'Change in Health Related Needs') {
            return (
                <P>
                    Would you keep your Hero at low or no cost? Did you know your subscription might
                    be covered by Medicare? If you'd like, I can submit eligibility request for you
                    immediately.
                </P>
            );
        }

        return null;
    }, [defaultNote, subReason]);
};

export default useEncourageHeroSubReasonNoteMap;
