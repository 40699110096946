import React, { CSSProperties, useEffect, useCallback } from 'react';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';

import Container from '@hero/ui-kit/layout/Container';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import { EnterpriseEnrollmentSubmissionParams } from '@hero/validators/forms/backoffice/enterpriseEnrollmentSubmissionSchema';
import { usStates } from '@hero/hero-utils/address/states';

type Props = {
    isPrepopulated: boolean;
    onReset: () => void;
};

const SignupFormFields: React.FC<Props> = ({ isPrepopulated, onReset }) => {
    const { reset } = useFormContext<EnterpriseEnrollmentSubmissionParams>();

    // make sure form actually gets cleared if it receives empty default values
    useEffect(() => {
        !isPrepopulated && reset({});
    }, [isPrepopulated, reset]);

    const handleReset = useCallback(() => {
        reset({});
        onReset();
    }, [reset, onReset]);

    const hasSmartphone = useWatch({ name: 'has_smartphone' });
    const dailyPills = useWatch({ name: 'daily_pills' });
    const hasWifi = useWatch({ name: 'has_wifi' });
    const needsSetupHelp = useWatch({ name: 'needs_setup_help' });

    const formWrapPadding = useComponentPadding({ paddingTop: 'small', paddingBottom: 'large' });
    const resetButtonMargin = useComponentMargin({ marginLeft: 'regular' });

    const formWrapStyles: CSSProperties = {
        width: '92.4rem',
        ...formWrapPadding
    };
    const controlWrapStyles: CSSProperties = {
        display: 'flex'
    };

    return (
        <div style={formWrapStyles}>
            <Container gridTemplateColumns="1fr 1fr 1fr" gridColumnGap="regular">
                <Input name="invite_code" displayName="Invitation Code" />
            </Container>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                <Input name="first_name" displayName="First Name" />
                <Input name="last_name" displayName="Last Name" />
                <Input name="email" displayName="Email Address" />
                <Input name="home_phone" displayName="Phone Number" />
                <Input name="address_line_1" displayName="Street Address" />
                <Input name="address_line_2" displayName="Street Address Line 2" />
            </Container>
            <Container gridTemplateColumns="1fr 1fr 1fr" gridColumnGap="regular">
                <Input name="city" displayName="City" />
                <Select name="state" displayName="State" visibleLimit={5} noExtraMargin>
                    {usStates.map(({ code, name }) => (
                        <Option key={code} value={code} labelText={name} />
                    ))}
                </Select>
                <Input name="zip" displayName="Zip Code" />
            </Container>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                <RadioGroup name="has_smartphone" displayName="Smart Phone">
                    <Radio
                        id="has_iphone"
                        value="iphone"
                        labelText="iPhone"
                        checkedValue={hasSmartphone}
                    />
                    <Radio
                        id="has_android"
                        value="android"
                        labelText="Android"
                        checkedValue={hasSmartphone}
                    />
                    <Radio
                        id="has_not_smartphone"
                        value="no"
                        labelText="No / Other"
                        checkedValue={hasSmartphone}
                    />
                </RadioGroup>
                <RadioGroup name="daily_pills" displayName="Pill Takes Per Day">
                    <Radio
                        id="daily_pill_max_5"
                        value="1-5"
                        labelText="1-5"
                        checkedValue={dailyPills}
                    />
                    <Radio
                        id="daily_pill_max_10"
                        value="6-10"
                        labelText="6-10"
                        checkedValue={dailyPills}
                    />
                    <Radio
                        id="daily_pill_min_11"
                        value="10+"
                        labelText="10+"
                        checkedValue={dailyPills}
                    />
                </RadioGroup>
                <RadioGroup name="has_wifi" displayName="WiFi Access">
                    <Radio id="has_wifi" value="yes" labelText="Yes" checkedValue={hasWifi} />
                    <Radio id="has_not_wifi" value="no" labelText="No" checkedValue={hasWifi} />
                </RadioGroup>
                <RadioGroup name="needs_setup_help" displayName="Need Support">
                    <Radio
                        id="needs_setup_help"
                        value="yes"
                        labelText="Yes"
                        checkedValue={needsSetupHelp}
                    />
                    <Radio
                        id="needs_not_setup_help"
                        value="no"
                        labelText="No"
                        checkedValue={needsSetupHelp}
                    />
                </RadioGroup>
            </Container>
            <div style={controlWrapStyles}>
                <Button width="large" type="submit">
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default SignupFormFields;
