import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { membership } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useRTMTransfer = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/rtm-transfer`, { ...attributes }, { ...params, timeout: 120000 }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [membership] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useRTMTransfer;
