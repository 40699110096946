import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMembershipPlanUpdatedSelector } from '@hero/redux-data/backoffice/membershipPlan/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipPlanUpdateSuccessAlert = () => {
    const isPlanUpdated = useSelector(isMembershipPlanUpdatedSelector);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isPlanUpdated) {
            id.current = addNotification({
                type: 'success',
                message: 'Subscription plan has been updated.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isPlanUpdated]);
};
export default useMembershipPlanUpdateSuccessAlert;
