import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from './useSetQueryParams';
import omitProp from '@hero/hero-utils/omitProp';
const useFilterQuery = (key) => {
    const params = getQueryParams();
    const value = params[key] || '';
    const rest = omitProp(params, key);
    const setQueryParams = useSetQueryParams();
    const handleSetFilterQuery = (cbVal) => {
        const newQueryParams = buildQuery({
            ...rest,
            offset: 0,
            ...(cbVal !== 'all' && { [key]: cbVal })
        });
        setQueryParams(newQueryParams);
    };
    return [String(value), handleSetFilterQuery];
};
export default useFilterQuery;
