import React, { CSSProperties, useCallback, useState } from 'react';

import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    CancellationDetails,
    CancellationOptions,
    RmaEnums
} from '@hero/hero-types';
import { formatDollarAmount } from '@hero/hero-utils/currency';
import Container from '@hero/ui-kit/layout/Container';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import CancellationFlow from '../../../../views/CancellationFlow';
import getCancellationTransactionLabel from '../../utils/getCancellationTransactionLabel';
import RmaDetails from '../../../Rma/RmaDetails';
import ChargeFlow from '../ChargeFlow';
import useSectionMargin from '@hero/ui-kit/hooks/useSectionMargin';
import RmaDetailsDataProviderV2 from '../../../Rma/RmaDetails/RmaDetailsDataProviderV2';

type Props = {
    data: CancellationDetails;
    fetchCancellationDetails?: () => void;
    isRetryActive?: boolean;
    onCompleteFlow?: () => void;
    membership?: BackofficeMembershipDetails;
    rmaEnums?: RmaEnums;
    cancellationOptions?: CancellationOptions;
    userDetails?: BackofficeUserDeviceDetails;
};

const DetailsSectionTransaction: React.FC<Props> = ({
    data,
    isRetryActive = false,
    onCompleteFlow,
    fetchCancellationDetails,
    membership,
    rmaEnums,
    cancellationOptions,
    userDetails
}) => {
    const {
        cancellation: {
            cancellation_type,
            cancellation_fee,
            charge_status,
            refund_status,
            cancellation_fee_collected,
            cancellation_fee_outstanding,
            cancellation_fee_last_attempt
        },
        subscription: { id },
        rma
    } = data;

    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };
    const buttonStyles: CSSProperties = {
        verticalAlign: 'top',
        marginTop: '-0.4rem'
    };

    const [openCancellationFlow, setOpenCancellationFlow] = useState(false);
    const [openRmaDetails, setOpenRmaDetails] = useState(false);

    const handleOpenCancellationFlow = useCallback(() => {
        setOpenCancellationFlow(true);
    }, []);

    const handleOpenRmaDetails = useCallback(() => {
        rma?.rma_type === 1 && rma?.rma_status === 0
            ? handleOpenCancellationFlow()
            : setOpenRmaDetails(true);
    }, [rma, handleOpenCancellationFlow]);

    const handleCloseRmaDetails = useCallback(() => {
        setOpenRmaDetails(false);
        fetchCancellationDetails && fetchCancellationDetails();
    }, [fetchCancellationDetails]);

    const [showDeviceRetryModal, setShowDeviceRetryModal] = React.useState(false);

    const handleShowDeviceRetryModal = React.useCallback(() => {
        setShowDeviceRetryModal(true);
    }, []);

    const lastCancellationAttemptLabel = React.useMemo(() => {
        if (cancellation_fee_last_attempt === null) {
            return 'None';
        }
        return cancellation_fee_last_attempt?.success ? ' Success' : ' Fail';
    }, [cancellation_fee_last_attempt]);

    const marginTop = useSectionMargin({ marginTop: 'regular' });

    return (
        <Container gridTemplateColumns="5fr 4fr">
            <div>
                <P strong styles={sectionLabelStyles}>
                    {getCancellationTransactionLabel(cancellation_type?.transaction)}
                </P>
                <Container gridTemplateColumns="2fr 3fr">
                    {(cancellation_type?.transaction === 'charge' ||
                        cancellation_type?.transaction === 'waive') &&
                        cancellation_fee && (
                            <>
                                <P strong>Total Amount</P>
                                <P>{`${formatDollarAmount(
                                    cancellation_fee.amount,
                                    true
                                )} + Tax`}</P>
                            </>
                        )}
                    {cancellation_type?.transaction === 'charge' && (
                        <>
                            <P strong>Status</P>
                            <P>{charge_status}</P>
                        </>
                    )}
                    {cancellation_type?.transaction === 'refund' && (
                        <>
                            <P strong>Status</P>
                            <P>{refund_status}</P>
                        </>
                    )}
                    <P strong noDefaultMargin>
                        Fee Collected
                    </P>
                    <P noDefaultMargin>
                        {`${formatDollarAmount(cancellation_fee_collected || 0, true)} + Tax`}
                    </P>
                    <P strong noDefaultMargin>
                        {`Fee Outstanding (${cancellation_fee?.months} ${
                            cancellation_fee?.months === 1 ? 'Month' : 'Months'
                        })`}
                    </P>
                    <P noDefaultMargin>
                        {`${formatDollarAmount(cancellation_fee_outstanding || 0, true)} + Tax`}
                    </P>
                </Container>
                <Container styles={marginTop} gridTemplateColumns="2fr 3fr">
                    <P strong noDefaultMargin>
                        Last Billing Attempt:
                        {lastCancellationAttemptLabel}
                    </P>
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <P noDefaultMargin>{`${formatDollarAmount(
                            cancellation_fee_last_attempt?.amount || 0,
                            true
                        )} + Tax (${formatDollarAmount(
                            cancellation_fee_last_attempt?.tax || 0,
                            true
                        )})`}</P>
                        <Button
                            disabled={!isRetryActive}
                            size="small"
                            noDefaultMargin
                            styles={{ marginLeft: '1.75rem' }}
                            onClick={handleShowDeviceRetryModal}
                        >
                            Retry
                        </Button>
                    </div>
                </Container>
            </div>
            {cancellation_type?.code !== 'c1' && (
                <Container gridTemplateColumns="1fr 3fr">
                    <P strong styles={sectionLabelStyles}>
                        RMA
                    </P>
                    <P>
                        {!rma && cancellation_type?.code !== 'c5' ? (
                            <Button
                                size="small"
                                onClick={handleOpenCancellationFlow}
                                noDefaultMargin
                                styles={buttonStyles}
                            >
                                Create RMA
                            </Button>
                        ) : (
                            'N/A'
                        )}
                    </P>
                    <P strong>RMA #</P>
                    <P>
                        {rma && rma.id > 0 ? (
                            <Button size="small" onClick={handleOpenRmaDetails} noDefaultMargin>
                                {rma.id}
                            </Button>
                        ) : (
                            'N/A'
                        )}
                    </P>
                    <P strong>Status</P>
                    <P>{rma?.rma_status || 'N/A'}</P>
                </Container>
            )}
            {openCancellationFlow ? (
                <CancellationFlow
                    isCreateNewRma
                    externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                    onCompleteCallback={fetchCancellationDetails}
                    membership={membership}
                    userDetails={userDetails}
                    rmaEnums={rmaEnums}
                    cancellationOptions={cancellationOptions}
                    cancellationDetails={data}
                />
            ) : null}
            {rma && rma.id > 0 && openRmaDetails ? (
                <RmaDetailsDataProviderV2 rmaId={rma.id}>
                    <RmaDetails onCloseModal={handleCloseRmaDetails} />
                </RmaDetailsDataProviderV2>
            ) : null}
            {id && showDeviceRetryModal ? (
                <ChargeFlow
                    onCompleteFlow={onCompleteFlow}
                    membershipId={id}
                    cancellation={data.cancellation}
                    chargeContext="cancellation_fee"
                    externalControls={[showDeviceRetryModal, setShowDeviceRetryModal]}
                />
            ) : null}
        </Container>
    );
};

export default DetailsSectionTransaction;
