import { useMemo } from 'react';
const useGetDetailViewTitleV2 = (userDetails, membership) => {
    const userFullName = `${userDetails?.user?.first_name || ''} ${userDetails?.user?.last_name || ''}`.trim();
    const deviceSerials = useMemo(() => {
        const device = {
            serial: membership?.serial || userDetails?.device?.serial,
            external_serial: userDetails?.device?.external_serial
        };
        return `Device: ${device?.serial || '-'} / ${device?.external_serial || '-'}`;
    }, [membership, userDetails]);
    return userFullName ? `${userFullName} - ${deviceSerials}` : deviceSerials;
};
export default useGetDetailViewTitleV2;
