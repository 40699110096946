import React from 'react';

import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

const LegendItemLabel: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const padding = useComponentPadding({ paddingHorizontal: 'small' });

    return (
        <P noDefaultMargin strong styles={padding}>
            {children}
        </P>
    );
};

const LegendItem: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const padding = useComponentPadding({ paddingHorizontal: 'small' });

    return (
        <P noDefaultMargin styles={padding}>
            {children}
        </P>
    );
};

const OutreachLegend: React.FC = () => {
    const padding = useComponentPadding({
        paddingTop: 'small',
        paddingBottom: 'regular',
        paddingHorizontal: 'small'
    });

    return (
        <Container
            gridTemplateColumns="2fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr"
            gridRowGap="small"
            styles={padding}
        >
            <LegendItemLabel>Outreach Day</LegendItemLabel>
            <LegendItemLabel>D 8</LegendItemLabel>
            <LegendItemLabel>D 11</LegendItemLabel>
            <LegendItemLabel>D 14</LegendItemLabel>
            <LegendItemLabel>D 16</LegendItemLabel>
            <LegendItemLabel>D 18</LegendItemLabel>
            <LegendItemLabel>D 19</LegendItemLabel>
            <LegendItemLabel>D 21</LegendItemLabel>
            <LegendItem>Outreach Type</LegendItem>
            <LegendItem>Email 1</LegendItem>
            <LegendItem>Email 1</LegendItem>
            <LegendItem>Phone</LegendItem>
            <LegendItem>Email 1</LegendItem>
            <LegendItem>Phone</LegendItem>
            <LegendItem>Email 1</LegendItem>
            <LegendItem>Phone + Email 2</LegendItem>
        </Container>
    );
};

export default OutreachLegend;
