import getQueryParams from '@hero/hero-utils/getQueryParams';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import useSetQueryParams from './useSetQueryParams';
const useResetFiltersQuery = () => {
    const { search = '', limit = '', offset = '' } = getQueryParams();
    const setQueryParams = useSetQueryParams();
    const handleResetFiltersQuery = () => {
        const newQueryParams = buildQuery({
            ...(search && { search }),
            ...(limit && { limit }),
            ...((offset || +offset === 0) && { offset })
        });
        setQueryParams(newQueryParams);
    };
    return handleResetFiltersQuery;
};
export default useResetFiltersQuery;
