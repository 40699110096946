// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.huTISBwJhBH40ucDsmBK {
    display: flex;
    align-items: center;
    width: 60rem;
    padding: 1rem 2.4rem !important;
    border-radius: 3rem;
    column-gap: 3rem;
    border: 3px solid rgba(231, 231, 231, 1) !important;
    background: #fff !important;
}

.zyCObCw2ZUPM6bidM6QH {
    margin-top: 12rem;
    height: 22rem;
}`, "",{"version":3,"sources":["webpack://./pages/Home/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,+BAA+B;IAC/B,mBAAmB;IACnB,gBAAgB;IAChB,mDAAmD;IACnD,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".search {\n    display: flex;\n    align-items: center;\n    width: 60rem;\n    padding: 1rem 2.4rem !important;\n    border-radius: 3rem;\n    column-gap: 3rem;\n    border: 3px solid rgba(231, 231, 231, 1) !important;\n    background: #fff !important;\n}\n\n.illustration {\n    margin-top: 12rem;\n    height: 22rem;\n}"],"sourceRoot":""}]);
// Exports
export var search = `huTISBwJhBH40ucDsmBK`;
export var illustration = `zyCObCw2ZUPM6bidM6QH`;
export default ___CSS_LOADER_EXPORT___;
