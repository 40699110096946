import React from 'react';
import envVars from '../../constants/envVars';
import LogoutModal from '@hero/hero-auth/Logout';

const Logout: React.FC = () => {
    const [show, setShow] = React.useState(false);
    const showLogoutModal = React.useCallback(() => {
        setShow(true);
    }, []);

    const styles: React.CSSProperties = {
        cursor: 'pointer',
        color: '#434343',
        fontWeight: 'bold',
        fontSize: '14px'
    };
    return (
        <>
            <LogoutModal showLogoutModal={[show, setShow]} constAccessParams={envVars} />
            <div style={styles} onClick={showLogoutModal}>
                Logout
            </div>
        </>
    );
};

export default Logout;
