import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { rtmTransferOptions } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetRTMTransferOptions = (props) => {
    const { hero_user_id, enabled } = props;
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/rtm-transfer-options?hero_user_id=${hero_user_id}`, { ...additional }),
        queryKey: [rtmTransferOptions, hero_user_id],
        enabled
    });
};
export default useGetRTMTransferOptions;
