export const headerWrapStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
};
export const searchWrapStyles = {
    display: 'flex',
    alignItems: 'flex-start'
};
export const filterToggleStyles = {
    margin: '2rem 2.4rem 0'
};
