import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
const useRmaFilterFormStyles = () => {
    const filterMargin = useComponentMargin({ marginRight: 'regular' });
    const resetButtonMargin = useComponentMargin({ marginBottom: 'regular' });
    const resetButtonWrapMargin = useComponentMargin({ marginTop: 'regular' });
    const filterWrapStyles = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    };
    const filterStyles = {
        width: '26rem',
        ...filterMargin
    };
    return { filterStyles, filterWrapStyles, resetButtonMargin, resetButtonWrapMargin };
};
export default useRmaFilterFormStyles;
