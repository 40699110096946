import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isEnterpriseEnrollmentVerificationUpdatedSelector, enterpriseEnrollmentVerificationSelector } from '@hero/redux-data/backoffice/enterpriseEnrollmentVerification/selectors';
import { useNotifications } from '../../context/notification';
const useEnterpriseEnrollmentVerifySuccessAlert = () => {
    const isEnrollmentVerified = useSelector(isEnterpriseEnrollmentVerificationUpdatedSelector);
    const verification = useSelector(enterpriseEnrollmentVerificationSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isEnrollmentVerified) {
            id.current = addNotification({
                type: 'success',
                message: verification?.approved
                    ? 'Enrollment has been approved.'
                    : 'Enrollment has been rejected.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, isEnrollmentVerified, verification]);
};
export default useEnterpriseEnrollmentVerifySuccessAlert;
