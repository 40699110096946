import React, { FC } from 'react';
import ExchangeProvider from './context/ExchangeProvider';
import ExchangeStepsModal from './ExchangeStepsModal';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    EnterpriseEnrollment,
    RmaEnums,
    RmaRequest
} from '@hero/hero-types';
import { AxiosErrorResponse } from '../../types';

type ExchangeFlowProps = {
    isOrganizationMember?: boolean;
    deviceSerial?: string;
    isLoading?: boolean;
    onCompleteCallback?: () => void;
    onCloseModal?: () => void;
    membership?: BackofficeMembershipDetails;
    rma?: RmaRequest;
    userDetails?: BackofficeUserDeviceDetails;
    exchangeRmaError?: AxiosErrorResponse;
    enterpriseEnrollment?: EnterpriseEnrollment;
    rmaEnums?: RmaEnums;
} & Pick<ModalProps, 'externalControls'>;

const ExchangeFlow: FC<ExchangeFlowProps> = ({
    isOrganizationMember = false,
    externalControls,
    deviceSerial,
    onCompleteCallback,
    isLoading = false,
    onCloseModal,
    membership,
    rma,
    userDetails,
    exchangeRmaError,
    enterpriseEnrollment,
    rmaEnums
}) => {
    return (
        <ExchangeProvider
            exchangeRmaError={exchangeRmaError}
            enterpriseEnrollment={enterpriseEnrollment}
            onCloseModal={onCloseModal}
            onCompleteCallback={onCompleteCallback}
            deviceSerial={deviceSerial}
            isOrganizationMember={isOrganizationMember}
            membership={membership}
            rma={rma}
            userDetails={userDetails}
            rmaEnums={rmaEnums}
        >
            <ExchangeStepsModal externalControls={externalControls} isLoading={isLoading} />
        </ExchangeProvider>
    );
};

export default ExchangeFlow;
