import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchMemberships, membershipsReset } from '@hero/redux-data/backoffice/memberships/actionCreators';
import { fetchMembershipPlans, membershipPlansReset } from '@hero/redux-data/backoffice/membershipPlans/actionCreators';
import { areMembershipsLoadingSelector, membershipPaginationSelector, membershipListSelector } from '@hero/redux-data/backoffice/memberships/selectors';
import { areMembershipPlansLoadingSelector } from '@hero/redux-data/backoffice/membershipPlans/selectors';
import usePagerProps from '../../../hooks/usePagerProps';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import getTableColumns from './utils/getTableColumns';
import repackTableData from './utils/repackTableData';
const useMembershipListData = () => {
    const dispatch = useDispatch();
    const isFetchingMemberships = useSelector(areMembershipsLoadingSelector);
    const memberships = useSelector(membershipListSelector, shallowEqual);
    const paginationData = useSelector(membershipPaginationSelector, shallowEqual);
    const isFetchingPlans = useSelector(areMembershipPlansLoadingSelector);
    const [sort, handleSort] = useTableSortQuery();
    const tableColumns = useMemo(() => getTableColumns(), []);
    const tableData = useMemo(() => repackTableData(memberships), [memberships]);
    const pagerProps = usePagerProps(paginationData);
    const query = window.location.search;
    useEffect(() => {
        dispatch(fetchMemberships(query));
    }, [dispatch, query]);
    useEffect(() => {
        dispatch(fetchMembershipPlans());
        return () => {
            dispatch(membershipsReset());
            dispatch(membershipPlansReset());
        };
    }, [dispatch]);
    return {
        dispatch,
        isFetchingPlans,
        tableData,
        tableColumns,
        isFetchingMemberships,
        sort,
        handleSort,
        pagerProps
    };
};
export default useMembershipListData;
