import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchRmaRequests, rmaRequestsReset } from '@hero/redux-data/backoffice/rmaRequests/actionCreators';
import { getRmaEnums, rmaEnumsReset } from '@hero/redux-data/backoffice/rmaEnums/actionCreators';
import { areRmaRequestsLoadingSelector, rmaRequestPaginationSelector, rmaRequestListSelector } from '@hero/redux-data/backoffice/rmaRequests/selectors';
import { areRmaEnumsLoadingSelector } from '@hero/redux-data/backoffice/rmaEnums/selectors';
import useTableSortQuery from '../../../../hooks/useTableSortQuery';
import usePagerProps from '../../../../hooks/usePagerProps';
import useTableDataRepack from './useTableDataRepack';
const useRmaData = () => {
    const dispatch = useDispatch();
    const isFetchingRmaRequests = useSelector(areRmaRequestsLoadingSelector);
    const rmaRequests = useSelector(rmaRequestListSelector, shallowEqual);
    const isFetchingEnums = useSelector(areRmaEnumsLoadingSelector);
    const [sort, handleSort] = useTableSortQuery();
    const paginationData = useSelector(rmaRequestPaginationSelector, shallowEqual);
    const pagerProps = usePagerProps(paginationData);
    const tableData = useTableDataRepack(rmaRequests);
    const queryParams = window.location.search;
    const fetchRmaList = useCallback(() => {
        dispatch(fetchRmaRequests(window.location.search));
    }, [dispatch]);
    useEffect(() => {
        fetchRmaList();
    }, [fetchRmaList, queryParams]);
    useEffect(() => {
        dispatch(getRmaEnums({}));
        return () => {
            dispatch(rmaRequestsReset());
            dispatch(rmaEnumsReset());
        };
    }, [dispatch]);
    return {
        isFetchingRmaRequests,
        isFetchingEnums,
        tableData,
        pagerProps,
        sort,
        handleSort,
        dispatch,
        fetchRmaList
    };
};
export default useRmaData;
