import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRequests } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useGetCancellationRequests = (props) => {
    const { organization_id, limit = 10, offset = 0, date_from, date_to, status } = props;
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/cancellation-requests/`, {
            ...additional,
            params: { organization_id, limit, offset, date_from, date_to, status }
        }),
        queryKey: [cancellationRequests, organization_id, status, offset]
    });
};
export default useGetCancellationRequests;
