import React from 'react';

import { Contact } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import { formatPhoneNumber } from '@hero/hero-utils/phone';

import SideBySideComparisonHeader from './SideBySideComparisonHeader';
import SideBySideComparisonRow from './SideBySideComparisonRow';
import usePersonalDataVerification from './utils/usePersonalDataVerification';

type Props = {
    organizationSubmitted: Contact | null | undefined;
    contactSubmitted: Contact | null | undefined;
};

const SideBySideComparison: React.FC<Props> = ({ organizationSubmitted, contactSubmitted }) => {
    const {
        hasDiscrepancyInFirstName,
        hasDiscrepancyInLastName,
        hasDiscrepancyInEmailAddress,
        hasDiscrepancyInPhoneNumber,
        hasDiscrepancyInStreetAddress,
        hasDiscrepancyInCity,
        hasDiscrepancyInState,
        hasDiscrepancyInZipCode
    } = usePersonalDataVerification(organizationSubmitted, contactSubmitted);

    const organizationHomePhone =
        organizationSubmitted?.home_phone &&
        formatPhoneNumber(organizationSubmitted.home_phone, 'international');
    const organizationCellPhone =
        organizationSubmitted?.cell_phone &&
        formatPhoneNumber(organizationSubmitted.cell_phone, 'international');
    const contactHomePhone =
        contactSubmitted?.home_phone &&
        formatPhoneNumber(contactSubmitted.home_phone, 'international');
    const contactCellPhone =
        contactSubmitted?.cell_phone &&
        formatPhoneNumber(contactSubmitted.cell_phone, 'international');

    return (
        <Container
            gridTemplateColumns="min-content 1fr 1fr"
            gridColumnGap="large"
            gridRowGap="regular"
        >
            <SideBySideComparisonHeader />
            <SideBySideComparisonRow
                header="First Name"
                organizationSubmitted={organizationSubmitted?.first_name || '-'}
                contactSubmitted={contactSubmitted?.first_name || '-'}
                hasDiscrepancy={hasDiscrepancyInFirstName}
            />
            <SideBySideComparisonRow
                header="Last Name"
                organizationSubmitted={organizationSubmitted?.last_name || '-'}
                contactSubmitted={contactSubmitted?.last_name || '-'}
                hasDiscrepancy={hasDiscrepancyInLastName}
            />
            <SideBySideComparisonRow
                header="Email Address"
                organizationSubmitted={organizationSubmitted?.email || '-'}
                contactSubmitted={contactSubmitted?.email || '-'}
                hasDiscrepancy={hasDiscrepancyInEmailAddress}
            />
            <SideBySideComparisonRow
                header="Phone Number"
                organizationSubmitted={
                    <>
                        {!organizationHomePhone && !organizationCellPhone && '-'}
                        {organizationHomePhone}
                        {organizationCellPhone && organizationCellPhone !== organizationHomePhone && (
                            <>
                                {organizationHomePhone && <br />}
                                {organizationCellPhone}
                            </>
                        )}
                    </>
                }
                contactSubmitted={
                    <>
                        {!contactHomePhone && !contactCellPhone && '-'}
                        {contactHomePhone}
                        {contactCellPhone && contactCellPhone !== contactHomePhone && (
                            <>
                                {contactHomePhone && <br />}
                                {contactCellPhone}
                            </>
                        )}
                    </>
                }
                hasDiscrepancy={hasDiscrepancyInPhoneNumber}
            />
            <SideBySideComparisonRow
                header="Street Address"
                organizationSubmitted={
                    <>
                        {organizationSubmitted?.address?.address_line_1 || '-'}
                        {organizationSubmitted?.address?.address_line_2 && <br />}
                        {organizationSubmitted?.address?.address_line_2}
                    </>
                }
                contactSubmitted={
                    <>
                        {contactSubmitted?.address?.address_line_1 || '-'}
                        {contactSubmitted?.address?.address_line_2 && <br />}
                        {contactSubmitted?.address?.address_line_2}
                    </>
                }
                hasDiscrepancy={hasDiscrepancyInStreetAddress}
            />
            <SideBySideComparisonRow
                header="City"
                organizationSubmitted={organizationSubmitted?.address?.city || '-'}
                contactSubmitted={contactSubmitted?.address?.city || '-'}
                hasDiscrepancy={hasDiscrepancyInCity}
            />
            <SideBySideComparisonRow
                header="State"
                organizationSubmitted={organizationSubmitted?.address?.state || '-'}
                contactSubmitted={contactSubmitted?.address?.state || '-'}
                hasDiscrepancy={hasDiscrepancyInState}
            />
            <SideBySideComparisonRow
                header="Zip Code"
                organizationSubmitted={organizationSubmitted?.address?.zip || '-'}
                contactSubmitted={contactSubmitted?.address?.zip || '-'}
                hasDiscrepancy={hasDiscrepancyInZipCode}
            />
        </Container>
    );
};

export default SideBySideComparison;
