import React from 'react';

import { RefundPreview } from '@hero/hero-types';
import { formatDollarAmount } from '@hero/hero-utils/currency';
import Container from '@hero/ui-kit/layout/Container';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

type Props = {
    refund: RefundPreview | null;
};

const ReviewRefund: React.FC<Props> = ({ refund }) => {
    const margin = useComponentMargin({ marginTop: 'small' });

    return refund ? (
        <div>
            <H role="h6" centered>
                Amount to be refunded
            </H>
            <Container gridTemplateColumns="auto min-content" gridColumnGap="regular">
                <P strong styles={{ textAlign: 'right', ...margin }} noDefaultMargin>
                    {formatDollarAmount(refund.total_amount, true)} - Total (incl. tax)
                </P>
            </Container>
        </div>
    ) : null;
};

export default ReviewRefund;
