import React from 'react';

import { useNotifications } from '../../context/notification';
import NotificationBar, { Alert } from '../NotificationBar';

const NotificationArea: React.FC = () => {
    const { notifications, removeNotification } = useNotifications();

    return (
        <>
            {notifications.map(({ id, config }) => (
                <NotificationBar
                    key={id}
                    alert={config as Alert}
                    onDismiss={() => removeNotification(id)}
                />
            ))}
        </>
    );
};

export default NotificationArea;
