import React from 'react';

import { DeviceInList } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../components/Link';

export type DeviceTableItem = Omit<DeviceInList, 'last_online'> & { last_online: string };
type Render = TableColumn<DeviceTableItem>['render'];

const serialRender: Render = ({ id, serial }) =>
    serial ? <Link to={`/devices/${id}`}>{serial}</Link> : '-';

type GetTableComns = () => TableColumn<DeviceTableItem>[];
const getTableColumns: GetTableComns = () => [
    {
        colKey: 'serial',
        name: 'Serial #',
        align: 'left',
        headAlign: 'left',
        render: serialRender
    },
    {
        colKey: 'id',
        name: 'ID'
    },
    {
        colKey: 'email',
        name: 'Email'
    },
    {
        colKey: 'last_online',
        name: 'Last\nOnline'
    }
];

export default getTableColumns;
