import * as yup from 'yup';
const hardshipOffersSchema = yup
    .object()
    .shape({
    hardship: yup
        .mixed()
        .required('Hardship offer is required')
        .oneOf([
        'mitigation-22',
        'mitigation-33',
        'mitigation-50',
        'mitigation-67',
        'mitigation-78',
        'pause-subscription',
        '1-month-free'
    ], 'Please select option'),
    months: yup
        .number()
        .required('Months is required')
        .typeError('Months must be a number')
        .min(1, 'Months must be greater than 0'),
    discount_type: yup.number().required(),
    mitigation_type: yup.number().required()
})
    .defined();
export default hardshipOffersSchema;
