import React from 'react';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

type Props = { errorMessage: string | null; isCancelable?: boolean; onClose?: () => void };

const DefaultErrorModal: React.FC<Props> = ({ errorMessage, isCancelable = true, onClose }) => (
    <Modal type="fail" isCancelable={isCancelable} onClose={onClose}>
        <H role="h4" inverted>
            An error occured
        </H>
        <P inverted>{errorMessage || 'Something went wrong'}</P>
    </Modal>
);

export default DefaultErrorModal;
