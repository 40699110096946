import React from 'react';
import PageSizeForm from '../../../../components/PageSizeForm';
import FiltersToggle from '../../../../components/FiltersToggle';
import useFilters from '../../../../hooks/useFiltersToggle';

import AdherenceFilterForm from './AdherenceFilterForm';

const UserAdherenceListFilter: React.FC = () => {
    const [areFiltersExpanded, toggleFiltersExpansion] = useFilters();

    const headerWrapStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const searchWrapStyles: React.CSSProperties = {
        display: 'flex',
        alignItems: 'flex-start'
    };
    const filterToggleStyles: React.CSSProperties = {
        margin: '2rem 2.4rem 0'
    };

    return (
        <>
            <div style={headerWrapStyles}>
                <div style={searchWrapStyles}>
                    <FiltersToggle
                        isExpanded={areFiltersExpanded}
                        onClick={toggleFiltersExpansion}
                        styles={filterToggleStyles}
                    />
                </div>
                <PageSizeForm />
            </div>
            {areFiltersExpanded && <AdherenceFilterForm />}
        </>
    );
};

export default UserAdherenceListFilter;
