import React from 'react';

import { BoUserDetails } from '@hero/hero-types';
import { TableColumn } from '@hero/ui-kit/tables/Table';

import Link from '../../../components/Link';

type Render = TableColumn<BoUserDetails>['render'];

const renderEmail: Render = ({ id, email }) =>
    email ? <Link to={`/users/${id}`}>{email}</Link> : '-';

const renderActivated: Render = ({ activated }) => (activated ? 'activated' : '-');

type GetTableComns = () => TableColumn<BoUserDetails>[];
const getTableColumns: GetTableComns = () => [
    {
        colKey: 'Email',
        name: 'Email',
        align: 'left',
        headAlign: 'left',
        render: renderEmail
    },
    {
        colKey: 'id',
        name: 'ID'
    },
    {
        colKey: 'activated',
        name: 'Activated',
        align: 'left',
        headAlign: 'left',
        render: renderActivated
    },
    {
        colKey: 'device_id',
        name: 'Device\nID'
    },
    {
        colKey: 'device_serial',
        name: 'Device\nSerial'
    }
];

export default getTableColumns;
