import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { removeTransferFlag } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';

type Props = {
    handleClose: () => void;
    device_id: number;
    organization_name?: string;
};

const RemoveTransferFlag: React.FC<Props> = ({ handleClose, device_id, organization_name }) => {
    const dispatch = useDispatch();
    const handleRemoveTransferFlag = useCallback(
        () => dispatch(removeTransferFlag({ id: device_id })),
        [dispatch, device_id]
    );

    return (
        <Modal onClose={handleClose} isCancelable>
            <H role="h4">Remove Transfer Flag</H>
            <P>Remove Transfer Flag</P>
            {organization_name && <P>{organization_name}</P>}
            <Button onClick={handleRemoveTransferFlag}>Remove</Button>
        </Modal>
    );
};

export default RemoveTransferFlag;
