import React, { useCallback, useEffect, useMemo } from 'react';

import { usStateByCode } from '@hero/hero-utils/address/states';
import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import useGoogleMaps from '@hero/react-hooks/useGoogleMaps';
import useGooglePlaceDetails from '@hero/react-hooks/useGooglePlaces/useGooglePlaceDetails';
import useGooglePlaceSuggestions from '@hero/react-hooks/useGooglePlaces/useGooglePlaceSuggestions';
import Combobox from '@hero/ui-kit/inputs/Combobox';
import { useFormContext } from '@hero/ui-kit/inputs/Form';

type Props = {
    addressName?: string;
    cityName?: string;
    stateName?: string;
    zipName?: string;
    displayAddressName?: string;
    disabled?: boolean;
};

const AddressCombobox: React.FC<Props> = ({
    addressName = 'address_line_1',
    cityName = 'city',
    stateName = 'state',
    zipName = 'zip',
    displayAddressName = 'Street Address (No P.O. Boxes)',
    disabled = false
}) => {
    const isMinL = useIsMinBreakpoint('l');

    const isGoogleApiReady = useGoogleMaps({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || ''
    });

    const { suggestions, getSuggestions, clearSuggestions } = useGooglePlaceSuggestions();
    const { details, streetAddress, city, stateCode, zipCode, getDetails, clearDetails } =
        useGooglePlaceDetails();

    const suggestionLabels = useMemo(() => suggestions.map(({ label }) => label), [suggestions]);

    const { setValue } = useFormContext();

    useEffect(
        () => () => {
            clearSuggestions();
            clearDetails();
        },
        [clearSuggestions, clearDetails]
    );

    useEffect(() => {
        streetAddress &&
            setValue(addressName, streetAddress, {
                shouldValidate: true,
                shouldDirty: true
            });
        city && setValue(cityName, city, { shouldValidate: true, shouldDirty: true });
        stateCode &&
            setValue(stateName, usStateByCode(stateCode)?.code || '', {
                shouldValidate: true,
                shouldDirty: true
            });
        zipCode && setValue(zipName, zipCode, { shouldValidate: true, shouldDirty: true });
        // details are used as dependency to ensure repopulation if the same data is to be used (edge case if user edited the fields manually between two suggestion clicks)
    }, [
        setValue,
        streetAddress,
        city,
        stateCode,
        zipCode,
        details,
        addressName,
        cityName,
        stateName,
        zipName
    ]);

    const handleFetchAddressSuggestions = useCallback(
        (input: string) => {
            if (input !== '') {
                isGoogleApiReady && getSuggestions(input);
            } else {
                clearSuggestions();
            }
        },
        [isGoogleApiReady, getSuggestions, clearSuggestions]
    );

    const handleAddressSelection = useCallback(
        (value: string) => {
            const address = suggestions.find(({ label }) => label === value);
            const placeId = address?.placeId;
            if (placeId) {
                isGoogleApiReady && getDetails(placeId);
            } else {
                clearDetails();
            }
        },
        [isGoogleApiReady, suggestions, getDetails, clearDetails]
    );

    return (
        <Combobox
            name={addressName}
            displayName={displayAddressName}
            placeholder={isMinL ? '' : 'Address'}
            suggestions={suggestionLabels}
            handleFetchSuggestions={handleFetchAddressSuggestions}
            onSelection={handleAddressSelection}
            noExtraMargin
            preventSuggestionFilter
            disabled={disabled}
        />
    );
};

export default AddressCombobox;
