import { useRef, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { areMembershipDetailsUpdatedSelector, membershipDetailsSelector, } from "@hero/redux-data/backoffice/membershipDetails/selectors";
import { areUserDeviceDetailsUpdatedSelector, userDetailsSelector, } from "@hero/redux-data/backoffice/userDeviceDetails/selectors";
import { useNotifications } from "../../context/notification";
const useMembershipUserWarnAlert = () => {
    const isMembershipFetched = useSelector(areMembershipDetailsUpdatedSelector);
    const membership = useSelector(membershipDetailsSelector, shallowEqual);
    const isUserDeviceFetched = useSelector(areUserDeviceDetailsUpdatedSelector);
    const user = useSelector(userDetailsSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (isMembershipFetched &&
            isUserDeviceFetched &&
            (!membership?.hero_user_id ||
                !user?.id ||
                membership?.hero_user_id !== String(user?.id))) {
            id.current = addNotification({
                type: "warn",
                message: "User not yet (properly) set on membership.",
            });
        }
        else {
            id.current = undefined;
        }
    }, [
        addNotification,
        removeNotification,
        isMembershipFetched,
        isUserDeviceFetched,
        membership,
        user,
    ]);
};
export default useMembershipUserWarnAlert;
