import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { membershipAlertsErrorSelector } from '@hero/redux-data/backoffice/membershipAlerts/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipAlertListFailAlert = () => {
    const { error, errorMessage } = useSelector(membershipAlertsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: errorMessage || 'Error fetching alerts.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage]);
};
export default useMembershipAlertListFailAlert;
