import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getUserDeviceDetails,
    userDeviceDetailsReset
} from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';
import { userDetailsSelector } from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

const UserHeader: React.FC = () => {
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const user = useSelector(userDetailsSelector, shallowEqual);

    React.useEffect(() => {
        user_id && dispatch(getUserDeviceDetails({ user_id: user_id ? +user_id : 0 }));
    }, [dispatch, user_id]);

    const userData = React.useMemo(() => {
        const userName =
            user?.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : '-';
        return {
            userName,
            email: user?.email || '-'
        };
    }, [user]);

    React.useEffect(() => {
        return () => {
            dispatch(userDeviceDetailsReset());
        };
    }, [dispatch]);

    return (
        <>
            <H role="h4" noDefaultMargin>
                {userData.userName}
            </H>
            <P>{userData.email}</P>
        </>
    );
};

export default UserHeader;
