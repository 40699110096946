import React, { CSSProperties, ReactNode } from 'react';

import useTheme from '@hero/branding/theme';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

type Props = {
    header: string;
    organizationSubmitted: ReactNode;
    contactSubmitted: ReactNode;
    hasDiscrepancy?: boolean;
};

const SideBySideComparisonRow: React.FC<Props> = ({
    header,
    organizationSubmitted,
    contactSubmitted,
    hasDiscrepancy = false
}) => {
    const { colors, borders } = useTheme();
    const padding = useComponentPadding({ paddingVertical: 'small', paddingHorizontal: 'regular' });
    const headerPadding = useComponentPadding({ paddingVertical: 'small' });

    const rowHeaderStyles: CSSProperties = {
        whiteSpace: 'nowrap',
        ...headerPadding
    };
    const discrepancyStyles: CSSProperties = {
        backgroundColor: colors.status.fail.gamma,
        ...borders.shape.rounded
    };

    return (
        <>
            <P noDefaultMargin strong styles={rowHeaderStyles}>
                {header}
            </P>
            <P noDefaultMargin styles={padding}>
                {organizationSubmitted}
            </P>
            <P noDefaultMargin styles={{ ...padding, ...(hasDiscrepancy && discrepancyStyles) }}>
                {contactSubmitted}
            </P>
        </>
    );
};

export default SideBySideComparisonRow;
