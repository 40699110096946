import getQueryParams from '@hero/hero-utils/getQueryParams';
import Form from '@hero/ui-kit/inputs/Form';
import React from 'react';
import useOrganizationListData from '../../../pages/Organizations/OrganizationsList/useOrganizationListData';
import FilterFields from './FilterFields';
import monitoringFilterSchema, { MonitoringFilterSchemaParams } from './schema';

interface FilterQueryParams {
    organization_id?: number;
    membership_key?: string;
}

interface FilterProps {
    hideAllFilterOption?: boolean;
}

const Filter: React.FC<FilterProps> = ({ hideAllFilterOption }) => {
    const { organizationList } = useOrganizationListData();
    const { organization_id, membership_key } = getQueryParams<FilterQueryParams>();

    const handleFilter = (params: MonitoringFilterSchemaParams) => {
        console.log(params);
    };

    const defaultValues = React.useMemo(() => {
        const firstOrg = organizationList?.length ? `${organizationList[0].id}` : undefined;
        return {
            filter_key: organization_id ? 'organizations' : 'all',
            organization_id: organization_id ? `${organization_id}` : firstOrg,
            membership_key
        };
    }, [organizationList, organization_id, membership_key]);

    return (
        <Form
            validationSchema={monitoringFilterSchema}
            defaultValues={defaultValues}
            submitFn={handleFilter}
        >
            <FilterFields
                hideAllFilterOption={hideAllFilterOption}
                organizationId={organization_id}
                membershipKey={membership_key}
                organizations={organizationList}
            />
        </Form>
    );
};

export default Filter;
