import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { membershipDetailsErrorSelector } from '@hero/redux-data/backoffice/membershipDetails/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipDetailsFailAlert = () => {
    const { error, errorMessage, originalMessage } = useSelector(membershipDetailsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error && originalMessage !== 'invalid_subscription') {
            id.current = addNotification({
                type: 'fail',
                message: errorMessage || 'Error fetching membership.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage, originalMessage]);
};
export default useMembershipDetailsFailAlert;
