import { CSSProperties } from 'react';

import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

export const useListStyles = () => {
    const innerWrapPadding = useComponentPadding({ paddingVertical: 'regular' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const tableWrapStyles: CSSProperties = {
        overflowX: 'auto'
    };

    return {
        innerWrapPadding,
        headerWrapStyles,
        tableWrapStyles
    };
};

export default useListStyles;
