import { useMemo } from 'react';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
const useStyles = () => {
    const formWrapPadding = useComponentPadding({ paddingTop: 'small', paddingBottom: 'large' });
    const resetButtonMargin = useComponentMargin({ marginLeft: 'regular' });
    const formWrapStyles = useMemo(() => ({
        width: '92.4rem',
        ...formWrapPadding
    }), [formWrapPadding]);
    const selectStyles = useMemo(() => ({
        width: '92.4rem'
    }), []);
    const controlWrapStyles = useMemo(() => ({
        display: 'flex',
        marginTop: '1.2rem'
    }), []);
    return { formWrapStyles, selectStyles, controlWrapStyles, resetButtonMargin };
};
export default useStyles;
