import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import usePagerProps from '../../../hooks/usePagerProps';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
import getTableColumns from './utils/getTableColumns';
import repackTableData from './utils/repackTableData';
import useGetCancellationRequests from './api/useGetCancellationRequests';
import { useSelector } from 'react-redux';
import { organizationListSelector } from '@hero/redux-data/backoffice/oldOrganizationList/selectors';
import { useSearchParams } from 'react-router-dom';
const useDischargedQueueMemberListData = (organization_id, noteActionHandler, status) => {
    const dispatch = useDispatch();
    const organizationList = useSelector(organizationListSelector);
    const [searchParams] = useSearchParams();
    const offsetParam = useMemo(() => {
        return searchParams.get('offset') || undefined;
    }, [searchParams]);
    const { data, isLoading: isFetchingMembers, refetch } = useGetCancellationRequests({
        organization_id,
        status,
        offset: offsetParam ? +offsetParam : 0
    });
    const { total_count = 0, limit = 10, offset = 0, list = [] } = data?.data ?? {};
    const refetchCancellationRequests = () => {
        refetch();
    };
    const tableData = useMemo(() => repackTableData(list, organizationList), [list, organizationList]);
    const pagerProps = usePagerProps({ total_count, limit, offset });
    const [sort, handleSort] = useTableSortQuery();
    return {
        dispatch,
        tableColumns: getTableColumns(noteActionHandler, refetchCancellationRequests),
        tableData,
        isFetchingMembers,
        sort,
        handleSort,
        pagerProps,
        refetchCancellationRequests
    };
};
export default useDischargedQueueMemberListData;
