import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Table from '@hero/ui-kit/tables/Table';
import LinkButton from '@hero/ui-kit/inputs/LinkButton';
import H from '@hero/ui-kit/typography/H';

import envVars from '../../../constants/envVars';
import InlineLoader from '../../../components/InlineLoader';
import TabBar from '../TabBar';

import { MembershipTableDataObject } from './utils/repackTableData';
import MembershipListFiltering from './MembershipListFiltering';
import useMembershipListData from './useMembershipListData';
import useMembershipListStyles from './useMembershipListStyles';
import DefaultLayout from '../../../components/DefaultLayout';

const MembershipList: React.FC = () => {
    const {
        isFetchingPlans,
        tableData,
        tableColumns,
        isFetchingMemberships,
        sort,
        handleSort,
        pagerProps
    } = useMembershipListData();
    const { innerWrapPadding, headerWrapStyles, tableWrapStyles } = useMembershipListStyles();

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">Membership</H>
                    <LinkButton
                        to={`${envVars.STRIPE_DASHBOARD_BASE_URL}/customers`}
                        noDefaultMargin
                    >
                        Go To Stripe
                    </LinkButton>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    {isFetchingPlans ? (
                        <InlineLoader />
                    ) : (
                        <>
                            <MembershipListFiltering />
                            <div style={tableWrapStyles}>
                                <Table<MembershipTableDataObject>
                                    columns={tableColumns}
                                    data={tableData}
                                    rowKey="id"
                                    emptyValues="-"
                                    isLoading={isFetchingMemberships}
                                    sortColumn={sort.sort_by}
                                    sortDirection={sort.sort_dir}
                                    onColumnSort={handleSort}
                                    pagerProps={pagerProps}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default MembershipList;
