import React from 'react';

import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import H from '@hero/ui-kit/typography/H';
import TabGroup from '@hero/ui-kit/components/TabGroup';
import Tab from '@hero/ui-kit/components/Tab';

import DefaultErrorModal from '../../../components/DefaultErrorModal';

import useRefurbishmentDetails from './useRefurbishmentDetailsdata';
import DefaultLayout from '../../../components/DefaultLayout';

const RefurbishmentDetailsWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { device_id, deviceName, hasError, errorMessage, isLoading } = useRefurbishmentDetails();

    return isLoading ? (
        <Loader />
    ) : (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h4">{`Refurbishment Details (${deviceName})`}</H>
                {device_id && (
                    <TabGroup fullWidth>
                        <Tab to={`/devices/${device_id}/details`}>Details</Tab>
                        <Tab to={`/devices/${device_id}/hazardous-drugs`}>Hazardous Drugs</Tab>
                        <Tab to={`/devices/${device_id}/restocking`}>Restocking</Tab>
                        <Tab to={`/devices/${device_id}/reprocessing`}>Reprocessing</Tab>
                    </TabGroup>
                )}
                {children}
            </Section>
        </DefaultLayout>
    );
};

export default RefurbishmentDetailsWrapper;
