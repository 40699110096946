import React, { CSSProperties } from 'react';

import { Cancellation } from '@hero/hero-types';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';

type Props = {
    cancellation: Cancellation;
};

const DetailsSectionReason: React.FC<Props> = ({ cancellation }) => {
    const sectionLabelStyles: CSSProperties = {
        textTransform: 'uppercase'
    };

    return (
        <>
            <P strong styles={sectionLabelStyles}>
                Reason of Cancellation
            </P>
            <Container gridTemplateColumns="2fr 7fr">
                <P strong>Reason</P>
                <P>{cancellation.reason.description}</P>
                <P strong>Notes</P>
                <P>{cancellation.notes || '-'}</P>
            </Container>
        </>
    );
};

export default DetailsSectionReason;
