import getFilterOptions from '../../../../utils/getFilterOptions';

import useRmaEnums from '../../utils/useRmaEnums';

const useRmaOptions = () => {
    const { rmaTypeOptions, rmaStatusOptions, returnStatusOptions, refundStatusOptions } =
        useRmaEnums();

    const rmaTypes = getFilterOptions(rmaTypeOptions);
    const rmaStatuses = getFilterOptions(rmaStatusOptions);
    const returnStatuses = getFilterOptions(returnStatusOptions);
    const refundStatuses = getFilterOptions(refundStatusOptions);

    return { rmaTypes, rmaStatuses, returnStatuses, refundStatuses };
};

export default useRmaOptions;
