import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { device } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useDeviceRemoteResetReboot = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_CLOUD_HOSTNAME}backoffice/device-remote-reboot-request/`, { ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [device] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useDeviceRemoteResetReboot;
