import React from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import CaretButton from '@hero/ui-kit/inputs/CaretButton';

type Props = {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isExpanded: boolean;
} & UiComponentProps;

export const FiltersToggle: React.FC<Props> = (props) => (
    <CaretButton
        size="small"
        textBefore="Filters"
        directionExpanded="up"
        directionCollapsed="down"
        {...props}
    />
);

export default FiltersToggle;
