import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRequests } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useCancellationRequestReject = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/cancellation-requests/reject`, { ...attributes }, params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [cancellationRequests] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useCancellationRequestReject;
