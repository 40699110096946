import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

import TabGroup from '@hero/ui-kit/components/TabGroup';
import Tab from '@hero/ui-kit/components/Tab';

import { MembershipRouteParams } from './types';

const TabBar: React.FC = () => {
    const { id } = useParams<MembershipRouteParams>();
    const { search, pathname } = useLocation();

    const detailViews = ['/membership/details', '/membership/exchange', '/membership/cancellation'];

    return id ? (
        <TabGroup fullWidth>
            <Tab to={`/membership/${id}/details`}>Membership Details</Tab>
            <Tab to={`/membership/${id}/exchange`}>Exchange</Tab>
            <Tab to={`/membership/${id}/cancellation`}>Cancellation</Tab>
            <Tab to={`/membership/${id}/rtm`}>RTM</Tab>
        </TabGroup>
    ) : detailViews.includes(pathname) ? (
        <TabGroup fullWidth>
            <Tab to={`/membership/details${search}`}>Membership Details</Tab>
            <Tab to={`/membership/exchange${search}`}>Exchange</Tab>
            <Tab to={`/membership/cancellation${search}`}>Cancellation</Tab>
        </TabGroup>
    ) : (
        <TabGroup fullWidth>
            <Tab to="/membership/list">List</Tab>
            <Tab to="/membership/monitoring">Monitoring</Tab>
            {/* <Tab to="/membership/cancellation-requests">Cancellation Requests</Tab> */}
        </TabGroup>
    );
};

export default TabBar;
