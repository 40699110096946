import React from 'react';
import Button from '@hero/ui-kit/inputs/Button';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import QtyInput from '@hero/ui-kit/inputs/QtyInput';
import { useChargeFlowContext } from './context/ChargeFlowProvider';

const CancellationChargeForm: React.FC = () => {
    const {
        formState: { isValid }
    } = useFormContext();

    const { cancellationMonths } = useChargeFlowContext();

    const regularTopMargin = useComponentMargin({ marginTop: 'regular' });
    const smallTopMargin = useComponentMargin({ marginTop: 'small' });
    return (
        <div style={regularTopMargin}>
            <QtyInput
                name="months"
                displayName="Number of months"
                minValue={cancellationMonths.min}
                maxValue={cancellationMonths.max}
                initialValue={cancellationMonths.min}
                styles={{ width: '28rem', ...smallTopMargin }}
            />
            <Button disabled={!isValid} width="large" type="submit">
                Charge
            </Button>
        </div>
    );
};

export default CancellationChargeForm;
