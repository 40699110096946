import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setDemoDevice } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';

import { DetailLabel, Detail } from '../../../components/Details';
import YesNoOptions from '../../../components/YesNoOptions';

type Props = { id: number; demo: boolean };

const DemoDetails: React.FC<Props> = ({ id, demo }) => {
    const dispatch = useDispatch();
    const handleDemo = useCallback(
        (flag: boolean) => {
            dispatch(setDemoDevice({ id, flag }));
        },
        [dispatch, id]
    );

    return (
        <>
            <DetailLabel>Demo</DetailLabel>
            <Detail>
                <YesNoOptions predicate={!!demo} handleToggle={handleDemo} />
            </Detail>
        </>
    );
};

export default DemoDetails;
