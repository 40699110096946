import React, { FC, useCallback, useMemo, useState } from 'react';
import { useExchangeContext } from '../context/ExchangeProvider';
import Form from '@hero/ui-kit/inputs/Form';
import { createExchangeFulfillmentSchema } from '@hero/validators/forms/backoffice/rmaRequestSchema';
import NewDeviceForm from '../forms/NewDeviceForm';
import useCustomerContactDetails from '../../utils/useCustomerContactDetails';
import ConfirmModal from '../../../components/ConfirmationModal';

interface NewDeviceProps {
    closeExchangeModal?: () => void;
}

export const NewDevice: FC<NewDeviceProps> = ({ closeExchangeModal }) => {
    const {
        changeExchangeStep,
        handleSetShippingAddress,
        shippingAddress,
        isOrganizationMember,
        voidRma,
        returnLocation,
        rmaId,
        membership,
        enterpriseEnrollment
    } = useExchangeContext();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleNext = useCallback(
        (formValues: any) => {
            handleSetShippingAddress(formValues);
            changeExchangeStep('NewDeviceConfirm');
        },
        [changeExchangeStep, handleSetShippingAddress]
    );

    const contactDetails = useCustomerContactDetails(
        isOrganizationMember,
        membership,
        enterpriseEnrollment
    );

    const defaultValues = useMemo(
        () => ({
            ...(typeof returnLocation === 'number' && {
                return_location: String(returnLocation)
            }),
            return_shipping_option: '1',
            ...contactDetails
        }),
        [returnLocation, contactDetails]
    );

    const handleVoid = useCallback(() => {
        if (rmaId) {
            voidRma(rmaId);
            setOpenConfirmModal(false);
            closeExchangeModal && closeExchangeModal();
        }
    }, [rmaId, voidRma, closeExchangeModal]);

    const handleOpenConfirmModal = useCallback(() => {
        setOpenConfirmModal(true);
    }, []);

    return (
        <>
            <Form
                submitFn={handleNext}
                defaultValues={
                    shippingAddress.address_line_1.length ? shippingAddress : defaultValues
                }
                validationSchema={createExchangeFulfillmentSchema}
            >
                <NewDeviceForm
                    defaultState={contactDetails?.state}
                    onVoid={handleOpenConfirmModal}
                />
            </Form>
            <ConfirmModal
                externalControls={[openConfirmModal, setOpenConfirmModal]}
                title={'Delete Draft RMA'}
                description={'Are you sure you want to delete draft RMA?'}
                onConfirm={handleVoid}
            />
        </>
    );
};
