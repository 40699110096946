import React from 'react';
import { useParams } from 'react-router-dom';

import TabGroup from '@hero/ui-kit/components/TabGroup';
import Tab from '@hero/ui-kit/components/Tab';

import { OrganizationRouteParams } from './types';

const TabBar: React.FC = () => {
    const { id, enrollment_id } = useParams<OrganizationRouteParams>();

    return id ? (
        enrollment_id ? (
            <TabGroup fullWidth>
                <Tab to={`/organizations/${id}/members/${enrollment_id}/details`}>
                    Membership Details
                </Tab>
                <Tab to={`/organizations/${id}/members/${enrollment_id}/exchange`}>Exchange</Tab>
                <Tab to={`/organizations/${id}/members/${enrollment_id}/cancellation`}>
                    Cancellation
                </Tab>
            </TabGroup>
        ) : (
            <TabGroup fullWidth>
                <Tab to={`/organizations/${id}/basic-info`}>Basic Info</Tab>
                <Tab to={`/organizations/${id}/enrollment`}>Enrollment</Tab>
                <Tab to={`/organizations/${id}/members`}>Members</Tab>
                <Tab to={`/organizations/${id}/verification`}>Verification</Tab>
            </TabGroup>
        )
    ) : null;
};

export default TabBar;
