import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRequests } from './queryKeys';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
import envVars from '../../../../constants/envVars';
const useGetCancellationRequestsReasons = () => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/cancellation-reasons/`, { ...additional }),
        queryKey: [cancellationRequests]
    });
};
export default useGetCancellationRequestsReasons;
