import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { membershipsErrorSelector } from '@hero/redux-data/backoffice/memberships/selectors';
import { useNotifications } from '../../context/notification';
const useMembershipListFailAlert = () => {
    const { error, errorMessage } = useSelector(membershipsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: errorMessage || 'Error fetching memberships.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage]);
};
export default useMembershipListFailAlert;
