import React from 'react';
import { noop } from 'ts-essentials';

import Form from '@hero/ui-kit/inputs/Form';
import Select from '@hero/ui-kit/inputs/Select';
import Button from '@hero/ui-kit/inputs/Button';
import enterpriseMemberFilterSchema from '@hero/validators/forms/backoffice/enterpriseMemberFilterSchema';

import useFilterQuery from '../../../hooks/useFilterQuery';
import useResetFiltersQuery from '../../../hooks/useResetFiltersQuery';

import useMemberFilterFormStyles from './useMemberFilterFormStyles';
import useMemberFilterFormOptions from './useMemberFilterFormOptions';

const EnterpriseMemberFilterForm: React.FC = () => {
    const [membership_status, handleMembershipStatusChange] = useFilterQuery('membership_status');
    const activeFilters = { membership_status };
    const handleReset = useResetFiltersQuery();
    const { memberStatusOptions } = useMemberFilterFormOptions();
    const { filterWrapStyles, resetButtonWrapMargin, resetButtonMargin, memberStatusFilterStyles } =
        useMemberFilterFormStyles();

    return (
        <Form
            validationSchema={enterpriseMemberFilterSchema}
            defaultValues={activeFilters}
            submitFn={noop}
        >
            <div style={filterWrapStyles}>
                <Select
                    name="membership_status"
                    displayName="Membership Status"
                    styles={memberStatusFilterStyles}
                    defaultValue={membership_status}
                    noExtraMargin
                    onChange={handleMembershipStatusChange}
                >
                    {memberStatusOptions}
                </Select>
                <div style={resetButtonWrapMargin}>
                    <Button variant="secondary" onClick={handleReset} styles={resetButtonMargin}>
                        Reset
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default EnterpriseMemberFilterForm;
