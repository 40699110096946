import { useMemo } from 'react';
const useRmaRequestErrorMessage = (errorMessage, originalMessage) => useMemo(() => {
    const recognizedErrors = [
        'previous_rma_not_completed',
        'object_not_found',
        'rma_type_does_not_allow_action',
        'rma_status_does_not_allow_action',
        'rma_shipping_does_not_allow_action',
        'shipment_status_does_not_allow_action'
    ];
    return originalMessage && recognizedErrors.includes(originalMessage)
        ? errorMessage || 'Error saving RMA.'
        : originalMessage?.replace(/[_-]/g, ' ') ||
            'Validation error. Please check input data.';
}, [errorMessage, originalMessage]);
export default useRmaRequestErrorMessage;
