import { formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import { formatDollarAmount } from '@hero/hero-utils/currency';
import getMembershipPlanLabel from '../../utils/getMembershipPlanLabel';
import getPastDueLabel from '../../utils/getPastDueLabel';
const repackTableData = (alerts) => alerts.map(({ hero_user_id, customer_id, plan_key, subscription_activated_at, invoice_amount, payment_failed_at, ...rest }) => ({
    ...rest,
    plan: plan_key ? getMembershipPlanLabel(plan_key) : null,
    subscription_activated_at: formatShortDate(normalizeDateString(subscription_activated_at)),
    invoice_amount: invoice_amount !== null ? formatDollarAmount(invoice_amount, true) : null,
    past_due: getPastDueLabel(payment_failed_at),
    payment_failed_at: formatShortDate(normalizeDateString(payment_failed_at, true))
}));
export default repackTableData;
