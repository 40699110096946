import React, { CSSProperties, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import InlineLoader from '../../../components/InlineLoader';
import TabBar from '../TabBar';
import { OrganizationRouteParams } from '../types';
import getDetailViewTitle from '../utils/getDetailViewTitle';
import ExchangeRma from '../../Membership/MembershipDeviceExchange/ExchangeRma';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetRmaExchangeRequest from '../../Rma/api/useGetRmaExchangeRequest';
import useGetEnterpriseMemberDetails from '../api/useGetEnterpriseMemberDetails';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import useGetRmaEnums from '../../Rma/api/useGetRmaEnums';
import { RmaRequest } from '@hero/hero-types';

const EnterpriseMemberDeviceExchange: React.FC = () => {
    const { id: organization_id, enrollment_id: id } =
        useParams<Required<OrganizationRouteParams>>();
    const navigate = useNavigate();

    const [rma, setRma] = React.useState<RmaRequest | null>(null);

    const { data: enrollmentResponse, isPending: isFetchingEnrollment } =
        useGetEnterpriseMemberDetails({
            id: id ? +id : 0
        });

    const enrollment = enrollmentResponse?.data;
    const heroUserId = enrollment?.member?.hero_user_id;
    const serial = enrollment?.member?.devices?.[0]?.device_serial;

    const { data: rmaEnumsResponse, isLoading: isFetchingRmaEnums } = useGetRmaEnums(true);

    const {
        mutate,
        data: userDeviceResponse,
        isPending: isFetchingUserDevice
    } = useGetUserDeviceDetails();

    useEffect(() => {
        if (heroUserId) {
            mutate({ user_id: heroUserId });
        }
    }, [heroUserId, mutate]);

    const userDetails = userDeviceResponse?.data;
    const rmaEnums = rmaEnumsResponse?.data;

    const isFetching = isFetchingEnrollment || isFetchingUserDevice || isFetchingRmaEnums;

    const {
        data: exchangeRmaResponse,
        error: exchangeRmaErrorResponse,
        refetch: refetchRmaExchange
    } = useGetRmaExchangeRequest({ device_serial: serial ? serial : '' }, !!serial);

    React.useEffect(() => {
        if (exchangeRmaErrorResponse?.response?.status === 404) {
            setRma(null);
        }

        if (exchangeRmaResponse?.data && exchangeRmaErrorResponse?.response?.status !== 404) {
            setRma(exchangeRmaResponse.data);
        }
    }, [exchangeRmaResponse, exchangeRmaErrorResponse]);

    const handleRefetchRmaExchange = () => {
        refetchRmaExchange();
    };

    const handleBackToList = useCallback(() => {
        navigate(`/organizations/${organization_id}/members`, { replace: true });
    }, [navigate, organization_id]);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const enterpriseDetailsPage = useMemo(() => {
        if (isFetching) {
            return <InlineLoader />;
        }
        if (!enrollment) {
            return <P>No membership data available.</P>;
        }

        if (serial && enrollment.member?.status !== 'CANCELLED') {
            return (
                <ExchangeRma
                    deviceSerial={serial}
                    isOrganizationMember
                    exchangeRmaError={exchangeRmaErrorResponse}
                    rma={rma}
                    enrollment={enrollment}
                    rmaEnums={rmaEnums}
                    userDetails={userDetails}
                    refetchRmaExchange={handleRefetchRmaExchange}
                />
            );
        }
        if (enrollment?.member?.status === 'CANCELLED') {
            return <P>Enrollment member canceled, exchange disabled.</P>;
        }
        return null;
    }, [isFetching, enrollment, serial, id, rma, rmaEnums, userDetails, exchangeRmaErrorResponse]);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{getDetailViewTitle(enrollment)}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>{enterpriseDetailsPage}</div>
            </Section>
        </DefaultLayout>
    );
};

export default EnterpriseMemberDeviceExchange;
