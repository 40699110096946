import React from 'react';
import TabGroup from '@hero/ui-kit/components/TabGroup';
import Tab from '@hero/ui-kit/components/Tab';

const TabBar: React.FC = () => {
    return (
        <TabGroup fullWidth styles={{ height: '7.5rem' }}>
            <Tab to="/monitoring/adherence">Adherence</Tab>
            <Tab to="/monitoring/running-low">Running Low</Tab>
            <Tab to="/monitoring/device">Device</Tab>
        </TabGroup>
    );
};

export default TabBar;
