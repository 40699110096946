import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { orgMember } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetEnterpriseMemberDetails = (props, enabled) => {
    const additional = useQueryRequestParams();
    const id = props.id;
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/enrollment/details/`, { ...additional, params: { ...props } }),
        queryKey: [orgMember, id],
        enabled
    });
};
export default useGetEnterpriseMemberDetails;
