import { combineReducers } from 'redux';
import userDeviceDetails from '@hero/redux-data/backoffice/userDeviceDetails/reducer';
import memberships from '@hero/redux-data/backoffice/memberships/reducer';
import membershipDetails from '@hero/redux-data/backoffice/membershipDetails/reducer';
import membershipPlans from '@hero/redux-data/backoffice/membershipPlans/reducer';
import membershipPlan from '@hero/redux-data/backoffice/membershipPlan/reducer';
import userCredit from '@hero/redux-data/backoffice/userCredit/reducer';
import userPaymentMethod from '@hero/redux-data/backoffice/userPaymentMethod/reducer';
import cancellationOptions from '@hero/redux-data/backoffice/cancellationOptions/reducer';
import refundPreview from '@hero/redux-data/backoffice/refundPreview/reducer';
import cancellationFeePreview from '@hero/redux-data/backoffice/cancellationFeePreview/reducer';
import membershipCancellation from '@hero/redux-data/backoffice/membershipCancellation/reducer';
import cancellationDetails from '@hero/redux-data/backoffice/cancellationDetails/reducer';
import membershipAlerts from '@hero/redux-data/backoffice/membershipAlerts/reducer';
import membershipAlert from '@hero/redux-data/backoffice/membershipAlert/reducer';
import organization from '@hero/redux-data/backoffice/organization/reducer';
import enterpriseEnrollmentSubmission from '@hero/redux-data/backoffice/enterpriseEnrollmentSubmission/reducer';
import enterpriseEnrollmentsForVerification from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/reducer';
import enterpriseEnrollmentVerification from '@hero/redux-data/backoffice/enterpriseEnrollmentVerification/reducer';
import enterpriseMembers from '@hero/redux-data/backoffice/enterpriseMembers/reducer';
import enterpriseMemberDetails from '@hero/redux-data/backoffice/enterpriseMemberDetails/reducer';
import enterpriseCancellationOptions from '@hero/redux-data/backoffice/enterpriseCancellationOptions/reducer';
import enterpriseMembershipCancellation from '@hero/redux-data/backoffice/enterpriseMembershipCancellation/reducer';
import enterpriseCancellationDetails from '@hero/redux-data/backoffice/enterpriseCancellationDetails/reducer';
import membershipReactivation from '@hero/redux-data/backoffice/membershipReactivation/reducer';
import membershipReactivationDetails from '@hero/redux-data/backoffice/membershipReactivationDetails/reducer';
import membershipReactivationRefundCharge from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/reducer';
import prospectSignup from '@hero/redux-data/backoffice/prospectSignup/reducer';
import prospectSignupSurveyOptions from '@hero/redux-data/backoffice/prospectSignupSurveyOptions/reducer';
import rmaEnums from '@hero/redux-data/backoffice/rmaEnums/reducer';
import rmaRequests from '@hero/redux-data/backoffice/rmaRequests/reducer';
import rmaRequest from '@hero/redux-data/backoffice/rmaRequest/reducer';
import googlePlaceSuggestions from '@hero/redux-data/googlePlaceSuggestions/reducer';
import googlePlaceDetails from '@hero/redux-data/googlePlaceDetails/reducer';
import deviceRestockingCheck from '@hero/redux-data/backoffice/deviceRestockingCheck/reducer';
import rmaRequestSave from '@hero/redux-data/backoffice/rmaRequestSave/reducer';
import cancellationFeeChargeAttemptPreview from '@hero/redux-data/backoffice/cancellationFeeChargeAttemptPreview/reducer';
import cancellationAttemptFeeCharge from '@hero/redux-data/backoffice/cancellationAttemptFeeCharge/reducer';
import usersReducer from '@hero/redux-data/backoffice/users/reducer';
// import patientsReducer from '@hero/redux-data/backoffice/patients/reducer';
import devices from '@hero/redux-data/backoffice/devices/reducer';
import deviceDetails from '@hero/redux-data/backoffice/deviceDetails/reducer';
import unresolvedOtcPrices from '@hero/redux-data/backoffice/unresolvedOtcPrices/reducer';
import auth from '@hero/redux-data/auth/reducer';
import user from '@hero/redux-data/userLogin/reducer';
import activateMembership from '@hero/redux-data/backoffice/activateMembership/reducer';
import mitigationList from '@hero/redux-data/backoffice/mitigationList/reducer';
import mitigationSave from '@hero/redux-data/backoffice/mitigationSave/reducer';
// import patientDetails from '@hero/redux-data/backoffice/patientDetails/reducer';
import userAdherence from '@hero/redux-data/backoffice/userAdherence/reducer';
import userResendActivationCode from '@hero/redux-data/backoffice/userResendActivationCode/reducer';
import userSetEnabledFeatures from '@hero/redux-data/backoffice/userSetEnabledFeatures/reducer';
import userSetPhone from '@hero/redux-data/backoffice/userSetPhone/reducer';
import userSetBlockedStatus from '@hero/redux-data/backoffice/userSetBlockedStatus/reducer';
import userConfirmPhone from '@hero/redux-data/backoffice/userConfirmPhone/reducer';
import userDeletePhone from '@hero/redux-data/backoffice/userDeletePhone/reducer';
import userSendTestNotification from '@hero/redux-data/backoffice/userSendTestNotification/reducer';
import hazardousDrugs from '@hero/redux-data/backoffice/hazardousDrugsCheck/reducer';
import oldOrganizationList from '@hero/redux-data/backoffice/oldOrganizationList/reducer';
import organizationDetails from '@hero/redux-data/backoffice/organizationDetails/reducer';
import organizationUpdate from '@hero/redux-data/backoffice/organizationUpdate/reducer';
import createOrganization from '@hero/redux-data/backoffice/createOrganization/reducer';
import enrollmentCreate from '@hero/redux-data/backoffice/enrollmentCreate/reducer';
import rtmSave from '@hero/redux-data/backoffice/rtmSave/reducer';
import rtmStatus from '@hero/redux-data/backoffice/rtmStatus/reducer';
// import patientInsuranceCard from '@hero/redux-data/backoffice/patientInsuranceCard/reducer';
import transferRequestList from '@hero/redux-data/backoffice/transferRequestList/reducer';
import updateMembershipFulfillment from '@hero/redux-data/backoffice/membershipUpdateFulfillment/reducer';
import fillOrders from '@hero/redux-data/backoffice/fillOrders/reducer';
// import transferRequestDetails from '@hero/redux-data/backoffice/transferRequestDetails/reducer';
// import transferRequestCancel from '@hero/redux-data/backoffice/transferRequestCancel/reducer';
// import transferRequestSetRxTransferred from '@hero/redux-data/backoffice/transferRequestSetRxTransferred/reducer';
import delayMembershipPlanChange from '@hero/redux-data/backoffice/membershipDelayPlanChange/reducer';
import userDetails from '@hero/redux-data/backoffice/userDetails/reducer';
import organizationAdherenceAlerts from '@hero/redux-data/backoffice/organizationAdherenceAlerts/reducer';
import organizationNoRefillAlerts from '@hero/redux-data/backoffice/organizationNoRefillAlerts/reducer';
import organizationDeviceMonitoringList from '@hero/redux-data/backoffice/organizationDeviceMonitoringList/reducer';
const rootReducer = combineReducers({
    users: usersReducer,
    userDetails,
    userDeviceDetails,
    userAdherence,
    userResendActivationCode,
    userSetPhone,
    userSetEnabledFeatures,
    userSetBlockedStatus,
    userSendTestNotification,
    userConfirmPhone,
    userDeletePhone,
    memberships,
    membershipDetails,
    membershipPlans,
    membershipPlan,
    userCredit,
    userPaymentMethod,
    cancellationOptions,
    refundPreview,
    cancellationFeePreview,
    cancellationFeeChargeAttemptPreview,
    cancellationAttemptFeeCharge,
    membershipCancellation,
    cancellationDetails,
    membershipAlerts,
    membershipAlert,
    organization,
    enterpriseEnrollmentSubmission,
    enterpriseEnrollmentsForVerification,
    enterpriseEnrollmentVerification,
    enterpriseMembers,
    enterpriseMemberDetails,
    enterpriseCancellationOptions,
    enterpriseMembershipCancellation,
    enterpriseCancellationDetails,
    membershipReactivation,
    membershipReactivationDetails,
    membershipReactivationRefundCharge,
    prospectSignup,
    prospectSignupSurveyOptions,
    rmaEnums,
    rmaRequests,
    rmaRequest,
    rmaRequestSave,
    rtmSave,
    rtmStatus,
    googlePlaceSuggestions,
    googlePlaceDetails,
    deviceRestockingCheck,
    devices,
    auth,
    user,
    deviceDetails,
    activateMembership,
    mitigationList,
    mitigationSave,
    unresolvedOtcPrices,
    hazardousDrugs,
    oldOrganizationList,
    organizationDetails,
    organizationUpdate,
    createOrganization,
    enrollmentCreate,
    transferRequestList,
    fillOrders,
    updateMembershipFulfillment,
    delayMembershipPlanChange,
    organizationAdherenceAlerts,
    organizationNoRefillAlerts,
    organizationDeviceMonitoringList
});
export default rootReducer;
