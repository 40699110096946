import React from 'react';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useChargeFlowContext } from '../context/ChargeFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

export const Confirm: React.FC = () => {
    const { amountToBeChargedLabel, makeCharge } = useChargeFlowContext();
    const margin = useComponentMargin({ marginTop: 'regular' });

    return (
        <>
            <P size="large" strong noDefaultMargin>
                {amountToBeChargedLabel}
            </P>
            <Button width="large" styles={margin} onClick={makeCharge}>
                Confirm Charge
            </Button>
        </>
    );
};
