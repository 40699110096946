import React from 'react';
import * as yup from 'yup';

import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

import { Response as SubmitLeadResponse } from '../api/useElectionSubmitLead';
import { useNewProviderElection } from '../context/NewProviderElectionProvider';

type Props = {
    closeModal?: () => void;
};

type FormFieldsProps = {
    providers: SubmitLeadResponse['data']['providers'];
};

const formSchema = yup
    .object()
    .shape({
        provider: yup.string().trim()
    })
    .defined();

export type FormParams = yup.InferType<typeof formSchema>;

const FormFields: React.FC<FormFieldsProps> = ({ providers }) => {
    const { handleResetForm } = useNewProviderElection();

    const {
        formState: { isValid }
    } = useFormContext<FormParams>();

    return (
        <>
            <H role="h6">Select available provider</H>

            <RadioGroup name="provider" displayName="" noBorders>
                {providers.map((provider) => (
                    <Radio
                        key={provider.provider_id}
                        id={provider.provider_id}
                        value={provider.provider_id}
                        labelText={provider.provider_name}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '2.4rem'
                            }}
                        >
                            <img
                                width={120}
                                height={120}
                                src={provider.provider_logo}
                                alt={provider.provider_name}
                            />

                            <div
                                style={{
                                    paddingTop: 'calc(var(--gutter)/2)'
                                }}
                            >
                                <P
                                    size="large"
                                    strong
                                    noDefaultMargin
                                    styles={{
                                        marginBottom: 'calc(var(--gutter)/3)'
                                    }}
                                >
                                    {provider.provider_name}
                                </P>
                                <P size="small" noDefaultMargin>
                                    {provider.provider_description}
                                </P>
                            </div>
                        </div>
                    </Radio>
                ))}
            </RadioGroup>

            <Section centered>
                <Button width="large" type="submit" disabled={!isValid}>
                    Next
                </Button>

                <Button width="large" variant="secondary" onClick={handleResetForm}>
                    Reset
                </Button>
            </Section>
        </>
    );
};

const Election: React.FC<Props> = ({ closeModal }) => {
    const { changeStep, handleSetFormValues, providers, enrollment } = useNewProviderElection();

    const handleNext = React.useCallback(
        (formValues: FormParams) => {
            handleSetFormValues(formValues);
            changeStep('Submit');
        },
        [changeStep]
    );

    const noProvidersText = `No providers available in ZIP ${enrollment?.member?.contact?.address?.zip || ''}`;

    return (
        <Section noDefaultPadding>
            <Section noDefaultPadding marginTop="regular">
                {providers.length > 0 ? (
                    <Form submitFn={handleNext} validationSchema={formSchema}>
                        <FormFields providers={providers} />
                    </Form>
                ) : (
                    <>
                        <H role="h5">{noProvidersText}</H>

                        <Section centered>
                            <Button width="large" variant="secondary" onClick={closeModal}>
                                Close
                            </Button>
                        </Section>
                    </>
                )}
            </Section>
        </Section>
    );
};

export default Election;
