import Table from '@hero/ui-kit/tables/Table';
import { BackofficeOrganizationDeviceMonitoring } from '@hero/hero-types';
import React from 'react';
import Header from '../Header';
import useDeviceMonitoringData from './useDeviceMonitoringData';
import Section from '@hero/ui-kit/layout/Section';
import Filter from '../Filter';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import useFilterQuery from '../../../hooks/useFilterQuery';
import DefaultLayout from '../../../components/DefaultLayout';

interface DeviceMonitoringPageProps {}

const MonitoringDeviceFilter: React.FC<{
    value: string;
    label: string;
    onClick: (value: string) => void;
    isActive?: boolean;
}> = ({ value, label, onClick, isActive = false }) => {
    const handleClick = () => {
        onClick(value);
    };
    return (
        <Button onClick={handleClick} variant={isActive ? 'primary' : 'outline'}>
            {label}
        </Button>
    );
};

const MonitoringDeviceFilterValues = [
    {
        id: 1,
        value: 'all',
        label: 'All'
    },
    {
        id: 2,
        value: 'delivered_not_activated',
        label: 'Delivered not activated'
    },
    {
        id: 3,
        value: 'activated_not_configured',
        label: 'Activated not configured'
    },
    {
        id: 4,
        value: 'offline',
        label: 'Offline'
    }
];

const DeviceMonitoringPage: React.FC<DeviceMonitoringPageProps> = () => {
    const { isLoading, deviceMonitoringList, pagerProps, tableColumns, sort, handleSort } =
        useDeviceMonitoringData();

    const [filterValue, handleMembershipStatusChange] = useFilterQuery('filter');

    return (
        <DefaultLayout>
            <Section>
                <Header title="Device Monitoring" />
                <Container gridTemplateColumns={'1fr 1fr'}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '1rem'
                        }}
                    >
                        {MonitoringDeviceFilterValues.map((filter) => (
                            <MonitoringDeviceFilter
                                onClick={handleMembershipStatusChange}
                                key={filter.id}
                                value={filter.value}
                                label={filter.label}
                                isActive={filter.value === filterValue}
                            />
                        ))}
                    </div>
                    <Filter />
                </Container>

                <Table<BackofficeOrganizationDeviceMonitoring>
                    columns={tableColumns}
                    data={deviceMonitoringList}
                    rowKey="id"
                    emptyValues="-"
                    isLoading={isLoading}
                    pagerProps={pagerProps}
                    sortColumn={sort.sort_by}
                    sortDirection={sort.sort_dir}
                    onColumnSort={handleSort}
                />
            </Section>
        </DefaultLayout>
    );
};

export default DeviceMonitoringPage;
