import { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { userDeviceDetailsErrorSelector } from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import { useNotifications } from '../../context/notification';
const useUserDeviceDetailsFailAlert = () => {
    const { error, errorMessage, originalMessage } = useSelector(userDeviceDetailsErrorSelector, shallowEqual);
    const { addNotification, removeNotification } = useNotifications();
    const id = useRef();
    useEffect(() => {
        id.current && removeNotification(id.current);
        if (error) {
            id.current = addNotification({
                type: 'fail',
                message: originalMessage === 'object_not_found'
                    ? 'Could not find user/device.'
                    : errorMessage || 'Error fetching user/device.'
            });
        }
        else {
            id.current = undefined;
        }
    }, [addNotification, removeNotification, error, errorMessage, originalMessage]);
};
export default useUserDeviceDetailsFailAlert;
