import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    EnterpriseEnrollment,
    ExchangeFulfillmentParams,
    RmaEnums,
    RmaRequest,
    RmaReturnLocation,
    RmaShippingOption
} from '@hero/hero-types';
import React, {
    ReactNode,
    useCallback,
    useState,
    createContext,
    useContext,
    useEffect,
    useMemo
} from 'react';
import { ExchangeMembershipParams } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import {
    createExchangeFulfillmentParams,
    SetRmaRequestPickUpParams
} from '@hero/validators/forms/backoffice/rmaRequestSchema';
import { AxiosErrorResponse } from '../../../types';
import useCreateRmaDraft from '../../../pages/Rma/api/useCreateRmaDraft';
import useVoidRmaRequest from '../../../pages/Rma/api/useVoidRmaRequest';
import useCreateExchangeFulfillment from '../../../pages/Rma/api/useCreateExchangeFulfillment';
import trimDeep from '@hero/hero-utils/trimDeep';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import useSetRmaPickup from '../../../pages/Rma/api/useSetRmaPickup';

export type ExchangeSteps = 'Details' | 'NewDevice' | 'NewDeviceConfirm' | 'OldDevice' | 'Success';

type ContextType = {
    exchangeStep: ExchangeSteps;
    changeExchangeStep: (exchangeStep: ExchangeSteps) => void;
    isOrganizationMember: boolean;
    handleSetShippingAddress: (attributes: createExchangeFulfillmentParams) => void;
    shippingAddress: createExchangeFulfillmentParams;
    createDraftRma: (attributes: ExchangeMembershipParams) => void;
    onCompleteRma: (attributes: SetRmaRequestPickUpParams) => void;
    onNewAddressConfirm: () => void;
    onCompleteCallback?: () => void;
    voidRma: (id: number) => void;
    onCloseModal?: () => void;
    membership?: BackofficeMembershipDetails;
    rma?: RmaRequest;
    userDetails?: BackofficeUserDeviceDetails;
    exchangeRmaError?: AxiosErrorResponse;
    createRmaDraftError?: AxiosErrorResponse;
    exchangeFulfillmentError?: AxiosErrorResponse;
    setRmaPickupError?: AxiosErrorResponse;
    enterpriseEnrollment?: EnterpriseEnrollment;
    rmaEnums?: RmaEnums;
    isCreateRmaDraftLoading: boolean;
    isExchangeFulfillmentLoading: boolean;
    isSetRmaPickupLoading: boolean;
    rmaId?: number;
    returnLocation?: RmaReturnLocation | null;
    eyebrow: string;
};

const ExchangeContext = createContext<ContextType | undefined>(undefined);

export const useExchangeContext = () => {
    const ctx = useContext(ExchangeContext);

    if (ctx === undefined) {
        throw new Error(`'useExchangeContext' must be used within a 'ExchangeContextProvider'`);
    }

    return ctx;
};

type IExchangeProvider = {
    children: ReactNode;
    isOrganizationMember: boolean;
    deviceSerial?: string;
    onCompleteCallback?: () => void;
    onCloseModal?: () => void;
    membership?: BackofficeMembershipDetails;
    rma?: RmaRequest;
    userDetails?: BackofficeUserDeviceDetails;
    exchangeRmaError?: AxiosErrorResponse;
    enterpriseEnrollment?: EnterpriseEnrollment;
    rmaEnums?: RmaEnums;
};

export const ExchangeProvider = ({
    children,
    isOrganizationMember = false,
    deviceSerial,
    onCompleteCallback,
    onCloseModal,
    membership,
    rma,
    userDetails,
    exchangeRmaError,
    enterpriseEnrollment,
    rmaEnums
}: IExchangeProvider) => {
    const [exchangeStep, setExchangeStep] = useState<ExchangeSteps>('Details');
    const [shippingAddress, setShippingAddress] = useState<createExchangeFulfillmentParams>({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        home_phone: ''
    });

    const {
        mutateAsync: setRmaRequestPickUp,
        error: setRmaPickupError,
        isPending: isSetRmaPickupLoading
    } = useSetRmaPickup();

    const setRmaPickUp = useCallback(
        (rmaId: number, attributes: SetRmaRequestPickUpParams) => {
            const {
                return_location,
                return_shipping_option,
                is_manual_return_shipping: is_manual,
                address_line_1,
                address_line_2,
                city,
                state,
                zip,
                home_phone,
                return_shipping_label_url,
                return_tracking_number,
                send_box
            } = trimDeep(attributes);

            const phone = home_phone && formatPhoneNumber(home_phone);

            const requestParams = {
                id: rmaId,
                return_location: +return_location as RmaReturnLocation,
                return_shipping_option: +return_shipping_option as RmaShippingOption,
                is_manual,
                send_box,
                ...(is_manual
                    ? {
                          return_shipping_label_url,
                          return_tracking_number
                      }
                    : {
                          contact: {
                              cell_phone: phone,
                              home_phone: phone,
                              address: {
                                  address_line_1: address_line_1,
                                  ...(address_line_2 && { address_line_2 }),
                                  city: city,
                                  zip: zip,
                                  state: state,
                                  country: 'US'
                              }
                          }
                      })
            };

            setRmaRequestPickUp(requestParams)
                .then(() => {
                    changeExchangeStep('Success');
                })
                .catch(() => null);
        },
        [setRmaRequestPickUp]
    );

    const changeExchangeStep = useCallback((step: ExchangeSteps) => {
        setExchangeStep(step);
    }, []);

    const handleSetShippingAddress = useCallback((attributes: createExchangeFulfillmentParams) => {
        setShippingAddress((prevState) => {
            return { ...prevState, ...attributes };
        });
    }, []);

    const eyebrow: string = useMemo(() => {
        if (isOrganizationMember) {
            const organizationName = enterpriseEnrollment?.organization.name || '-';
            const userFullName =
                `${enterpriseEnrollment?.member?.contact?.first_name || ''} ${
                    enterpriseEnrollment?.member?.contact?.last_name || ''
                }`.trim() ||
                `${enterpriseEnrollment?.contact?.first_name || ''} ${
                    enterpriseEnrollment?.contact?.last_name || ''
                }`.trim() ||
                '-';
            const userEmail =
                enterpriseEnrollment?.member?.contact?.email ||
                enterpriseEnrollment?.contact?.email ||
                '-';
            const deviceSerial = enterpriseEnrollment?.member?.devices[0]?.device_serial || '-';
            return `${organizationName} / ${userFullName} / ${userEmail} / ${deviceSerial}`;
        } else {
            const userFullName =
                `${userDetails?.user?.first_name || ''} ${userDetails?.user?.last_name || ''}`.trim() ||
                '-';
            const userEmail = membership?.order_email || '-';
            const deviceSerial = userDetails?.device?.external_serial || membership?.serial || '-';
            return `${userFullName} / ${userEmail} / ${deviceSerial}`;
        }
    }, [isOrganizationMember, enterpriseEnrollment, membership, userDetails]);

    const {
        mutateAsync: createDraftRmaRequest,
        error: createRmaDraftError,
        isPending: isCreateRmaDraftLoading
    } = useCreateRmaDraft();
    const { mutate: voidRmaRequest } = useVoidRmaRequest();
    const {
        mutateAsync: createExchangeFulfillment,
        isPending: isExchangeFulfillmentLoading,
        error: exchangeFulfillmentError
    } = useCreateExchangeFulfillment();

    const createDraftRma = useCallback(
        (attributes: ExchangeMembershipParams) => {
            if (deviceSerial) {
                createDraftRmaRequest({
                    rma_type: 2, // Exchange
                    device_serial: deviceSerial,
                    reason_code: +attributes.reason_code,
                    reason_sub_code: +attributes.reason_sub_code,
                    zendesk_ticket_number: attributes.zendesk_ticket_number,
                    ...(attributes.notes && { comments: attributes.notes })
                })
                    .then(() => {
                        changeExchangeStep('NewDevice');
                    })
                    .catch(() => null);
            }
        },
        [deviceSerial]
    );

    const setExchangeFulfillment = useCallback(
        (attributes: ExchangeFulfillmentParams) => {
            createExchangeFulfillment(attributes).then(() => {
                changeExchangeStep('OldDevice');
            });
        },
        [changeExchangeStep, createExchangeFulfillment]
    );

    const voidRma = useCallback((id: number) => {
        voidRmaRequest({ id });
    }, []);

    const isExchangeRmaDraft = rma?.rma_status === 0 && rma.rma_type === 2;
    const isFulfillmentProvided = !!rma?.fulfillment_request?.id;
    // const isExchangeFlowCompleted = rma?.rma_status === 5 && rma.rma_type === 2;
    const rmaId = rma?.id;

    const onCompleteRma = useCallback(
        (formValues: SetRmaRequestPickUpParams) => {
            if (rmaId && isExchangeRmaDraft && isFulfillmentProvided) {
                setRmaPickUp(rmaId, formValues);
            }
        },
        [rmaId, setRmaPickUp, isExchangeRmaDraft, isFulfillmentProvided]
    );

    const onNewAddressConfirm = useCallback(() => {
        if (isExchangeRmaDraft && !isFulfillmentProvided && rmaId) {
            const { home_phone, ...rest } = shippingAddress;
            setExchangeFulfillment({
                id: rmaId,
                contact: {
                    cell_phone: home_phone,
                    home_phone,
                    address: rest
                }
            });
        }
    }, [setExchangeFulfillment, isExchangeRmaDraft, rmaId, shippingAddress, isFulfillmentProvided]);

    useEffect(() => {
        if (!isCreateRmaDraftLoading && isExchangeRmaDraft) {
            changeExchangeStep(isFulfillmentProvided ? 'OldDevice' : 'NewDevice');
        }
    }, [isFulfillmentProvided, changeExchangeStep, isCreateRmaDraftLoading, isExchangeRmaDraft]);

    return (
        <ExchangeContext.Provider
            value={{
                exchangeStep,
                changeExchangeStep,
                isOrganizationMember,
                handleSetShippingAddress,
                shippingAddress,
                createDraftRma,
                onCompleteRma,
                onNewAddressConfirm,
                onCompleteCallback,
                voidRma,
                onCloseModal,
                membership,
                rma,
                userDetails,
                exchangeRmaError,
                enterpriseEnrollment,
                rmaEnums,
                createRmaDraftError,
                isCreateRmaDraftLoading,
                rmaId,
                returnLocation: rma?.return_location,
                isExchangeFulfillmentLoading,
                exchangeFulfillmentError,
                setRmaPickupError,
                isSetRmaPickupLoading,
                eyebrow
            }}
        >
            {children}
        </ExchangeContext.Provider>
    );
};

export default ExchangeProvider;
