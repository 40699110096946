import React, { useMemo } from 'react';
import P from '@hero/ui-kit/typography/P';
import Table from '@hero/ui-kit/tables/Table';
import Form from '@hero/ui-kit/inputs/Form';
import chargeFeeSchema from '@hero/validators/forms/backoffice/chargeFeeSchema';
import cancellationFeeSchema from '@hero/validators/forms/backoffice/cancellationFeeSchema';
import DetailsChargeForm from '../DetailsChargeForm';
import CancellationChargeForm from '../CancellationChargeForm';
import { useChargeFlowContext, BillingAttempts } from '../context/ChargeFlowProvider';

export const Details: React.FC = () => {
    const {
        note,
        previousAttemptsLabel,
        confirmCharge,
        confirmCancellationCharge,
        amountOutstandingLabel,
        billingAttemptHeaders,
        billingAttempts,
        outstandingAmount,
        chargeContext
    } = useChargeFlowContext();

    const handleCharge = React.useCallback(
        (attributes: any) => {
            chargeContext === 'cancellation_fee'
                ? confirmCancellationCharge(attributes)
                : confirmCharge(attributes);
        },
        [confirmCharge, confirmCancellationCharge, chargeContext]
    );

    const defaultValues = useMemo(() => {
        return {
            outstandingAmount,
            amount: outstandingAmount
        };
    }, [outstandingAmount]);

    return (
        <>
            <P strong size="large">
                {amountOutstandingLabel}
            </P>
            <P strong>{note}</P>
            <P noDefaultMargin>{previousAttemptsLabel}</P>
            <Table<BillingAttempts>
                columns={billingAttemptHeaders}
                data={billingAttempts}
                rowKey="id"
                noOuterBorder
                noDataMessage="No previous billing attempts"
                noDataStyles={{ padding: '2rem' }}
            />
            {chargeContext === 'cancellation_fee' ? (
                <Form
                    defaultValues={defaultValues}
                    validationMode="onChange"
                    validationSchema={cancellationFeeSchema}
                    submitFn={handleCharge}
                >
                    <CancellationChargeForm />
                </Form>
            ) : (
                <Form
                    defaultValues={defaultValues}
                    validationMode="onChange"
                    validationSchema={chargeFeeSchema}
                    submitFn={handleCharge}
                >
                    <DetailsChargeForm />
                </Form>
            )}
        </>
    );
};
