import React, { useMemo } from 'react';

import { Pill } from '@hero/hero-types';
import H from '@hero/ui-kit/typography/H';

import PillDetails from './PillDetails';

type Props = { pills?: Pill[]; user_id?: number };

const PillsData: React.FC<Props> = ({ pills = [], user_id }) => {
    const storedInHeroPills = useMemo(() => pills.filter((pill) => pill.stored_in_hero), [pills]);
    const restPills = useMemo(() => pills.filter((pill) => !pill.stored_in_hero), [pills]);

    return (
        <div>
            <H role="h6">Stored in Hero</H>
            {storedInHeroPills.map((pill) => (
                <PillDetails key={pill.slot} pill={pill} user_id={user_id} marginBottom="large" />
            ))}
            <H role="h6">Not Stored in Hero</H>
            {restPills.map((pill) => (
                <PillDetails key={pill.slot} pill={pill} user_id={user_id} />
            ))}
        </div>
    );
};

export default PillsData;
