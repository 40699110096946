import React from 'react';

import { UiComponentProps } from '@hero/ui-kit/types';
import TextArea, { Props as TextAreaProps } from '@hero/ui-kit/inputs/TextArea';

import StaticFormElement from '../StaticFormElement';

type Props = {
    staticValue?: string | null;
    isEditable?: boolean;
} & UiComponentProps &
    Pick<TextAreaProps, 'name' | 'displayName' | 'placeholder' | 'rows' | 'disabled'>;

const StaticOrInput: React.FC<Props> = ({
    staticValue,
    isEditable = false,
    displayName,
    rows = 4,
    ...rest
}) =>
    isEditable ? (
        <TextArea displayName={displayName} rows={rows} resize="none" {...rest} />
    ) : (
        <StaticFormElement label={displayName}>{staticValue || '-'}</StaticFormElement>
    );

export default StaticOrInput;
