import React from 'react';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';

type Props = { children: string; isCancelable?: boolean; onClose?: () => void };

const DefaultSuccessModal: React.FC<Props> = ({ children, isCancelable = true, onClose }) => (
    <Modal type="fail" isCancelable={isCancelable} onClose={onClose}>
        <H role="h4" inverted>
            Success
        </H>
        <P inverted>{children}</P>
    </Modal>
);

export default DefaultSuccessModal;
