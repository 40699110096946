import React, { CSSProperties, useState, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
    enterpriseEnrollmentSelector,
    organizationSubmittedDetailsSelector,
    contactSubmittedDetailsSelector
} from '@hero/redux-data/backoffice/enterpriseEnrollmentsForVerification/selectors';
import Takeover from '@hero/ui-kit/components/Takeover';
import Button from '@hero/ui-kit/inputs/Button';
import Span from '@hero/ui-kit/typography/Span';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

import SideBySideComparison from './SideBySideComparison';

type Props = {
    enrollmentId: number;
    onApprove: (id: number) => void;
    onReject: (id: number) => void;
    onBack: () => void;
};

const EnrollmentDetails: React.FC<Props> = ({ enrollmentId, onApprove, onReject, onBack }) => {
    const enrollment = useSelector(enterpriseEnrollmentSelector(enrollmentId), shallowEqual);
    const organizationSubmitted = useSelector(
        organizationSubmittedDetailsSelector(enrollmentId),
        shallowEqual
    );
    const contactSubmitted = useSelector(
        contactSubmittedDetailsSelector(enrollmentId),
        shallowEqual
    );

    const fullName = useMemo(() => {
        if (!organizationSubmitted || !contactSubmitted) return '';
        const fullName = `${organizationSubmitted.first_name} ${organizationSubmitted.last_name}`;
        const contactFullName = `${contactSubmitted.first_name} ${contactSubmitted.last_name}`;
        return contactFullName !== fullName ? `${fullName} (${contactFullName})` : fullName;
    }, [organizationSubmitted, contactSubmitted]);

    const [approvalModalOpen, setApprovalModalOpen] = useState(false);
    const [rejectionModalOpen, setRejectionModalOpen] = useState(false);

    const handleApprove = useCallback(() => {
        setApprovalModalOpen(true);
    }, []);

    const handleReject = useCallback(() => {
        setRejectionModalOpen(true);
    }, []);

    const handleApproveConfirm = useCallback(() => {
        onApprove(enrollmentId);
        setApprovalModalOpen(false);
    }, [enrollmentId, onApprove]);

    const handleRejectConfirm = useCallback(() => {
        onReject(enrollmentId);
        setRejectionModalOpen(false);
    }, [enrollmentId, onReject]);

    const controlWrapMargin = useComponentMargin({ marginTop: 'large' });
    const buttonMargin = useComponentMargin({ marginRight: 'regular' });
    const previewWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const previewWrapStyles: CSSProperties = {
        width: '92.4rem',
        ...previewWrapPadding
    };
    const controlWrapStyles: CSSProperties = {
        display: 'flex',
        ...controlWrapMargin
    };

    return (
        <div style={previewWrapStyles}>
            {approvalModalOpen && (
                <Takeover
                    type="warn"
                    title="Approve Enrollment"
                    actions={[
                        { label: 'Approve', action: handleApproveConfirm },
                        { label: 'Cancel' }
                    ]}
                    externalControls={[approvalModalOpen, setApprovalModalOpen]}
                    isCancelable
                >
                    <P size="large" centered>
                        Are you sure you want to approve <Span strong>{fullName}</Span>’s
                        enrollment?
                    </P>
                </Takeover>
            )}
            {rejectionModalOpen && (
                <Takeover
                    type="warn"
                    title="Reject Enrollment"
                    actions={[
                        { label: 'Reject', action: handleRejectConfirm },
                        { label: 'Cancel' }
                    ]}
                    externalControls={[rejectionModalOpen, setRejectionModalOpen]}
                    isCancelable
                >
                    <P size="large" centered>
                        Are you sure you want to reject <Span strong>{fullName}</Span>’s enrollment?
                    </P>
                </Takeover>
            )}
            <H role="h6">{`Invitation Code: ${enrollment?.invite_code || '-'}`}</H>
            <SideBySideComparison
                organizationSubmitted={organizationSubmitted}
                contactSubmitted={contactSubmitted}
            />
            <div style={controlWrapStyles}>
                <Button
                    width="large"
                    onClick={handleApprove}
                    disabled={!enrollment}
                    styles={buttonMargin}
                >
                    Approve
                </Button>
                <Button
                    width="large"
                    onClick={handleReject}
                    disabled={!enrollment}
                    styles={buttonMargin}
                >
                    Reject
                </Button>
                <Button variant="secondary" onClick={onBack}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default EnrollmentDetails;
