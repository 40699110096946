import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { rmaTypesSelector, rmaStatusesSelector, orderSourcesSelector, returnLocationsSelector, returnStatusesSelector, refundStatusesSelector, reprocessingCheckValuesSelector, exchangeSourcesSelector, shippingOptionsSelector, rmaReasonsSelector } from '@hero/redux-data/backoffice/rmaEnums/selectors';
import useEnumOptions from './useEnumOptions';
import useGetReturnLocations from '../../../hooks/useGetReturnLocations';
const useRmaEnums = (rmaEnums) => {
    const preparedReturnLocations = useGetReturnLocations(rmaEnums);
    const rmaTypes = rmaEnums?.rma_type || useSelector(rmaTypesSelector, shallowEqual);
    const rmaStatuses = rmaEnums?.rma_status || useSelector(rmaStatusesSelector, shallowEqual);
    const orderSources = rmaEnums?.order_source || useSelector(orderSourcesSelector, shallowEqual);
    const returnLocations = rmaEnums
        ? preparedReturnLocations
        : useSelector(returnLocationsSelector, shallowEqual);
    const returnStatuses = rmaEnums?.return_status || useSelector(returnStatusesSelector, shallowEqual);
    const refundStatuses = rmaEnums?.refund_status || useSelector(refundStatusesSelector, shallowEqual);
    const reprocessingCheckValues = rmaEnums?.reprocessing_check || useSelector(reprocessingCheckValuesSelector, shallowEqual);
    const exchangeSources = rmaEnums?.exchange_source || useSelector(exchangeSourcesSelector, shallowEqual);
    const shippingOptionValues = rmaEnums?.shipping_option || useSelector(shippingOptionsSelector, shallowEqual);
    const rmaReasons = rmaEnums?.reason_code || useSelector(rmaReasonsSelector, shallowEqual);
    const rmaTypeOptions = useEnumOptions(rmaTypes);
    const rmaStatusOptions = useEnumOptions(rmaStatuses);
    const orderSourceOptions = useEnumOptions(orderSources);
    const returnLocationOptions = useEnumOptions(returnLocations);
    const returnStatusOptions = useEnumOptions(returnStatuses);
    const refundStatusOptions = useEnumOptions(refundStatuses);
    const reprocessingCheckValueOptions = useEnumOptions(reprocessingCheckValues);
    const exchangeSourceOptions = useEnumOptions(exchangeSources);
    const shippingOptionOptions = useEnumOptions(shippingOptionValues);
    const rmaReasonOptions = useMemo(() => rmaReasons
        ? Object.entries(rmaReasons).map(([key, val]) => [key, val.description])
        : [], [rmaReasons]);
    const rmaSubReasonOptions = useMemo(() => rmaReasons
        ? Object.fromEntries(Object.entries(rmaReasons).map(([key, val]) => [
            key,
            Object.entries(val.sub_codes)
        ]))
        : {}, [rmaReasons]);
    return {
        rmaTypes,
        rmaStatuses,
        orderSources,
        returnLocations,
        returnStatuses,
        refundStatuses,
        reprocessingCheckValues,
        exchangeSources,
        shippingOptionValues,
        rmaReasons,
        rmaTypeOptions,
        rmaStatusOptions,
        orderSourceOptions,
        returnLocationOptions,
        returnStatusOptions,
        refundStatusOptions,
        reprocessingCheckValueOptions,
        exchangeSourceOptions,
        shippingOptionOptions,
        rmaReasonOptions,
        rmaSubReasonOptions
    };
};
export default useRmaEnums;
