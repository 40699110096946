import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import SuppressionEmail from './SuppressionEmail';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import DefaultLayout from '../../components/DefaultLayout';

interface UtilitiesPageProps {}

const UtilitiesPage: React.FC<UtilitiesPageProps> = () => {
    const [showSuppressedModal, setShowSuppressedModal] = React.useState(false);
    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h4">Backoffice Utilities</H>
                <Container
                    gridTemplateColumns={'1fr 6fr'}
                    gridColumnGap="large"
                    gridRowGap="large"
                    styles={{ alignItems: 'center' }}
                >
                    <Button size="small" onClick={() => setShowSuppressedModal(true)}>
                        Suppressed Email
                    </Button>
                    <P noDefaultMargin>Remove User's email from suppression list</P>
                </Container>
                <SuppressionEmail
                    externalControls={[showSuppressedModal, setShowSuppressedModal]}
                />
            </Section>
        </DefaultLayout>
    );
};

export default UtilitiesPage;
