import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { removeDeviceFromOrganization } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';

type Props = {
    handleClose: () => void;
    device_id: number;
    organization_name: string;
};

const RemoveFromOrgModal: React.FC<Props> = ({ handleClose, device_id, organization_name }) => {
    const dispatch = useDispatch();
    const handleRemoveDeviceFromOrganization = useCallback(
        () => dispatch(removeDeviceFromOrganization({ id: device_id, flag: true })),
        [dispatch, device_id]
    );

    return (
        <Modal onClose={handleClose} isCancelable>
            <H role="h4">Remove from Organization</H>
            <P>Remove from this organization:</P>
            <P>{organization_name}</P>
            <Button onClick={handleRemoveDeviceFromOrganization}>Remove</Button>
        </Modal>
    );
};

export default RemoveFromOrgModal;
