import getQueryParams from '@hero/hero-utils/getQueryParams';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationDeviceMonitoringList } from '@hero/redux-data/backoffice/organizationDeviceMonitoringList/actionCreators';
import { areOrgDeviceMonitoringListLoadingSelector, orgDeviceMonitoringListListSelector, orgDeviceMonitoringListPaginationSelector } from '@hero/redux-data/backoffice/organizationDeviceMonitoringList/selectors';
import usePagerProps from '../../../hooks/usePagerProps';
import getTableColumns from './getTableColumns';
import useTableSortQuery from '../../../hooks/useTableSortQuery';
const useDeviceMonitoringData = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(areOrgDeviceMonitoringListLoadingSelector, shallowEqual);
    const deviceMonitoringList = useSelector(orgDeviceMonitoringListListSelector, shallowEqual);
    const paginationData = useSelector(orgDeviceMonitoringListPaginationSelector, shallowEqual);
    const pagerProps = usePagerProps(paginationData);
    const [sort, handleSort] = useTableSortQuery();
    const tableColumns = useMemo(() => getTableColumns(), []);
    const { offset = 0, limit = 20, filter = 'all', sort_dir = 'asc', sort_by = 'last_online', organization_id, membership_key } = getQueryParams();
    useEffect(() => {
        dispatch(fetchOrganizationDeviceMonitoringList({
            offset,
            limit,
            sort_dir,
            sort_by,
            filter,
            ...(organization_id && { organization_id: +organization_id }),
            ...(membership_key && { membership_key })
        }));
    }, [dispatch, offset, limit, sort_by, sort_dir, filter, organization_id, membership_key]);
    return {
        dispatch,
        isLoading,
        deviceMonitoringList,
        pagerProps,
        tableColumns,
        sort,
        handleSort
    };
};
export default useDeviceMonitoringData;
