import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import UsersList from './UsersList';

import UserAdherence from './UserDetails/Adherence';
import UserDetails from './UserDetails';
import UserCaregiver from './UserDetails/Caregiver';
import UserNotifications from './UserDetails/Notifications';
import { UserRouteParams } from './types';

const NavigateDetails: React.FC = () => {
    const { user_id } = useParams<UserRouteParams>();
    return <Navigate to={`/users/${user_id}/details`} replace />;
};

const UserRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path=":user_id" element={<NavigateDetails />} />
            <Route path=":user_id/details" element={<UserDetails />} />
            <Route path=":user_id/caregiver" element={<UserCaregiver />} />
            <Route path=":user_id/notifications" element={<UserNotifications />} />
            <Route path=":user_id/adherence" element={<UserAdherence />} />
            <Route path="/" element={<UsersList />} />
        </Routes>
    );
};

export default UserRoutes;
