import { Organization } from '@hero/hero-types';
import Option from '@hero/ui-kit/inputs/Option';
import Select from '@hero/ui-kit/inputs/Select';
import Container from '@hero/ui-kit/layout/Container';
import React from 'react';
import buildQuery from '@hero/hero-utils/buildQueryParams';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import omitProp from '@hero/hero-utils/omitProp';
import useSetQueryParams from '../../../hooks/useSetQueryParams';

interface FilterFieldsProps {
    organizations: Organization[];
    organizationId?: number;
    membershipKey?: string;
    hideAllFilterOption?: boolean;
}

const FilterFields: React.FC<FilterFieldsProps> = ({
    organizations,
    organizationId,
    membershipKey,
    hideAllFilterOption = false
}) => {
    const setQueryParams = useSetQueryParams();
    const params = getQueryParams<Record<string, string>>();

    const filterKeyDefaultValue = React.useMemo(() => {
        if (organizationId) {
            return 'organizations';
        }
        if (membershipKey) {
            return 'membership_key';
        }

        return hideAllFilterOption ? 'organizations' : 'all';
    }, [organizationId, membershipKey, hideAllFilterOption]);

    const [filterKey, handleFilterKeyChange] = React.useState(filterKeyDefaultValue);

    const handleMembershipKeyChange = (membership_key: string) => {
        const rest = omitProp(params, 'organization_id');
        const newQueryParams = buildQuery({
            ...rest,
            membership_key
        });

        setQueryParams(newQueryParams);
    };

    const handleOrgIdChange = (organization_id: string) => {
        const rest = omitProp(params, 'membership_key');
        const newQueryParams = buildQuery({ ...rest, organization_id });

        setQueryParams(newQueryParams);
    };

    const handleFilterChange = (value: string) => {
        const rest = omitProp(params, 'organization_id');
        const props = omitProp(rest, 'membership_key');
        handleFilterKeyChange(value);
        const newQueryParams = buildQuery(props);
        setQueryParams(newQueryParams);
    };

    const filterOptions = React.useMemo(() => {
        const filters = [
            {
                value: 'all',
                label: 'All'
            },
            {
                value: 'organizations',
                label: 'Organizations'
            },
            {
                value: 'membership_key',
                label: 'Membership Key'
            }
        ];

        return hideAllFilterOption ? filters.filter((filter) => filter.value !== 'all') : filters;
    }, [hideAllFilterOption]);

    return (
        <Container gridTemplateColumns={'1fr 1fr'} gridColumnGap="large">
            <Select
                name="filter_key"
                displayName="Filter"
                onChange={handleFilterChange}
                defaultValue={filterKeyDefaultValue}
            >
                {filterOptions.map((option) => (
                    <Option key={option.value} value={option.value} labelText={option.label} />
                ))}
            </Select>
            {filterKey === 'organizations' ? (
                <Select
                    id="org-select"
                    name="organization_id"
                    displayName="Organizations"
                    onChange={handleOrgIdChange}
                    defaultValue={organizationId ? `${organizationId}` : undefined}
                >
                    {organizations.map((org) => (
                        <Option key={org.id} value={`${org.id}`} labelText={org.name} />
                    ))}
                </Select>
            ) : null}
            {filterKey === 'membership_key' ? (
                <Select
                    id="membership_select"
                    defaultValue={membershipKey || 'super_hero'}
                    name="membership_key"
                    displayName="Membership"
                    onChange={handleMembershipKeyChange}
                >
                    <Option key={'super-hero-1'} value="super_hero" labelText={'Super Hero'} />
                    <Option
                        key={'super-hero-plus-2'}
                        value="super_hero_plus"
                        labelText={'Super Hero Plus'}
                    />
                </Select>
            ) : null}
        </Container>
    );
};

export default FilterFields;
