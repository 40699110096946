import React, { CSSProperties, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';

import InlineLoader from '../../../components/InlineLoader';
import Details from './Details';
import TabBar from '../TabBar';
import { OrganizationRouteParams } from '../types';
import getDetailViewTitle from '../utils/getDetailViewTitle';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetEnterpriseMemberDetails from '../api/useGetEnterpriseMemberDetails';
import useGetCancellationEnterpriseMembershipDetails from '../api/useGetCancellationEnterpriseMembershipDetails';

const EnterpriseMemberDetails: React.FC = () => {
    const { id: organization_id, enrollment_id: id } =
        useParams<Required<OrganizationRouteParams>>();
    const navigate = useNavigate();

    const { data: enrollmentResponse, isLoading: isFetchingEnrollment } =
        useGetEnterpriseMemberDetails({
            id: id ? +id : 0
        });

    const enrollment = enrollmentResponse?.data;
    const isCancelled = enrollment?.member?.status === 'CANCELLED';

    const { data: orgCancelDetailsResponse, isLoading: isFetchingCancelDetails } =
        useGetCancellationEnterpriseMembershipDetails({ id: id ? +id : 0 }, isCancelled);

    const orgCancellationDetails = orgCancelDetailsResponse?.data;

    const isFetching = isFetchingEnrollment || isFetchingCancelDetails;

    const handleBackToList = useCallback(() => {
        navigate(`/organizations/${organization_id}/members`, { replace: true });
    }, [navigate, organization_id]);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{getDetailViewTitle(enrollment)}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    {isFetching ? (
                        <InlineLoader />
                    ) : enrollment ? (
                        <Details
                            enrollment={enrollment}
                            orgCancellationDetails={orgCancellationDetails}
                        />
                    ) : (
                        <P>No membership data available.</P>
                    )}
                </div>
            </Section>
        </DefaultLayout>
    );
};

export default EnterpriseMemberDetails;
