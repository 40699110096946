import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isOrganizationUpdateLoadingSelector, organizationUpdateErrorSelector } from '@hero/redux-data/backoffice/organizationUpdate/selectors';
import { organizationUpdate } from '@hero/redux-data/backoffice/organizationUpdate/actionCreators';
import { useParams } from 'react-router-dom';
import useGetOrganization from '../api/useGetOrganization';
const useOrganizationDetailsData = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: organizationResponse, isLoading: areOrganizationDetailsLoading, isError: detailsError, error: detailsErrorResponse } = useGetOrganization({ id: id ? +id : 0 }, !!id);
    const organization = organizationResponse?.data;
    const detailsErrorMessage = detailsErrorResponse?.message;
    const isOrganizationUpdateLoading = useSelector(isOrganizationUpdateLoadingSelector, shallowEqual);
    const { error: updateError, errorMessage: updateErrorMessage } = useSelector(organizationUpdateErrorSelector, shallowEqual);
    const handleUpdateOrganization = useCallback(({ name }) => {
        organization &&
            dispatch(organizationUpdate({
                id: organization.id,
                external_user_id_type: organization.external_user_type,
                name
            }));
    }, [dispatch, organization]);
    const defaults = useMemo(() => ({ name: organization?.name }), [organization]);
    return {
        dispatch,
        isLoading: areOrganizationDetailsLoading || isOrganizationUpdateLoading,
        hasError: detailsError || updateError,
        errorMessage: detailsErrorMessage || updateErrorMessage,
        handleUpdateOrganization,
        name: organization?.name || '',
        defaults
    };
};
export default useOrganizationDetailsData;
