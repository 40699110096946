import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import Table from '@hero/ui-kit/tables/Table';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';

import TabBar from '../TabBar';
import EnrollmentVerificationFiltering from './EnrollmentVerificationFiltering';
import EnrollmentDetails from './EnrollmentDetails';
import { EnterpriseEnrollmentTableDataObject } from './utils/repackTableData';
import useEnrollmentVerificationStyles from './useEnrollmentVerificationStyles';
import useEnrollmentVerificationData from './useEnrollmentVerificationData';
import DefaultLayout from '../../../components/DefaultLayout';

const EnterpriseEnrollmentVerification: React.FC = () => {
    const { innerWrapPadding, tableWrapStyles, headerWrapStyles } =
        useEnrollmentVerificationStyles();
    const {
        isVerifyingEnrollment,
        organization,
        selectedEnrollment,
        handleBack,
        handleApprove,
        handleReject,
        tableColumns,
        tableData,
        isFetchingEnrollments,
        pagerProps
    } = useEnrollmentVerificationData();

    return isVerifyingEnrollment ? (
        <Loader />
    ) : (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{organization?.name || 'Organization'}</H>
                    {selectedEnrollment && (
                        <Button variant="secondary" onClick={handleBack} noDefaultMargin>
                            Back To List
                        </Button>
                    )}
                </div>
                <TabBar />
                {selectedEnrollment ? (
                    <EnrollmentDetails
                        enrollmentId={selectedEnrollment}
                        onApprove={handleApprove}
                        onReject={handleReject}
                        onBack={handleBack}
                    />
                ) : (
                    <div style={innerWrapPadding}>
                        <EnrollmentVerificationFiltering />
                        <div style={tableWrapStyles}>
                            <Table<EnterpriseEnrollmentTableDataObject>
                                columns={tableColumns}
                                data={tableData}
                                rowKey="id"
                                emptyValues="-"
                                isLoading={isFetchingEnrollments}
                                pagerProps={pagerProps}
                            />
                        </div>
                    </div>
                )}
            </Section>
        </DefaultLayout>
    );
};

export default EnterpriseEnrollmentVerification;
