import React, { CSSProperties, useMemo } from 'react';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import DeviceReturnTips from './DeviceReturnTips';
import { preparedValue } from '../../pages/Rma/utils/prepareValue';
import SuccessTransactionReport from './SuccessTransactionReport';
import useGetReturnLocations from '../../hooks/useGetReturnLocations';

type Props = {
    handleEndFlow: () => void;
};

const SuccessReport: React.FC<Props> = ({ handleEndFlow }) => {
    const {
        isOrganizationMember,
        cancellation,
        userDetails,
        membership,
        enrollment,
        deviceSerial,
        rmaPickupRequest,
        rmaEnums
    } = useCancellationStateMachine();
    const user = userDetails?.user;

    const returnLocations = useGetReturnLocations(rmaEnums);

    const firstName = isOrganizationMember
        ? enrollment?.member?.contact?.first_name || enrollment?.contact?.first_name
        : user?.first_name;
    const lastName = isOrganizationMember
        ? enrollment?.member?.contact?.last_name || enrollment?.contact?.last_name
        : user?.last_name;
    const email = isOrganizationMember
        ? enrollment?.member?.contact?.email || enrollment?.contact?.email
        : membership?.order_email;

    const rmaReturnLocation =
        returnLocations && typeof rmaPickupRequest?.return_location === 'number'
            ? returnLocations[rmaPickupRequest.return_location]
            : '-';

    const wrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'center'
    };

    const detailsStyles: CSSProperties = {
        flexBasis: '60%'
    };

    const isRmaFlow = useMemo(() => {
        const code = cancellation?.cancellation_type.code;
        return !!code && !['c1', 'ec1', 'ec3'].includes(code);
    }, [cancellation?.cancellation_type.code]);

    const returnTrackingNumber = useMemo(() => {
        if (rmaPickupRequest?.return_tracking_number) {
            return `${rmaPickupRequest.return_tracking_number}`;
        }
        if (rmaPickupRequest?.return_label?.tracking_number) {
            return `${rmaPickupRequest.return_label.tracking_number}`;
        }
        return '-';
    }, [rmaPickupRequest?.return_tracking_number, rmaPickupRequest?.return_label?.tracking_number]);

    const fieldStyles: CSSProperties = {
        inlineSize: '260px',
        overflowWrap: 'break-word'
    };

    return (
        <Section noDefaultPadding>
            <div style={wrapStyles}>
                <Container
                    gridTemplateColumns="1fr 2fr"
                    gridColumnGap="small"
                    styles={detailsStyles}
                >
                    <P noDefaultMargin strong>
                        First Name
                    </P>
                    <P noDefaultMargin styles={fieldStyles} size="small">
                        {preparedValue(firstName)}
                    </P>
                    <P noDefaultMargin strong>
                        Last Name
                    </P>
                    <P noDefaultMargin styles={fieldStyles} size="small">
                        {preparedValue(lastName)}
                    </P>
                    <P noDefaultMargin strong>
                        Email
                    </P>
                    <P noDefaultMargin styles={fieldStyles} size="small">
                        {preparedValue(email)}
                    </P>
                    <P noDefaultMargin strong>
                        Device Serial #
                    </P>
                    <P noDefaultMargin styles={fieldStyles} size="small">
                        {preparedValue(deviceSerial)}
                    </P>
                    {isRmaFlow ? (
                        <>
                            <P noDefaultMargin strong>
                                Type
                            </P>
                            <P noDefaultMargin styles={fieldStyles} size="small">
                                Cancellation
                            </P>
                            <P noDefaultMargin strong>
                                Return Location
                            </P>
                            <P noDefaultMargin styles={fieldStyles} size="small">
                                {rmaReturnLocation}
                            </P>
                            <P noDefaultMargin strong>
                                Return Tracking #
                            </P>
                            <P noDefaultMargin styles={fieldStyles} size="small">
                                {returnTrackingNumber}
                            </P>
                        </>
                    ) : null}
                    <SuccessTransactionReport cancellationData={cancellation} />
                </Container>
                {isRmaFlow ? <DeviceReturnTips /> : null}
            </div>

            <Section centered noDefaultPadding marginTop="regular">
                <Button width="large" variant="inverted" onClick={handleEndFlow}>
                    Ok
                </Button>
            </Section>
        </Section>
    );
};

export default SuccessReport;
