import React, { useCallback } from 'react';

import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useDeviceRemoteResetReboot from '../../api/useDeviceRemoteResetReboot';
import InlineLoader from '../../../../components/InlineLoader';
import QueryInlineError from '../../../../components/QueryInlineError';

type Props = {
    handleClose: () => void;
    device_id: number;
    serial: string;
    isReset?: boolean;
};

const RebootResetDeviceModal: React.FC<Props> = ({
    handleClose,
    device_id,
    serial,
    isReset = false
}) => {
    const { mutate, isError, error, isPending, isSuccess } = useDeviceRemoteResetReboot();
    const handleAction = useCallback(() => {
        mutate({ id: device_id, reset: isReset });
    }, [device_id, isReset]);

    return (
        <Modal onClose={handleClose} isCancelable type={isSuccess ? 'success' : 'neutral'}>
            {isPending ? (
                <InlineLoader />
            ) : isSuccess ? (
                <>
                    <H role="h5" centered>{`Successful reboot ${
                        isReset ? '& reset' : ''
                    } device for device:`}</H>
                    <P>{serial}</P>
                    <Button onClick={handleClose}>Close</Button>
                </>
            ) : (
                <>
                    <H role="h4">{`Reboot ${isReset ? '& Reset' : ''} device`}</H>
                    <P>{`Reboot ${isReset ? '& Reset' : ''} device with serial:`}</P>
                    <P>{serial}</P>
                    <Button onClick={handleAction}>Submit</Button>
                    {isError ? <QueryInlineError error={error} /> : null}
                </>
            )}
        </Modal>
    );
};

export default RebootResetDeviceModal;
