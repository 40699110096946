import React from 'react';
import { List, ListItem } from '../../components/BulletedList';

type Props = {
    isExchange?: boolean;
};

const DeviceReturnTips: React.FC<Props> = ({ isExchange = false }) => {
    return (
        <List styles={{ flexBasis: '50%' }}>
            {!isExchange ? (
                <>
                    <ListItem>
                        Email has been sent to customer with repacking and shipping label
                    </ListItem>
                    <ListItem>Must return Hero within 30 days</ListItem>
                    <ListItem>
                        Use factory reset to get their medication and make sure travel bar is
                        installed
                    </ListItem>
                    <ListItem>Use original Hero packaging to ship</ListItem>
                    <ListItem>Drop off at nearest courier location</ListItem>
                </>
            ) : (
                <>
                    <ListItem>
                        Email has been sent to customer with repacking instructions and return
                        shipping label
                    </ListItem>
                    <ListItem>
                        Once they receive new Hero use the transfer function in the Hero app to move
                        medication between devices
                    </ListItem>
                    <ListItem>Use original Hero packaging to ship Hero within 30 days</ListItem>
                    <ListItem>Drop off at nearest courier location</ListItem>
                </>
            )}
        </List>
    );
};

export default DeviceReturnTips;
