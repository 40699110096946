import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEnrollmentCreateLoadingSelector, isEnrollmentCreateUpdatedSelector, enrollmentCreateErrorSelector } from '@hero/redux-data/backoffice/enrollmentCreate/selectors';
import { enrollmentCreate, enrollmentCreateReset } from '@hero/redux-data/backoffice/enrollmentCreate/actionCreators';
const useOrganizationEnrollmentData = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const isLoading = useSelector(isEnrollmentCreateLoadingSelector, shallowEqual);
    const isUpdated = useSelector(isEnrollmentCreateUpdatedSelector, shallowEqual);
    const { error, errorMessage } = useSelector(enrollmentCreateErrorSelector, shallowEqual);
    const handleEnrollmentCreate = useCallback((params) => {
        dispatch(enrollmentCreate({ ...params, organization_id: +id, status: 0 }));
    }, [dispatch, id]);
    const handleEnrollmentReset = useCallback(() => {
        dispatch(enrollmentCreateReset());
    }, [dispatch]);
    useEffect(() => {
        return () => {
            // dispatch(enrollmentCreateReset());
        };
    });
    return {
        dispatch,
        isLoading,
        isUpdated,
        hasError: error,
        errorMessage,
        handleEnrollmentCreate,
        handleEnrollmentReset
    };
};
export default useOrganizationEnrollmentData;
