import React, { CSSProperties, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
    fetchMembershipPlans,
    membershipPlansReset
} from '@hero/redux-data/backoffice/membershipPlans/actionCreators';
import {
    areMembershipPlansLoadingSelector,
    membershipPlansErrorSelector,
    activeMembershipPlansSelector
} from '@hero/redux-data/backoffice/membershipPlans/selectors';
import { currentPlanSelector } from '@hero/redux-data/backoffice/membershipDetails/selectors';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Form from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import H from '@hero/ui-kit/typography/H';
import editPlanSchema, { EditPlanParams } from '@hero/validators/forms/backoffice/editPlanSchema';

import InlineLoader from '../../../components/InlineLoader';
import getSubscriptionPlanLabel from '../utils/getSubscriptionPlanLabel';

type Props = {
    onSubmit: (formValues: EditPlanParams) => void;
} & Pick<ModalProps, 'externalControls'>;

// used plans by storefront key: https://herohealth.atlassian.net/browse/HWM-12931
const usedPlansByMX = [
    'unlimited-free',
    'legacy-unlimited-free',
    'basic-extended-trial',
    'basic',
    'annual',
    'commitment-monthly-12months',
    'biannual',
    'commitment-monthly-24months'
];

const EditPlanModal: React.FC<Props> = ({ onSubmit, externalControls }) => {
    const dispatch = useDispatch();
    const isFetchingPlans = useSelector(areMembershipPlansLoadingSelector);
    const { error } = useSelector(membershipPlansErrorSelector, shallowEqual);
    const plans = useSelector(activeMembershipPlansSelector, shallowEqual);
    const currentPlan = useSelector(currentPlanSelector, shallowEqual);

    const defaultValues = useMemo(
        () => ({
            plan_id: null
        }),
        [currentPlan]
    );

    const [, setExternalState] = externalControls ?? [];

    useEffect(() => {
        dispatch(fetchMembershipPlans());

        return () => {
            dispatch(membershipPlansReset());
        };
    }, [dispatch]);

    useEffect(() => {
        // in case of a data fetching error, simply close the modal
        // and let the appropriate notification show up
        error && setExternalState && setExternalState(false);
    }, [error, setExternalState]);

    const plansForOptionList = useMemo(
        () =>
            plans
                .filter((plan) => usedPlansByMX.includes(`${plan?.storefront_key || ''}`))
                .map((plan) => {
                    const label = getSubscriptionPlanLabel(plan);
                    return { ...plan, label };
                }),
        [plans]
    );

    const handleCancel = useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const processedOptions = plansForOptionList.map(({ plan_id, label }) => (
        <Option key={plan_id} value={String(plan_id)} labelText={label} />
    ));

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    return (
        <Modal externalControls={externalControls} isCancelable>
            <div style={innerWrapperStyles}>
                <H role="h4" centered>
                    Update Plan
                </H>
                {isFetchingPlans ? (
                    <InlineLoader />
                ) : (
                    <Form
                        validationSchema={editPlanSchema}
                        defaultValues={defaultValues}
                        submitFn={onSubmit}
                        render={(_, formState) => (
                            <>
                                <Container>
                                    <Select
                                        name="plan_id"
                                        displayName="Subscription Plan"
                                        visibleLimit={5}
                                        noExtraMargin
                                    >
                                        {processedOptions}
                                    </Select>
                                </Container>
                                <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                                    <Button
                                        width="large"
                                        type="submit"
                                        disabled={!formState.isValid}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        width="large"
                                        variant="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Section>
                            </>
                        )}
                    />
                )}
            </div>
        </Modal>
    );
};

export default EditPlanModal;
