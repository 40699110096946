import useTheme from '@hero/branding/theme';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Radio from '@hero/ui-kit/inputs/Radio';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import mitigationOffersSchema from './validator/mitigationOffersSchema';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import { useCancellationStateMachine } from './context/cancellationStateMachine';

const FormElements: React.FC<{ continueCancellation: () => void }> = ({ continueCancellation }) => {
    const { borders, colors } = useTheme();
    const {
        formState: { isValid }
    } = useFormContext();

    const radioStyle: React.CSSProperties = {
        padding: '1rem'
    };

    const border: React.CSSProperties = {
        ...borders.bottom,
        ...borders.variant.secondary,
        borderWidth: 2,
        borderColor: colors.neutrals.borderAlpha
    };
    return (
        <>
            <H role="h6">{`Let's encourage keeping Hero!`}</H>
            <P>Select the plan the members agrees to</P>
            <RadioGroup
                name="mitigation_offers"
                displayName=""
                noBorders
                styles={{ marginBottom: '0' }}
            >
                <Radio
                    id="regular-plans"
                    value="regular-plans"
                    labelText="Regular plans"
                    styles={{ ...radioStyle, ...border }}
                />
                <Radio
                    id="hardship-offers"
                    value="hardship-offers"
                    labelText="Hardship offers"
                    styles={{ ...radioStyle, ...border }}
                />
            </RadioGroup>
            <Section centered>
                <Button type="submit" width="large" disabled={!isValid}>
                    Next
                </Button>
                <Button type="button" variant="secondary" onClick={continueCancellation}>
                    Continue Cancellation
                </Button>
            </Section>
        </>
    );
};

const StepMitigationOffers: React.FC = () => {
    const { continueCancellation, handleChooseMitigationOffer } = useCancellationStateMachine();

    return (
        <Form validationSchema={mitigationOffersSchema} submitFn={handleChooseMitigationOffer}>
            <FormElements continueCancellation={continueCancellation} />
        </Form>
    );
};

export default StepMitigationOffers;
