import React from 'react';

import { HazardousDrugCheckResult, ManuallyEnteredMedication } from '@hero/hero-types';
import Table from '@hero/ui-kit/tables/Table';

import tableColumns from './tableColumns';

type Props = Pick<HazardousDrugCheckResult, 'manually_entered_medications'>;

const ManuallyEnteredMedications: React.FC<Props> = ({ manually_entered_medications }) => {
    return (
        <Table<ManuallyEnteredMedication>
            columns={tableColumns}
            data={manually_entered_medications}
            rowKey="slot"
        />
    );
};

export default ManuallyEnteredMedications;
