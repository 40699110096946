import React from 'react';
import TabBar from '../TabBar';
import Section from '@hero/ui-kit/layout/Section';
import UserHeader from '../UserHeader';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    userDeviceDetailsSelector,
    areUserDeviceDetailsLoadingSelector
} from '@hero/redux-data/backoffice/userDeviceDetails/selectors';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useTheme from '@hero/branding/theme';
import InlineLoader from '../../../../components/InlineLoader';
import { userSetPhone } from '@hero/redux-data/backoffice/userSetPhone/actionCreators';
import {
    userSetPhoneErrorSelector,
    isUserSetPhoneLoadingSelector
} from '@hero/redux-data/backoffice/userSetPhone/selectors';
import { userConfirmPhone } from '@hero/redux-data/backoffice/userConfirmPhone/actionCreators';
import {
    userConfirmPhoneErrorSelector,
    isUserConfirmPhoneLoadingSelector
} from '@hero/redux-data/backoffice/userConfirmPhone/selectors';
import { sentUserTestNotification } from '@hero/redux-data/backoffice/userSendTestNotification/actionCreators';
import {
    userSendTestNotifcationErrorSelector,
    isUserSendTestNotifcationLoadingSelector
} from '@hero/redux-data/backoffice/userSendTestNotification/selectors';
import { userDeletePhone } from '@hero/redux-data/backoffice/userDeletePhone/actionCreators';
import {
    userDeletePhoneErrorSelector,
    isUserDeletePhoneLoadingSelector
} from '@hero/redux-data/backoffice/userDeletePhone/selectors';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import DefaultLayout from '../../../../components/DefaultLayout';

const UserNotifications: React.FC = () => {
    const dispatch = useDispatch();
    const marginBottom = useComponentMargin({ marginBottom: 'regular' });
    const data = useSelector(userDeviceDetailsSelector, shallowEqual);
    const { error: isSetPhoneError, errorMessage: setPhoneErrorMessage } = useSelector(
        userSetPhoneErrorSelector,
        shallowEqual
    );
    const isSetPhoneLoading = useSelector(isUserSetPhoneLoadingSelector, shallowEqual);
    const { error: isConfirmPhoneError, errorMessage: confirmPhoneErrorMessage } = useSelector(
        userConfirmPhoneErrorSelector,
        shallowEqual
    );
    const isUserConfirmPhoneLoading = useSelector(isUserConfirmPhoneLoadingSelector, shallowEqual);
    const { error: isDeletePhoneError, errorMessage: deletePhoneErrorMessage } = useSelector(
        userDeletePhoneErrorSelector,
        shallowEqual
    );
    const isUserDeletePhoneLoading = useSelector(isUserDeletePhoneLoadingSelector, shallowEqual);
    const { error: isSendTestNotificationError, errorMessage: sendTestNotificationErrorMessage } =
        useSelector(userSendTestNotifcationErrorSelector, shallowEqual);
    const isUserSendTestNotifcationLoading = useSelector(
        isUserSendTestNotifcationLoadingSelector,
        shallowEqual
    );
    const isLoading = useSelector(areUserDeviceDetailsLoadingSelector, shallowEqual);
    const theme = useTheme();
    const [mobile, setMobile] = React.useState('');
    const { user } = data ?? {};
    const changedMobile = user?.change_mobile_to;
    const mobileSet = user?.mobile;

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setMobile(newValue);
    }, []);

    const handleSetPhone = React.useCallback(() => {
        mobile.length &&
            user?.id &&
            dispatch(
                userSetPhone({
                    id: user.id,
                    phone: mobile,
                    message: 'Phone changed via Backoffice admin'
                })
            );
    }, [mobile, dispatch, user]);

    const mobileNotSetTemplate = React.useMemo(() => {
        return (
            <Section subtheme={['regular', 'background']} paddingVertical="regular">
                <H role="h6">Mobile number isn't set.</H>
                <P>Phone No:</P>
                <input
                    name="mobile"
                    onChange={handleChange}
                    value={mobile}
                    placeholder="Insert mobile number"
                    style={{
                        ...theme.inputs.base,
                        ...theme.inputs.shape.pill,
                        ...theme.inputs.size.regular,
                        ...marginBottom
                    }}
                />
                <div>
                    <Button
                        disabled={mobile.length === 0 || isSetPhoneLoading}
                        size="small"
                        onClick={handleSetPhone}
                    >
                        Submit
                    </Button>
                </div>
            </Section>
        );
    }, [handleChange, handleSetPhone, theme, mobile, marginBottom, isSetPhoneLoading]);

    const handleMobileConfirmChange = React.useCallback(() => {
        user?.id && dispatch(userConfirmPhone({ id: user.id }));
    }, [dispatch, user]);

    const mobileConfirmTemplate = React.useMemo(() => {
        return (
            <Section subtheme={['regular', 'background']} paddingVertical="regular">
                <P>{`Phone number change request sent to number: ${changedMobile}`}</P>
                <Button
                    size="small"
                    disabled={isUserConfirmPhoneLoading}
                    onClick={handleMobileConfirmChange}
                >
                    Confirm Change
                </Button>
            </Section>
        );
    }, [changedMobile, handleMobileConfirmChange, isUserConfirmPhoneLoading]);

    const handleMobileSendNotification = React.useCallback(() => {
        user?.id && dispatch(sentUserTestNotification({ id: user.id }));
    }, [dispatch, user]);

    const handleMobileDelete = React.useCallback(() => {
        user?.id && dispatch(userDeletePhone({ id: user.id }));
    }, [dispatch, user]);

    const mobileSetTemplate = React.useMemo(() => {
        return (
            <Section subtheme={['regular', 'background']} paddingVertical="regular">
                <P>{`Phone number: ${mobileSet}`}</P>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        size="small"
                        disabled={isUserDeletePhoneLoading}
                        onClick={handleMobileDelete}
                    >
                        Delete Phone
                    </Button>
                    <Button
                        disabled={isUserSendTestNotifcationLoading}
                        styles={{ marginLeft: '1rem' }}
                        size="small"
                        onClick={handleMobileSendNotification}
                    >
                        Send
                    </Button>
                </div>
            </Section>
        );
    }, [
        handleMobileDelete,
        mobileSet,
        handleMobileSendNotification,
        isUserSendTestNotifcationLoading,
        isUserDeletePhoneLoading
    ]);

    const errors = React.useMemo(() => {
        if (isSetPhoneError) {
            return (
                <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                    {setPhoneErrorMessage}
                </P>
            );
        }

        if (isConfirmPhoneError) {
            return (
                <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                    {confirmPhoneErrorMessage}
                </P>
            );
        }

        if (isDeletePhoneError) {
            return (
                <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                    {deletePhoneErrorMessage}
                </P>
            );
        }

        if (isSendTestNotificationError) {
            return (
                <P strong styles={{ color: theme.colors.status.fail.alpha }}>
                    {sendTestNotificationErrorMessage}
                </P>
            );
        }

        return null;
    }, [
        theme,
        isSetPhoneError,
        setPhoneErrorMessage,
        confirmPhoneErrorMessage,
        isConfirmPhoneError,
        isDeletePhoneError,
        deletePhoneErrorMessage,
        isSendTestNotificationError,
        sendTestNotificationErrorMessage
    ]);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <UserHeader />
                <TabBar />
                {isLoading ? (
                    <InlineLoader />
                ) : (
                    <>
                        <Section subtheme={['regular', 'background']} paddingHorizontal="regular">
                            <>
                                <H role="h4" noDefaultMargin>
                                    Web
                                </H>
                                {!mobileSet && !changedMobile ? mobileNotSetTemplate : null}
                                {changedMobile ? mobileConfirmTemplate : null}
                                {mobileSet ? mobileSetTemplate : null}
                            </>
                            {errors}
                        </Section>
                        <Section subtheme={['regular', 'background']} paddingHorizontal="regular">
                            <>
                                <H role="h4" noDefaultMargin>
                                    App
                                </H>
                                <Section
                                    subtheme={['regular', 'background']}
                                    paddingVertical="regular"
                                >
                                    <H role="h6">Status:</H>
                                    <P>Not Installed</P>
                                </Section>
                            </>
                        </Section>
                    </>
                )}
            </Section>
        </DefaultLayout>
    );
};

export default UserNotifications;
