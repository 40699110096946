import React, { CSSProperties } from 'react';

import SearchForm from '../../../components/SearchForm';
import PageSizeForm from '../../../components/PageSizeForm';

const headerWrapStyles: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
};

const MembershipMonitoringFiltering: React.FC = () => (
    <div style={headerWrapStyles}>
        <SearchForm placeholder="Search by email, customer ID or serial #" />
        <PageSizeForm />
    </div>
);

export default MembershipMonitoringFiltering;
