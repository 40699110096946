import axios from 'axios';

import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';

type Payload = {
    user_id: number;
    lead_id: string;
    provider_id: string | null;
    is_tcpa_opt_in: boolean;
    contact_phone: string;
    email: string;
    caregiver_first_name?: string;
    caregiver_last_name?: string;
    consent_sign?: boolean;
};

export type Response = {
    data: {
        lead_id: string;
        inside_working_hours: false;
    };
};

const useElectionComplete = () => {
    const params = useQueryRequestParams();

    return (payload: Payload) => {
        return axios.post<Response>(
            `${envVars.API_CLOUD_HOSTNAME}backoffice/election-select-provider/`,
            payload,
            params
        );
    };
};

export default useElectionComplete;
